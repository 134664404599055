.donutWrapper {
  position: relative;
  width: 623px;
  height: 550px;
  background-image: url("https://cdn.builder.io/api/v1/image/assets%2F2dcadbf627b34cb5811ae15b698d7ebb%2Fa93fb25f4c6447a4a4783b7433288994");
  background-size: cover;
  background-position: center;
}

.donutLeft {
  position: absolute;
  top: 0;
  left: 0;
  width: auto;
  height: 100%;
  background: none;
  border: none;
  cursor: pointer;
  margin: 0;
  padding: 0;
}

.donutLeft img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.donutLeft img:hover {
  opacity: 0.5;
  transition: opacity 0.3s ease-in-out;
}

.donutCenter {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 329px;
  height: auto;
  z-index: 1;
  border-radius: 100%;
  background: none;
  border: none;
  cursor: pointer;
  margin: 0;
  padding: 0;
}

.donutCenter img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.donutCenter img:hover {
  opacity: 0.5;
  transition: opacity 0.3s ease-in-out;
}

.donutRight {
  position: absolute;
  top: 0;
  right: 0;
  width: auto;
  height: 100%;
  background: none;
  border: none;
  cursor: pointer;
  margin: 0;
  padding: 0;
}

.donutRight img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.donutRight img:hover {
  opacity: 0.5;
  transition: opacity 0.3s ease-in-out;
}
