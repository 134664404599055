.categoryStructureFramework {
  position: relative;
  display: flex;
  padding-bottom: 40px;
  flex-direction: column;
  align-items: center;
  width: 1224px;
  isolation: isolate;
  margin-top: 60px;
}

.heading {
  color: #FFF;
  text-align: center;
  font-size: 28px;
  font-style: normal;
  font-weight: 700;
  line-height: 130%;
  margin-bottom: 40px;
}

.tripGrid {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(100px, 1fr));
  gap: 20px;
  width: 100%;
  max-width: 1513px;
  margin-top: 15px;
  padding: 0 20px;
}

@media (max-width: 991px) {
  .categoryStructureFramework {
    padding-bottom: 100px;
  }
}
