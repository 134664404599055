.yellowGradBg {
  background: linear-gradient(
    190deg,
    rgba(241, 185, 26, 0.425) -3%,
    rgba(0, 0, 0, 0) 60%
  );
  display: flex;
  min-height: 440px;
  margin-bottom: -390px;
  margin-top: 120px;
}
