.anchorBar {
  position: fixed;
  top: 50px;
  left: 0;
  right: 0;
  display: flex;
  width: 100%;
  flex-wrap: wrap;
  padding: 0;
  margin: 0;
  z-index: 1;

  @media (max-width: 991px) {
    max-width: 100%;
    margin-bottom: 0;
  }
}

.mainNavAtomAnchorBarLink {
  border-radius: 2px;
  display: flex;
  align-items: center;
  min-width: 240px;
  gap: 4px;
  justify-content: center;
  height: 100%;
  padding: 8px 20px 8px 100px;

  @media (max-width: 991px) {
    padding-left: 20px;
  }
}

.navIcon {
  color: rgba(0, 0, 0, 0.5);
  aspect-ratio: 1;
  object-fit: contain;
  object-position: center;
  width: 24px;
  align-self: stretch;
  margin: auto 0;
}

.subNav {
  display: flex;
  min-width: 240px;
  padding-left: 30px;
  margin: 0;
  justify-content: flex-start;
  flex-wrap: wrap;
  height: auto;
  flex: 1;
  flex-basis: 100px;

  @media (max-width: 991px) {
    max-width: 100%;
  }
}