.container {
  position: relative;
  color: #fff;
  text-align: center;
  padding: 40px 0;
  font: 600 31px Work Sans, sans-serif;
  width: 575px;
  min-height: 200px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.highlight {
  color: #f5df42;
}

@media (max-width: 991px) {
  .container {
    max-width: 100%;
    padding: 0 20px;
  }
}

.card {
  border-radius: 0 0 16px 16px;
  background: rgba(255, 255, 255, 0.16);
  box-shadow: 0 0 4px 0 rgba(0, 0, 0, 0.16);
  position: absolute;
  inset: 0;
}

.takeawayText {
  max-width: 650px;
  margin: 0 auto;
  padding: 0 25px;
}

.divider {
  margin: 20px auto;
  width: 95%;
  border: 1px solid rgba(255, 255, 255, 0.5);
  background-color: rgba(255, 255, 255, 0.5);
}

@media (max-width: 991px) {
  .divider {
    max-width: 100%;
  }
}
