.sectionGroup {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 1224px;
  margin: 0 auto;
}

.paternCardGroup {
  display: flex;
  width: 100%;
  align-items: center;
  gap: 20px;
  text-align: center;
  justify-content: center;
  flex-wrap: wrap;
}

.spendBreakdownCard {
  position: relative;
  display: flex;
  margin-top: 40px;
  width: 100%;
  align-items: center;
  gap: 40px 60px;
  justify-content: center;
  padding: 50px 32px;
}

.card {
  border-radius: 16px;
  align-self: start;
  position: absolute;
  z-index: 0;
  display: flex;
  width: 1224px;
  height: 100%;
  justify-content: center;
  align-items: center;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  border: 1px solid rgba(20, 20, 20, 0.08);
}

.breakdownCard {
  background-color: #F5DF42
}

.categoriesCard {
  background-color: #2E2E2E;
  height: 100%;
}

.titleGroup {
  display: flex;
  align-items: center;
  gap: 20px;
}

.icon {
  aspect-ratio: 1.12;
  object-fit: contain;
  object-position: center;
  width: 90px;
  z-index: 0;
}

.title {
  color: #000;
  font: 700 36px/1 Barlow, sans-serif;
  z-index: 0;
}

.statsContainer {
  display: flex;
  align-items: start;
  text-align: center;
  justify-content: space-between;
  flex-wrap: wrap;
  width: 525px;
}

.categoryBreakdown {
  position: relative;
  display: flex;
  margin-top: 26px;
  width: 100%;
  align-items: center;
  gap: 40px 60px;
  font-family: Work Sans, sans-serif;
  font-weight: 600;
  justify-content: center;
  padding: 40px 30px;
}

.header {
  text-align: center;
  color: #fff;
  font-size: 36px;
  line-height: 1;
  padding: 0 60px;
  z-index: 0;
}

.header .title {
  color: #fff;
}

.tableContainer {
  display: flex;
  flex-direction: column;
  overflow: hidden;
  font-size: 24px;
  justify-content: start;
  align-items: center;
  margin: auto 0;
  flex: 1;
  padding: 0px 40px 0 0;
  z-index: 0;
}

.tableTitle {
  color: #fff;
  font-family: "Work Sans", sans-serif;
  font-size: 24px;
  font-style: normal;
  font-weight: 600;
  margin-top: 0;
  z-index: 0;
}

.tableHeader {
  display: flex;
  width: 100%;
  align-items: start;
  gap: 40px 100px;
  font-size: 20px;
  color: #f6e003;
  white-space: nowrap;
  justify-content: space-between;
  flex-wrap: wrap;
}

.columnStats {
  display: flex;
  align-items: center;
  gap: 16px;
  justify-content: space-between;
  width: 120px;
}

.footnote {
  color: #fff;
  text-align: right;
  margin-top: 20px;
  font: italic 500 14px/1 Work Sans, -apple-system, Roboto, Helvetica, sans-serif;
  width: 100%;
  align-self: flex-end;
}

@media (max-width: 991px) {
  .paternCardGroup,
  .spendBreakdownCard,
  .card,
  .statsContainer,
  .categoryBreakdown,
  .tableContainer,
  .tableHeader,
  .footnote {
    max-width: 100%;
  }
  
  .spendBreakdownCard,
  .categoryBreakdown {
    padding: 0 20px;
  }
}