.contentCard {
  display: flex;
  flex-direction: column;
  position: relative;
  height: 300px;
  justify-content: end;
  flex: 1;
  color: #fff;
}

.cardImage {
  border-radius: 16px;
  position: absolute;
  height: 300px;
  width: 100%;
  object-fit: cover;
}

.textContent {
  position: relative;
  width: 82%;
  border-radius: 16px;
  background-color: rgba(29, 29, 29, 1);
  padding: 10px;
  margin: 0 0 20px 20px;
}

.highlightText {
  color: #f5df42;
}

@media (max-width: 991px) {
  .contentCard {
    max-width: 100%;
    padding-top: 100px;
  }
}