@use '../theme';

.image-card-container {
    display: flex;
    height: 100%;
    width: 100%;
    align-items: center;
    justify-content: center;
    gap: 10px;
    flex: 1 0 0;
    // border-radius: 1rem;

    // background-position: center;
    // background-size: cover;
    // background-repeat: no-repeat;

    .image-card-visual {
        height: 100%;
        width: 100%;
        border-radius: 1rem;
        object-fit: cover;
    }
}