@use "theme";

.content {

    .tnc-container{
        display: flex;
        //max-width: 38.75rem;
        flex-direction: column;
        justify-content: space-around;
        align-items: center;
        //gap: 2.5rem;
        //border-radius: 1.5rem;
        //background: var(--grayscale-white, theme.$white);
        //box-shadow: 0 0.5rem 1.25rem 0 rgba(0, 0, 0, 0.25);

        .tnc-container-item {
            border-radius: 1.5rem;
            background: var(--grayscale-white, theme.$white);
            box-shadow: 0 0.5rem 1.25rem 0 rgba(0, 0, 0, 0.25);
            .tnc-content {
                display: flex;
                flex-direction: column;
                align-items: center;
                
                margin: var(--Spacing-M, 2.5rem) var(--Spacing-L, 3.75rem);
                max-width: 30rem;
                gap: 2.5rem;

                .tnc-heading {
                    display: flex;
                    flex-direction: row;
                    align-items: center;
                    justify-content: center;
                    gap: 2.5rem;
                    align-self: stretch;
    
                    .heading{
                        color: var(--grayscale-black, theme.$black);
                        font-family: Zuume-Bold;
                        font-size: 30px;
                        font-style: normal;
                        line-height: 100%; /* 30px */
                        letter-spacing: 0.6px;
                        text-transform: uppercase;
                    }
                }
    
                .tnc-body{
                    display: flex;
                    //width: 31.25rem;
                    //max-height: 17.625rem;
                    flex-direction: column;
                    justify-content: flex-start;
                    align-items: flex-start;
                    gap: 0.5rem;
                    overflow-y: auto;

                    .tnc-subline {
                        color: var(--grayscale-black, theme.$black);
                        font-family: Inter-Bold;
                        font-size: 20px;
                        font-style: normal;
                        line-height: 130%; /* 20.8px */
                        -webkit-font-smoothing: antialiased;
                        @media screen and (width < 100rem) {
                            font-size: 16px;
                         }
                    }
    
                    .tnc-points {
                        color: var(--grayscale-black, theme.$black);
                        font-family: Inter-Regular;
                        font-size: 16px;
                        font-style: normal;
                        font-weight: 400;
                        -webkit-font-smoothing: antialiased;
                        line-height: 130%; /* 20.8px */
                        opacity: 0.85;
                        @media screen and (width < 100rem) {
                            font-size: 13px;
                         }
                    }
    
                    .tnc-disclaimer {
                        color: var(--Grayscale-Black, theme.$black);
                        font-family: Inter-Italic;
                        font-size: 13px;
                        font-style: italic;
                        font-weight: 400;
                        line-height: 130%; /* 16.9px */
                        opacity: 0.65;
                    }
                }
    
                .tnc-actions-items{
                    display: flex;
                    // padding: 0px 4rem;
                    justify-content: space-between;
                    align-items: flex-start;
                    align-self: stretch;
    
                    .tnc-button{
                        display: flex;
                        height: 2.5rem;
                        flex-direction: column;
                        justify-content: center;
                        align-items: center;
                        gap: 0.5rem;
                        border-radius: 6.25rem;
                        color: theme.$black;
                        
                        font-family: Inter-Regular;
                        font-size: 14px;
                        font-style: normal;
                        font-weight: 600;
                        line-height: 20px; /* 142.857% */
                        letter-spacing: 0;
                        text-transform: none;

                        &.accept {
                            background: var(--Actions, theme.$ab-yellow);
                        }
    
                        &.decline {
                            background: var(--Actions, theme.$white);
                        }
    
                    }
                }
            }
        }
    }
}
