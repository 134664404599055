.statsContainer {
  display: flex;
  flex-direction: column;
  gap: 20px;
  flex: 1;
}

.statBlock {
  background: rgba(29, 29, 29, 1);
  border-radius: 16px;
  display: flex;
  flex: 1;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 10px;
}

.statContent {
  display: flex;
  align-items: center;
  gap: 20px;
}

.amount {
  color: #fff;
  font-size: 48px;
  font-family: Barlow, sans-serif;
  font-weight: 700;
  line-height: 1;
}

.index {
  color: #fff;
  padding: 4px 8px;
  border-radius: 4px;
  font-size: 36px;
  font-family: Barlow, sans-serif;
  font-weight: 700;
}

.label {
  color: #fff;
  font-size: 24px;
  font-family: Work Sans, sans-serif;
  font-weight: 600;
}

@media (max-width: 991px) {
  .statBlock {
    padding: 20px;
  }
  
  .amount {
    font-size: 40px;
  }
}
