.associationCards {
  display: flex;
  align-items: center;
  gap: 26px;
  color: #000;
  text-align: center;
  text-transform: uppercase;
  flex-wrap: wrap;
  font: 700 36px Barlow, sans-serif;
  margin: 20px auto;
  justify-content: space-between;
  max-width: 1224px;
  width: 100%;
}
