.section {
  display: flex;
  flex-direction: column;
  justify-content: start;
  width: 1224px;
  margin: 0 auto;
}

.header {
  display: flex;
  flex-direction: column;
  align-items: start;
  font-family: Barlow, sans-serif;
  font-weight: 700;
  text-transform: uppercase;
}

.subtitle {
  color: #f6e003;
  font-size: 24px;
}

.title {
  color: #fff;
  font-size: 48px;
  line-height: 1;
  margin: 10px 0 30px;
}

.cardGrid {
  display: flex;
  flex-wrap: wrap;
  gap: 20px;
}

.demographicIcon {
  width: 90%;
  height: auto;
  margin: 10px auto;
}

.footer {
  color: #fff;
  font-style: italic;
  font-size: 14px;
  line-height: 0.5;
  margin-top: 20px;
  text-align: right;
}

@media (max-width: 991px) {
  .title {
    font-size: 40px;
    max-width: 100%;
  }

  .cardGrid {
    max-width: 100%;
  }
}
