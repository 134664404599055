.testimonialSection {
  display: flex;
  flex-direction: column;
}

.sectionHeader {
  display: flex;
  flex-direction: column;
  width: 1224px;
  margin: 0 auto;
}

.subheading {
  color: #f6e003;
  font-size: 24px;
  font-family: Barlow, sans-serif;
  font-weight: 700;
  text-transform: uppercase;
  margin: 0;
}

.heading {
  color: #fff;
  font-size: 48px;
  font-family: Barlow, sans-serif;
  font-weight: 700;
  text-transform: uppercase;
  line-height: 1;
  margin: 10px 0 30px;
}

.testimonialContainer {
  width: 100%;
  overflow: hidden;
}

.testimonialGrid {
  display: flex;
  width: calc(100vw - ((100vw - 1224px) / 2));
  margin-left: calc((100vw - 1224px) / 2);
  gap: 20px;
  margin-bottom: 30px;
  overflow-x: scroll;
  scroll-behavior: smooth;
  -webkit-overflow-scrolling: touch;
  scrollbar-width: none;
  -ms-overflow-style: none;
  cursor: grab;
  user-select: none;
}

.testimonialGrid:active {
  cursor: grabbing;
}

.cartImages {
  display: flex;
  width: calc(100vw - ((100vw - 1224px) / 2));
  margin-left: calc((100vw - 1224px) / 2);
  gap: 20px;
  overflow-x: scroll;
  scroll-behavior: smooth;
  -webkit-overflow-scrolling: touch;
  scrollbar-width: none;
  -ms-overflow-style: none;
  cursor: grab;
  user-select: none;
}

.cartImages:active {
  cursor: grabbing;
}

.cartImages::-webkit-scrollbar,
.testimonialGrid::-webkit-scrollbar {
  display: none;
}

.cartImage {
  height: 260px;
  width: 305px;
  object-fit: cover;
  border-radius: 16px;
  pointer-events: none;
  user-select: none;
  -webkit-user-drag: none;
}

@media (max-width: 991px) {
  .testimonialSection {
    padding-top: 100px;
  }
  
  .heading {
    font-size: 40px;
  }
  
  .testimonialContainer,
  .footerImage {
    max-width: 100%;
  }
}
