$main-nav-height: 3.125rem;
$anchor-nav-height: 2.5rem;
$runner-height: 4rem;
$disclaimer-height: 3.5rem;
$max-view-height: calc(100vh - $main-nav-height - $anchor-nav-height);

$ab-yellow: #f6e003;
$ab-dark-purple: #1c1737;

//status
$positive: #309427;
$neutral: #434343;
$negative: #cb4242;

//Login
$login-logo-gradient: radial-gradient(
  659.2% 130.7% at 3.36% 5.88%,
  #f1f603 0%,
  #f6e003 51.04%,
  #ff7a00 100%
);

//People Segment
$comfort-craver: #b983ff;
$loyal-traditionalist: #ada1f8;
$carefree-fun-lover: #8fe8bd;
$proud-celebrator: #79d271;
$creative-explorer: #a1e8f8;
$social-curator: #83bcff;
$balanced-enjoyers: #ffc759;
$diligent-discoverer: #ff8811;
$familiar-reserved: linear-gradient(100deg, #ada1f8 0%, #b983ff 100%);
$familiar-reserved-dark: linear-gradient(180deg, #322b59 0%, #000000 25%);
$familiar-outgoing: linear-gradient(100deg, #8fe8bd 0%, #79d271 100%);
$familiar-outgoing-dark: linear-gradient(180deg, #2a5653 0%, #000000 25%);
$exploratory-outgoing: linear-gradient(100deg, #a1e8f8 0%, #83bcff 100%);
$exploratory-outgoing-dark: linear-gradient(180deg, #1d2846 0%, #000000 25%);
$exploratory-reserved: linear-gradient(100deg, #ffc759 0%, #ff8811 100%);
$exploratory-reserved-dark: linear-gradient(180deg, #542323 0%, #000000 25%);

$reserved-familiar-gradient-light: linear-gradient(
  to right,
  $comfort-craver,
  $loyal-traditionalist
);
$reserved-familiar-gradient-dark: linear-gradient(to right, #322b59, #000000);
$outgoing-familiar-gradient-light: linear-gradient(
  to right,
  $carefree-fun-lover,
  $proud-celebrator
);
$outgoing-familiar-gradient-dark: linear-gradient(to right, #2a5653, #000000);
$outgoing-exploratory-gradient-light: linear-gradient(
  to right,
  $creative-explorer,
  $social-curator
);
$outgoing-exploratory-gradient-dark: linear-gradient(
  to right,
  #1d2846,
  #000000
);
$reserved-exploratory-gradient-light: linear-gradient(
  to right,
  $balanced-enjoyers,
  $diligent-discoverer
);
$reserved-exploratory-gradient-dark: linear-gradient(
  to right,
  #542323,
  #b983ff
);

$people-segment-gradient: linear-gradient(
  to right,
  $proud-celebrator,
  $carefree-fun-lover,
  $creative-explorer,
  $social-curator,
  $comfort-craver,
  $loyal-traditionalist,
  $balanced-enjoyers,
  $diligent-discoverer
);

//text colors
$white: #ffffff;
$grey-200: #efefef;
$grey-300: #c7c7c7;
$grey-700: #3d3d3d;
$grey-800: #1d1d1d;
$black: #000000;

//Demand spaces
$easygoing-hangout: linear-gradient(100deg, #ada1f8 0%, #b983ff 100%);
$routine-living: linear-gradient(100deg, #ada1f8 0%, #b983ff 100%);
$casual-good-times: linear-gradient(100deg, #8fe8bd 0%, #79d271 100%);
$simple-unplug: linear-gradient(100deg, #8fe8bd 0%, #79d271 100%);
$lively-social-gathering: linear-gradient(100deg, #a1e8f8 0%, #83bcff 100%);
$intentional-unwind: linear-gradient(100deg, #a1e8f8 0%, #83bcff 100%);
$refined-weekend-out: linear-gradient(100deg, #ffc759 0%, #f81 100%);
$thoughtful-connection: linear-gradient(100deg, #ffc759 0%, #f81 100%);
$sensible-slowdown: linear-gradient(100deg, #ffc759 0%, #f81 100%);
$energetic-night-out: linear-gradient(
    90deg,
    rgba(131, 188, 255, 0) 26.56%,
    #83bcff 68.49%,
    #a1e8f8 100%
  ),
  linear-gradient(100deg, #8fe8bd 0%, #79d271 74.48%, #59b650 100%);
$upbeat-social-meal: linear-gradient(
    90deg,
    rgba(131, 188, 255, 0) 26.56%,
    #83bcff 68.49%,
    #a1e8f8 100%
  ),
  linear-gradient(100deg, #8fe8bd 0%, #79d271 74.48%, #59b650 100%);
$intimate-dining: linear-gradient(
  90deg,
  #8fe8bb 0%,
  #7bd375 20%,
  #a1e8f8 40%,
  #83bcff 60%,
  #ffc759 80%,
  #ff8811 100%
);
$everyday-meal: linear-gradient(
  90deg,
  #8fe8bb 0%,
  #7bd375 20%,
  #a1e8f8 40%,
  #83bcff 60%,
  #ffc759 80%,
  #ff8811 100%
);

//Inter fonts
@font-face {
  font-family: "Inter-Black";
  src: url("../assets/fonts/Inter/static/Inter-Black.ttf") format("truetype");
}
@font-face {
  font-family: "Inter-Bold";
  src: url("../assets/fonts/Inter/static/Inter-Bold.ttf") format("truetype");
}
@font-face {
  font-family: "Inter-ExtraBold";
  src: url("../assets/fonts/Inter/static/Inter-ExtraBold.ttf") format("truetype");
}
@font-face {
  font-family: "Inter-ExtraLight";
  src: url("../assets/fonts/Inter/static/Inter-ExtraLight.ttf") format("truetype");
}
@font-face {
  font-family: "Inter-Light";
  src: url("../assets/fonts/Inter/static/Inter-Light.ttf") format("truetype");
}
@font-face {
  font-family: "Inter-Medium";
  src: url("../assets/fonts/Inter/static/Inter-Medium.ttf") format("truetype");
}
@font-face {
  font-family: "Inter-Regular";
  src: url("../assets/fonts/Inter/static/Inter-Regular.ttf") format("truetype");
}
@font-face {
  font-family: "Inter-SemiBold";
  src: url("../assets/fonts/Inter/static/Inter-SemiBold.ttf") format("truetype");
}
@font-face {
  font-family: "Inter-Thin";
  src: url("../assets/fonts/Inter/static/Inter-Thin.ttf") format("truetype");
}

//Zuume fonts 
@font-face {
  font-family: "Zuume-Bold";
  src: url("../assets/fonts/zuume/Zuume\ Bold.ttf") format("truetype");
}
@font-face {
  font-family: "Zuume-Black";
  src: url("../assets/fonts/zuume/Zuume\ Black.ttf") format("truetype");
}
@font-face {
  font-family: "Zuume-Black-Italic";
  src: url("../assets/fonts/zuume/Zuume\ Black\ Italic.ttf") format("truetype");
}
@font-face {
  font-family: "Zuume-Bold-Italic";
  src: url("../assets/fonts/zuume/Zuume\ Bold\ Italic.ttf") format("truetype");
}
@font-face {
  font-family: "Zuume-ExtraBold";
  src: url("../assets/fonts/zuume/Zuume\ ExtraBold.ttf") format("truetype");
}
@font-face {
  font-family: "Zuume-ExtraBold-Italic";
  src: url("../assets/fonts/zuume/Zuume\ ExtraBold\ Italic.ttf") format("truetype");
}
@font-face {
  font-family: "Zuume-ExtraLight";
  src: url("../assets/fonts/zuume/Zuume\ ExtraLight.ttf") format("truetype");
}
@font-face {
  font-family: "Zuume-ExtraLight-Italic";
  src: url("../assets/fonts/zuume/Zuume\ ExtraLight\ Italic.ttf") format("truetype");
}
@font-face {
  font-family: "Zuume-Italic";
  src: url("../assets/fonts/zuume/Zuume\ Italic.ttf") format("truetype");
}
@font-face {
  font-family: "Zuume-Light";
  src: url("../assets/fonts/zuume/Zuume\ Light.ttf") format("truetype");
}
@font-face {
  font-family: "Zuume-Light-Italic";
  src: url("../assets/fonts/zuume/Zuume\ Light\ Italic.ttf") format("truetype");
}
@font-face {
  font-family: "Zuume-Medium";
  src: url("../assets/fonts/zuume/Zuume\ Medium.ttf") format("truetype");
}
@font-face {
  font-family: "Zuume-Medium-Italic";
  src: url("../assets/fonts/zuume/Zuume\ Medium\ Italic.ttf") format("truetype");
}
@font-face {
  font-family: "Zuume-Regular";
  src: url("../assets/fonts/zuume/Zuume\ Regular.ttf") format("truetype");
}
@font-face {
  font-family: "Zuume-SemiBold";
  src: url("../assets/fonts/zuume/Zuume\ SemiBold.ttf") format("truetype");
}
@font-face {
  font-family: "Zuume-SemiBold-Italic";
  src: url("../assets/fonts/zuume/Zuume\ SemiBold\ Italic.ttf") format("truetype");
}
