.row {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.brandImage {
  width: 62px;
  height: auto;
  object-fit: contain;
}

.info {
  display: flex;
  min-height: 54px;
  flex-direction: column;
  justify-content: space-between;
}

.name {
  color: #fff;
  font-size: 22px;
  font-weight: 600;
  line-height: 1;
  width: 230px;
}

.share {
  font-family: 'Work Sans', sans-serif;
  font-size: 18px;
  font-weight: 500;
  line-height: 1;
  margin-top: 10px;
}
