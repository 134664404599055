@use "theme";

.main-content {
  .wrapup-paper-comfort {
    background: linear-gradient(180deg,
        rgba(0, 0, 0, 0) 0%,
        rgba(0, 0, 0, 0.5) 100%),
      lightgray 50% / cover no-repeat;

    background-blend-mode: multiply, normal;
  }

  .card-text-wrapup {
    /* Desktop/Body/Default/Bold */
    font-family: Inter;
    font-size: 20px;
    font-style: normal;
    font-weight: 700;
    line-height: 130%;

    /* 26px */
    @media screen and (width < 100rem) {
      font-size: 16px;
    }

    strong {
      color: #ada1f8;
      font-style: normal;
      display: contents;
    }
  }

  .wrapup-button {
    display: flex;
    padding: 14px 14px;
    justify-content: center;
    align-items: center;
    gap: 26px;
    flex: 1 0 0;
    align-self: stretch;
    border-radius: 10px;
    color: var(--grayscale-black, #000);
    text-align: center;

    /* Desktop/Body/Overline */
    font-family: Inter;
    font-size: 15px;
    font-style: normal;
    font-weight: 900;
    line-height: 150%;
    /* 22.5px */
    letter-spacing: 0.45px;
    text-transform: uppercase;

    @media screen and (width < 100rem) {
      font-size: 12px;
    }
  }

  .wrapup-button:hover {
    background: var(--persona-colors-reserved-familiar-comfort-cravers,
        #ada1f8);
  }
}

.wrapup-bottom {
  color: var(--grayscale-white, #fff);
  font-family: Inter;
  font-size: 16px;
  font-style: italic;
  font-weight: 400;
  line-height: 130%;

  /* 20.8px */
  @media screen and (width < 100rem) {
    font-size: 13px;
  }

  i {
    text-decoration: underline;
  }

  .wrapup-cta-text {
    font-family: Inter-Regular;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 130%;

    i {
      text-decoration-line: underline;
      font-style: italic;
    }
  }
}

.wrapup-bottom-casual {
  margin-left: 132px;
}

.section7-wrapup {
  .container {
    min-height: 80vh !important;
  }

  .separator {
    width: 1px;
    height: 40px;
    opacity: 0.2;
    background: #FFF;
  }

  .main-content-section3 {
    margin-left: 100px;

    .c {
      font-family: Inter;
      font-size: 22px;
      font-style: normal;
      font-weight: 700;
      line-height: 130%;
      // green {
      //   color: #79d271;
      //   font-style: normal;
      //   display: contents;
      // }
      // yellow {
      //   color: #f6e003;
      //   font-style: normal;
      //   display: contents;
      // }
    }
  }

  .main-section3-slider {
    margin-top: 230px;
    margin-left: 140px;

    .slider {
      width: 400px;
      display: flex;
      height: 4px;
      justify-content: center;
      align-items: center;
      flex: 1 0 0;
      border-radius: 4px;
      color: rgba(255, 255, 255, 0.5);
    }

    .custom-thumb {
      background-color: #ada1f8;
    }

    .custom-mark {
      height: 12px;
    }

    .slider-texts {
      color: var(--grayscale-white, #fff);
      font-family: Inter;
      font-size: 13px;
      font-style: normal;
      font-weight: 700;
      line-height: 100%;
      /* 15px */
      letter-spacing: 0.5px;
    }
  }

  .main-section4-casual {
    .boxes-container {
      display: flex;
    }

    .box {
      margin-right: 100px;
    }

    .container {
      margin-top: 0px;
      min-height: 50vh !important;
    }

    .top-needs {
      color: var(--grayscale-white, #fff);
      text-align: center;

      margin-left: 16px;
      /* Desktop/Body/Default/Bold */
      font-family: Inter-Bold;
      font-size: 16px;
      font-style: normal;
      line-height: 130%;

      /* 26px */
      @media screen and (width < 100rem) {
        font-size: 13px;
      }
    }

    .vol-index {
      color: var(--grayscale-300, #c7c7c7);
      text-align: center;
      display: flex;
      flex-direction: row;
      column-gap: 10px;
      /* Desktop/Body/Small/Small Bold */
      font-family: Inter;
      font-size: 16px;
      font-style: normal;
      font-weight: 700;
      line-height: 130%;

      /* 20.8px */
      @media screen and (width < 100rem) {
        font-size: 13px;
      }
    }

    .casual-box {
      display: flex;
      margin-top: 8px;
      padding: var(--spacing-s, 20px);
      justify-content: space-between;
      align-items: center;
      align-self: stretch;
      border-radius: 16px;
      background: var(--grayscale-800, #1d1d1d);
    }

    .head-text {
      align-self: stretch;
      color: var(--global-colors-ab-light-gold, #f6e003);

      /* Desktop/Headers/H5 Styled */
      font-family: Zuume;
      font-size: 24px;
      font-style: normal;
      font-weight: 700;
      line-height: 100%;
      /* 30px */
      letter-spacing: 0.6px;
      text-transform: uppercase;
    }

    .head-sub-text {
      height: 20px;
      align-self: stretch;
      color: var(--grayscale-white, #fff);

      /* Desktop/Body/Default/Regular */
      font-family: Inter;
      font-size: 17px;
      font-style: normal;
      font-weight: 400;
      line-height: 130%;
      /* 26px */
    }

    .vol-per {
      color: var(--grayscale-white, #fff);
      text-align: right;

      /* Desktop/Data/Data Point Medium */
      font-family: Zuume;
      font-size: 24px;
      font-style: normal;
      font-weight: 400;
      line-height: normal;
      letter-spacing: 0.87px;
    }

    .index {
      display: flex;
      padding: 1px 3px;
      justify-content: center;
      align-items: center;
      gap: 10px;
      border-radius: 8px;
      background: var(--global-colors-index-positive, #309427);
      color: var(--grayscale-white, #fff);
      text-align: center;
      width: 54px;
      /* Desktop/Data/Data Point Medium */
      font-family: Zuume;
      font-size: 24px;
      font-style: normal;
      font-weight: 400;
      line-height: normal;
      letter-spacing: 0.87px;
    }

    .gray {
      background: var(--global-colors-index-neutral, #434343) !important;
    }

    .other-green {
      background: var(--global-colors-index-positive, #309427) !important;
    }

    .red {
      background: var(--global-colors-index-negative, #cb4242) !important;
    }

    .right-box-margin {
      padding-right: 50px;
    }

    .icon-text {
      color: var(--grayscale-white, #fff);
      text-align: start;
      font-family: Inter;
      font-size: 24px;
      font-style: normal;
      font-weight: 900;
      line-height: 130%;
      /* 36.4px */
    }

    .icon-text-margin {
      margin-left: 15px;
    }
  }

  .main-section4-weekday {
    .container {
      margin-top: 0px;
    }

    .weekday-box {
      display: flex;
      padding: var(--spacing-s, 20px);
      align-items: center;
      gap: 20px;
      flex: 1 0 0;
      border-radius: var(--radius-m, 16px);
      background: var(--grayscale-800, #1d1d1d);
    }

    .group-size {
      color: var(--global-colors-ab-light-gold, #f6e003);
      margin-top: 10px;
      /* Desktop/Body/Overline */
      font-family: Inter;
      font-size: 14px;
      font-style: normal;
      font-weight: 900;
      line-height: 150%;
      /* 22.5px */
      letter-spacing: 0.45px;
      text-transform: uppercase;

      @media screen and (width < 100rem) {
        font-size: 12px;
      }
    }

    .primarily-alone {
      color: var(--grayscale-white, #fff);
      margin-top: 8px;
      /* Desktop/Headers/H4 - Styled */
      font-family: Zuume;
      font-size: 28px;
      font-style: normal;
      font-weight: 900;
      line-height: 90%;
      /* 38.7px */
      letter-spacing: 1.72px;

      @media screen and (width < 100rem) {
        font-size: 1.3125rem;
      }
    }

    .always-meal {
      color: var(--grayscale-white, #fff);
      margin-top: 8px;
      /* Desktop/Headers/H4 - Styled */
      font-family: Zuume;
      font-size: 26px;
      font-style: normal;
      font-weight: 900;
      line-height: 90%;
      /* 38.7px */
      letter-spacing: 1.72px;
    }
  }
}