@use 'theme';

.runner-container{
    display: flex;
    align-items: center;
    justify-content: center;
    padding: var(--spacing-xs, 0.75rem) 0px var(--spacing-xs, 0.75rem) 0;
    margin: auto;
    overflow: hidden;
    
    &.homepage,
    &.helppage, 
    &.occasionspage,
    &.occasionsTemplatePage {
        background: var(--Actions, theme.$ab-yellow);
        color: theme.$black;
    }
    // &.demandspaceTemplatePage {
    //   background: var(--Actions, theme.$ab-yellow);
    //   color: theme.$black;
    // }

    //Personas
    &.Fram_PSGMT_2 {
      background: var(--people-segment-colors-reserved-familiar-comfort-cravers, theme.$comfort-craver);
    }
    &.Fram_PSGMT_3 {
      background: var(--people-segment-colors-reserved-familiar-comfort-cravers, theme.$loyal-traditionalist);
    }
    &.Fram_PSGMT_5 {
      background: var(--people-segment-colors-reserved-familiar-comfort-cravers, theme.$balanced-enjoyers);
    }
    &.Fram_PSGMT_6 {
      background: var(--people-segment-colors-reserved-familiar-comfort-cravers, theme.$diligent-discoverer);
    }
    &.Fram_PSGMT_8 {
      background: var(--people-segment-colors-reserved-familiar-comfort-cravers, theme.$carefree-fun-lover);
    }
    &.Fram_PSGMT_9 {
      background: var(--people-segment-colors-reserved-familiar-comfort-cravers, theme.$proud-celebrator);
    }
    &.Fram_PSGMT_11 {
      background: var(--people-segment-colors-reserved-familiar-comfort-cravers, theme.$creative-explorer);
    }
    &.Fram_PSGMT_12 {
      background: var(--people-segment-colors-reserved-familiar-comfort-cravers, theme.$social-curator);
    }

    //Demand spaces
    &.Fram_DS_5 { //"Casual Good Times"
      background: theme.$casual-good-times;
    }
    &.Fram_DS_1 { //"Easygoing Hangout"
      background: theme.$easygoing-hangout;
    }
    &.Fram_DS_3 { //"Energetic Night Out"
      background: theme.$energetic-night-out;
    }
    &.Fram_DS_4 { //"Upbeat Social Meal"
      background: theme.$upbeat-social-meal;
    }
    &.Fram_DS_9 { //"Refined Weekend Out"
      background: theme.$refined-weekend-out;
    }
    &.Fram_DS_7 { //"Lively Social Gathering"
      background: theme.$lively-social-gathering;
    }
    &.Fram_DS_10 { //"Thoughtful Connection"
      background: theme.$thoughtful-connection;
    }
    &.Fram_DS_2 { //"Routine Living"
      background: theme.$routine-living;
    }
    &.Fram_DS_12 { //"Intimate Dining"
      background: theme.$intimate-dining;
    }
    &.Fram_DS_13 { //"Everyday Meal"
      background: theme.$everyday-meal;
    }
    &.Fram_DS_6 { //"Simple Unplug"
      background: theme.$simple-unplug;
    }
    &.Fram_DS_8 { //"Intentional Unwind"
      background: theme.$intentional-unwind;
    }
    &.Fram_DS_11 { //"Sensible Slowdown"
      background: theme.$sensible-slowdown;
    }
    .runner-text {
        display: flex;
        align-items: center;
        justify-content: flex-start;
        white-space: nowrap;
        transition: all 1s ease;

        & > div {
            display: flex;
            animation: scrollText 10s linear infinite;
            gap: var(--spacing-s, 1.25rem);
            @media screen and (width < 100rem){
              animation: scrollTextFont 5s linear infinite;
            }

          }
          span {
            font-family: Zuume-Black;
            font-size: 80px;
            font-style: normal;
            line-height: 80%; /* 64px */
            text-transform: uppercase;
            // transition: all 1s ease;
            @media screen and (width < 100rem){
              font-size: 3.75rem;
            }
        }
    }
}

@keyframes scrollText {
    from {
      transform: translateX(0%);
    }
    to {
      transform: translateX(-29%);
    }
  }

  @keyframes scrollTextFont {
    from {
      transform: translateX(0%);
    }
    to {
      transform: translateX(-15%);
    }
  }



// .runner-container {
//   display: flex;
//     align-items: center;
//     justify-content: center;
//     padding: var(--spacing-xs, 0.75rem) 0px var(--spacing-xs, 0.75rem) 0;
//     margin: auto;
//   overflow: hidden;
//   width: 100%;
//   &.homepage,
//       &.helppage, 
//       &.occasionspage,
//       &.occasionsTemplatePage {
//           background: var(--Actions, theme.$ab-yellow);
//           color: theme.$black;
//       }
//       &.demandspaceTemplatePage {
//         background: var(--Actions, theme.$ab-yellow);
//         color: theme.$black;
//     }
//       &.Fram_PSGMT_2 {
//         background: var(--people-segment-colors-reserved-familiar-comfort-cravers, theme.$comfort-craver);
//       }
//       &.Fram_PSGMT_3 {
//         background: var(--people-segment-colors-reserved-familiar-comfort-cravers, theme.$loyal-traditionalist);
//       }
//       &.Fram_PSGMT_5 {
//         background: var(--people-segment-colors-reserved-familiar-comfort-cravers, theme.$balanced-enjoyers);
//       }
//       &.Fram_PSGMT_6 {
//         background: var(--people-segment-colors-reserved-familiar-comfort-cravers, theme.$diligent-discoverer);
//       }
//       &.Fram_PSGMT_8 {
//         background: var(--people-segment-colors-reserved-familiar-comfort-cravers, theme.$carefree-fun-lover);
//       }
//       &.Fram_PSGMT_9 {
//         background: var(--people-segment-colors-reserved-familiar-comfort-cravers, theme.$proud-celebrator);
//       }
//       &.Fram_PSGMT_11 {
//         background: var(--people-segment-colors-reserved-familiar-comfort-cravers, theme.$creative-explorer);
//       }
//       &.Fram_PSGMT_12 {
//         background: var(--people-segment-colors-reserved-familiar-comfort-cravers, theme.$social-curator);
//       }
//   .runner-text {
//     white-space: nowrap;
//     animation: moveText linear infinite;
//     animation-duration: 10s; /* Adjust duration as needed */
//     .moving-text {
//       display: inline-block;
//       padding-right: 100%; /* Ensure text doesn't wrap */
//       font-family: Zuume;
//       font-size: 80px;
//       font-style: normal;
//       font-weight: 900;
//       line-height: 80%; /* 64px */
//       text-transform: uppercase;
//       transition: all 1s ease;
//       @media screen and (width < 100rem){
//         font-size: 3.75rem;
//       }
//     }
//   }
// }

// @keyframes moveText {
//   0% {
//     transform: translateX(100%);
//   }
//   100% {
//     transform: translateX(-100%);
//   }
// }