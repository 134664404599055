.container {
  width: 1224px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin: 0 auto;
}

.title {
  color: #fff;
  text-transform: uppercase;
  font: 700 48px/1 Barlow, sans-serif;
  margin-top: 0;
}

.underline {
  text-decoration: underline;
}

.content {
  display: flex;
  gap: 20px;
}

@media (max-width: 991px) {
  .title {
    max-width: 100%;
    font-size: 40px;
  }
  
  .content {
    max-width: 100%;
  }
}
