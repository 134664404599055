.indicator {
  border-radius: 512px;
  box-shadow: 0 0 4px 0 rgba(0, 0, 0, 0.16);
  align-self: stretch;
  display: flex;
  width: 32px;
  height: 8px;
  margin: auto 0;
}

.active {
  border: 1px solid #fff;
  background-color: #006edb;
}

.inactive {
  background-color: rgba(255, 255, 255, 0.24);
}
