@use "theme";

.main-content {
  .hero-demand {
    // width: 100%;
    display: flex;
    //padding: 5.565rem 6.25rem 6.25rem 6.25rem;
    flex-direction: column;
    align-items: flex-start;
    gap: var(--Spacing-L, 60px);
    align-self: stretch;
    background: linear-gradient(
        73deg,
        #ff8c8c 23.9%,
        #f3b2d6 33.25%,
        #b983ff 42.59%,
        #ada1f8 51.93%,
        #83bcff 61.28%,
        #a1e8f8 70.62%,
        #8fe8bd 79.96%,
        #79d271 89.31%
      ),
      linear-gradient(180deg, #353535 5%, rgba(0, 0, 0, 1) 95%);
    background-size: 100% 100%;
    background-repeat: no-repeat;
    background-blend-mode: multiply;

    .hero-head {
      //max-width: 87.5rem;
      margin: calc((100vh - theme.$main-nav-height) * 0.1) 6.25rem 6.25rem
        6.25rem;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      gap: 3.1875rem;
      // gap: calc((100vh - theme.$main-nav-height) * 0.1);
      align-self: stretch;
      .head-text-class {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        gap: 16px;
        align-self: stretch;
        .title-head {
          color: var(--grayscale-white, #fff);

          /* Desktop/Headers/H3 */
          font-family: Zuume-Black;
          font-size: 80px;
          font-style: normal;
          line-height: 80%; /* 64px */
          text-transform: uppercase;
          letter-spacing: normal;
          @media screen and (width < 100rem) {
            font-size: 60px;
          }
        }
        .subtitle-head {
          color: var(--grayscale-white, theme.$white);
          text-align: center;

          /* Desktop/Body/Default/Bold */
          font-family: Inter-Bold;
          font-size: 20px;
          font-style: normal;
          line-height: 130%; /* 26px */
          @media screen and (width < 100rem) {
            font-size: 16px;
          }
          strong {
            color: theme.$ab-yellow;
          }
        }
      }
      .wayfinder-component {
        display: flex;
        flex-direction: column;
        gap: 1rem;
        // max-height: 80vh;

        .layout-grid {
          display: flex;
          // max-height: 80vh;
          max-height: calc(
            100vh - theme.$main-nav-height - theme.$disclaimer-height
          );
          // padding: calc((100vh - theme.$main-nav-height) * 0.15) 1.725rem;
          padding: 1.725rem;
          // padding: 3.75% 2.56%;
          flex-direction: column;
          justify-content: center;
          align-items: center;
          // max-height: 80vh;
          gap: 0.862rem;
          border-radius: 1.725rem;
          border: 1.149px solid rgba(255, 255, 255, 0.1);
          background: linear-gradient(
              0deg,
              rgba(255, 255, 255, 0.1) 0%,
              rgba(255, 255, 255, 0.1) 100%
            ),
            rgba(0, 0, 0, 0.8);

          .major-occ-title {
            writing-mode: vertical-rl;
            transform: rotate(180deg);
            // transform-origin: bottom;
            // position: relative;
            // margin: -10px 0;
            margin-left: -16px;

            color: var(--Global-Colors-AB-Light-Gold, #f6e003);
            font-family: Inter-Bold;
            font-size: 8.494px;
            font-style: normal;
            line-height: normal;
            letter-spacing: 0.115px;
            text-transform: uppercase;
            white-space: nowrap;
            @media screen and (width > 100rem) {
              font-size: 8.6205px;
            }
          }
          .vertical-line {
            position: absolute;
            top: 16px;
            bottom: 0;
            left: 95%; /* Adjust as needed */
            width: 2.3px; /* Width of the vertical line */
            background-color: #f6e003; /* Color of the vertical line */
          }
        }
        .full-meal-present {
          display: flex;
          padding: 0px var(--Spacing-S, 20px);
          justify-content: space-between;
          align-items: center;
          align-self: stretch;
          .meal-disclaimer-container {
            display: flex;
            align-items: center;
            gap: 8px;
            .full-meal-icon {
              display: flex;
              width: 24px;
              height: 24px;
              justify-content: center;
              align-items: center;
              gap: 8px;
              border-radius: var(--Radius-M, 16px);
              background: var(--Grayscale-White, #fff);
              .restaurant-icon {
                width: 16px;
                height: 16px;
                flex-shrink: 0;
                color: #000000;
              }
            }
            .meal-disclaimer {
              color: #fff;
              font-family: Inter-Medium;
              font-size: 17.241px;
              font-style: normal;
              line-height: 130%; /* 22.414px */
              letter-spacing: -0.345px;
              @media screen and (width < 100rem) {
                font-size: 12.93px;
              }
            }
          }

          .tool-tip-container {
            display: flex;
            align-items: center;
            gap: 8px;
            .info-icon {
              width: 24px;
              height: 24px;
              color: #f6e003;
            }

            .tool-tip-text {
              color: var(--Grayscale-White, #fff);
              font-family: Inter-Regular;
              font-size: 16px;
              font-style: normal;
              font-weight: 400;
              line-height: 130%;
              @media screen and (width < 100rem) {
                font-size: 13px;
              }
            }
          }
        }
      }
      .type-of-occasions {
        display: flex;
        // width: 114.943px;
        height: 114.943px;
        // height: calc((100vh - theme.$main-nav-height) * 0.209);
        flex-direction: column;
        justify-content: center;
        align-items: center;
        gap: 11.494px;
        border-radius: 10px;

        background: linear-gradient(
            180deg,
            rgba(0, 0, 0, 0) 0%,
            rgba(0, 0, 0, 0.7) 50%,
            /* Adjust the opacity and position */ rgba(0, 0, 0, 0.5) 100%
          ),
          lightgray 50% / cover no-repeat;
        background-blend-mode: multiply, normal;

        &.comfort-craver {
          --border-color: #b983ff;
        }
        &.loyal-traditionalist {
          --border-color: #ada1f8;
        }
        &.carefree-fun-lover {
          --border-color: #8fe8bd;
        }
        &.proud-celebrator {
          --border-color: #79d271;
        }
        &.creative-explorer {
          --border-color: #a1e8f8;
        }
        &.social-curator {
          --border-color: #83bcff;
        }
        &.balanced-enjoyers {
          --border-color: #ffc759;
        }
        &.diligent-discoverer {
          --border-color: #ff8811;
        }
      }
      .type-of-occasions:hover::after {
        content: "";
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        border: 3px solid var(--border-color, red); /* Change the border color on hover */
        border-radius: 10px;
        pointer-events: none; /* Ensure the hover effect does not interfere with the content */
        box-sizing: border-box; /* Ensure the border stays within the element's dimensions */
      }
    }
  }

  .grid-layout-left {
    display: flex;
    height: calc(2.4rem * 0.7);
    // height: calc((100vh - theme.$main-nav-height) * 0.0655);
    padding-left: 15px;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    gap: 11.991px;
    border-radius: 2.298rem;
    background: var(--grayscale-700, #3d3d3d);
  }
  .grid-layout-left:hover {
    background: var(--grayscale-700, #f6e003);
  }
  .big-square {
    display: flex;
    height: calc(14.5rem * 0.7);
    // height: calc((100vh - theme.$main-nav-height) * 0.415);
    padding: 9.195px;
    justify-content: center;
    align-items: center;
    flex: 1 0 0;
    align-self: stretch;
    border-radius: 9.195px;
    border: 0.848px solid var(--grayscale-700, #3d3d3d);
    background: var(--grayscale-700, #3d3d3d);
  }
  .long-bar {
    border-radius: 9.195px;
    display: flex;
    height: calc(2.298rem * 0.7);
    // min-height: 2.298rem;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 11.991px;
    align-self: stretch;
    background: var(--grayscale-700, #3d3d3d);
  }
  .refined-box {
    display: flex;
    height: calc(3.9rem * 0.7);
    padding: 9.195px;
    justify-content: center;
    align-items: center;
    align-self: stretch;
    border-radius: 9.195px;
    border: 0.848px solid var(--grayscale-700, #3d3d3d);
    background: var(--grayscale-700, #3d3d3d);
  }
  .below-long-bar {
    display: flex;
    height: calc(7.625rem * 0.7);
    padding: 9.195px;
    justify-content: center;
    align-items: center;
    flex: 1 0 0;
    align-self: stretch;
    border-radius: 9.195px;
    border: 0.848px solid var(--grayscale-700, #3d3d3d);
    background: var(--grayscale-700, #3d3d3d);
  }
  .occasion-text-style {
    color: var(--grayscale-white, #fff);
    text-align: center;
    font-family: Inter-Bold;
    font-size: 17.241px;
    font-style: normal;
    line-height: 130%; /* 19.5px */
    letter-spacing: -0.3px;
    @media screen and (width < 100rem) {
      font-size: 12.93px;
    }

    &:hover {
      color: var(--grayscale-white, #000000);
    }
  }
  // .occasion-text-style:hover {
  //   color: var(--grayscale-white, #000000);
  //   text-align: center;
  //   font-family: Inter-Bold;
  //   font-size: 17.241px;
  //   font-style: normal;
  //   font-weight: 700;
  //   line-height: 130%; /* 19.5px */
  //   letter-spacing: -0.3px;
  //   @media screen and (width < 100rem) {
  //     font-size: 12.93px;
  //   }
  // }
  .occ-layout-text1 {
    color: var(--persona-colors-reserved-familiar-comfort-cravers, #ada1f8);
    text-align: center;
    font-family: Inter-Bold;
    font-size: 11.494px;
    font-style: normal;
    line-height: normal;
    letter-spacing: 0.115px;
    text-transform: uppercase;
    @media screen and (width < 100rem) {
      font-size: 8.6205px;
    }
  }
  .occ-layout-text2 {
    color: var(
      --persona-colors-outgoing-familiar-proud-celebrators,
      var(--Proud-Celebrators, #79d271)
    );
    text-align: center;
    font-family: Inter-Bold;
    font-size: 11.494px;
    font-style: normal;
    line-height: normal;
    letter-spacing: 0.115px;
    text-transform: uppercase;
    @media screen and (width < 100rem) {
      font-size: 8.6205px;
    }
  }
  .occ-layout-text3 {
    color: var(
      --persona-colors-outgoing-exploratory-social-curators,
      var(--Social-Curators, #83bcff)
    );
    text-align: center;
    font-family: Inter-Bold;
    font-size: 11.494px;
    font-style: normal;
    line-height: normal;
    letter-spacing: 0.115px;
    text-transform: uppercase;
    @media screen and (width < 100rem) {
      font-size: 8.6205px;
    }
  }
  .occ-layout-text4 {
    color: var(
      --persona-colors-reserved-exploratory-diligent-discoverers,
      #f81
    );
    text-align: center;
    font-family: Inter-Bold;
    font-size: 11.494px;
    font-style: normal;
    line-height: normal;
    letter-spacing: 0.115px;
    text-transform: uppercase;
    @media screen and (width < 100rem) {
      font-size: 8.6205px;
    }
  }
  .card-text {
    flex: 1 0 0;

    color: var(--Grayscale-White, #fff);
    text-align: center;
    text-shadow: 0px 4.598px 17.241px #1c1737;
    font-family: Zuume-Bold;
    font-size: 17.241px;
    font-style: normal;
    line-height: 80%; /* 13.793px */
    letter-spacing: 0.862px;
    // white-space: break-spaces;
    @media screen and (width < 100rem) {
      font-size: 12.93px;
    }
  }
  .grid-below-text {
    color: #fff;
    font-family: Inter;
    font-size: 15px;
    font-style: normal;
    font-weight: 500;
    line-height: 130%; /* 22.414px */
    letter-spacing: -0.345px;
    @media screen and (width < 100rem) {
      font-size: 12px;
    }
  }
}

.vertical-text {
  display: flex;
  // width: 243.829px;
  // height: 21.469px;
  transform: rotate(-90deg);
  flex-direction: column;
  justify-content: center;
  flex-shrink: 0;
  color: var(--grayscale-white, #fff);
  text-align: center;
  font-family: Inter;
  font-size: 18px;
  font-style: normal;
  font-weight: 700;
  line-height: 130%; /* 23.4px */
  text-transform: uppercase;
}

.section2-demand-spaces {
  display: flex;
  padding: 0 100px var(--Spacing-Section, 150px)
    var(--Spacing-Horizontal-Margin, 100px);
  align-items: flex-start;
  gap: var(--Spacing-XXL, 100px);
  align-self: stretch;
}
