@use "../theme";

.jumbosmallquoteimageleft-card-container {
    display: flex;
    height: 100%;
    width: 100%;
    align-items: flex-start;
    justify-content: space-between;
    gap: 10px;
    flex: 1 0 0;
    border-radius: 1rem;

    .card-content-container {
        height: 100%;
        .quote-attribution-card-container {
            border-radius: 0 1rem 1rem 0;
        }
        .image-card-container {
            .image-card-visual{
            border-radius: 1rem 0 0 0;
            }
        }
        .smallquote-attribution-card-container{
            border-radius : 0 0 0 1rem;
        }
    }
}
