.testimonialSection {
  display: flex;
  flex-direction: column;
  justify-content: start;
  width: 1224px;
  margin: 0 auto;
}

.sectionHeader {
  display: flex;
  min-height: 92px;
  max-width: 100%;
  flex-direction: column;
  font-family: Barlow, sans-serif;
  font-weight: 700;
  text-transform: uppercase;
  justify-content: start;
}

.subheading {
  color: #f6e003;
  font-size: 24px;
}

.heading {
  color: #fff;
  font-feature-settings: "salt" on;
  font-size: 48px;
  line-height: 1;
  margin-top: 10px;
}

.testimonialGrid {
  display: flex;
  margin-top: 30px;
  width: 100%;
  gap: 20px;
  justify-content: center;
  flex-wrap: wrap;
}

@media (max-width: 991px) {
  .subheading {
    max-width: 100%;
  }
  
  .heading {
    max-width: 100%;
    font-size: 40px;
  }
  
  .testimonialGrid {
    max-width: 100%;
  }
}
