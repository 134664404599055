@use "theme";
@use "runner";

// Pages
@use "login";
@use "terms";
@use "home";
@use "peoplesegmenthub";
@use "peoplesegmenttemplate";
@use "occasions";
@use "occasionstemplate";
@use "demand-spaces";
@use "demandspacetemplate";
@use "help";
@use "wrapup";

//Common components
@use "exploresection";
@use "downloadresources";
@use "scopebadge";
@use "anchornavigationbar";
@use "Cards";
@use "UGCCard";
@use "sliders";
@use "SectionIntro";
@use "ContextualFactorsDataCard";
@use "ContextualFactorCard";
@use "DataTable";
@use "FeedbackSection";
@use "charts";
@use "meetthepeople";
@use "ConsumptionVisualCard";
@use "ConsumptionDataTable";
@use "OccasionsExampleAudioCard";
@use "demandspacescommon";
@use "wayfindercommon";
@use "WrapUpSection";
@use "DemandSpaceSectionUGCCard";
@use "DividerWithArrows";
@use "HomeModal";
@use "DropDownMenu";
@use "Loader";
@use "ToolTipPopUp";
@use "CustomCard";
@use "CarouselModal";

@use "./cards/QuoteAttributionCard.scss";
@use "./cards/StatementCard.scss";
@use "./cards/ImageCard.scss";
@use "./cards/SmallQuoteAttribution.scss";
@use "./cards/JumboQuoteImageRight.scss";
@use "./cards/JumboQuoteImageLeft.scss";
@use "./cards/JumboSmallQuoteImageRight.scss";
@use "./cards/JumboSmallQuoteImageLeft.scss";
@use "./cards/VideoCard.scss";
@use "./cards/BigQuoteImageRightCard.scss";
@use "./cards/BigQuoteVideoRightCard.scss";
@use "./cards/StatementQuoteImageCard.scss";
@use "./cards/QuoteAttributionImageCard.scss";


.builder-container {
  width: 100vw;
}

.container {
  min-height: 100vh;
  width: 100vw;
  max-width: 100vw;
  background-color: theme.$black;
  -webkit-font-smoothing: antialiased;

  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  align-content: center;
  flex-shrink: 0;

  .main-content {
    display: flex;
    flex-direction: column;
    max-width: 100%;
  }

  .content {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin: 0 6.25rem;
  }
}

.page-header {
  height: theme.$main-nav-height;
  width: 100%;
  max-width: 100%;
  z-index: 1100;
  .app-bar-container {
    background-color: theme.$black;
  }
  .app-bar-container-nc {
    background-color: theme.$black;

    display: flex;
    align-items: center;
    padding: 60px 20px 20px 20px;

    &.mobile {
      padding: 20px;
    }
  }
  .nav-bar-container {
    display: flex;
    height: theme.$main-nav-height;
    margin: 0 6.25rem;
    padding: 0;
    justify-content: space-between;
    align-items: center;
    align-self: stretch;
    background: #000;
    backdrop-filter: blur(25px);

    .header-logo {
      display: flex;
      align-items: center;
      gap: 9.171px;
      width: auto;
      height: 1.875rem;
    }
    .header-text {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: flex-start;
      gap: 2.293px;
      .header-text-typo {
        color: var(--Grayscale-White, theme.$white);
        font-family: Inter-Black;
        font-size: 12px;
        font-style: normal;
        line-height: 90%;
        letter-spacing: 0.84px;
        text-transform: uppercase;
        @media screen and (width < 100rem) {
          font-size: 9px;
        }
      }
      .header-text-typo2 {
        color: var(--Grayscale-White, theme.$white);
        font-family: Inter-Medium;
        font-size: 12px;
        font-style: normal;
        line-height: 90%;
        letter-spacing: 0.84px;
        text-transform: uppercase;
        opacity: 0.5;
        @media screen and (width < 100rem) {
          font-size: 9px;
        }
      }
    }
    .header-typo1 {
      flex-grow: 1;
      display: none;
      @media screen and (min-width: 600px) {
        display: block;
      }
    }
    .box-header-content {
      display: none;
      @media screen and (min-width: 600px) {
        display: block;
      }
    }
    .header-nav-item {
      color: theme.$white;
      text-transform: none;
      font-family: Inter-Bold;
      font-size: 14px;
      font-style: normal;
      line-height: 130%;
    }
    .header-nav-item2 {
      color: var(--Grayscale-White, theme.$white);
      font-family: Inter-Bold;
      font-size: 14px;
      font-style: normal;
      text-transform: none;
      line-height: 130%;

      @media screen and (width < 100rem) {
        font-size: 13px;
      }
    }
    .logged-in {
      &:hover {
        color: theme.$ab-yellow;
        background-color: transparent;
      }
    }
  }

  .dropdown {
    position: fixed;
    top: theme.$main-nav-height;
    width: 100%;
    max-width: 100%;
  }
}

.page-footer {
  color: theme.$grey-200;
  display: flex;
  flex-direction: row;
  margin: 0 6.25rem;
  align-self: stretch;
  align-items: center;
  align-content: space-between;
  max-width: 100%;
  .page-footer-div {
    display: flex;
    position: static;
    .footer-nav-item {
      opacity: 0.4;
      color: var(--Grayscale-200, theme.$grey-200);
      font-family: Inter-Regular;
      font-size: 13px;
      font-style: normal;
      text-transform: none;
      font-weight: 400;
      line-height: 130%;
      letter-spacing: 2px;
      // padding: 0px 16px;
    }
    .footer-nav-item-separator {
      opacity: 0.3;
      color: var(--Grayscale-200, theme.$grey-200);
      font-family: Inter;
      font-size: 13px;
      font-style: normal;
      text-transform: none;
      font-weight: 400;
      line-height: 130%;
      letter-spacing: 2px;
    }
    .box-footer-content2 {
      flex-grow: 1;
    }
    .footer-typo2 {
      flex-grow: 1;
      display: none;
      @media screen and (min-width: 600px) {
        display: block;
      }
    }
    .box-footer-content {
      display: none;
      text-align: center;
      @media screen and (min-width: 600px) {
        display: block;
      }
    }
  }
}

.color-white {
  color: white !important;
}

.popover-items-container {
  .popover-clickable-button {
    font-family: Inter;
    font-size: 16px;
    font-style: normal;
    text-transform: none;
    font-weight: 400;
    line-height: 130%;
    text-align: left;
    @media screen and (width < 100rem) {
      font-size: 13px;
    }
  }
}
