.hero {
  position: relative;
  width: 100%;
  height: 1020px;
  margin: 0 auto;
}

.heroImage {
  width: 100vw;
  height: auto;
  object-fit: cover;
}

.heroContent {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  padding: 30px 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 1224px;
  margin: 0 auto;
}

.heroTitle {
  color: #fff;
  font: 800 75px/1 Barlow, sans-serif;
  text-transform: uppercase;
  margin-bottom: 0;
}

.description {
  color: #fff;
  font: 600 24px/35px Work Sans, -apple-system, Roboto, Helvetica, sans-serif;
  text-align: center;
  width: 920px;
}

.highlight {
  color: #ffe500;
}

.flavorSpectrumImage {
  width: 1224px;
  height: auto;
  margin-top: 50px;
}

.scrollContainer {
  width: 100vw;
  overflow-x: auto;
  margin-top: 20px;
  scroll-behavior: smooth;
  &::-webkit-scrollbar {
    display: none;
  }
  -ms-overflow-style: none;
  scrollbar-width: none;
}

.categoryFrameworkWrapper {
  min-width: min-content;
  display: flex;
  justify-content: center;
  padding: 0 100px;
}

.categoryFrameworkImage {
  max-width: none;
  height: auto;
  display: block;
}

.instructionsCardWrapper {
  position: absolute;
  top: 575px;
  right: 0;
}

@media (max-width: 1360px) {
  .heroTitle {
    font-size: 60px;
  }
}

@media (max-width: 991px) {
  .heroTitle {
    font-size: 40px;
  }
  
  .heroContent {
    flex-direction: column;
    align-items: center;
    gap: 24px;
  }
}
