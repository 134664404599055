.header {
  display: flex;
  min-height: 90px;
  width: 100%;
  white-space: nowrap;
  justify-content: flex-start;
  flex-wrap: wrap;
}

.emptyCell {
  border-radius: 16px 0 0 0;
  background-color: #141414;
  min-width: 90px;
  width: 150px;
  height: 90px;
  border: 1px solid #fff;
}

.headerCell {
  background-color: #141414;
  display: flex;
  min-width: 90px;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  flex: 1;
  padding: 4px 16px;
  border: 1px solid #fff;
}

.headerCell:last-child {
  border-radius: 0 16px 0 0;
}

.title {
  color: #f5df42;
  text-align: center;
  font-feature-settings: "salt" on;
  text-overflow: ellipsis;
  margin-bottom: 5px;
}

@media (max-width: 991px) {
  .header {
    max-width: 100%;
    white-space: initial;
  }
  .headerCell {
    white-space: initial;
  }
}
