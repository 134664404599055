.patternCard {
  align-self: stretch;
  position: relative;
  display: flex;
  min-width: 240px;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  flex: 1;
  flex-basis: 0%;
  margin: auto 0;
  padding: 40px;
}

.card {
  border-radius: 16px;
  background-color: rgba(46, 46, 46, 1);
  align-self: start;
  position: absolute;
  z-index: 0;
  display: flex;
  min-height: 193px;
  justify-content: start;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  border: 1px solid rgba(20, 20, 20, 0.08);
}

.text {
  color: #fff;
  z-index: 0;
  font: 600 36px/1 Work Sans, -apple-system, Roboto, Helvetica, sans-serif;
}

.highlight {
  color: rgba(246,224,3,1);
}

.subtitle {
  color: #f5df42;
  font-feature-settings: "salt" on;
  text-transform: uppercase;
  z-index: 0;
  margin-top: 16px;
  font: 700 48px/1 Barlow, sans-serif;
}

@media (max-width: 991px) {
  .patternCard {
    max-width: 100%;
    padding: 0 20px;
  }
  
  .subtitle {
    max-width: 100%;
    font-size: 40px;
  }
  
  .text {
    max-width: 100%;
  }
}