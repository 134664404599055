.row {
  display: flex;
  min-height: 90px;
  width: 100%;
  white-space: nowrap;
  justify-content: flex-start;
  flex-wrap: wrap;
}

.labelCell {
  background-color: #141414;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 150px;
  border: 1px solid #fff;
}

.title {
  color: var(--trip_types_yellow_1, #f5df42);
  text-align: center;
  font-feature-settings: "salt" on;
  text-overflow: ellipsis;
  margin-bottom: 5px;
}

.dataCell {
  background-color: #434343;
  display: flex;
  align-items: center;
  gap: 8px;
  color: #fff;
  text-align: center;
  justify-content: center;
  height: 100%;
  flex: 1;
  padding: 0 8px;
  border: 1px solid #fff;
}

.content {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  min-height: 90px;
}

@media (max-width: 991px) {
  .row {
    max-width: 100%;
    white-space: initial;
  }
  .dataCell {
    white-space: initial;
  }
}
