.divider {
  min-height: 1px;
  width: 100%;
  border: 1px solid #fff;
  background-color: #fff;
}

@media (max-width: 991px) {
  .divider {
    max-width: 100%;
  }
}
