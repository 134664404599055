@use "theme";

.meetthepeople-container {
  //max-height: theme.$max-view-height;
  min-height: theme.$max-view-height;

  .meeththepeople-avatar-list-grid-item {
    //max-height: theme.$max-view-height;
    min-height: theme.$max-view-height;

    .avatar {
      display: flex;
      //margin: 20px;
      //margin: 0.75rem;
      margin: 0.5rem;
      flex-direction: column;
      // width: 5.625rem;
      // height: 5.625rem;
      width: 5rem;
      height: 5rem;
      background: var(--Grayscale-800, #1d1d1d);
      border-radius: 1000px;
      background: #1d1d1d;
      cursor: pointer;
    }
  }

  .meeththepeople-avatar-data-grid-item-top,
  .meeththepeople-avatar-data-grid-item-bottom {
    min-height: calc((theme.$max-view-height - 1rem)/2);

    .avatar-data {
      // display: flex;
      // padding: 36px;
      //min-height: calc((theme.$max-view-height - 1rem)/2 - 1.25rem);
      min-height: calc((theme.$max-view-height)/2);
      display: flex;
      padding: 36px;
      flex-direction: column;
      align-items: center;
      flex: 1 0 0;
      align-self: stretch;
      border-radius: 16px;
      background: var(--Grayscale-800, #1d1d1d);

      .avatar-big {
        //height: 11.875rem;
        //width: 11.875rem;
        height: 70%;
        width: 70%;
        border-radius: 1000px;
        background: #1d1d1d;
      }

      .card-text {
        display: flex;
        flex-direction: column;
        justify-content: flex-end;
        align-items: flex-start;
        // gap: 1rem;
        flex: 1 0 0;
        align-self: stretch;

        .card-text-spacing {
          display: flex;
          flex-direction: column;
          gap: 1rem;
          align-items: flex-start;

          .name-text {
            text-align: center;
            /* Desktop/Headers/H4 - Styled */
            font-family: Zuume-Black;
            font-size: 43px;
            font-style: normal;
            line-height: 90%;
            /* 38.7px */
            letter-spacing: 1.72px;

            @media screen and (width < 100rem) {
              font-size: 32.5px;
            }
          }

          .segment-name {
            /* Desktop/Functional/Label */
            font-family: Zuume-Bold;
            font-size: 20px;
            font-style: normal;
            line-height: 100%;
            /* 20px */
            letter-spacing: 1px;
            text-transform: uppercase;
          }

          .attribution-text-container {
            display: flex;
            flex-direction: row;
            justify-content: center;
            height: 1.25rem;
            align-items: flex-start;
            gap: 0.5rem;

            .details-text {
              text-align: left;
              color: var(--Grayscale-White, #FFF);
              font-family: Inter-SemiBold;
              font-size: 16px;
              font-style: normal;
              line-height: 100%;
              letter-spacing: 1px;
              text-transform: uppercase;

              // @media screen and (width < 100rem) {
              //   font-size: 13px;
              // }
            }

            .attributions-divider {
              width: 1px;
              background: var(--Grayscale-White, #FFF);
              // opacity: 0.2;
              height: 1.25rem;
            }
          }
        }

        .attributionDetails-text {
          text-align: left;
          color: var(--Grayscale-White, #FFF);
          font-family: Inter-SemiBold;
          font-size: 16px;
          font-style: normal;
          line-height: 130%;
          /* 20.8px */
          // @media screen and (width < 100rem) {
          //   font-size: 13px;
          // }
        }

        .attribution-items {
          ///demand-space-attribution
          display: flex;
          flex-direction: row;
          justify-content: center;
          height: 1.25rem;
          align-items: flex-start;
          gap: 0.25rem;

          .ds-attribution-text {
            font-family: Inter-SemiBold;
            font-size: 16px;
            font-style: normal;
            line-height: 100%;
            /* 20px */
            letter-spacing: 1px;
            text-transform: uppercase;
            // @media screen and (width < 100rem) {
            //   font-size: 13px;
            // }
          }

          .attribution {
            color: var(--Grayscale-White, #fff);

            /* Desktop/Functional/Label */
            font-family: Zuume-Bold;
            font-size: 20px;
            font-style: normal;
            line-height: 100%;
            /* 20px */
            letter-spacing: 1px;
            text-transform: uppercase;

            @media screen and (width < 100rem) {
              font-size: 16px;
            }
          }

          &.demandspace {
            color: theme.$white;
          }

          .attribution-divider {
            width: 1px;
            height: 100%;
            // opacity: 0.52;
            background: theme.$white;
          }
        }

        .quote-text {
          align-self: stretch;
          color: #fff;
          font-family: Inter-SemiBold;
          font-size: 16px;
          font-style: italic;
          line-height: 130%;
          text-align: left;

          @media screen and (width < 100rem) {
            font-size: 13px;
          }
        }
      }

      .demandspace-color {
        color: #f6e003;
      }
    }

    .image-class {
      //height: calc(((theme.$max-view-height - 1rem)/2 - 0.25rem)/2);
      //max-height: calc(((theme.$max-view-height - 1rem)/2)/2);

      height: 100%;
      min-height: calc(((theme.$max-view-height)/2)/2);
      overflow: hidden;
      border-radius: 1rem;
      background-position: center;
      background-repeat: no-repeat;
      background-size: cover;

      img {
        width: 100%;
        height: 100%;
        object-fit: cover;
      }
    }

    .big-image-class {
      //max-height: calc((theme.$max-view-height - 1rem)/2);
      min-height: calc((theme.$max-view-height)/2);
      height: 100%;
      overflow: hidden;
      border-radius: 1rem;

      background-position: center;
      background-repeat: no-repeat;
      background-size: cover;

      img {
        width: 100%;
        height: 100%;
        object-fit: cover;
      }
    }

    .big-quote-container {
      display: flex;
      flex-direction: column;
      align-self: stretch;
      min-height: calc((theme.$max-view-height)/2);


    }

    .big-quote {
      //height: calc((theme.$max-view-height - 1rem)/2 - 1rem);
      //max-height: calc((theme.$max-view-height - 1rem)/2 - 1rem);
      min-height: calc((theme.$max-view-height)/2);
      // min-height: 87%;
      border-radius: 1rem;
      background: #1d1d1d;
      padding: 1rem;
      font-style: italic;
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      align-items: flex-start;
      gap: 3rem;
      flex: 1 0 0;
      align-self: stretch;
    }

    .custom-mobile-stepper {
      max-width: 100%;
      // flex-grow: 1;
      display: flex;
      justify-content: center;
      align-items: center;
      background-color: theme.$black;

      .nextButton,
      .backButton {
        &.Fram_PSGMT_2 {
          color: theme.$comfort-craver;
        }

        &.Fram_PSGMT_3 {
          color: theme.$loyal-traditionalist;
        }

        &.Fram_PSGMT_5 {
          color: theme.$balanced-enjoyers;
        }

        &.Fram_PSGMT_6 {
          color: theme.$diligent-discoverer;
        }

        &.Fram_PSGMT_8 {
          color: theme.$carefree-fun-lover;
        }

        &.Fram_PSGMT_9 {
          color: theme.$proud-celebrator;
        }

        &.Fram_PSGMT_11 {
          color: theme.$creative-explorer;
        }

        &.Fram_PSGMT_12 {
          color: theme.$social-curator;
        }
      }

      .custom-mobile-stepper-button {
        &.MuiButton-root {
          &.Mui-disabled {
            background-color: transparent;

            .MuiSvgIcon-root {
              color: theme.$ab-yellow;
            }
          }
        }
      }

      .MuiMobileStepper-dot {
        background-color: grey;

        &.MuiMobileStepper-dotActive {
          background-color: theme.$ab-yellow;
        }
      }

      &.Fram_PSGMT_2 {
        .MuiMobileStepper-dotActive {
          background-color: theme.$comfort-craver;
        }
      }

      &.Fram_PSGMT_3 {
        .MuiMobileStepper-dotActive {
          background-color: theme.$loyal-traditionalist;
        }
      }

      &.Fram_PSGMT_5 {
        .MuiMobileStepper-dotActive {
          background-color: theme.$balanced-enjoyers;
        }
      }

      &.Fram_PSGMT_6 {
        .MuiMobileStepper-dotActive {
          background-color: theme.$diligent-discoverer;
        }
      }

      &.Fram_PSGMT_8 {
        .MuiMobileStepper-dotActive {
          background-color: theme.$carefree-fun-lover;
        }
      }

      &.Fram_PSGMT_9 {
        .MuiMobileStepper-dotActive {
          background-color: theme.$proud-celebrator;
        }
      }

      &.Fram_PSGMT_11 {
        .MuiMobileStepper-dotActive {
          background-color: theme.$creative-explorer;
        }
      }

      &.Fram_PSGMT_12 {
        .MuiMobileStepper-dotActive {
          background-color: theme.$social-curator;
        }
      }
    }

    .quote-mark {
      height: 50px;
      font-family: Zuume-Black;
      font-size: 140px;
      font-style: normal;
      line-height: 90%;
      letter-spacing: 1.72px;
    }

    .quote-class {
      display: flex;
      align-self: flex-start;
      justify-content: flex-start;
      flex: 1;
      color: var(--Grayscale-White, #fff);
      font-family: Inter-SemiBold;
      font-size: 20px;
      font-style: italic;
      line-height: 130%;

      &.ds-bigquote {
        display: flex;
        align-items: center;
        justify-content: center;
        color: var(--Global-Colors-AB-Light-Gold, #F6E003);
        text-align: center;
        font-family: Zuume-Black;
        font-size: 43px;
        font-style: normal;
        line-height: 90%;
        /* 38.7px */
        letter-spacing: 1.72px;

        @media screen and (width < 100rem) {
          font-size: 32.5px;
        }

        //Personas
        &.Fram_PSGMT_2 {
          color: theme.$comfort-craver;
        }

        &.Fram_PSGMT_3 {
          color: theme.$loyal-traditionalist;
        }

        &.Fram_PSGMT_5 {
          color: theme.$balanced-enjoyers;
        }

        &.Fram_PSGMT_6 {
          color: theme.$diligent-discoverer;
        }

        &.Fram_PSGMT_8 {
          color: theme.$carefree-fun-lover;
        }

        &.Fram_PSGMT_9 {
          color: theme.$proud-celebrator;
        }

        &.Fram_PSGMT_11 {
          color: theme.$creative-explorer;
        }

        &.Fram_PSGMT_12 {
          color: theme.$social-curator;
        }
      }

      @media screen and (width < 100rem) {
        font-size: 16px;
      }

      // &.large-font{
      //   font-size: 28px;
      // }
    }

    .video-class {
      //height: calc((theme.$max-view-height - 1rem)/2);
      //max-height: calc((theme.$max-view-height - 1rem)/2);
      min-height: calc((theme.$max-view-height - 1rem)/2);
      overflow: hidden;
      border-radius: 16px 0 0 16px;

      display: flex;
      align-items: center;
      justify-content: center;

      img {
        width: 100%;
        height: 100%;
        object-fit: cover;
      }

      video {
        object-fit: contain;
        height: calc((100vh - 3.125rem - 2.5rem - 1rem) / 2);
      }

      .demandspace-video {
        object-fit: contain;
        height: calc((100vh - 3.125rem - 2.5rem - 1rem) / 2);
      }
    }

    .text-comp {
      display: flex;
      height: 100%;
      min-height: calc((theme.$max-view-height - 1rem)/2);
      flex-direction: column;
      justify-content: center;
      align-items: flex-start;
      gap: 0.5rem;
      flex: 1 0 0;
      align-self: stretch;
      background: #1d1d1d;
      //border-radius: 0 16px 16px 0;
      border-radius: 0 1rem 1rem 0;

      .text-approach-quote-mark {
        height: 50px;
        font-family: Zuume-Black;
        font-size: 140px;
        font-style: normal;
        line-height: 90%;
        letter-spacing: 1.72px;
        color: yellow;
      }

      .custom-mobile-stepper {
        max-width: 100%;
        // flex-grow: 1;
        display: flex;
        justify-content: center;
        align-items: center;
        background-color: theme.$black;

        .nextButton,
        .backButton {
          &.Fram_PSGMT_2 {
            color: theme.$comfort-craver;
          }

          &.Fram_PSGMT_3 {
            color: theme.$loyal-traditionalist;
          }

          &.Fram_PSGMT_5 {
            color: theme.$balanced-enjoyers;
          }

          &.Fram_PSGMT_6 {
            color: theme.$diligent-discoverer;
          }

          &.Fram_PSGMT_8 {
            color: theme.$carefree-fun-lover;
          }

          &.Fram_PSGMT_9 {
            color: theme.$proud-celebrator;
          }

          &.Fram_PSGMT_11 {
            color: theme.$creative-explorer;
          }

          &.Fram_PSGMT_12 {
            color: theme.$social-curator;
          }
        }

        .custom-mobile-stepper-button {
          &.MuiButton-root {
            &.Mui-disabled {
              background-color: transparent;

              .MuiSvgIcon-root {
                color: theme.$ab-yellow;
              }
            }
          }
        }

        .MuiMobileStepper-dot {
          background-color: grey;

          &.MuiMobileStepper-dotActive {
            background-color: theme.$ab-yellow;
          }
        }

        &.Fram_PSGMT_2 {
          .MuiMobileStepper-dotActive {
            background-color: theme.$comfort-craver;
          }
        }

        &.Fram_PSGMT_3 {
          .MuiMobileStepper-dotActive {
            background-color: theme.$loyal-traditionalist;
          }
        }

        &.Fram_PSGMT_5 {
          .MuiMobileStepper-dotActive {
            background-color: theme.$balanced-enjoyers;
          }
        }

        &.Fram_PSGMT_6 {
          .MuiMobileStepper-dotActive {
            background-color: theme.$diligent-discoverer;
          }
        }

        &.Fram_PSGMT_8 {
          .MuiMobileStepper-dotActive {
            background-color: theme.$carefree-fun-lover;
          }
        }

        &.Fram_PSGMT_9 {
          .MuiMobileStepper-dotActive {
            background-color: theme.$proud-celebrator;
          }
        }

        &.Fram_PSGMT_11 {
          .MuiMobileStepper-dotActive {
            background-color: theme.$creative-explorer;
          }
        }

        &.Fram_PSGMT_12 {
          .MuiMobileStepper-dotActive {
            background-color: theme.$social-curator;
          }
        }
      }
    }

    .text-approach {
      color: var(--Grayscale-White, #fff);
      font-family: Inter-SemiBold;
      font-size: 20px;
      font-style: italic;
      line-height: 130%;
      /* 20.8px */
      letter-spacing: 0.64px;
      padding: 0 1.25rem;

      .text-approach-quote-mark {
        height: 50px;
        font-family: Zuume-Black;
        font-size: 140px;
        font-style: normal;
        line-height: 90%;
        letter-spacing: 1.72px;
        color: yellow;
      }

      @media screen and (width < 100rem) {
        font-size: 16px;
      }
    }

    .text-comp-xs-12 {
      display: flex;
      // height: 100%;
      min-height: calc((theme.$max-view-height - 1rem)/2);
      flex-direction: column;
      justify-content: center;
      align-items: flex-start;
      gap: 8px;
      flex: 1 0 0;
      align-self: stretch;
      background: #1d1d1d;
      border-radius: 16px;
    }

    .text-comp-container {
      display: flex;
      flex-direction: column;
      min-height: calc((theme.$max-view-height - 1rem)/2);

      .text-comp-ds {
        display: flex;
        // height: 100%;
        // min-height: calc((theme.$max-view-height - 1rem)/2);
        flex-direction: column;
        padding: 24px;
        justify-content: flex-start;
        align-items: flex-start;
        gap: 0.5rem;
        flex: 1 0 0;
        align-self: stretch;
        background: #1d1d1d;
        //border-radius: 0 16px 16px 0;
        border-radius: 0 1rem 1rem 0;

        .text-approach-quote-mark-ds {
          // height: 50px;
          font-family: Zuume-Black;
          font-size: 140px;
          font-style: normal;
          line-height: 90%;
          letter-spacing: 1.72px;
          color: yellow;

          @media screen and (width < 100rem) {
            font-size: 75px;
          }
        }

        .text-approach-ds {
          color: var(--Grayscale-White, #fff);
          font-family: Inter-SemiBold;
          font-size: 20px;
          font-style: italic;
          line-height: 130%;
          /* 20.8px */
          letter-spacing: 0.64px;
          padding: 0 1.25rem;

          &.card-1 {
            color: theme.$black;
          }

          @media screen and (width < 100rem) {
            font-size: 16px;
          }
        }
      }

      .custom-mobile-stepper {
        max-width: 100%;
        // flex-grow: 1;
        display: flex;
        justify-content: center;
        align-items: center;
        background-color: theme.$black;

        .nextButton,
        .backButton {
          &.Fram_PSGMT_2 {
            color: theme.$comfort-craver;
          }

          &.Fram_PSGMT_3 {
            color: theme.$loyal-traditionalist;
          }

          &.Fram_PSGMT_5 {
            color: theme.$balanced-enjoyers;
          }

          &.Fram_PSGMT_6 {
            color: theme.$diligent-discoverer;
          }

          &.Fram_PSGMT_8 {
            color: theme.$carefree-fun-lover;
          }

          &.Fram_PSGMT_9 {
            color: theme.$proud-celebrator;
          }

          &.Fram_PSGMT_11 {
            color: theme.$creative-explorer;
          }

          &.Fram_PSGMT_12 {
            color: theme.$social-curator;
          }
        }

        .custom-mobile-stepper-button {
          &.MuiButton-root {
            &.Mui-disabled {
              background-color: transparent;

              .MuiSvgIcon-root {
                color: theme.$ab-yellow;
              }
            }
          }
        }

        .MuiMobileStepper-dot {
          background-color: grey;

          &.MuiMobileStepper-dotActive {
            background-color: theme.$ab-yellow;
          }
        }

        &.Fram_PSGMT_2 {
          .MuiMobileStepper-dotActive {
            background-color: theme.$comfort-craver;
          }
        }

        &.Fram_PSGMT_3 {
          .MuiMobileStepper-dotActive {
            background-color: theme.$loyal-traditionalist;
          }
        }

        &.Fram_PSGMT_5 {
          .MuiMobileStepper-dotActive {
            background-color: theme.$balanced-enjoyers;
          }
        }

        &.Fram_PSGMT_6 {
          .MuiMobileStepper-dotActive {
            background-color: theme.$diligent-discoverer;
          }
        }

        &.Fram_PSGMT_8 {
          .MuiMobileStepper-dotActive {
            background-color: theme.$carefree-fun-lover;
          }
        }

        &.Fram_PSGMT_9 {
          .MuiMobileStepper-dotActive {
            background-color: theme.$proud-celebrator;
          }
        }

        &.Fram_PSGMT_11 {
          .MuiMobileStepper-dotActive {
            background-color: theme.$creative-explorer;
          }
        }

        &.Fram_PSGMT_12 {
          .MuiMobileStepper-dotActive {
            background-color: theme.$social-curator;
          }
        }
      }
    }

    .text-comp-container {
      display: flex;
      flex-direction: column;
      min-height: calc((theme.$max-view-height - 1rem)/2);

      .text-comp-xs-12-ds {
        display: flex;
        // min-height: 100%;
        // min-height: calc((theme.$max-view-height - 1rem)/2);
        padding: 24px;
        flex-direction: column;
        justify-content: flex-start;
        align-items: flex-start;
        gap: 8px;
        flex: 1 0 0;
        align-self: stretch;
        background: #1d1d1d;
        border-radius: 16px;

        &.card-1 {
          &.Fram_PSGMT_2 {
            background: theme.$comfort-craver;
          }

          &.Fram_PSGMT_3 {
            background: theme.$loyal-traditionalist;
          }

          &.Fram_PSGMT_5 {
            background: theme.$balanced-enjoyers;
          }

          &.Fram_PSGMT_6 {
            background: theme.$diligent-discoverer;
          }

          &.Fram_PSGMT_8 {
            background: theme.$carefree-fun-lover;
          }

          &.Fram_PSGMT_9 {
            background: theme.$proud-celebrator;
          }

          &.Fram_PSGMT_11 {
            background: theme.$creative-explorer;
          }

          &.Fram_PSGMT_12 {
            background: theme.$social-curator;
          }
        }

        .text-approach-quote-mark-ds {
          // height: 50px;
          font-family: Zuume-Black;
          font-size: 140px;
          font-style: normal;
          line-height: 90%;
          letter-spacing: 1.72px;
          color: yellow;

          @media screen and (width < 100rem) {
            font-size: 75px;
          }
        }

        .text-approach-ds {
          color: var(--Grayscale-White, #fff);
          font-family: Inter-SemiBold;
          font-size: 20px;
          font-style: italic;
          line-height: 130%;
          /* 20.8px */
          letter-spacing: 0.64px;
          // padding: 0 1.25rem;

          &.card-1 {
            color: theme.$black;
          }

          @media screen and (width < 100rem) {
            font-size: 16px;
          }
        }

        .custom-mobile-stepper {
          max-width: 100%;
          // flex-grow: 1;
          display: flex;
          justify-content: center;
          align-items: center;
          background-color: theme.$black;

          .nextButton,
          .backButton {
            &.Fram_PSGMT_2 {
              color: theme.$comfort-craver;
            }

            &.Fram_PSGMT_3 {
              color: theme.$loyal-traditionalist;
            }

            &.Fram_PSGMT_5 {
              color: theme.$balanced-enjoyers;
            }

            &.Fram_PSGMT_6 {
              color: theme.$diligent-discoverer;
            }

            &.Fram_PSGMT_8 {
              color: theme.$carefree-fun-lover;
            }

            &.Fram_PSGMT_9 {
              color: theme.$proud-celebrator;
            }

            &.Fram_PSGMT_11 {
              color: theme.$creative-explorer;
            }

            &.Fram_PSGMT_12 {
              color: theme.$social-curator;
            }
          }

          .custom-mobile-stepper-button {
            &.MuiButton-root {
              &.Mui-disabled {
                background-color: transparent;

                .MuiSvgIcon-root {
                  color: theme.$ab-yellow;
                }
              }
            }
          }

          .MuiMobileStepper-dot {
            background-color: grey;

            &.MuiMobileStepper-dotActive {
              background-color: theme.$ab-yellow;
            }
          }

          &.Fram_PSGMT_2 {
            .MuiMobileStepper-dotActive {
              background-color: theme.$comfort-craver;
            }
          }

          &.Fram_PSGMT_3 {
            .MuiMobileStepper-dotActive {
              background-color: theme.$loyal-traditionalist;
            }
          }

          &.Fram_PSGMT_5 {
            .MuiMobileStepper-dotActive {
              background-color: theme.$balanced-enjoyers;
            }
          }

          &.Fram_PSGMT_6 {
            .MuiMobileStepper-dotActive {
              background-color: theme.$diligent-discoverer;
            }
          }

          &.Fram_PSGMT_8 {
            .MuiMobileStepper-dotActive {
              background-color: theme.$carefree-fun-lover;
            }
          }

          &.Fram_PSGMT_9 {
            .MuiMobileStepper-dotActive {
              background-color: theme.$proud-celebrator;
            }
          }

          &.Fram_PSGMT_11 {
            .MuiMobileStepper-dotActive {
              background-color: theme.$creative-explorer;
            }
          }

          &.Fram_PSGMT_12 {
            .MuiMobileStepper-dotActive {
              background-color: theme.$social-curator;
            }
          }
        }
      }
    }
  }

  .selected {
    filter: grayscale(100%);
  }

  .comfort-craver-border {
    border: 4px solid #b983ff !important;
  }

  .loyal-traditionalist-border {
    border: 4px solid #ada1f8 !important;
  }

  .carefree-fun-lover-border {
    border: 4px solid #8fe8bd !important;
  }

  .proud-celebrator-border {
    border: 4px solid #79d271 !important;
  }

  .creative-explorer-border {
    border: 4px solid #a1e8f8 !important;
  }

  .social-curator-border {
    border: 4px solid #83bcff !important;
  }

  .mature-discoverer-border {
    border: 4px solid #ffc759 !important;
  }

  .diligent-discoverer-border {
    border: 4px solid #ff8811 !important;
  }

  .demandspacecolor-border {
    border: 4px solid #f6e003 !important;
  }

  .comfort-craver-main-avatar-border {
    border: 8px solid #b983ff !important;
  }

  .loyal-traditionalist-main-avatar-border {
    border: 8px solid #ada1f8 !important;
  }

  .carefree-fun-lover-main-avatar-border {
    border: 8px solid #8fe8bd !important;
  }

  .proud-celebrator-main-avatar-border {
    border: 8px solid #79d271 !important;
  }

  .creative-explorer-main-avatar-border {
    border: 8px solid #a1e8f8 !important;
  }

  .social-curator-main-avatar-border {
    border: 8px solid #83bcff !important;
  }

  .mature-discoverer-main-avatar-border {
    border: 8px solid #ffc759 !important;
  }

  .diligent-discoverer-main-avatar-border {
    border: 8px solid #ff8811 !important;
  }

  .demandspacecolor-main-avatar-border {
    border: 8px solid #f6e003 !important;
  }
}

.comfort-craver {
  //Comfort Cravers
  color: theme.$comfort-craver !important;
  &.card-1 {
    color: theme.$black !important;
  }
}

.loyal-traditionalist {
  //Loyal Traditionalists
  color: theme.$loyal-traditionalist !important;
  &.card-1 {
    color: theme.$black !important;
  }
}

.mature-discoverer {
  //Balanced Enjoyers
  color: theme.$balanced-enjoyers !important;
  &.card-1 {
    color: theme.$black !important;
  }
}

.diligent-discoverer {
  //Diligent Discoverers
  color: theme.$diligent-discoverer !important;
  &.card-1 {
    color: theme.$black !important;
  }
}

.carefree-fun-lover {
  //Carefree Fun-Lovers
  color: theme.$carefree-fun-lover !important;
  &.card-1 {
    color: theme.$black !important;
  }
}

.proud-celebrator {
  //Proud Celebrators
  color: theme.$proud-celebrator !important;
  &.card-1 {
    color: theme.$black !important;
  }
}

.creative-explorer {
  //Creative Explorers
  color: theme.$creative-explorer !important;
  &.card-1 {
    color: theme.$black !important;
  }
}

.social-curator {
  //Social Curators
  color: theme.$social-curator !important;
  &.card-1 {
    color: theme.$black !important;
  }
}