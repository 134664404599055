@use "theme.scss";

.main-content {
  .hero {
    display: flex;
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
    // margin-top: 3.8rem;
    //min-height: 38.75rem;
    min-height: calc(theme.$max-view-height - 2*theme.$runner-height);
    background-color: lightgray;

    .hero-content-container {
      display: flex;
      margin: 7rem var(--spacing-horizontal-margin, 6.25rem) 5rem
        var(--spacing-horizontal-margin, 6.25rem);
      flex-direction: column;
      justify-content: flex-end;
      align-items: flex-start;
      gap: 9.5rem;

      .hero-intro-container {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        max-width: 32rem;

        .title {
          color: var(--grayscale-white, theme.$white);
          font-family: Zuume-Black;
          font-size: 8rem;
          font-style: normal;
          line-height: 90%;
          text-transform: uppercase;
          margin-top: 10rem;
          letter-spacing: 1px;
          @media screen and (width < 100rem) {
            font-size: 6rem;
          }
        }
        .subtitle {
          color: var(--grayscale-white, theme.$white);
          font-family: Inter-Bold;
          font-style: normal;
          font-size: 1.75rem;
          line-height: 130%;
          width: 42rem;
          @media screen and (width < 100rem) {
            font-size: 1.3125rem;
          }
          span,strong {
            color: theme.$ab-yellow;
            font-style: normal;
            display: contents;
          }
          .color-text {
            color: var(--grayscale-yellow, theme.$ab-yellow);
          }
        }
      }
    }
  }

  .occasions-hub-section2 {
    display: flex;
    flex-direction: column;
    align-items: center;
    //gap: var(--Spacing-Section, 9.375rem);
    gap: 6.25rem;
    align-self: stretch;
    width: 100vw;

    .section1-background {
      display: flex;
      flex-direction: column;
      background: linear-gradient(180deg, #29270b 0%, #000 18.61%);

      .ugc-container {
        display: flex;
        flex-direction: column;
        align-items: center;
        gap: var(--spacing-section, 2.5rem);
        align-self: stretch;
        margin: var(--spacing-xxl, 6.25rem) var(--spacing-none, 6.25rem)
          var(--spacing-section, 0) var(--spacing-none, 6.25rem);
      }

      .section1-main {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        gap: 40px;

        .section1-sub1 {
          display: flex;
          align-items: flex-start;
          flex-wrap: wrap;
          justify-content: center;
          gap: 40px;
          @media (max-width: 1200px) {
            flex-direction: column;
          }
        }
      }
    }

    .occasion-hub-section2-background {
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      color: var(--grayscale-white, theme.$white);

      .occasion-hub-section2-main {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        margin: 6.25rem;
        max-height: calc(theme.$max-view-height + theme.$anchor-nav-height);
      }
    }

    .key-occassion-elements-main {
      display: flex;
      margin: var(--spacing-none, 0px) var(--spacing-xxl, 6.5rem);
      flex-direction: column;
      justify-content: center;
      align-items: center;
      gap: var(--spacing-section, 6.5rem);
      align-self: stretch;
      max-height: calc(theme.$max-view-height + theme.$anchor-nav-height);

      .key-occassion-elements {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        gap: var(--spacing-s, 20px);

        .key-occassion-text {
          color: var(--global-colors-ab-light-gold, var(--Actions, #f6e003));
          font-family: Zuume-Black;
          font-style: normal;
          line-height: 150%;
          letter-spacing: 2.4px;
          text-transform: uppercase;

          @media screen and (width < 100rem) {
            font-size: 3.75rem;
          }
        }
        .key-occassion-subtext {
          width: 65%;
          color: var(--grayscale-white, #fff);
          text-align: center;
          font-family: Inter-Bold;
          font-size: 1.75rem;
          font-style: normal;
          line-height: 130%;
          @media screen and (width < 100rem) {
            font-size: 1.3125rem;
          }

          i {
            font-style: normal;
            color: theme.$ab-yellow;
          }
        }
      }

      .key-occassion-cards-main {
        display: flex;
        justify-content: center;
        align-items: flex-start;
        align-self: stretch;

        .key-occassion-cards {
          display: flex;
          min-height: 18.5rem;
          flex-direction: column;
          align-items: center;
          gap: var(--Spacing-S, 1.25rem);
          flex: 1 0 0;
          border-radius: 1rem;
          background: var(--grayscale-800, #1d1d1d);

          .card-content {
            display: flex;
            padding: 1.75rem;
            flex-direction: column;
            align-items: center;
            justify-content: center;
            height: 100%;
            align-self: stretch;
            gap: var(--Spacing-S, 20px);
            flex: 1 0 0;

            .card-icon {
              width: 4.5rem;
              height: 4.5rem;
            }

            .card-text {
              display: flex;
              flex-direction: column;
              justify-content: flex-start;
              align-items: center;
              gap: var(--Spacing-XS, 0.75rem);
              align-self: stretch;

              .card-title {
                align-self: stretch;
                color: var(--grayscale-white, #fff);
                background: var(--grayscale-800, #1d1d1d);
                text-align: center;
                font-family: Zuume-Black;
                font-size: 43px;
                font-style: normal;
                line-height: 90%;
                letter-spacing: 1.72px;
                @media screen and (width < 100rem) {
                  font-size: 32.25px;
                }
              }
              .card-description {
                align-self: stretch;
                color: var(--grayscale-300, #c7c7c7);
                text-align: center;
                font-family: Inter-Regular;
                font-size: 16px;
                font-style: normal;
                font-weight: 400;
                line-height: 130%;
                @media screen and (width < 100rem) {
                  font-size: 13px;
                }
              }
            }
          }
        }
      }
    }

    .macro-occassion-elements-main {
      display: flex;
      flex-direction: column;
      margin: var(--spacing-none, 0px) var(--spacing-xxl, 6.25rem);
      justify-content: center;
      align-items: center;
      gap: var(--spacing-section, 6.5rem);
      align-self: stretch;
      min-height: calc(theme.$max-view-height + theme.$anchor-nav-height);

      .macro-occassion-elements {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        gap: var(--spacing-s, 20px);

        .macro-occassion-text {
          color: var(--global-colors-ab-light-gold, var(--Actions, #f6e003));
          font-family: Zuume-Black;
          font-style: normal;
          line-height: 150%;
          letter-spacing: 2.4px;
          text-transform: uppercase;

          @media screen and (width < 100rem) {
            font-size: 3.75rem;
          }
        }

        .macro-occassion-subtext {
          width: 65%;
          color: var(--grayscale-white, #fff);
          text-align: center;
          font-family: Inter-Bold;
          font-size: 28px;
          font-style: normal;
          line-height: 130%;
          @media screen and (width < 100rem) {
            font-size: 1.3125rem;
          }
          i {
            color: var(--Global-Colors-AB-Light-Gold, #f6e003);
            font-style: normal;
          }
        }
      }

      .macro-occassion {
        display: flex;
        //margin: var(--spacing-none, 0px) var(--spacing-xxl, 6.25rem);
        flex-direction: column;
        justify-content: center;
        align-items: center;
        gap: var(--spacing-section, 150px);
        align-self: stretch;
        .macro-occassion-cards {
          min-height: 20rem;
          justify-content: flex-start;
          padding: var(--spacing-s, 2.5rem) var(--spacing-m, 2.5rem);
          .card-text-content-container {
            .macro-card-title {
              @media screen and (width < 100rem) {
                font-size: 3.75rem;
              }
            }
            .macro-card-description {
              @media screen and (width < 100rem) {
                font-size: 13px;
              }
            }
          }
        }
      }

      .micro-section {
        margin: 0px var(--spacing-xxl, 6.25rem);
  
        .micro-section-content-container {
          display: flex;
          flex-direction: column;
          justify-content: center;
          align-items: flex-start;
          align-self: stretch;
          gap: 24px;
          //padding: 0 6.25rem;
          .home-container {
            display: flex;
            padding: 0.5rem 0.5rem 0.5625rem 0.5rem;
            align-items: center;
            gap: 24px;
            align-self: stretch;
            height: 20px;
  
            .occasion-location-spectrum-container {
              display: flex;
              flex-direction: row;
              gap: 2rem;
              width: 100%;
  
              .off-premise-divider-box {
                width: 75%;
                display: flex;
                flex-direction: row;
                //gap: 1rem;
                align-items: center;
                justify-content: center;
              }
              .on-premise-divider-box {
                width: 25%;
                display: flex;
                flex-direction: row;
                //gap: 1rem;
                align-items: center;
                justify-content: center;
              }
  
              .dot {
                width: 0.5rem;
                height: 0.5rem;
                border-radius: 50%;
                background-color: #ffffff;
              }
  
              .location-spectrum-divider {
                display: flex;
                height: 1px;
                flex: 1 0;
                background-color: #ffffff;
              }
  
              .home-typo {
                color: var(--global-colors-ab-light-gold, theme.$ab-yellow);
                font-family: Zuume-Black;
                font-size: 20px;
                font-style: normal;
                line-height: 100%;
                letter-spacing: 1px;
                text-transform: uppercase;
                margin: 0 1rem;
                @media screen and (width < 100rem) {
                  font-size: 16px;
                }
              }
            }
  
            .on-premise-divider {
              display: flex;
              border: 2px solid #ffffff;
              flex: 1 0;
            }
  
            .home-typo {
              color: var(--global-colors-ab-light-gold, #f6e003);
              font-family: Zuume-Black;
              font-size: 20px;
              font-style: normal;
              line-height: 100%;
              letter-spacing: 1px;
              text-transform: uppercase;
              @media screen and (width < 100rem) {
                font-size: 16px;
              }
            }
  
            // .top-arrow-container {
            //   display: flex;
            //   padding: 8px;
            //   align-items: center;
            //   gap: 8px;
            //   flex: 1 0 0;
  
            //   .top-left-arrow {
            //     width: 654px;
            //     stroke-width: 4px;
            //     stroke: var(--Grayscale-White, #fff);
            //   }
  
            //   .top-right-arrow {
            //     width: 136px;
            //     stroke-width: 4px;
            //     stroke: var(--Grayscale-White, #fff);
            //   }
            // }
          }
          .tempo-container {
            display: flex;
            flex-direction: column;
            padding: 0.5rem 0.5rem 0.5625rem 0.5rem;
            align-items: center;
            gap: 0.5rem;
            align-self: stretch;
  
            .tempo-spectrum-divider-container {
              display: flex;
              flex-direction: row;
              align-items: center;
              justify-content: center;
              width: 100%;
            }
  
            .tempo-label-container {
              display: flex;
              align-items: center;
              justify-content: space-between;
              align-self: stretch;
              width: 100%;
            }
  
            .arrow {
              fill: theme.$white;
              stroke: theme.$white;
              color: theme.$white;
              &.right {
                transform: rotate(180deg);
              }
            }
  
            .location-spectrum-divider {
              display: flex;
              height: 1px;
              flex: 1 0;
              background-color: #ffffff;
            }
  
            .tempo-typo {
              color: var(--Global-Colors-AB-Light-Gold, theme.$ab-yellow);
              text-align: center;
              font-family: Zuume-Black;
              font-size: 20px;
              font-style: normal;
              line-height: 100%;
              letter-spacing: 1px;
              text-transform: uppercase;
              &.left {
                align-self: flex-start;
              }
              &.right {
                align-self: flex-end;
              }
              @media screen and (width < 100rem) {
                font-size: 16px;
              }
            }
  
            .tempo-typo-info {
              color: var(--Grayscale-White, theme.$white);
              text-align: center;
              /* Desktop/Body/Small/Small Regular */
              font-family: Inter-Regular;
              font-size: 16px;
              font-style: normal;
              font-weight: 400;
              line-height: 130%;
            }
  
            // .bottom-arrow {
            //   width: 803px;
            //   flex: 1 0 0;
            //   stroke-width: 4px;
            //   stroke: var(--Grayscale-White, #fff);
            // }
          }
  
          .micro-section-cards-main {
            display: flex;
            //padding: 8px;
            justify-content: center;
            align-items: center; //flex-start;
            //gap: 8px;
            flex: 1 0;
            align-self: stretch;
  
            .micro-section-card-div {
              display: flex;
              padding: 2px 4px 4px 4px;
              flex-direction: column;
              align-items: flex-start;
              align-self: stretch;
              gap: 8px;
              border-radius: var(--Radius-L, 24px);
              border: 1px solid rgba(255, 255, 255, 0.1);
              border-bottom-width: 0;
              background: linear-gradient(
                180deg,
                rgba(255, 255, 255, 0.1) 0%,
                rgba(255, 255, 255, 0) 100%
              );
  
              .micro-section-card-heading-container {
                display: flex;
                padding: 8px 0px;
                justify-content: center;
                gap: 8px;
                align-self: stretch;
  
                .micro-section-card-heading {
                  align-self: "stretch";
                  color: var(--grayscale-300, #c7c7c7);
                  text-align: center;
                  font-family: Inter-Bold;
                  font-size: 14px;
                  font-style: normal;
                  line-height: 130%;
                  letter-spacing: -0.3px;
                  @media screen and (width < 100rem) {
                    font-size: 12px;
                  }
                }
              }
              .micro-section-card-box {
                display: flex;
                align-items: flex-start;
                //gap: 8px;
  
                .micro-section-card-container {
                  display: flex;
                  flex-direction: column;
                  align-items: center;
                  gap: 8px;
  
                  .micro-section-card-content {
                    box-sizing: border-box;
                    display: flex;
                    //width: 90px;
                    //min-width: 6.4rem;
                    height: 81px;
                    //min-width: 90px;
                    padding: var(--Spacing-XS, 12px) var(--Spacing-XXS, 4px);
                    flex-direction: column;
                    justify-content: center;
                    align-items: center;
                    align-self: stretch;
                    gap: 8px;
                    border-radius: var(--Radius-S, 8px);
                    background: var(--Grayscale-700, #3d3d3d);
  
                    .micro-section-card-content-text {
                      color: var(--Grayscale-White, #fff);
                      text-align: center;
                      font-family: Inter-Bold;
                      font-size: 15px;
                      font-style: normal;
                      line-height: 130%;
                      letter-spacing: -0.3px;
                      text-transform: capitalize;
                      @media screen and (width < 100rem) {
                        font-size: 12px;
                      }
                    }
                  }
                  .micro-section-card-icon {
                    width: 24px;
                    height: 24px;
                    fill: var(--Grayscale-White, #fff);
                  }
                }
              }
            }
          }
          .full-meal-container {
            display: flex;
            justify-content: space-between;
            opacity: 0.5;
            align-items: center;
            align-self: stretch;
  
            .meal-disclaimer-container {
              display: flex;
              align-items: center;
              gap: 8px;
  
              .full-meal-icon-container {
                display: flex;
                width: 24px;
                height: 24px;
                justify-content: center;
                align-items: center;
                gap: 8px;
                border-radius: var(--Radius-M, 16px);
                background: var(--Grayscale-White, #fff);
  
                .full-meal-icon {
                  width: 16px;
                  height: 16px;
                  flex-shrink: 0;
                }
              }
  
              .meal-disclaimer {
                color: #fff;
                font-family: Inter-Medium;
                font-size: 17.241px;
                font-style: normal;
                line-height: 130%;
                letter-spacing: -0.345px;
                @media screen and (width < 100rem) {
                  font-size: 12.93px;
                }
              }
            }
  
            .hosted-meal-container {
              display: flex;
              padding-left: 10px;
              align-items: flex-end;
              gap: 3px;
  
              .hosted-meal {
                color: #fff;
                font-family: Inter-Medium;
                font-size: 17.241px;
                font-style: normal;
                line-height: 130%;
                letter-spacing: -0.345px;
                @media screen and (width < 100rem) {
                  font-size: 12.93px;
                }
              }
            }
          }
        }
      }
    }
  }

  .section3-background {
    background: theme.$black;

    .ugc-container {
      display: flex;
      flex-direction: column;
      align-items: center;
      gap: var(--spacing-section, 2.5rem);
      align-self: stretch;
      margin: 6.25rem;
      max-height: calc(theme.$max-view-height + theme.$anchor-nav-height);
    }
  }

  .section4-occassions {
    display: flex;
    max-height: calc(theme.$max-view-height + theme.$anchor-nav-height);
    margin: 6.25rem;

    .section4-main {
      display: flex;
      align-items: flex-start;
      justify-content: space-between;

      .section-left-item {
        display: flex;
        flex-direction: column;
        // align-self: stretch;
        flex: 1 0 0;
        gap: 7.5rem;
        // height: 39.125rem;
        .divider {
          display: flex;
          flex: 1 0 0;
          border: 2px solid #f6e003;
          width: 13rem;
        }

        .section4-title-container {
          display: flex;
          flex-direction: column;
          margin: 0;
          gap: 1.25rem;

          .section-heading {
            align-self: stretch;
            color: var(--Grayscale-White, #fff);
            font-family: Zuume-Black;
            font-size: 128px;
            font-style: normal;
            line-height: 80%; /* 102.4px */
            text-transform: uppercase;
            letter-spacing: 0;
            @media screen and (width < 100rem) {
              font-size: 96px;
            }
          }

          .outlined-button {
            width: 50%;
          }
        }
      }

      .section-right-item {
        align-self: flex-end;

        .visual-container {
          position: relative;
          display: flex;
          align-self: stretch;
          flex-direction: column;
          min-width: max-content;
          margin-left: auto;

          .button-to-ds {
            position: absolute;
            z-index: 1;
            background-color: theme.$ab-yellow;
            color: theme.$black;
            border-radius: 2rem;
            top: 47.25%;
            left: 45%;
            // bottom: 8%;

            display: flex;
            flex-direction: row;
            justify-content: center;
            align-items: center;
            padding: 10px 24px 10px 16px;
            border-radius: 6.25rem;
            color: var(--grayscale-black, theme.$black);
            text-align: center;
            font-family: Inter-Bold;
            font-size: 15px;
            font-style: normal;
            line-height: 130%;
            letter-spacing: -0.3px;
            text-transform: none;
            @media screen and (width < 100rem) {
              font-size: 12px;
              left: 40%;
            }
          }
        }
      }
    }
  }

  .section5-occassions {
    display: flex;
    margin: 6.25rem;
  }
}

.section4-typo {
  align-self: stretch;
  color: var(--grayscale-white, #fff);
  font-family: Zuume;
  font-size: 6rem;
  padding-top: 5rem;
  font-style: normal;
  font-weight: 900;
  line-height: 80%;
  text-transform: uppercase;
}
.people-segments-head {
  // color: var(--grayscale-700, #3d3d3d);
  margin-bottom: 1rem;
  text-align: center;
  font-family: Inter-Bold;
  font-size: 18px;
  font-style: normal;
  line-height: 130%; /* 23.4px */
  text-transform: uppercase;
}
.dim-color {
  color: var(--Grayscale-700, #3d3d3d);
}
.highlight-color {
  color: var(--grayscale-white, #fff);
}
.people-segments-titles {
  color: var(--global-colors-ab-light-gold, var(--Actions, #f6e003));
  text-align: right;
  font-family: Inter-Black;
  font-size: 13.5px;
  font-style: normal;
  white-space: nowrap;
  line-height: 150%; /* 20.25px */
  letter-spacing: 0.405px;
  text-transform: uppercase;
}
.people-segments-titles-disabled {
  color: var(--global-colors-ab-light-gold, var(--Actions, #d4c100));
  text-align: right;
  font-family: Inter-Black;
  font-size: 13.5px;
  font-style: normal;
  line-height: 150%; /* 20.25px */
  letter-spacing: 0.405px;
  text-transform: uppercase;
}
.people-segments-vertical-text {
  display: flex;
  width: 243.829px;
  height: 21.469px;
  transform: rotate(180deg);
  writing-mode: vertical-rl;
  flex-direction: column;
  justify-content: center;
  flex-shrink: 0;

  text-align: center;
  font-family: Inter-Bold;
  font-size: 18px;
  font-style: normal;
  line-height: 130%; /* 23.4px */
  text-transform: uppercase;
}

.people-segments-headings {
  text-align: center;
  font-family: Inter-Black;
  font-size: 11.5px;
  font-style: normal;
  line-height: 150%; /* 20.25px */
  letter-spacing: 0.405px;
  text-transform: uppercase;
  white-space: break-spaces;
}
.common-bg {
  background: #1d1d1d !important;
}
.people-segments-headings-main {
  margin-left: 90px;
}
.comfort-craver-background {
  background: theme.$comfort-craver !important;
}
.loyal-traditionalist-background {
  background: theme.$loyal-traditionalist !important;
}
.carefree-fun-lover-background {
  background: theme.$carefree-fun-lover !important;
}
.proud-celebrator-background {
  background: theme.$proud-celebrator !important;
}
.creative-explorer-background {
  background: theme.$creative-explorer !important;
}
.social-curator-background {
  background: theme.$social-curator !important;
}
.mature-discoverer-background {
  background: theme.$balanced-enjoyers !important;
}
.diligent-discoverer-background {
  background: theme.$diligent-discoverer !important;
}

.familiar-reserved {
  color: #ada1f8;
}
.familiar-outgoing {
  color: #79d271;
}
.exploratory-outgoing {
  color: #83bcff;
}
.exploratory-reserved {
  color: #f81;
}

.demand-landscape-click-container {
  display: inline-flex;
  padding: 0px 30.6px;
  flex-direction: column;
  justify-content: flex-end;
  align-items: flex-start;
  gap: 9px;
  // margin-left: 195px;
  margin-top: 18.03px;

  .interaction-tool-tip {
    display: flex;
    align-items: center;
    gap: 4px;

    .info-icon {
      width: 24px;
      height: 24px;
    }
    .tool-tip-text {
      color: var(--Grayscale-White, #fff);
      font-family: Inter;
      font-size: 16px;
      font-style: normal;
      font-weight: 400;
      line-height: 130%;
      @media screen and (width < 100rem) {
        font-size: 13px;
      }
    }
  }
}

.MuiPopover-paper {
  background-color: transparent !important;
  color: #ffffff !important;
}
