.container {
  gap: 20px;
  display: flex;
  margin: 100px 0;
  width: 1224px;
}

@media (max-width: 991px) {
  .container {
    flex-direction: column;
    gap: 0;
  }
}

.contentColumn {
  display: flex;
  flex-direction: column;
  line-height: normal;
  width: 60%;
  margin-left: 0;
}

@media (max-width: 991px) {
  .contentColumn {
    width: 100%;
  }
}

.contentWrapper {
  display: flex;
  flex-direction: column;
}

@media (max-width: 991px) {
  .contentWrapper {
    max-width: 100%;
    margin-top: 33px;
  }
}

.textContent {
  display: flex;
  height: auto;
  width: 800px;
  flex-direction: column;
  text-transform: uppercase;
  justify-content: space-between;
}

@media (max-width: 991px) {
  .textContent {
    max-width: 100%;
  }
}

.titleContainer {
  display: flex;
  min-height: 246px;
  width: 100%;
  flex-direction: column;
  justify-content: space-between;
}

@media (max-width: 991px) {
  .titleContainer {
    max-width: 100%;
  }
}

.introText {
  color: rgba(245, 223, 66, 1);
  font: 600 24px/1 Work Sans, -apple-system, Roboto, Helvetica, sans-serif;
}

@media (max-width: 991px) {
  .introText {
    max-width: 100%;
  }
}

.mainTitle {
  color: #fff;
  margin-top: 7px;
  font: 800 75px/68px Barlow, -apple-system, Roboto, Helvetica, sans-serif;
  z-index: 1;
}

@media (max-width: 991px) {
  .mainTitle {
    max-width: 100%;
    font-size: 40px;
    line-height: 40px;
  }
}

.actionButton {
  justify-content: center;
  border-radius: 100px;
  background: #f6e003;
  display: flex;
  width: 255px;
  max-width: 100%;
  color: #000;
  text-align: center;
  letter-spacing: -0.3px;
  font: 700 15px/1.3 Inter, sans-serif;
  border: none;
  cursor: pointer;
}

.buttonContent {
  width: 100%;
  padding: 10px 24px;
}

@media (max-width: 991px) {
  .buttonContent {
    padding: 0 20px;
  }
}

.imageColumn {
  display: flex;
  flex-direction: column;
  line-height: normal;
  margin-left: 20px;
}

@media (max-width: 991px) {
  .imageColumn {
    width: 100%;
  }
}

.categoryImage {
  aspect-ratio: 1.3;
  object-fit: contain;
  object-position: center;
  width: 100%;
  border-radius: 16px;
  margin-top: -10px;
  flex-grow: 1;
  z-index: 0;
}

@media (max-width: 991px) {
  .categoryImage {
    max-width: 100%;
  }
}

.visually-hidden {
  position: absolute;
  width: 1px;
  height: 1px;
  padding: 0;
  margin: -1px;
  overflow: hidden;
  clip: rect(0, 0, 0, 0);
  white-space: nowrap;
  border: 0;
}
