.card {
  justify-content: space-between;
  border-radius: 8px;
  box-shadow: 0 0 4px 0 rgba(0, 0, 0, 0.16);
  position: absolute;
  z-index: 0;
  display: flex;
  min-height: 98px;
  max-width: 100%;
  width: 350px;
  inset: 0;
}

.cardWrapper {
  position: relative;
  display: flex;
  min-width: 240px;
  flex-direction: column;
  justify-content: space-between;
  width: 280px;
  height: 98px;
  padding: 0 24px;
}

.cardText {
  z-index: 1;
  font-family: Barlow, sans-serif;
  font-weight: 700;
  text-align: center;
  text-transform: uppercase;
  margin: auto 0;
}

@media (max-width: 991px) {
  .cardWrapper {
    padding: 0 20px;
  }
}
