.activeTab {
  font-family: Barlow;
  font-size: 24px;
  font-style: normal;
  font-weight: 700;
  border-radius: 64px;
  background-color: rgba(80, 75, 33, 1);
  color: #ffe500;
  flex: 1;
  padding: 0 16px;
  border: 3px solid rgba(245, 223, 66, 1);
  cursor: pointer;
}

.inactiveTab {
  font-family: Barlow;
  font-size: 24px;
  font-style: normal;
  font-weight: 700;
  border-radius: 64px;
  color: rgba(255, 255, 255, 0.32);
  background-color: transparent;
  flex: 1;
  padding: 0 16px;
  border: none;
  cursor: pointer;
}
