.hero {
  position: relative;
  max-height: 355px;
  width: 100%;
  margin: 0 auto;
  background: linear-gradient(
    90deg,
    rgba(241, 185, 26, 0.35) -3%,
    rgba(0, 0, 0, 0) 35%
  );
}

.heroImage {
  width: 100vw;
  max-height: 355px;
  object-fit: cover;
}

.heroContent {
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  padding: 80px 0;
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 1224px;
  margin: 0 auto;
}

.heroTitle {
  color: #fff;
  font-family: Barlow, sans-serif;
  font-size: 100px;
  font-weight: 900;
  line-height: 90px;
  text-transform: uppercase;
  margin: 0;
}

.stats {
  display: flex;
  gap: 16px;
}

@media (max-width: 991px) {
  .heroTitle {
    font-size: 40px;
  }
  
  .heroContent {
    flex-direction: column;
    align-items: center;
    gap: 24px;
  }
}
