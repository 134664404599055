@use "theme";

.divider-container {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;

  .arrow-back-icon {
    position: absolute;
    left: 0;
    height: 16px;
    width: 8px;
    color: #3d3d3d;
  }
  .arrow-forward-icon {
    position: absolute;
    right: 0;
    height: 16px;
    width: 8px;
    color: #3d3d3d;
    transform: rotate(180deg);
  }

  .divider-line {
    // position: absolute;
    left: 2px;
    right: 2px;
    display: flex;
    flex: 1 0;
    height: 4px;
    align-self: center;
    background-color: #3d3d3d;
  }
}
