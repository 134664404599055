@use "theme";

.carousel-with-mobile-stepper-container {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    gap: 20px;

    .carousel-card-container {
        padding: 20px 0;
        // min-height: 544px;
        display: flex;
        align-items: flex-start;
        //gap: var(--Spacing-S, 1.25rem);
        align-self: stretch;

        .modular-item {
            display: flex;
            // height: 500px;
            // max-height: 500px;
            justify-content: center;
            align-items: center;
            gap: 10px;
            //flex: 1 0 0;
            min-height: 100%;
            align-self: stretch;
        }
    }

    .mobile-stepper-container {
        display: flex;
        justify-content: center;
        align-items: center;
        gap: 16px;

        & .MuiMobileStepper-root {
            .MuiMobileStepper-dots {
                gap: 8px;
            }
        }

        & .MuiMobileStepper-dot {
            background-color: #ffffff4c;
        }

        // & .MuiMobileStepper-dotActive {
        //     background-color: theme.$white;
        // }

        .left-chevron-icon {
            .MuiSvgIcon-root {
                width: 1.5rem;
                height: 1.5rem;
            }
        }
        .right-chevron-icon {
            .MuiSvgIcon-root {
                width: 1.5rem;
                height: 1.5rem;
            }
        }

        &.Fram_PSGMT_2 {
            & .MuiMobileStepper-dotActive {
                background-color: theme.$comfort-craver;
            }
        }
        &.Fram_PSGMT_3 {
            & .MuiMobileStepper-dotActive {
                background-color: theme.$loyal-traditionalist;
            }
        }
        &.Fram_PSGMT_5 {
            & .MuiMobileStepper-dotActive {
                background-color: theme.$balanced-enjoyers;
            }
        }
        &.Fram_PSGMT_6 {
            & .MuiMobileStepper-dotActive {
                background-color: theme.$diligent-discoverer;
            }
        }
        &.Fram_PSGMT_8 {
            & .MuiMobileStepper-dotActive {
                background-color: theme.$carefree-fun-lover;
            }
        }
        &.Fram_PSGMT_9 {
            & .MuiMobileStepper-dotActive {
                background-color: theme.$proud-celebrator;
            }
        }
        &.Fram_PSGMT_11 {
            & .MuiMobileStepper-dotActive {
                background-color: theme.$creative-explorer;
            }
        }
        &.Fram_PSGMT_12 {
            & .MuiMobileStepper-dotActive {
                background-color: theme.$social-curator;
            }
        }
    }
}
