.quoteContent {
  background-color: rgba(29, 29, 29, 1);
  display: flex;
  min-width: 260px;
  flex-direction: column;
  overflow: hidden;
  color: #fff;
  justify-content: space-between;
  flex: 1;
  flex-basis: 0%;
  padding: 24px 20px;
}

.quoteIcon {
  aspect-ratio: 1.13;
  object-fit: contain;
  object-position: center;
  width: 34px;
}

.quoteText {
  font: italic 600 26px/27px Work Sans, -apple-system, Roboto, Helvetica, sans-serif;
}

.authorInfo {
  display: flex;
  width: 100%;
  flex-direction: column;
  justify-content: start;
}

.authorName {
  text-transform: uppercase;
  font: 700 24px Barlow, sans-serif;
}

.authorDetails {
  align-self: start;
  display: flex;
  margin-top: 4px;
  align-items: center;
  gap: 8px;
  justify-content: start;
  font: 500 16px/1 Work Sans, -apple-system, Roboto, Helvetica, sans-serif;
}

.detailDivider {
  align-self: stretch;
  width: 0;
  height: 22px;
  border: 1px solid #fff;
}
