@use "theme";

.dialog-content {
    //position: relative;
    overflow: hidden !important;
    /* AB Drop Shadow */
    box-shadow: 0px 4px 15px 0px #1C1737;
    display: flex;
    align-self: stretch;

    .card-content-box {
        display: flex;
        align-self: stretch;

        .card {
            display: flex;
            flex-direction: column;
            justify-content: space-between;
            align-self: stretch;
            .card-text-container {
                display: flex;
                flex-direction: column;
                align-items: center;
                gap: 16px;
                align-self: stretch;
                margin: 0 40px;
        
                .card-title-text {
                    color: var(--Grayscale-Black, #000);
                    text-align: center;
                    font-family: Graphik;
                    font-size: 32px;
                    font-style: normal;
                    font-weight: 700;
                    line-height: 100%; /* 32px */
                    letter-spacing: -1.28px;
                    display: flex;
                    width: 377px;
                    height: 27px;
                    flex-direction: column;
                    justify-content: center;
                }
        
                .card-description-text {
                    color: var(--Grayscale-Black, #000);
                    text-align: center;
                    font-family: Inter-Regular;
                    font-size: 16px;
                    font-style: normal;
                    font-weight: 400;
                    line-height: 130%; /* 20.8px */
                    @media screen and (width < 100rem) {
                        font-size: 13px;
                     }
                }
            }
        
            .blackDot {
                background-color: #1c1737;
            }
        
            .popup-stepper-container {
                display: flex;
                justify-content: space-between;
                padding: 0;
                justify-self: flex-end;
                .next-btn {
                    display: flex;
                    align-self: center;
                }
            }
        
            .next-btn {
                color: #000;
                text-align: center;
                font-family: Inter-Bold;
                font-size: 15px;
                font-style: normal;
                line-height: 130%; /* 19.5px */
                letter-spacing: -0.3px;
                text-transform: none;
                @media screen and (width < 100rem) {
                    font-size: 12px;
                 }
            }
        }
    }
    /*.card-text-container {
        display: flex;
        flex-direction: column;
        align-items: center;
        gap: 16px;
        align-self: stretch;
        margin: 0 40px;

        .card-title-text {
            color: var(--Grayscale-Black, #000);
            text-align: center;
            font-family: Graphik;
            font-size: 32px;
            font-style: normal;
            font-weight: 700;
            line-height: 100%;
            letter-spacing: -1.28px;
            display: flex;
            width: 377px;
            height: 27px;
            flex-direction: column;
            justify-content: center;
        }

        .card-description-text {
            color: var(--Grayscale-Black, #000);
            text-align: center;
            font-family: Inter;
            font-size: 16px;
            font-style: normal;
            font-weight: 400;
            line-height: 130%;
            @media screen and (width < 100rem) {
                font-size: 13px;
             }
        }
    }

    .blackDot {
        background-color: #1c1737;
    }

    .icon-button {
        position: absolute;
        top: 8px;
        right: 8px;
        color: white;
    }

    .popup-stepper-container {
        display: flex;
        justify-content: space-between;
        .next-btn {
            display: flex;
            align-self: center;
        }
    }

    .next-btn {
        color: #000;
        text-align: center;
        font-family: Inter;
        font-size: 15px;
        font-style: normal;
        font-weight: 700;
        line-height: 130%;
        letter-spacing: -0.3px;
        text-transform: none;
        @media screen and (width < 100rem) {
            font-size: 12px;
         }
    }*/
    .icon-button {
        position: absolute;
        top: 8px;
        right: 8px;
        color: white;
    }
}
