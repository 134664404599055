.icon-container {
  position: relative;
  background-color: #f6e003;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  // height: 5.5rem;
  // width: 5.5rem;
  height: 6.25rem;
  width: 6.25rem;

  &.dark {
    background-color: #000;
  }

  .outline {
    position: absolute;
    border-radius: 50%;
    height: 87.5%;
    width: 87.5%;
    border: 1px solid #000000;

    &.dark {
      border: 1px solid #f6e003;
    }
  }

  .icon-box {
    position: absolute;
    display: flex;
    justify-content: center;
    .icon {
      width: 60px;
      height: 60px;
    }
  }
}
