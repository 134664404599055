.card {
  display: flex;
  min-width: 390px;
  flex-direction: column;
  flex: 1;
}

.cardHeader {
  border-radius: 16px 16px 0px 0px;
  display: flex;
  height: 70px;
  width: 100%;
  flex-direction: column;
  color: #fff;
  text-align: center;
  text-transform: uppercase;
  justify-content: center;
  font: 600 24px/1 Work Sans, -apple-system, Roboto, Helvetica, sans-serif;
}

.cardContent {
  border-radius: 0px 0px 16px 16px;
  background: #1d1d1d;
  background-color: #1d1d1d;
  display: flex;
  width: 100%;
  flex-direction: column;
  overflow: hidden;
  justify-content: center;
  flex: 1;
}

@media (max-width: 991px) {
  .card {
    max-width: 100%;
  }
  
  .cardHeader {
    max-width: 100%;
  }
  
  .cardContent {
    max-width: 100%;
  }
}
