.block {
  display: flex;
  flex-direction: column;
  text-align: center;
  justify-content: start;
  flex: 1;
}

.topBlock {
  border-radius: 16px 16px 0 0;
  background-color: #F5DF42;
  display: flex;
  height: 550px;
  flex-direction: column;
  justify-content: center;
}

.categoryIcon {
  align-self: center;
  height: 92px;
}

.categoryTitle {
  color: #000;
  text-transform: uppercase;
  margin-top: 33px;
  font: 700 48px/1 Barlow, sans-serif;
}

.statGroup {
  display: flex;
  flex-direction: column;
  width: 100%;
  gap: 40px 60px;
}

.stat {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 10px;
}

.statWrapper {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 20px;
}

.value {
  color: #000;
  font: 700 48px/1 Barlow, sans-serif;
}

.tag {
  border-radius: 4px;
  padding: 4px 8px;
  color: #fff;
  font: 700 36px/1 Barlow, sans-serif;
}

.label {
  color: #000;
  font: 500 18px/1 Work Sans, -apple-system, Roboto, Helvetica, sans-serif;
}

.bottomBlock {
  border-radius: 0 0 16px 16px;
  background-color: #434343;
  display: flex;
  flex-direction: column;
  color: #fff;
  justify-content: center;
  flex: 1;
  font: 600 24px/35px Work Sans, -apple-system, Roboto, Helvetica, sans-serif;
}

.divider {
  min-height: 1px;
  background-color: rgba(255, 255, 255, 0.5);
  margin: 33px 0;
  width: 90%;
  align-self: center;
}

.largeValue {
  font-family: Barlow, sans-serif;
  font-size: 48px;
}

@media (max-width: 991px) {
  .block,
  .topBlock,
  .bottomBlock {
    max-width: 100%;
  }
  
  .categoryTitle,
  .value,
  .largeValue {
    font-size: 40px;
  }
  
  .topBlock {
    padding: 0 20px;
  }
  
  .bottomBlock {
    padding: 100px 20px;
  }
}
