@use 'theme';

.occasions-example-audio-card {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    flex: 1 0 0;
    border-radius: var(--radius-l, 1.5rem);
    //height: 307px;
    height: 17.5rem;
    max-height: 18.75rem;

    .card-media {
        display: flex;
        flex-direction: column;
        justify-content: flex-end;
        align-items: flex-end;
        gap: 0.5rem;
        flex: 1 0 0;
        height: 200px;
        width: 100%;
        &.MuiCardMedia-root {
            object-fit: cover;
        }
    }

    .card-content-container {
        display: flex;
        padding: var(--spacing-s, 1.25rem);
        justify-content: space-between;
        align-items: flex-start;
        align-self: stretch;
        background: var(--grayscale-800, theme.$grey-800);
        
        .card-content-text-container {
            display: flex;
            //width: 316px;
            flex-direction: column;
            align-items: flex-start;
            gap: 0.25rem;

            .title-text {
                color: var(--grayscale-white, theme.$white);
                font-family: Inter-Bold;
                font-style: normal;
                line-height: 130%;
            }

            .subtitle-text {
                color: var(--grayscale-300, theme.$grey-300);
                font-family: Inter-Regular;
                font-style: normal;
                font-weight: 400;
                line-height: 130%;
            }
        }

        .card-action-button {
            display: flex;
            padding: 0.5rem;
            align-items: flex-end;
            gap: 0.5rem;
            border-radius: 6.25rem;
            .icon {
                height: 2.5rem;
                width: 2.5rem;
            }
        }
    }
}