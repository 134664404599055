.actionButton {
  display: inline-block;
  white-space: nowrap;
  width: fit-content;
  padding: 10px 24px;
  border-radius: 100px;
  background: #f6e003;
  color: #000;
  text-align: center;
  letter-spacing: -0.3px;
  font: 700 15px/1.3 Inter, sans-serif;
  border: none;
  cursor: pointer;
}

.actionButton:hover {
  opacity: 0.8;
}
