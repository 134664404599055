.tableItem {
  display: flex;
  width: 100%;
  align-items: center;
  gap: 40px 100px;
  line-height: 1;
  justify-content: space-between;
  flex-wrap: wrap;
}

.text {
  color: #fff;
  align-self: stretch;
  margin: auto 0;
}

.stat {
  align-self: stretch;
  display: flex;
  align-items: center;
  gap: 8px;
  color: #fff;
  justify-content: space-between;
  width: 120px;
  margin: auto 0;
}

.tag {
  align-self: stretch;
  border-radius: 4px;
  min-height: 24px;
  gap: 4px;
  text-align: center;
  padding: 4px 8px;
  margin: 3px 0;
}

.tableLine {
  min-height: 0;
  width: 100%;
  border: 1px solid #fff;
}

@media (max-width: 991px) {
  .tableItem {
    max-width: 100%;
  }
  
  .stat {
    white-space: initial;
  }
  
  .tableLine {
    max-width: 100%;
  }
}