.container {
  display: flex;
  max-width: 648px;
  flex-direction: column;
  justify-content: space-between !important;
  padding: 2px 0;
}

.content {
  display: flex;
  width: 85%;
  flex-direction: column;
  justify-content: space-between !important;
}

.header {
  display: flex;
  height: 100px;
  flex-direction: column;
  align-items: center;
  font-family: Barlow, sans-serif;
  text-transform: uppercase;
  justify-content: center;
}

.titleWrapper {
  display: flex;
  width: 100%;
  flex-direction: column;
}

.eyebrow {
  color: #f6e003;
  font-size: 24px;
  font-weight: 700;
  margin-bottom: -50px;
}

.title {
  color: #f5f5f5;
  font-size: 75px;
  font-weight: 800;
  line-height: 68px;
}

.description {
  color: #f5f5f5;
  align-self: stretch;
  font: 500 18px/26px Work Sans, -apple-system, Roboto, Helvetica, sans-serif;
}

.indicators {
  display: flex;
  margin-top: 51px;
  align-items: center;
  gap: 9px;
  justify-content: start;
}

.indicators button {
  border: none;
  background: none;
  cursor: pointer;
  margin: 0;
  padding: 0;
}
