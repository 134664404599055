.container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  color: #fff;
  text-align: center;
  margin: 130px auto 0;
  width: 1224px;
}

.header {
  display: flex;
  width: 875px;
  flex-direction: column;
}

.title {
  font: 700 48px Barlow, sans-serif;
  text-transform: uppercase;
  margin-bottom: 0;
}

.description {
  font: 600 20px/25px Work Sans, -apple-system, Roboto, Helvetica, sans-serif;
  margin-top: 0;
}

.segments {
  display: flex;
  margin-top: 16px;
  width: 100%;
  flex-direction: column;
  gap: 77px;
}

.beerSection,
.hardBeverageSection {
  display: flex;
  width: 100%;
  align-items: space-between;
  gap: 14px;
}

.beerContent,
.hardBeverageContent {
  flex: 1;
  display: flex;
  flex-direction: column;
  gap: 21px;
}

.segmentGrid {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
}

.segmentCardButton {
  border: none;
  background: none;
  color: #fff;
  cursor: pointer;
  margin: 0;
  padding: 0;
}

@media (max-width: 991px) {
  .container {
    padding-right: 20px;
  }

  .title {
    font-size: 40px;
  }

  .segments {
    max-width: 100%;
  }

  .hardBeverageSection {
    margin-top: 40px;
  }
}
