.main-content-wayfinder {
  width: 100%;
  height: 100%;

  .wayfinder-content {
    height: 100%;
    width: 100%;
  }
  .people-segments-title {
    color: var(--Grayscale-White, #fff);
    text-align: center;
    font-family: Inter-Black;
    font-size: 15.252px;
    font-style: normal;
    line-height: 130%; /* 17.54px */
    text-transform: uppercase;
    margin-bottom: 8px;
    margin-left: 30px;
    &.font-change{
      font-size: 15px;
      @media screen and (width < 100rem) {
        font-size: 12px;
     }
    }
  }
  .occasions-vertical-title {
    display: flex;
    transform: rotate(-90deg);
    color: var(--grayscale-white, #fff);
    text-align: center;
    font-family: Inter-Black;
    font-size: 15.252px;
    font-style: normal;
    line-height: 130%; /* 23.4px */
    text-transform: uppercase;
    margin-top: 145px;
    &.font-change{
      font-size: 15px;
      @media screen and (width < 100rem) {
        font-size: 12px;
     }
    }
  }

  &.wayfinder-common {
    .wayfinderitem {
      &.highlight {
        background-color: #F6E003;
      }
    }
  }
}
