.container {
  display: flex;
  align-items: center;
  gap: 40px 20px;
  justify-content: space-between;
  flex-wrap: wrap;
  margin: 80px auto;
  width: 1224px;
}

.card {
  align-self: stretch;
  position: relative;
  display: flex;
  height: 200px;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 680px;
  margin: auto 0;
  padding: 54px 4px;
}

.cardBackground {
  border-radius: 16px;
  border: 1px solid #424242;
  background: rgba(53, 53, 53, 0.5);
  box-shadow: 0 0 4px rgba(0, 0, 0, 0.16);
  position: absolute;
  inset: 0;
  min-height: 348px;
  max-width: 100%;
}

.title {
  color: #fff;
  text-align: center;
  font: 700 48px Barlow, sans-serif;
  text-transform: uppercase;
  z-index: 0;
}

.subtitle {
  color: #fff;
  text-align: center;
  font: 600 24px/35px Work Sans, -apple-system, Roboto, Helvetica, sans-serif;
  margin-top: -20px;
  z-index: 0;
}

.flavorSlider {
  width: 80%;
  z-index: 0;
}

.description {
  color: #fff;
  width: 450px;
  margin-left: 30px;
  font: 600 36px/45px Work Sans, -apple-system, Roboto, Helvetica, sans-serif;
}

@media (max-width: 991px) {
  .card {
    max-width: 100%;
  }
  
  .title {
    font-size: 40px;
  }
  
  .subtitle,
  .description {
    max-width: 100%;
  }
}
