.categoryStructureFramework {
  position: relative;
  display: flex;
  padding-bottom: 40px;
  flex-direction: column;
  align-items: center;
  width: 1224px;
  isolation: isolate;
}

.categoryStructureFramework::before {
  content: '';
  position: absolute;
  inset: -50px;
  opacity: 0.35;
  background: var(--category-bg, linear-gradient(107deg, #8CBBD9 8.07%, #F4C537 31.56%, #947BBF 58.78%, #A01648 87.6%));
  filter: blur(150px);
  z-index: -1;
  pointer-events: none;
}

.tripGrid {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(100px, 1fr));
  gap: 20px;
  width: 100%;
  max-width: 1513px;
  margin-top: 15px;
  padding: 0 20px;
}

@media (max-width: 991px) {
  .categoryStructureFramework {
    padding-bottom: 100px;
  }
}