@use "theme";

.consumption-data-table-container {
  display: flex;
  width: 100%;
  // padding: var(--spacing-s, 1.25rem) var(--spacing-s, 1.25rem)
  //   var(--spacing-xs, 0.75rem) var(--spacing-s, 1.25rem);
  margin: 1.25rem 0;
  flex-direction: column;
  align-items: center;
  gap: 1.25rem;

  .data-table-head {
    .MuiTableCell-root {
      padding: 16px 2px;
    }
    .data-table-cell {
      padding: 0.75rem 0;
      border-bottom: 0.2px solid rgba($color: theme.$white, $alpha: 0.2);
      p {
        font-family: Inter-Bold;
        font-size: 15px;
        font-style: normal;
        line-height: 100%;
        letter-spacing: 0.5px;
        // color: theme.$white;
        text-transform: uppercase;

        &.occasions {
          color: var(--global-colors-ab-light-gold, theme.$ab-yellow);
        }

        &:nth-last-child(0) {
          align-items: start;
        }
        &:nth-last-child(1) {
          align-items: end;
        }
      }
    }
  }

  .data-table-row {
    border-bottom: 0.2px solid rgba($color: theme.$white, $alpha: 0.2);
  }

  .data-table-body {
    .data-table-row {
      .data-table-cell {
        color: var(--grayscale-white, theme.$white);
        font-style: normal;
        font-weight: 700;
        align-self: stretch;
        padding: 0.75rem 0;
        border-bottom: 0.2px solid rgba($color: theme.$white, $alpha: 0.2);
  
        &:nth-last-child(0) {
          align-items: start;
        }
        &:nth-last-child(1) {
          align-items: end;
        }
  
        &.small {
          width: 55%;
          /* Desktop/Data/Data Label */
          font-family: Inter-Bold;
          font-size: 15px;
          line-height: 100%; /* 15px */
          letter-spacing: 0.5px;
          text-align: left;
          @media screen and (width < 100rem) {
            font-size: 12px;
         }
        }
        &.medium {
          /* Desktop/Data/Data Point Small */
          font-family: Zuume-Bold;
          font-size: 20px;
          line-height: normal;
          letter-spacing: 0.6px;
          text-align: center;
          @media screen and (width < 100rem) {
            font-size: 16px;
          }
        }
  
        .table-cell-text {
          // height: 1.375rem;
          // border-radius: 3px;
          // justify-content: center;
          // text-align: center;
          // align-items: center;
          // gap: 10px;
          // padding: 0px 5px;
  
          // font-family: Zuume;
          // font-size: 20px;
          // line-height: normal;
          // letter-spacing: 0.6px;
          font-size: 15px;
          border-radius: 0.5rem;
          color: var(--Grayscale-White, #fff);
          font-family: Inter-Bold;
          font-style: normal;
          line-height: 100%; /* 15px */
          letter-spacing: 0.5px;
        }
        .table-cell-values {
          color: var(--Grayscale-White, #fff);
          text-align: center;
          border-radius: 3px;
          /* Desktop/Data/Data Point Medium */
          font-family: Zuume-Bold;
          font-size: 29px;
          font-style: normal;
          line-height: normal;
          letter-spacing: 0.87px;
          &.positive {
            background-color: theme.$positive;
          }
          &.neutral {
            background-color: theme.$neutral;
          }
          &.negative {
            background-color: theme.$negative;
          }
        }
      }
    }

    .data-unavailable-info-text {
      color: var(--grayscale-white, theme.$white);
      font-family: Inter-bold;
      font-size: 15px;
      font-style: normal;
      align-self: stretch;
      text-align: center;
      @media screen and (width < 100rem) {
        font-size: 12px;
     }
    }
  }

  .data-table-pagination {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    width: 100%;

    &.hideExpand {
      visibility: hidden;
    }

    .label {
      color: var(--grayscale-white, theme.$ab-yellow);
      font-family: Inter-Bold;
      font-style: normal;
      line-height: 130%; /* 20.8px */
    }

    .icon {
      color: var(--grayscale-white, theme.$ab-yellow);
    }
  }
}
