.chartContainer {
  position: relative;
  display: flex;
  min-width: 240px;
  min-height: 540px;
  margin: auto 0;
  padding: 24px 20px;
  justify-content: center;
  align-items: center;
  z-index: 0;
}

.cardBackground {
  border-radius: 16px;
  border: 1px solid #424242;
  background: rgba(53, 53, 53, 0.5);
  box-shadow: 0 0 4px 0 rgba(0, 0, 0, 0.16);
  position: absolute;
  inset: 0;
  z-index: 0;
}

.content {
  position: relative;
  z-index: 1;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.titleSection {
  width: 100%;
  text-align: center;
}

.title {
  color: #fff;
  text-transform: uppercase;
  font: 700 24px Barlow, sans-serif;
  margin: 0;
}

.divider {
  opacity: 0.2;
  background-color: #fff;
  height: 1px;
  margin-top: 16px;
  width: 100%;
  border: 1px solid #fff;
}

.chart {
  width: 550px;
  margin-top: 11px;
}

@media (max-width: 991px) {
  .chartContainer {
    max-width: 100%;
  }
}
