.table {
  display: flex;
  flex-direction: column;
  min-width: 240px;
  flex: 1;
  height: 100%;
  font-size: 24px;
  font-weight: 600;
  line-height: 1;
}
