.cardContainer {
  flex: 1;
}

.cardTop {
  border-radius: 16px 16px 0 0;
  background: #1d1d1d;
  padding: 40px;
}

.titleSection {
  display: flex;
  flex-direction: column;
  color: #fff;
  text-transform: uppercase;
  font: 700 36px/1 Barlow, sans-serif;
}

.categoryIcon {
  width: 110px;
  align-self: center;
}

.categoryTitle {
  margin-top: 20px;
}

.statsSection {
  margin-top: 30px;
}

.statsGroup {
  display: flex;
  align-items: center;
  gap: 20px;
  font-family: Barlow, sans-serif;
  font-weight: 700;
  text-transform: uppercase;
  justify-content: center;
}

.percentageText {
  color: #fff;
  font-size: 48px;
  line-height: 1;
}

.countBadge {
  border-radius: 4px;
  font-size: 36px;
  color: #fff;
  padding: 4px 8px;
}

.spendLabel {
  color: #fff;
  margin-top: 20px;
  font: 500 18px/1 Work Sans, -apple-system, Roboto, Helvetica, sans-serif;
}

.basketPercentage {
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 0 0 16px 16px;
  min-height: 70px;
  color: #fff;
  font: 600 24px/1 Work Sans, -apple-system, Roboto, Helvetica, sans-serif;
}

@media (max-width: 991px) {
  .cardTop {
    padding: 0 20px;
  }

  .statsGroup {
    white-space: initial;
  }

  .percentageText {
    font-size: 40px;
  }

  .basketPercentage {
    padding: 0 20px;
  }
}
