.stat {
  display: flex;
  align-items: center;
  gap: 10px;
  color: #fff;
  justify-content: flex-start;
}

.percentage {
  align-self: stretch;
  margin: auto 0;
}

.tag {
  align-self: stretch;
  border-radius: 4px;
  min-height: 24px;
  text-align: center;
  margin: auto 0;
  padding: 4px 8px;
}

@media (max-width: 991px) {
  .stat {
    white-space: initial;
  }
  .tag {
    white-space: initial;
  }
}
