@use "theme";

.login-content {
  display: flex;
  flex-direction: row;
  align-items: center;
  //justify-content: space-between;
  justify-content: center;
  margin: 0 6.25rem 3.8rem 6.25rem;
  min-height: theme.$max-view-height;
  gap: 5rem;

  @media screen and (width < 1500px) {
    gap: 3.5rem;
  }

  .logo-container {
    display: flex;
    padding: 0.585rem;
    margin-top: 4rem;
    width: 23.375rem;
    height: 14.434rem;
    justify-content: center;
    align-items: center;
    gap: 0.585rem;
    border-radius: 3.125rem;
    background: theme.$black;
    box-shadow: -24px 0px 100px 0px rgba(242, 245, 4, 0.3),
      24px 0px 100px 0px rgba(255, 131, 1, 0.3);

    .logo-text {
      font-family: Inter-Black;
      font-size: 7.5rem;
      font-style: normal;
      line-height: 80%; /* 95.744px */
      letter-spacing: 1.58px;
      text-transform: uppercase;

      background: radial-gradient(
        100.2% 180.7% at 4.5% 5.88%,
        #f1f603 0%,
        #f6e003 51.04%,
        #ff7a00 100%
      );
      background-clip: text;
      -webkit-background-clip: text;
      -webkit-text-fill-color: transparent;
      // @media screen and (width < 100rem) {
      //   font-size: 89.25px;
      // }
    }
  }

  .app-description-container {
    display: flex;
    width: auto; //43.625rem;
    flex-direction: column;
    align-items: center;
    text-align: center;
    gap: var(--spacing-m, 2.5rem);

    .app-introduction-container {
      display: flex;
      flex-direction: column;
      align-items: center;
      gap: 0.5rem;

      .login-title {
        color: var(
          --global-colors-ab-light-gold,
          var(--Actions, theme.$ab-yellow)
        );
        font-family: Inter-Black;
        font-size: 15px;
        font-style: normal;
        line-height: 150%; /* 22.5px */
        letter-spacing: 0.75px;
        text-transform: uppercase;
        // @media screen and (width < 100rem) {
        //   font-size: 12px;
        // }
      }
      .login-project-title {
        color: var(--grayscale-white, theme.$white);
        font-family: Zuume-Black;
        font-size: 128px;
        font-style: normal;
        line-height: 80%; /* 102.4px */
        letter-spacing: 2.56px;
        text-transform: uppercase;
        // @media screen and (width < 100rem) {
        //   font-size: 96px;
        // }
      }
    }

    .login-project-summary {
      display: flex;
      //margin: 0 16rem;
      margin: auto;
      width: 75%;
      align-items: center;
      gap: 0.5rem;
      align-self: stretch;
      flex: 1 0 0;

      color: var(--grayscale-white, theme.$white);
      text-align: center;
      font-family: Inter-Bold;
      font-size: 20px;
      font-style: normal;
      line-height: 130%; /* 26px */
      letter-spacing: 0;
      // @media screen and (width < 100rem) {
      //   font-size: 16px;
      // }
    }

    .login-button-container {
      display: flex;
      height: 2.5rem;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      gap: 0.5rem;

      .signIn-button {
        display: flex;
        padding: 0.641rem 1.415rem;
        justify-content: center;
        align-items: center;
        gap: 0.5rem;
        flex: 1 0 0;
        align-self: stretch;

        color: var(--grayscale-black, theme.$black);
        border-radius: 6.25rem;
        background: var(--Actions, theme.$ab-yellow);

        text-align: center;
        font-family: Inter-Bold;
        font-size: 15px;
        font-style: normal;
        text-transform: none;
        letter-spacing: 0;
        -webkit-font-smoothing: antialiased;
        line-height: 130%; /* 19.5px */
        // @media screen and (width < 100rem) {
        //   font-size: 12px;
        // }
      }
    }
  }
}
.login-content-not-compatible {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  //width: 936.398px;
  width: 100vw;
  //height: 638.758px;
  // flex-shrink: 0;
  gap: 50px;
  flex: 1 0 auto;
  
  @media screen and (min-width: 768px) and (max-width: 1024px) {
    background-image: linear-gradient(rgba(0, 0, 0, 0.6), rgba(0, 0, 0, 0.6)),
    url("../assets/images/Login/TabletBackground.jpg");
    background-size: cover; /* Adjust as needed */
    background-position: center; /* Adjust as needed */
  }
  
  @media screen and (max-width: 500px) {
    background-image: linear-gradient(rgba(0, 0, 0, 0.6), rgba(0, 0, 0, 0.6)),
      url("../assets/images/Login/MobileBackground.jpg");
    background-size: cover; /* Adjust as needed */
    background-position: center; /* Adjust as needed */
  }

  .logo-container-nc {
    display: flex;
    width: 178.138px;
    height: 110px;
    padding: 4.453px;
    justify-content: center;
    align-items: center;
    gap: 4.453px;
    flex-shrink: 0;

    border-radius: 23.815px;
    background: #000;
    box-shadow: 11.431px 0px 47.63px 0px rgba(255, 131, 1, 0.3),
      -11.431px 0px 47.63px 0px rgba(242, 245, 4, 0.3);

    .logo-text-nc {
      font-family: Inter-Black;
      font-size: 57.004px;
      font-style: normal;
      line-height: 80%; /* 45.603px */
      text-transform: uppercase;

      background: radial-gradient(
        100.2% 180.7% at 4.5% 5.88%,
        #f1f603 0%,
        #f6e003 51.04%,
        #ff7a00 100%
      );
      background-clip: text;
      -webkit-background-clip: text;
      -webkit-text-fill-color: transparent;
      // @media screen and (width < 100rem) {
      //   font-size: 89.25px;
      // }
    }
  }

  .app-description-container-nc {
    display: flex;
    flex-direction: column;
    text-align: center;
    align-items: center;
    gap: var(--Spacing-XL, 50px);
    margin: 0 1.25rem;

    .app-introduction-container-nc {
      display: flex;
      flex-direction: column;
      align-items: center;
      gap: var(--Spacing-M, 30px);
      align-self: stretch;

      .login-title-nc {
        color: var(--Global-Colors-AB-Light-Gold, #f6e003);

        /* Mobile/Body/Overline */
        font-family: Inter-Black;
        font-size: 10px;
        font-style: normal;
        line-height: 150%; /* 15px */
        letter-spacing: 0.3px;
        text-transform: uppercase;
        // @media screen and (width < 100rem) {
        //   font-size: 12px;
        // }
      }
      .login-project-title-nc {
        color: var(--Grayscale-White, #fff);

        /* Mobile/Headers/H2 */
        font-family: Zuume-Bold;
        font-size: 57.6px;
        font-style: normal;
        //font-weight: 900;
        line-height: 80%; /* 46.08px */
        // @media screen and (width < 100rem) {
        //   font-size: 96px;
        // }
      }
    }

    .login-project-summary-nc {
      color: var(--Grayscale-White, #fff);
      text-align: center;
      /* Mobile/Body/Body Bold */
      font-family: Inter-Bold;
      font-size: 14px;
      font-style: normal;
      line-height: 120%; /* 16.8px */
      letter-spacing: 0.14px;
      //max-width: 500px;
      width: 80%;

      b {
        color: theme.$ab-yellow;
      }

      @media screen and (max-width: 375px) {
        max-width: 335px;
      }
    }
  }

  .screen-image-container {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    gap: 0.5rem;

    .screen-image {
      width: 140px;
      height: 140px;
      flex-shrink: 0;
    }

    .screen-image2 {
      color: theme.$ab-yellow;
      height: 140px;
      width: 70px;
    }
  }

  
}
