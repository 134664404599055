.container {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 22px;
  justify-content: space-between;
  margin: 20px auto;
  z-index: 0;
  width: 1224px;
}

@media (max-width: 991px) {
  .container {
    max-width: 100%;
    margin-top: 40px;
  }
}
