@use "theme";

.section1-hero-container-people-segment {
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  min-height: calc(theme.$max-view-height - 2*theme.$runner-height);
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  background-color: lightgray;

  .hero-content {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    align-self: stretch;
    margin: 0 0 5rem 6.25rem;
    gap: 1.25rem;
    width: 50%;

    .hero-text {
      width: 50%;
      color: var(--Grayscale-White, theme.$white);
      font-family: Zuume-Black;
      font-size: 9rem;
      font-style: normal;
      line-height: 80%; /* 115.2px */
      text-transform: uppercase;
      @media screen and (width < 100rem) {
        font-size: 108px;
      }
    }
    .hero-title-text-description {
      width: 100%;
      .hero-content-text {
        color: theme.$white;
        font-size: 1.75rem;
        font-family: Inter-Bold;
        line-height: 130%;
        word-wrap: break-word;
        @media screen and (width < 100rem) {
          font-size: 1.3125rem;
        }

        i {
          color: theme.$ab-yellow;
          font-style: normal;
        }
      }

      /*.hero-content-text-2 {
        color: theme.$ab-yellow;
        font-size: 1.75rem;
        font-family: Inter;
        font-weight: 700;
        line-height: 36.4px;
        word-wrap: break-word;
        margin-left: 10px !important;
        margin-right: 10px !important;
        @media screen and (width < 100rem) {
          font-size: 1.3125rem;
        }
      }*/
    }
  }
}

.ps-hub-section2-background {
  width: 100%;
  background: linear-gradient(
      90deg,
      rgb(121, 210, 113) 4.05%,
      rgb(143, 232, 189) 16.91%,
      rgb(161, 232, 248) 29.77%,
      rgb(131, 188, 255) 42.63%,
      rgb(173, 161, 248) 55.5%,
      rgb(185, 131, 255) 68.36%,
      rgb(243, 178, 214) 81.22%,
      rgb(255, 140, 140) 94.09%
    ),
    linear-gradient(180deg, rgb(66, 66, 66) 5%, rgba(0, 0, 0, 1) 95%);
  background-size: 100% 9.3%;
  background-repeat: no-repeat;
  background-blend-mode: multiply;
  .section2-visual-container {
    display: flex;
    flex-direction: column;
    margin: 6.25rem;
    gap: 2.5rem;
    min-height: calc(theme.$max-view-height + theme.$anchor-nav-height);

    .section2-visual-container-grid {
      flex-grow: 1;

      .grid-item {
        padding-top: 0;
        .card-content-hover {
          box-shadow: 0 0 0 5px transparent;
          transition: box-shadow 0.3s ease;
          border-radius: 16px;
          &:hover {
            transform: scale(1.02);
            opacity: 0.8;
            // box-shadow: 0 0 0 1px theme.$ab-yellow;
          }
        }
        .card-grid-item-hover-effect:hover::after {
          content: "";
          position: absolute;
          top: 0;
          left: 0;
          right: 0;
          bottom: 0;
          border: 3px solid var(--border-color, red); /* Change the border color on hover */
          border-radius: 10px;
          pointer-events: none; /* Ensure the hover effect does not interfere with the content */
          box-sizing: border-box; /* Ensure the border stays within the element's dimensions */
        }

        .comfort-craver {
          --border-color: #b983ff;
        }
        .card-grid-item-hover-effect {
          transition: box-shadow 0.3s ease;

          // &:hover {
          //   // Define the styles for highlighting on hover
          //   box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.2); // Example shadow effect
          // }
        }

        .card-media {
          aspect-ratio: 1.5;
        }
      }

      .stack-container {
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: center;
        margin-bottom: 1rem;
        gap: 1rem;

        .ps-section2-card-title {
          color: var(--Grayscale-White, #fff);
          font-family: Inter-Bold;
          font-size: 1.75rem;
          font-style: normal;
          line-height: 130%;
          @media screen and (width < 100rem) {
            font-size: 1.3125rem;
          }
        }

        .card-title-divider {
          flex: 1 0 0;
          border: 1px solid #ffffff80;
          width: 50%;
        }
      }

      .card-container {
        .card-content {
          .card-content-text {
            text-transform: uppercase;
            word-wrap: break-word;
            color: var(--Grayscale-Black, #000);
            font-family: Zuume-Black;
            font-size: 2.25rem;
            font-style: normal;
            line-height: 90%;
            letter-spacing: 1.44px;
            @media screen and (width < 100rem) {
              font-size: 1.6875rem;
            }
          }
        }
      }
    }
  }
}

.ps-hub-section3-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  gap: 6.25rem;

  .ps-hub-section3-intro {
    margin: 0 6.25rem;
    display: flex;
    flex-direction: column;
    gap: 6.25rem;

    .section-main {
      max-height: calc(theme.$max-view-height + theme.$anchor-nav-height);
    }
  }

  .kf-centertext-box {
    display: flex;
    justify-content: center;
    align-items: center;
    align-self: stretch;
    margin: 0 6.25rem;

    .kf-text-header5 {
      width: 80%;
      color: var(--Grayscale-White, theme.$white);
      text-align: center;
      font-family: Zuume-Black;
      font-style: normal;
      line-height: 80%;
      text-transform: uppercase;
      @media screen and (width < 100rem) {
        font-size: 3.75rem;
      }

      i {
        font-style: normal;
        color: theme.$ab-yellow;
      }
    }
  }

  .line-chart-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin: 0 6.25rem;
    max-height: calc(theme.$max-view-height + theme.$anchor-nav-height);
    margin: 0 6.25rem;
    gap: 2.5rem;

    .line-chart-grid1 {
      width: 80%;
      flex-grow: 1;
      height: 100%;

      .left-item {
        position: relative;

        .graph-title {
          display: flex;
          align-items: center;
          transform-origin: center;

          color: var(--Grayscale-White, #fff);
          transform: rotate(-90deg);
          text-align: center;
          /* Desktop/Body/Overline */
          font-family: Inter-Black;
          font-size: 15px;
          font-style: normal;
          line-height: 150%; /* 22.5px */
          letter-spacing: 0.45px;
          text-transform: uppercase;
          @media screen and (width < 100rem) {
            font-size: 12px;
          }

          &.demographics {
            padding-top: 2.5rem;
          }
        }

        .bar-chart-title {
          color: var(--Grayscale-White, #fff);
          text-align: center;
          /* Desktop/Body/Overline */
          font-family: Inter-Bold;
          font-size: 20px;
          font-style: normal;
          line-height: 150%; /* 22.5px */
          letter-spacing: 0.45px;
          text-transform: uppercase;
          @media screen and (width < 100rem) {
            font-size: 16px;
          }
        }
        .left-item-div {
          display: flex;
          // flex-direction: column;
          // align-items: center;
          align-self: flex-start;

          border-color: theme.$ab-yellow;
          border-style: dotted;
          border-radius: 8px;
          height: 15.5rem;
          padding: 0px 20px;
          margin: 12px 0;

          .grid-left-item {
            display: flex;
            align-items: center;
            justify-content: center;
          }

          .bar-chart-percent-section3 {
            color: var(--Grayscale-White, theme.$white);
            text-align: center;
            font-family: Zuume-Bold;
            font-size: 30px;
            font-style: normal;
            line-height: 100%;
            letter-spacing: 0.6px;
            text-transform: uppercase;
          }
        }
        .left-item-arrow-div {
          display: flex;
          align-items: center;
          justify-content: center;
          position: absolute;
          top: 30.5%;
          left: 100%;
          .left-item-arrow-subdiv {
            border-top-color: theme.$ab-yellow;
            border-style: dotted;
            align-self: stretch;
            width: 40px;
          }
          .left-arrow-icon {
            color: theme.$ab-yellow;
            margin-bottom: -12px;
            margin-top: -13px;
            margin-left: -10px;
          }
        }
        .left-item-demographics {
          display: flex;
          // align-items: center;
          align-self: flex-start;

          border-color: theme.$grey-700;
          border-style: dotted;
          border-radius: 8px;
          height: 10.25rem;

          .grid-left-item {
            display: flex;
            align-items: center;
            justify-content: center;
          }
        }
        .bar-chart-percent-section3 {
          color: var(--Grayscale-White, theme.$white);
          text-align: center;
          font-family: Zuume-Bold;
          font-size: 30px;
          font-style: normal;
          line-height: 100%;
          letter-spacing: 0.6px;
          text-transform: uppercase;
        }
      }

      .linebar-label-text {
        color: var(--Grayscale-White, #fff);
        text-align: right;
        font-family: Inter-Black;
        font-size: 15px;
        font-style: normal;
        line-height: 150%;
        letter-spacing: 0.45px;
        text-transform: uppercase;
        @media screen and (width < 100rem) {
          font-size: 12px;
        }
      }
    }
    .right-item {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      gap: 1.25rem;
      //padding-top: 5rem;
      margin-left: 5rem;

      .right-title-text {
        overflow: hidden;
        margin-top: 2rem;
        color: var(--Grayscale-White, #fff);
        text-overflow: ellipsis;
        font-family: Inter-Bold;
        //font-size: 28px;
        font-size: 20px;
        font-style: normal;
        line-height: 130%;
        //width: 70%;
        @media screen and (width < 100rem) {
          font-size: 15px;
        }
      }
      .right-desc-text {
        overflow: hidden;
        color: var(--Grayscale-300, #c7c7c7);
        text-overflow: ellipsis;
        font-family: Inter-SemiBold;
        font-size: 16px;
        font-style: italic;
        line-height: 130%;
        //width: 70%;
        @media screen and (width < 100rem) {
          font-size: 13px;
        }
      }
    }
    .info-div-hub {
      display: flex;
      align-items: center;
      justify-content: flex-end;
      align-self: flex-end;
      // margin-top: 2.5rem;
      // margin-left: 1.5rem;
      .info-div-typo {
        color: theme.$white;
        margin-left: 5px;
        font-family: Inter-Regular;
        font-size: 16px;
        font-style: normal;
        font-weight: 400;
        line-height: 130%;
        @media screen and (width < 100rem) {
          font-size: 13px;
        }
      }
    }
  }

  .key-factors-frame {
    display: flex;
    flex-direction: column;
    //margin: 0 6.25rem;
    gap: 1.875rem;

    .kf-centertext-box {
      display: flex;
      justify-content: center;
      align-items: center;
      align-self: stretch;
      margin: 0 6.25rem;

      .kf-text-header5 {
        width: 80%;
        color: var(--Grayscale-White, theme.$white);
        text-align: center;
        font-family: Zuume-Black;
        font-style: normal;
        line-height: 80%;
        @media screen and (width < 100rem) {
          font-size: 3.75rem;
        }
  
        i {
          font-style: normal;
          color: theme.$ab-yellow;
        }
      }
    }

    .div2 {
      width: 100%;
      display: flex;
      flex-direction: column;
      gap: 1.5rem;

      .kf-centertext-box {
        display: flex;
        justify-content: center;
        align-items: center;
        align-self: stretch;
        margin: 0 6.25rem;

        .kf-text-header5 {
          width: 55%;
          color: var(--Grayscale-White, theme.$white);
          text-align: center;
          font-family: Inter-Bold;
          font-size: 28px;
          font-style: normal;
          line-height: 130%;
          text-transform: none;
          @media screen and (width < 100rem) {
            font-size: 1.3125rem;
          }

          i {
            font-style: normal;
            color: theme.$ab-yellow;
          }
        }
      }
      .rectangular-card-background {
        border-radius: var(--Radius-M, 16px);
        background: var(--Grayscale-800, theme.$grey-800);
        padding: 1.5rem 2rem;

        .rectangular-card-container {
          display: flex;
          justify-content: space-between;
          align-items: flex-end;
          align-self: stretch;

          .content-left-right-text {
            color: var(--Grayscale-White, theme.$white);
            font-family: Inter-Regular;
            font-size: 16px;
            font-style: normal;
            font-weight: 400;
            line-height: 130%;
            @media screen and (width < 100rem) {
              font-size: 13px;
            }
          }

          .flex-align-center {
            display: flex;
            justify-content: center;
            align-items: center;
            .flex-align-div {
              margin-right: 2.5rem;
              margin-left: 2.5rem;
              display: flex;
              flex-direction: column;
              justify-content: center;
              align-items: center;
              align-self: flex-start;
              gap: 8px;
              .yellow-icon-style {
                font-size: 72px;
                color: theme.$ab-yellow;
              }
              .content-icon-text {
                color: var(--Grayscale-White, theme.$white);
                text-align: center;
                font-family: Zuume-Black;
                font-size: 43px;
                font-style: normal;
                line-height: 90%;
                letter-spacing: 1.72px;
              }
            }
            .vs-text {
              color: var(--Grayscale-White, theme.$white);
              text-align: center;

              /* Desktop/Body/Overline */
              font-family: Inter-Black;
              font-size: 15px;
              font-style: normal;
              line-height: 150%; /* 22.5px */
              letter-spacing: 0.45px;
              text-transform: uppercase;
              @media screen and (width < 100rem) {
                font-size: 12px;
              }
            }
          }
        }
        .greydivider-with-arrow-container {
          padding: 40px 0 0 0;
        }
      }
    }

    .div3 {
      width: 100%;
      display: flex;
      flex-direction: column;
      gap: 1.5rem;

      .kf-centertext-box {
        display: flex;
        justify-content: center;
        align-items: center;
        align-self: stretch;
        margin: 0 6.25rem;

        .kf-text-header5 {
          width: 55%;
          color: var(--Grayscale-White, theme.$white);
          text-align: center;
          font-family: Inter-Bold;
          font-size: 28px;
          font-style: normal;
          line-height: 130%;
          text-transform: none;
          @media screen and (width < 100rem) {
            font-size: 1.3125rem;
          }

          i {
            font-style: normal;
            color: theme.$ab-yellow;
          }
        }
      }
      .rectangular-card-background {
        border-radius: var(--Radius-M, 16px);
        background: var(--Grayscale-800, theme.$grey-800);
        padding: 1.5rem 2rem;

        .rectangular-card-container {
          display: flex;
          justify-content: space-between;
          align-items: flex-end;
          align-self: stretch;

          .content-left-right-text {
            color: var(--Grayscale-White, theme.$white);
            font-family: Inter-Regular;
            font-size: 16px;
            font-style: normal;
            font-weight: 400;
            line-height: 130%;
            @media screen and (width < 100rem) {
              font-size: 13px;
            }
          }

          .flex-align-center {
            display: flex;
            justify-content: center;
            align-items: center;
            .flex-align-div {
              // margin-top: 1rem;
              margin-right: 2.5rem;
              margin-left: 2.5rem;
              display: flex;
              flex-direction: column;
              justify-content: center;
              align-items: center;
              align-self: flex-start;
              gap: 8px;
              .yellow-icon-style {
                font-size: 72px;
                // margin-left: 25%;
                color: theme.$ab-yellow;
              }
              .content-icon-text {
                color: var(--Grayscale-White, theme.$white);
                text-align: center;
                font-family: Zuume-Black;
                font-size: 43px;
                font-style: normal;
                line-height: 90%;
                letter-spacing: 1.72px;
              }
            }
            .vs-text {
              color: var(--Grayscale-White, theme.$white);
              text-align: center;

              /* Desktop/Body/Overline */
              font-family: Inter-Black;
              font-size: 15px;
              font-style: normal;
              line-height: 150%; /* 22.5px */
              letter-spacing: 0.45px;
              text-transform: uppercase;
              @media screen and (width < 100rem) {
                font-size: 12px;
              }
            }
          }
        }
        .greydivider-with-arrow-container {
          padding: 40px 0 0 0;
        }
      }
    }

    .div4 {
      width: 100%;

      .defining-ps-container {
        margin: 0 6.25rem;
        background: linear-gradient(180deg, #29270b 0%, #000000 60.94%);
        border-radius: 1.5rem;

        .text-with-graph-container {
          display: flex;
          justify-content: center;
          align-items: center;
          align-self: stretch;
          padding: 4rem 3.75rem;

          .stack-texts {
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: flex-start;
            gap: 1.25rem;
            // width: 563px;

            .defining-ps-hero-text {
              color: var(--Global-Colors-AB-Light-Gold, #f6e003);
              font-family: Inter-Black;
              font-size: 15px;
              font-style: normal;
              line-height: 150%; /* 22.5px */
              letter-spacing: 0.45px;
              text-transform: uppercase;
              @media screen and (width < 100rem) {
                font-size: 12px;
              }
            }
            .defining-ps-content-text {
              align-self: stretch;

              color: var(--Grayscale-White, #fff);
              font-family: Inter-Bold;
              font-size: 28px;
              font-style: normal;
              line-height: 130%; /* 36.4px */
              width: 82%;
              @media screen and (width < 100rem) {
                font-size: 1.3125rem;
              }
            }
          }
          .graph-container-with-4-elements {
            display: flex;
            align-items: center;
            justify-content: flex-end;
            .graph-container {
              position: relative;
              width: 597px; /* Make the graph container responsive */
              height: 422px; /* Adjust the height as needed */
              display: flex;
              // zoom: 80%;
            }
            .quadrant {
              position: absolute;
            }

            .top-left {
              top: 5px;
              left: -5px;
            }

            .top-right {
              top: 5px;
              right: -5px;
            }

            .bottom-left {
              bottom: 22px;
              left: -5px;
            }

            .bottom-right {
              bottom: 22px;
              right: -5px;
            }

            .horizontal-axis,
            .vertical-axis {
              position: absolute;
              background-color: #3d3d3d;
            }

            .horizontal-axis {
              left: 0;
              width: 100%;
              height: 2.043px;
              top: 50%;
              display: flex;
              align-items: center;
              .arrow-left {
                font-size: 40px;
                color: #3d3d3d;
                margin-left: -16px;

                margin-right: auto;
              }
              .arrow-right {
                font-size: 40px;
                color: #3d3d3d;
                margin-right: -16px;
                margin-left: auto;
              }
            }

            .vertical-axis {
              width: 2.043px;
              height: 100%;
              left: 50%;
              display: flex;
              align-items: center;

              .arrow-up {
                font-size: 40px;
                color: #3d3d3d;
                margin-left: -19px;
                margin-bottom: auto;
                margin-top: -16px;
              }
              .arrow-down {
                font-size: 40px;
                color: #3d3d3d;
                margin-left: -40px;
                margin-top: auto;
                margin-bottom: -16px;
              }
            }

            .x-axis-label-left,
            .x-axis-label-right {
              position: absolute;
              bottom: 52%;
            }

            .x-axis-label-left {
              left: 0;
            }

            .x-axis-label-right {
              right: 0;
            }

            .y-axis-label-top,
            .y-axis-label-bottom {
              position: absolute;
              left: 50%;
              transform: translateX(-50%);
            }

            .y-axis-label-top {
              top: -10%;
            }

            .y-axis-label-bottom {
              bottom: -10%;
            }

            .label-text {
              display: flex;
              // width: 94.979px;
              height: 40.851px;
              flex-direction: column;
              justify-content: center;

              color: var(--Grayscale-300, #c7c7c7);
              text-align: center;
              font-family: Zuume-Bold;
              font-size: 18px;
              font-style: normal;
              line-height: 100%; /* 18px */
              letter-spacing: 0.9px;
              text-transform: uppercase;

              opacity: 1;
            }

            .rounded-div {
              display: flex;
              width: 197px;
              height: 165px;
              padding: 0px var(--Spacing-M, 40px);
              justify-content: center;
              align-items: center;
              gap: 8px;
              flex-shrink: 0;

              border-radius: 244px;
            }
            .rounded-div-text {
              flex: 1 0 0;

              color: var(--Grayscale-Black, #000);
              text-align: center;
              font-family: Inter-Bold;
              font-size: 15.319px;
              font-style: normal;
              line-height: 130%; /* 19.915px */
              letter-spacing: -0.306px;
            }
          }
        }
      }
    }

    .div5 {
      display: flex;
      flex-direction: column;
      width: 100%;
      gap: 3.75rem;
      max-height: theme.$max-view-height;

      .kf-centertext-box {
        display: flex;
        justify-content: center;
        align-items: center;
        align-self: stretch;
        margin: 0 6.25rem;

        .kf-text-header5 {
          width: 65%;
          color: var(--Grayscale-White, theme.$white);
          text-align: center;
          font-family: Zuume-Black;
          font-style: normal;
          line-height: 80%;
          @media screen and (width < 100rem) {
            font-size: 3.75rem;
          }
    
          i {
            font-style: normal;
            color: theme.$ab-yellow;
          }
        }
      }
      .supporting-factors-box {
        display: flex;
        padding: 0px 100px;
        justify-content: center;
        align-items: flex-start;
        gap: var(--Spacing-S, 20px);
        align-self: stretch;

        .factor-content-stack-box {
          width: 50%;
          .factor-content-stack {
            display: flex;
            padding: 1.4rem;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            gap: 1.25rem;
            flex: 1 0 0;

            .icon-styles {
              color: theme.$ab-yellow;
              font-size: 5.6rem;
            }

            .text-box {
              display: flex;
              flex-direction: column;
              justify-content: center;
              align-items: center;
              gap: 0.5rem;
              align-self: stretch;

              .factorcontent-title-text {
                align-self: stretch;

                color: var(--Grayscale-White, #fff);
                text-align: center;
                font-family: Inter-Bold;
                font-size: 20px;
                font-style: normal;
                line-height: 130%; /* 26px */
                word-wrap: break-word;
                @media screen and (width < 100rem) {
                  font-size: 16px;
                }
              }
              .factorcontent-desc-text {
                align-self: stretch;

                color: var(--Grayscale-300, #c7c7c7);
                text-align: center;
                font-family: Inter-SemiBold;
                font-size: 16px;
                font-style: italic;
                line-height: 130%; /* 20.8px */
                @media screen and (width < 100rem) {
                  font-size: 13px;
                }
              }
            }
          }
        }
      }
    }
  }
  .defining-ps-frame {
    display: flex;
    flex-direction: column;

    .defining-ps-container {
      margin: 0 6.25rem;
      background: linear-gradient(180deg, #29270b 0%, #000000 60.94%);
      border-radius: 1.5rem;

      .text-with-graph-container {
        display: flex;
        justify-content: center;
        align-items: center;
        align-self: stretch;
        padding: 4rem 3.5rem;

        .stack-texts {
          display: flex;
          flex-direction: column;
          justify-content: center;
          align-items: flex-start;
          gap: 1.25rem;

          .defining-ps-hero-text {
            color: var(--Global-Colors-AB-Light-Gold, #f6e003);
            font-family: Inter-Black;
            font-size: 15px;
            font-style: normal;
            line-height: 150%; /* 22.5px */
            letter-spacing: 0.45px;
            text-transform: uppercase;
            @media screen and (width < 100rem) {
              font-size: 12px;
            }
          }
          .defining-ps-content-text {
            align-self: stretch;

            color: var(--Grayscale-White, #fff);
            font-family: Inter-Bold;
            font-size: 28px;
            font-style: normal;
            line-height: 130%; /* 36.4px */
            width: 82%;
            @media screen and (width < 100rem) {
              font-size: 1.3125rem;
            }
          }
        }
        .graph-container-with-8-elements {
          display: flex;
          align-items: center;
          justify-content: flex-end;
          .graph-container {
            position: relative;
            width: 597px;
            height: 422px;
            display: flex;
          }
          .quadrant {
            position: absolute;
            display: flex;
            flex-direction: column;
          }

          .top-left {
            top: 0;
            left: 0;
            .item1 {
              margin-top: 50px;
              margin-left: 110px;
            }
            .item2 {
              margin-top: 30px;
              margin-left: -10px;
            }
          }

          .top-right {
            top: 0;
            right: 0;
            .item1 {
              margin-top: 10px;
              margin-left: 0;
            }
            .item2 {
              margin-top: 70px;
              margin-left: -40px;
            }
          }

          .bottom-left {
            bottom: 0;
            left: 0;
            .item1 {
              margin-bottom: 30px;
              margin-left: -10px;
            }
            .item2 {
              margin-bottom: 45px;
              margin-left: 110px;
            }
          }

          .bottom-right {
            bottom: 0;
            right: 0;
            .item1 {
              margin-bottom: 70px;
              margin-left: 100px;
            }
            .item2 {
              margin-bottom: 45px;
              margin-right: 100px;
            }
          }

          .horizontal-axis,
          .vertical-axis {
            position: absolute;
            background-color: #3d3d3d;
          }

          .horizontal-axis {
            left: 0;
            width: 100%;
            height: 2.043px;
            top: 50%;
            display: flex;
            align-items: center;
            .arrow-left {
              font-size: 40px;
              color: #3d3d3d;
              margin-left: -16px;

              margin-right: auto;
            }
            .arrow-right {
              font-size: 40px;
              color: #3d3d3d;
              margin-right: -16px;
              margin-left: auto;
            }
          }

          .vertical-axis {
            width: 2.043px;
            height: 100%;
            left: 50%;
            display: flex;
            align-items: center;

            .arrow-up {
              font-size: 40px;
              color: #3d3d3d;
              margin-left: -19px;
              margin-bottom: auto;
              margin-top: -16px;
            }
            .arrow-down {
              font-size: 40px;
              color: #3d3d3d;
              margin-left: -40px;
              margin-top: auto;
              margin-bottom: -16px;
            }
          }

          .x-axis-label-left,
          .x-axis-label-right {
            position: absolute;
            bottom: 50%;
          }

          .x-axis-label-left {
            left: 0;
          }

          .x-axis-label-right {
            right: 0;
          }

          .y-axis-label-top,
          .y-axis-label-bottom {
            position: absolute;
            left: 50%;
            transform: translateX(-50%);
          }

          .y-axis-label-top {
            top: -10%;
          }

          .y-axis-label-bottom {
            bottom: -10%;
          }

          .label-text {
            display: flex;
            height: 40.851px;
            flex-direction: column;
            justify-content: center;

            color: var(--Grayscale-300, #c7c7c7);
            text-align: center;
            font-family: Zuume-Bold;
            font-size: 18px;
            font-style: normal;
            line-height: 100%; /* 18px */
            letter-spacing: 0.9px;
            text-transform: uppercase;

            opacity: 1;
          }

          .rounded-div {
            display: flex;
            width: 154.978px;
            height: 31.253px;
            padding: 0px 8.756px 0px 9.631px;
            justify-content: center;
            align-items: center;
            flex-shrink: 0;

            border-radius: 16.34px;
          }
          .rounded-div-text {
            flex: 1 0 0;

            color: var(--Grayscale-Black, #000);
            text-align: center;
            font-family: Inter-Bold;
            font-size: 15.319px;
            font-style: normal;
            line-height: 130%; /* 19.915px */
            letter-spacing: -0.306px;
          }
        }
      }
    }
  }

  .ps-horizontal-card-box {
    display: flex;
    flex-direction: column;
    gap: 3.75rem;
    max-height: calc(theme.$max-view-height + theme.$anchor-nav-height);

    .kf-centertext-box {
      display: flex;
      justify-content: center;
      align-items: center;
      align-self: stretch;
      margin: 0 6.25rem;

      .kf-text-header5 {
        width: 80%;
        color: var(--Grayscale-White, theme.$white);
        text-align: center;
        font-family: Zuume-Black;
        font-style: normal;
        line-height: 80%;
        @media screen and (width < 100rem) {
          font-size: 3.75rem;
        }

        i {
          font-style: normal;
          color: theme.$ab-yellow;
        }
      }
    }
    .divider-with-text-container {
      display: flex;
      align-items: center;
      justify-content: center;
      gap: 1.5rem;

      .arrow-text {
        color: var(--Global-Colors-AB-Light-Gold, #f6e003);
        // margin-bottom: 8px;
        text-align: center;
        text-wrap: nowrap;
        // margin-left: 8px;
        font-family: Zuume-Medium;
        font-size: 20px;
        font-style: normal;
        line-height: 100%;
        letter-spacing: 1px;
        text-transform: uppercase;
        @media screen and (width < 100rem) {
          font-size: 16px;
        }
      }
      .divider-container {
        position: relative;
        display: flex;
        align-items: center;
        justify-content: center;
        width: 100%;

        .arrow-back-icon {
          position: absolute;
          left: 0;
          height: 32px;
          width: 16px;
          color: #ffffff;
        }
        .arrow-forward-icon {
          position: absolute;
          right: 0;
          height: 32px;
          width: 16px;
          color: #ffffff;
          transform: rotate(180deg);
        }

        .divider-line {
          position: absolute;
          left: 2px;
          right: 2px;
          display: flex;
          flex: 1 0;
          height: 4px;
          align-self: center;
          background-color: #ffffff;
        }
      }
    }
    .people-segment-cards {
      #FR {
        color: #ada1f8;
      }
      .stack-container {
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: center;
        margin-bottom: 1rem;
        gap: 1rem;
        .ps-section2-card-title {
          text-align: center;
          font-family: Inter-Black;
          font-size: 15px;
          font-style: normal;
          line-height: 150%; /* 22.5px */
          letter-spacing: 0.45px;
          text-transform: uppercase;
          @media screen and (width < 100rem) {
            font-size: 12px;
          }

          &.Fram_PSGMT_1 {
            background: theme.$familiar-reserved;
            background-clip: text;
            -webkit-background-clip: text; /* For Safari */
            color: transparent;
          }
          &.Fram_PSGMT_4 {
            background: theme.$exploratory-reserved;
            background-clip: text;
            -webkit-background-clip: text;
            color: transparent;
          }
          &.Fram_PSGMT_7 {
            background: theme.$familiar-outgoing;
            background-clip: text;
            -webkit-background-clip: text;
            color: transparent;
          }
          &.Fram_PSGMT_10 {
            background: theme.$exploratory-outgoing;
            background-clip: text;
            -webkit-background-clip: text;
            color: transparent;
          }
        }
      }
      .card-content-hover-effect {
        box-shadow: 0 0 0 5px transparent;
        transition: box-shadow 0.3s ease;
        border-radius: 16px;
        &:hover {
          transform: scale(1.02);
          opacity: 0.8;
          // box-shadow: 0 0 0 1px theme.$ab-yellow;
        }
      }

      .card-container {
        .card-media {
          aspect-ratio: 1.5;
        }

        .card-content {
          height: 0px;
          .card-content-text {
            color: var(--Grayscale-Black, #000);
            font-family: Zuume-Black;
            font-size: 18px;
            font-style: normal;
            line-height: 90%; /* 16.2px */
            letter-spacing: 0.72px;
          }
        }
      }
    }
  }
}

.ps-hub-section4-container {
  display: flex;
  
  .left-item {
    padding: 0px;
    .divider-line {
      border: 2px solid #f6e003;
      width: 12.5rem;
    }
    .heading-text {
      align-self: stretch;
      color: var(--Grayscale-White, #fff);
      font-family: Zuume-Black;
      font-size: 8rem;
      font-style: normal;
      line-height: 80%;
      text-transform: uppercase;
      margin-top: 7.5rem;
      width: 85%;
      @media screen and (width < 100rem) {
        font-size: 6rem;
      }
    }
    .view-demandspace-btn {
      color: var(--Grayscale-Black, #000);
      text-align: center;
      text-transform: none;
      border-radius: 100px;
      padding: 10px 24px;
      margin-top: 3.75rem;
      font-family: Inter-Bold;
      font-size: 1.25rem;
      font-style: normal;
      line-height: 130%;
      letter-spacing: -0.4px;
      @media screen and (width < 100rem) {
        font-size: 1rem;
      }
    }
  }
  .right-item {
    display: flex;
  }
  .right-item-maxcontent {
    position: relative;
    display: flex;
    align-self: stretch;
    flex-direction: column;

    width: 95%;
    color: theme.$white;
    margin-top: 4rem;
    margin-left: auto;

    .button-to-ds {
      position: absolute;
      z-index: 1;
      background-color: theme.$ab-yellow;
      color: theme.$black;
      border-radius: 2rem;
      top: 35%;
      left: 32%;
      // bottom: 8%;

      display: flex;
      flex-direction: row;
      justify-content: center;
      align-items: center;
      padding: 10px 24px 10px 16px;
      border-radius: 6.25rem;
      color: var(--grayscale-black, theme.$black);
      text-align: center;
      font-family: Inter-Bold;
      font-size: 15px;
      font-style: normal;
      line-height: 130%;
      letter-spacing: -0.3px;
      text-transform: none;
      @media screen and (width < 100rem) {
        font-size: 12px;
        top: 42%;
        left: 36%;
      }
    }
  }

  .right-item-maxcontent .icon-color {
    color: theme.$ab-yellow;
  }

  .right-item-maxcontent,
  .icon-color {
    flex-shrink: 0;
  }
}

.ps-hub-section5 {
  display: flex;
  margin: 6.25rem;
  align-items: flex-start;
  align-self: stretch;
}

.custom-tooltip2 {
  position: relative;
  z-index: 1000;
  background: theme.$white;
  border-radius: 0.5rem;
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  @media screen and (width < 100rem) {
    width: 350px;
  }

  .description {
    font-size: 16px;
    padding: 10px;
    display: flex;
    text-align: left;
    color: theme.$black;
    font-family: Inter-Regular;
    font-style: normal;
    font-weight: 400;
    line-height: 130%;
  }
}
