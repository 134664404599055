.cardContainer {
  border-radius: 16px;
  border: 1px solid #424242;
  background: rgba(53, 53, 53, 0.5);
  box-shadow: 0px 0px 4px 0px rgba(0, 0, 0, 0.16);
  display: flex;
  margin: 0 auto;
  justify-content: center;
  align-items: center;
  padding: 24px;
}

.instructionsImage {
  width: 100%;
  height: auto;
}
