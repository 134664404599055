.blockGroup {
  display: flex;
  gap: 10px;
}

.titleCard {
  display: flex;
  font-size: 36px;
  text-transform: uppercase;
  line-height: 1;
  align-items: center;
  width: 250px;
  min-height: 450px;
  border-radius: 16px;
  box-shadow: 0 0 4px 0 rgba(0, 0, 0, 0.16);
  padding: 24px;
}

.title {
  text-align: left;
  color: #fff;
  margin: 0 auto;
}

.factorsContainer {
  display: flex;
  flex-direction: column;
  flex: 1;
  gap: 10px;
}

@media (max-width: 991px) {
  .blockGroup {
    max-width: 100%;
  }
  
  .titleCard {
    padding: 0 20px;
  }
  
  .factorsContainer {
    max-width: 100%;
  }
}
