.methodologySection {
  display: flex;
  align-items: center;
  gap: 40px 78px;
  color: #fff;
  justify-content: space-between;
  margin: 40px auto;
  width: 1224px;
}

.methodologyImage {
  object-fit: contain;
  object-position: left;
  width: 665px;
  border-radius: 18px;
  min-width: 240px;
  margin: auto 0;
}

.contentWrapper {
  display: flex;
  min-width: 240px;
  flex-direction: column;
  width: 560px;
  margin: auto 0;
}

.textContainer {
  display: flex;
  width: 500px;
  flex-direction: column;
}

.heading {
  font: 700 48px/48px Barlow, sans-serif;
  text-transform: uppercase;
  margin-top: 40px;
}

.description {
  font: 600 24px/30px Work Sans, -apple-system, Roboto, Helvetica, sans-serif;
  margin-top: -30px;
}

@media (max-width: 991px) {
  .methodologyImage {
    max-width: 100%;
  }
  
  .contentWrapper {
    max-width: 100%;
  }
  
  .heading {
    font-size: 40px;
  }
  
  .description {
    max-width: 100%;
  }
}
