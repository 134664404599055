.cardContainer {
  border-radius: 16px;
  border: 1px solid #424242;
  background: rgba(53, 53, 53, 0.5);
  box-shadow: 0px 0px 4px 0px rgba(0, 0, 0, 0.16);
  margin: 100px auto 50px;
  display: flex;
  justify-content: center;
  padding: 24px 0;
  width: 1224px;
}

.categoryContainer {
  display: flex;
  width: 100%;
  justify-content: space-evenly;
}

.headerSection {
  display: flex;
  flex-direction: column;
  color: #fff;
  width: 300px;
  margin: auto -50px;
}

.mainTitle {
  text-transform: uppercase;
  font: 700 48px Barlow, sans-serif;
  margin-bottom: 0px;
}

.description {
  font: 500 18px/26px Work Sans, -apple-system, Roboto, Helvetica, sans-serif;
}

.cardsSection {
  display: flex;
  align-items: center;
  gap: 0px 86px;
  justify-content: center;
  flex-wrap: wrap;
  margin: auto 0;
}

@media (max-width: 991px) {
  .mainTitle {
    font-size: 40px;
  }
  
  .cardsSection {
    max-width: 100%;
  }
}
