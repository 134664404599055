@use "theme";

.section-main {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  color: var(--grayscale-white, theme.$white);
  // max-width: 87.5rem;
  //max-height: 40.625rem;
  max-height: 100%;
  height: 100%;

  .section-left-item {
    display: flex;
    flex-direction: column;
    align-self: stretch;
    flex: 1 0 0;
    //height: 39.125rem;
    max-height: 100%;
    //height: 100%;

    .divider {
      display: flex;
      flex: 1 0 0;
      border: 2px solid #f6e003;
      width: 13rem;
    }

    .section-title-container {
      display: flex;
      align-items: flex-start;
      justify-content: center;
      margin-right: 1.8rem;
      gap: var(--spacing-s, 1.25rem);
      height: 100%;

      .section-heading {
        align-self: stretch;
        color: var(--Grayscale-White, #fff);
        font-family: Zuume-Black;
        font-size: 8rem;
        font-style: normal;
        line-height: 80%; /* 102.4px */
        text-transform: uppercase;
        letter-spacing: 0;
        @media screen and (width < 100rem){
          font-size: 6rem;
        }
      }

      .section-description {
        display: -webkit-box;
        -webkit-box-orient: vertical;
        -webkit-line-clamp: 5;
        overflow: hidden;
        text-overflow: ellipsis;
        width: 83%;
        font-family: Inter-Bold;
        font-size: 1.25rem;
        font-style: normal;
        line-height: 130%;
        @media screen and (width < 100rem){
          font-size: 1rem;
        }
      }
    }
  }

  .section-right-item {
    flex-grow: 1;

    .visual-container {
      display: flex;
      justify-content: flex-start;
      align-items: flex-start;
      max-height: 100%;

      .back-image {
        float: right;
        display: flex;
        align-items: flex-start;
        justify-content: flex-end;
        width: 100%;
        height: 80%;
        margin-bottom: 8.6%;

        .visual {
          width: 100%;
          height: 100%;
          border-radius: 1rem;
        }
      }

      .divider {
        display: flex;
        flex: 1 0 0;
        border: 2px solid #f6e003;
        width: 10rem;
      }

      .section-title-container {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        gap: var(--spacing-s, 1.25rem);
        margin: 25% 0;

        .section-heading {
          width: auto;
          align-self: stretch;
          font-family: Zuume;
          font-size: 8rem;
          font-style: normal;
          font-weight: 900;
          line-height: 80%;
          text-transform: uppercase;
          @media screen and (width < 100rem){
            font-size: 6rem;
          }
        }

        .section-description {
          display: -webkit-box;
          -webkit-box-orient: vertical;
          -webkit-line-clamp: 5;
          overflow: hidden;
          text-overflow: ellipsis;

          font-family: Inter;
          font-size: 1.25rem;
          font-style: normal;
          font-weight: 700;
          line-height: 130%;
          @media screen and (width < 100rem){
            font-size: 1rem;
          }
        }
      }
      .front-image {
        display: flex;
        align-items: flex-start;
        align-self: flex-end;
        z-index: 1;
        bottom: 0;
        width: 65%;
        height: 48%;
        box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.3),
          0px 8px 12px 6px rgba(0, 0, 0, 0.15);
        .visual {
          width: 100%;
          height: 100%;
          border-radius: 1rem;
        }
      }
    }
  }
}
