.personaContainer {
  display: flex;
  height: 175px;
  gap: 40px;
}

.personaItem {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100px;
}

.personaImage {
  width: 100px;
  height: auto;
  border-radius: 100px;
}

@media (max-width: 991px) {
  .personaContainer {
    margin-top: 40px;
  }
}
