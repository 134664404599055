@use "theme";

.main-content {
  .img-container {
    //position: relative;
    width: 100%;
    //height: 100%;
    min-height: theme.$max-view-height;
    display: flex;
    justify-content: center;
    // position: relative;
    // margin-top: 3.8rem;
    // width: 100%;
    background-position: center;
    background-size: cover;
    background-color: lightgray;
    background-repeat: no-repeat;

    .hero-container {
      display: flex;
      //height: 734.645px;
      //min-height: 700px;
      max-height: 100%;
      box-sizing: border-box;

      //padding: 7rem 6.25rem 5rem 6.25rem;
      margin: calc(theme.$max-view-height * 0.15) 6.25rem calc(theme.$max-view-height * 0.1) 6.25rem;
      ;
      flex-direction: column;
      justify-content: space-between;
      align-items: flex-end;
      flex: 1 0 0;
      align-self: stretch;
      gap: 4.375rem;

      .hero-wayfinder {
        display: flex;
        width: 21.875rem;
        // height: 10rem;
        padding: 0 0.625rem 0.5rem 0.1875rem;
        flex-direction: column;
        align-items: flex-end;
        gap: 1.721px;
        border-radius: 0.4375rem;
        background: rgba(29, 29, 29, 0.8);
        backdrop-filter: blur(13.330080032348633px);
      }

      .hero-content {
        display: flex;
        // flex-direction: row;
        justify-content: space-between;
        align-items: flex-end;
        // gap: var(--spacing-m, 2.5rem);
        align-self: stretch;

        .demandspace-type {
          display: flex;
          align-items: flex-start;
          width: 80%;
          gap: var(--spacing-s, 1.25rem);
          color: var(--grayscale-white, theme.$white);
          font-family: Zuume-Black;
          font-size: 9rem;
          font-style: normal;
          line-height: 80%;
          text-transform: uppercase;

          @media screen and (width < 100rem) {
            font-size: 108px;
          }
        }

        .demandspace-type-scope {
          display: flex;
          align-items: flex-start;
          gap: 1rem;
        }
      }
    }
  }

  .ds-section-1 {
    display: flex;
    // max-width: 87.5rem;
    justify-content: space-between;
    align-self: center;

    .section1-demandspacetemplate {
      display: flex;
      /*margin: var(--spacing-xxl, 6.25rem) 6.25rem
        var(--spacing-section, 9.375rem) 6.25rem;*/
      margin: 6.25rem;
      flex-direction: column;
      align-items: flex-start;
      gap: var(--spacing-xxl, 6.25rem);
      align-self: stretch;


      .intro-demandspacetemplate {
        display: flex;
        align-items: flex-start;
        gap: var(--spacing-xxl, 2.25rem);
        flex: 1 0 0;

        max-height: theme.$max-view-height;

        .left-item-demandspacetemplate {
          .left-item-content-demandspacetemplate {
            display: flex;
            flex-direction: column;
            align-items: flex-start;
            gap: 3.75rem;
            flex: 1 0 0;

            .paragraph-demandspacetemplate {
              color: var(--grayscale-white, theme.$white);
              font-family: Inter-Black;
              font-style: normal;
              line-height: 110%;
              font-size: 40px;

              @media screen and (width < 100rem) {
                font-size: 30px;
              }

              strong {
                color: var(--Actions, theme.$ab-yellow);
                font-style: normal;
              }

              i {
                font-style: normal;
                overflow: hidden;
              }

              b {
                font-style: normal;
                overflow: hidden;
                //background: linear-gradient(100deg, #8fe8bd 0%, #79d271 100%);
                background: theme.$familiar-outgoing;
                background-clip: text;
                -webkit-background-clip: text;
                -webkit-text-fill-color: transparent;
                text-overflow: ellipsis;
              }

              strong {
                font-style: normal;
                overflow: hidden;
                color: theme.$ab-yellow;
              }

              .colored-orange {
                //background: linear-gradient(100deg, #ffc759 0%, #ff8811 100%);
                background: theme.$exploratory-reserved;
                background-clip: text;
                -webkit-background-clip: text;
                -webkit-text-fill-color: transparent;
                text-overflow: ellipsis;
              }

              u {
                text-decoration: none;
                //background: linear-gradient(100deg, #ada1f8 0%, #b983ff 100%);
                background: theme.$familiar-reserved;
                background-clip: text;
                -webkit-background-clip: text;
                -webkit-text-fill-color: transparent;
                text-overflow: ellipsis;
              }

              .colored-blue {
                //background: linear-gradient(100deg, #a1e8f8 0%, #83bcff 100%);
                background: theme.$exploratory-outgoing;
                background-clip: text;
                -webkit-background-clip: text;
                -webkit-text-fill-color: transparent;
                text-overflow: ellipsis;
              }

              .colored-outgoing {
                background: linear-gradient(100deg, #79d271 0%, #83bcff 100%);
                background-clip: text;
                -webkit-background-clip: text;
                -webkit-text-fill-color: transparent;
                text-overflow: ellipsis;
              }
            }

            // .hero-wayfinder {
            //   display: flex;
            //   width: 21.875rem;
            //   // height: 10rem;
            //   padding: 0 0.625rem 0.5rem 0.1875rem;
            //   flex-direction: column;
            //   align-items: flex-end;
            //   gap: 1.721px;

            //   border-radius: 0.4375rem;
            //   background: rgba(29, 29, 29, 0.8);
            //   backdrop-filter: blur(13.330080032348633px);
            // }

            .left-item-box {
              display: flex;
              flex-direction: column;
              align-items: flex-start;
              gap: var(--Spacing-XXS, 4px);
              align-self: stretch;

              .greytext {
                //color: theme.$grey-300;
                color: theme.$ab-yellow;
                font-family: Inter-Bold;
                font-size: 1rem;
                font-style: normal;
                line-height: 130%;

                @media screen and (width < 100rem) {
                  font-size: 0.8125rem;
                }
              }

              .descriptiontext {
                color: theme.$white;
                font-family: Inter-Bold;
                font-size: 1.75rem;
                font-style: normal;
                line-height: 130%;

                @media screen and (width < 100rem) {
                  font-size: 1.3125rem;
                }
              }
            }
          }
        }

        .vertical-divider {
          width: 1px;
          height: 24.7903rem;
          opacity: 0.6;
          background: #3d3d3d;
        }

        .right-item {
          display: flex;
          flex-direction: column;
          align-items: flex-start;
          //max-width: 46.875rem;
          gap: 3.75rem;
          flex: 1 0 0;

          // .right-item-box {
          //   display: flex;
          //   flex-direction: column;
          //   align-items: flex-start;
          //   gap: var(--Spacing-XXS, 4px);
          //   align-self: stretch;

          //   .greytext {
          //     color: theme.$grey-300;
          //     font-family: Inter;
          //     font-size: 1rem;
          //     font-style: normal;
          //     font-weight: 700;
          //     line-height: 130%;

          //     @media screen and (width < 100rem) {
          //       font-size: 0.8125rem;
          //     }
          //   }

          //   .descriptiontext {
          //     color: theme.$white;
          //     font-family: Inter;
          //     font-size: 1.75rem;
          //     font-style: normal;
          //     font-weight: 700;
          //     line-height: 130%;

          //     @media screen and (width < 100rem) {
          //       font-size: 1.3125rem;
          //     }
          //   }
          // }

          .hero-wayfinder {
            display: flex;
            //width: 21.875rem;
            width: 65%;
            max-height: calc(theme.$max-view-height/2);
            //padding: 0 0.625rem 0.5rem 0.1875rem;
            flex-direction: column;
            align-items: flex-start;
            align-self: flex-end;
            gap: 1.721px;

            border-radius: 0.4375rem;
            background: rgba(29, 29, 29, 0.8);
            backdrop-filter: blur(13.330080032348633px);
          }
        }
      }

      .spaces-container-video-div {
        position: relative;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: flex-end;

        gap: 2.5rem;
        //margin-top: 4rem;
        align-self: stretch;
        border-radius: 2.5rem;

        .callout-button {
          position: absolute;
          top: 50%;
          left: 50%;
          transform: translate(-50%, -50%);
          z-index: 1;
          color: theme.$black;
          border-radius: 2rem;

          .play-circle {
            color: theme.$white;
            height: 12rem;
            width: 12rem;
          }
        }

        .space-container {
          .video-tag {
            width: 100%;
            flex-shrink: 0;
            border-radius: 2rem;
            align-self: stretch;
            box-shadow: 0px 0px 20px 0px rgba(173, 161, 248, 0.31);
          }
        }
      }

      .spaces-container {
        margin: 0rem 6.25rem;
        width: auto;
      }

      .visual-snapshots-container {
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: center;
        align-self: stretch;
        gap: 0.75rem;

        //margin-top: 6.25rem;
        //margin-bottom: 2.5rem;
        .snapshot-typo {
          color: theme.$white;
          font-family: Inter-Black;
          font-size: 15px;
          font-style: normal;
          line-height: 150%;
          letter-spacing: 0.45px;
          text-transform: uppercase;

          @media screen and (width < 100rem) {
            font-size: 12px;
          }
        }

        .snapshot-divider {
          background: theme.$ab-yellow;
          height: 1px;
        }
      }

      .section1-image-list {
        display: flex;
        align-self: stretch;
        justify-content: space-between;

        .consumption-card-content {
          min-height: 18rem;

          @media screen and (width < 100rem) {
            min-height: 15.5rem;
          }
        }
      }

      // .visual-snapshot-imagelist {
      //   max-height: calc(theme.$max-view-height - 2.5rem);
      // }
    }
  }

  .section4-container {
    .section4 {
      .section4-image-list {
        overflow: hidden;

        .data-table-row {
          min-height: 8rem;

          &.averageFieldRow:last-child() {
            border-bottom: 0.2px solid theme.$ab-yellow;
          }
        }
      }
    }
  }

  .section8-container {
    display: flex;
    width: 100%;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    .section8 {
      display: flex;
      margin: var(--spacing-section, 9.375rem) var(--spacing-horizontal-margin, 6.25rem);
      justify-content: space-between;
      align-items: flex-start;
      align-self: stretch;
    }
  }

  .cards-section {
    color: theme.$white;
  }

  .people-background {
    display: flex;
    padding: var(--spacing-horizontal-margin, 6.25rem) var(--spacing-horizontal-margin, 6.25rem) 4.1875rem var(--spacing-horizontal-margin, 6.25rem);
    flex-direction: column;
    align-items: center;
    gap: var(--spacing-xxl, 6.25rem);
    background: linear-gradient(180deg, #29270b 0%, #000 18.44%);
    max-height: theme.$max-view-height;

    .people-main {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;

      .people-description-typo {
        overflow: hidden;
        color: var(--Grayscale-White, #fff);
        text-overflow: ellipsis;
        font-family: Inter-Bold;
        font-size: 20px;
        font-style: normal;
        line-height: 130%;

        @media screen and (width < 100rem) {
          font-size: 16px;
        }
      }

      b {
        font-style: normal;
        overflow: hidden;
        background: linear-gradient(100deg, #8fe8bd 0%, #79d271 100%);
        background-clip: text;
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;
        text-overflow: ellipsis;
      }

      .colored-yellow {
        color: theme.$ab-yellow;
      }

      .colored-orange {
        background: linear-gradient(100deg, #ff8811 0%, #ff8811 100%);
        background-clip: text;
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;
        text-overflow: ellipsis;
      }

      u {
        background: linear-gradient(100deg, #ada1f8 0%, #b983ff 100%);
        background-clip: text;
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;
        text-overflow: ellipsis;
        text-decoration: none;
      }

      .colored-blue {
        background: linear-gradient(100deg, #a1e8f8 0%, #83bcff 100%);
        background-clip: text;
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;
        text-overflow: ellipsis;
      }
    }
  }

  .content {
    display: flex;
    gap: 2.5rem;

    .macro-segement {
      display: flex;
      padding: var(--spacing-s, 1.25rem) 0rem 2.68rem 0rem;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      gap: var(--spacing-m, 2.5rem);
      align-self: stretch;
      border-radius: var(--radius-l, 1.5rem);
      background: var(--grayscale-800, theme.$grey-800);

      .macro-segment-multiple {
        display: flex;
        flex-direction: row;
        justify-content: center;
        flex-wrap: wrap;

        .macro-segment-section {
          display: flex;
          // padding: 0rem var(--spacing-l, 3.75rem);
          justify-content: space-between;
          align-items: flex-end;
          gap: 1.25rem;
          align-self: stretch;

          .macro-micro-box {
            display: flex;
            flex-direction: column;
            align-items: flex-start;
            gap: var(--spacing-s, 1.25rem);
            flex: 1 0 0;

            .macro-typo-box {
              display: flex;
              flex-direction: column;
              justify-content: flex-start;
              gap: var(--spacing-s, 1.25rem);
              align-self: stretch;
              border-radius: 14.493px;

              &.macro-height {
                @media screen and (width < 100rem) {
                  min-height: 5rem;
                }
              }

              .macro-typo {
                text-align: center;
                font-family: Inter-Bold;
                font-size: 1.58rem;
                font-style: normal;
                line-height: 130%;
                overflow: hidden;
                -webkit-box-orient: vertical;
                -webkit-line-clamp: 5;
                // background: var(--people-segment-colors-outgoing-familiar-segment-gradient, linear-gradient(100deg, #8FE8BD 0%, #79D271 100%));
                background-clip: text;
                -webkit-background-clip: text;
                -webkit-text-fill-color: transparent;
                text-overflow: ellipsis;
                // background-clip: text;
                // -webkit-background-clip: text;
                // -webkit-text-fill-color: transparent;
              }
            }

            .segment-types {
              display: flex;
              align-items: flex-start;
              gap: var(--spacing-s, 0.625rem);
              align-self: stretch;

              .micro-typo-box {
                display: flex;
                padding: 0.792rem 0rem;
                flex-direction: column;
                justify-content: center;
                align-items: center;
                gap: 0.566rem;
                flex: 1 0 0;
                border-radius: 0.9058rem;
                background-color: white;

                .micro-typo {
                  color: var(--grayscale-black, theme.$black);
                  text-align: center;
                  font-family: Inter-Bold;
                  font-size: 1rem;
                  font-style: normal;
                  line-height: 130%;

                  @media screen and (width < 100rem) {
                    font-size: 0.8125rem;
                  }
                }
              }

              .Fram_PSGMT_8 {
                background-color: #8fe8bd;
              }

              .Fram_PSGMT_9 {
                background-color: #79d271;
              }

              .Fram_PSGMT_2 {
                background-color: #b983ff;
              }

              .Fram_PSGMT_3 {
                background-color: #ada1f8;
              }

              .Fram_PSGMT_11 {
                background-color: #a1e8f8;
              }

              .Fram_PSGMT_12 {
                background-color: #83bcff;
              }

              .Fram_PSGMT_5 {
                background-color: #ffc759;
              }

              .Fram_PSGMT_6 {
                background-color: #ff8811;
              }
            }
          }
        }
      }

      .demandspace {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        border-radius: 6.25rem;
        border: 1px solid var(--grayscale-black, theme.$black);

        .demand-space-demographics {
          display: flex;
          gap: 6px;
          color: var(--grayscale-white, theme.$white);
          text-transform: none;
          text-align: center;
          font-family: Inter-Regular;
          font-size: 0.875rem;
          font-style: normal;
          font-weight: 600;
          line-height: 1.25rem;
          letter-spacing: 0.1px;
          padding: 10px 24px 10px 16px;

          &:hover {
            border: 2px solid theme.$ab-yellow;
            border-radius: 6.25rem;
          }
        }
      }

      .demographics-grid-container {
        .demographics-tool-tip-container {
          display: flex;
          justify-content: center;
          align-items: center;
          gap: 8px;
          padding-top: 25px;

          .demographics-info-icon {
            width: 24px;
            height: 24px;
            color: theme.$ab-yellow;
          }

          .demographics-tool-tip {
            color: var(--Grayscale-White, #fff);
            font-family: Inter-Regular;
            font-size: 16px;
            font-style: normal;
            font-weight: 400;
            line-height: 130%;

            @media screen and (width < 100rem) {
              font-size: 13px;
            }
          }
        }
      }
    }

    .shared-traits-main-div {
      display: flex;
      padding: var(--spacing-m, 2.5rem);
      flex-direction: column;
      align-items: center;
      gap: var(--spacing-m, 2.5rem);
      align-self: stretch;
      //margin-top: 3rem;
      border-radius: var(--radius-l, 1.5rem);
      background: var(--grayscale-800, theme.$grey-800);

      .shared-traits-text {
        display: flex;
        flex-direction: column;
        align-items: center;
        gap: var(--spacing-s, 1.25rem);
        align-self: stretch;
        color: var(--grayscale-white, theme.$white);
        text-align: center;
        font-family: Zuume-Black;
        font-size: 2.6875rem;
        font-style: normal;
        line-height: 90%;
        letter-spacing: 1.72px;
      }

      .shared-traits-div {
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: flex-start;
        gap: var(--spacing-s, 2.5rem);
        flex: 1 0 0;

        .shared-traits-title-div {
          display: flex;
          flex-direction: column;
          justify-content: center;
          align-items: center;
          gap: 1rem;

          .shared-traits-title-container {
            display: flex;
            padding-bottom: var(--spacing-xs, 0.75rem);
            align-items: center;
            justify-content: center;
            gap: 0.5rem;
            align-self: stretch;

            .shared-divider-line {
              flex: 1 0 0;
              border: 1px solid #f6e003;
            }

            .shared-traits-typo {
              color: var(--grayscale-white, theme.$white);
              font-family: Inter-Black;
              font-size: 0.9375rem;
              font-style: normal;
              line-height: 150%;
              text-transform: uppercase;
              letter-spacing: 0.45px;

              @media screen and (width < 100rem) {
                font-size: 0.75rem;
              }
            }
          }

          .shared-traits-Box {
            display: flex;
            flex-direction: column;
            gap: 1rem;
            width: 100%;

            .shared-traits-items {
              display: flex;
              padding: 1.25rem 1.5rem;
              justify-content: center;
              align-items: center;
              gap: 1.5rem;
              min-height: 2rem;
              align-self: stretch;
              border-radius: 1rem;
              background: var(--grayscale-black, theme.$black);

              .shared-traits-desc-typo {
                color: var(--grayscale-white, theme.$white);
                text-align: center;
                text-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
                font-family: Inter-Bold;
                font-size: 1rem;
                font-style: normal;
                line-height: 130%;

                @media screen and (width < 100rem) {
                  font-size: 0.8125rem;
                }
              }
            }
          }
        }
      }
    }

    .notable-differences-main {
      display: flex;
      // padding: var(--spacing-m, 2.5rem) var(--spacing-m, 2.5rem)
      //   var(--spacing-xl, 5rem) var(--spacing-m, 2.5rem);
      flex-direction: column;
      align-items: center;
      //margin-top: 4rem;
      gap: var(--spacing-m, 2.5rem);
      border-radius: 2rem;
      background: var(--grayscale-800, theme.$grey-800);
      align-self: stretch;
      //max-height: theme.$max-view-height;
      min-height: theme.$max-view-height;

      .notable-diff-div {
        display: flex;
        flex-direction: column;
        align-items: center;
        margin: 40px 60px 0px 60px;
        //gap: var(--spacing-xs, 0.75rem);
        align-self: stretch;

        .notable-diff-heading {
          display: flex;
          flex-direction: column;
          align-items: center;
          //gap: var(--spacing-s, 1.25rem);
          gap: 0.75rem;
          align-self: stretch;

          .notable-typo {
            align-self: stretch;
            color: var(--grayscale-white, theme.$white);
            text-align: center;
            font-family: Zuume-Black;
            font-size: 2.6875rem;
            font-style: normal;
            line-height: 90%;
            letter-spacing: 1.72px;
            text-transform: uppercase;
          }

          .infosharp-div {
            display: flex;
            align-items: center;
            justify-content: center;
            gap: 8px;

            .infosharpicon {
              color: theme.$ab-yellow;
              background-color: theme.$black;
            }

            .notable-hover-typo {
              color: var(--Grayscale-White, theme.$white);
              /* Desktop/Body/Small/Small Regular */
              font-family: Inter-Regular;
              font-size: 16px;
              font-style: normal;
              font-weight: 400;
              line-height: 130%;

              @media screen and (width < 100rem) {
                font-size: 13px;
              }
            }
          }
        }
      }

      .notable-divider {
        width: 100%;
        height: 1px;
        background: theme.$grey-700;
        margin: 0px 60px;
      }

      .notable-diff-leftright {
        display: flex;
        align-items: flex-start;
        //padding: 0px 60px 80px 60px;
        padding: 0px 3.75rem 3.75rem 3.75rem;

        // gap: var(--spacing-xl, 5rem);
        // margin-top: 2rem;
        // align-self: stretch;
        .notable-left-div {
          display: flex;
          flex-direction: column;
          align-items: flex-start;
          gap: var(--spacing-s, 1.25rem);

          .notable-image-div {
            display: flex;
            flex-direction: row;
            padding: 0;

            &:hover {
              background-color: transparent;
            }

            .notable-images {
              display: flex;
              //width: 6.25rem;
              //height: 6.25rem;
              width: 4.5rem;
              height: 4.5rem;
              flex-direction: column;
              justify-content: center;
              align-items: flex-end;
              border-radius: 12.5rem;
              margin-right: -5px;
              border: 4px solid var(--persona-colors-outgoing-familiar-carefree-fun-lovers,
                  theme.$carefree-fun-lover);
              object-fit: cover;

              &.notable-blur-image {
                filter: grayscale(1);
              }
            }

            .Fram_PSGMT_8 {
              border-color: #8fe8bd;
            }

            .Fram_PSGMT_9 {
              border-color: #79d271;
            }

            .Fram_PSGMT_2 {
              border-color: #b983ff;
            }

            .Fram_PSGMT_3 {
              border-color: #ada1f8;
            }

            .Fram_PSGMT_11 {
              border-color: #a1e8f8;
            }

            .Fram_PSGMT_12 {
              border-color: #83bcff;
            }

            .Fram_PSGMT_5 {
              border-color: #ffc759;
            }

            .Fram_PSGMT_6 {
              border-color: #ff8811;
            }

            .care-free-items {
              display: flex;
              //padding: var(--spacing-xs, 0.75rem) var(--spacing-s, 1.25rem);
              padding: var(--spacing-xs, 0.5rem) var(--spacing-s, 1rem);
              flex-direction: column;
              justify-content: center;
              align-items: center;
              border-radius: 0rem var(--radius-l, 2rem) var(--radius-l, 2rem) 0rem;

              &.care-free-non-selected {
                background: var(--persona-colors-outgoing-familiar-carefree-fun-lovers,
                    theme.$grey-700) !important;
                color: theme.$grey-300;
              }

              .care-free-typo {
                text-transform: none;
                color: var(--grayscale-black, #000);
                text-align: center;
                font-family: Inter-Bold;
                font-size: 1rem;
                font-style: normal;
                line-height: 130%;

                @media screen and (width < 100rem) {
                  font-size: 0.8125rem;
                }

                &.care-free-non-selected-text {
                  background: var(--persona-colors-outgoing-familiar-carefree-fun-lovers,
                      theme.$grey-700) !important;
                  color: theme.$grey-300;
                }
              }
            }

            .Fram_PSGMT_8 {
              background-color: #8fe8bd;
            }

            .Fram_PSGMT_9 {
              background-color: #79d271;
            }

            .Fram_PSGMT_2 {
              background-color: #b983ff;
            }

            .Fram_PSGMT_3 {
              background-color: #ada1f8;
            }

            .Fram_PSGMT_11 {
              background-color: #a1e8f8;
            }

            .Fram_PSGMT_12 {
              background-color: #83bcff;
            }

            .Fram_PSGMT_5 {
              background-color: #ffc759;
            }

            .Fram_PSGMT_6 {
              background-color: #ff8811;
            }
          }

          // .notable-clearAll {
          // }
        }

        .notable-right-div {
          display: flex;
          padding: 0px var(--spacing-none, 0px);
          flex-direction: column;
          align-items: flex-start;
          //gap: var(--spacing-l, 3.75rem);
          //gap: 1.875rem;
          gap: 1.25rem;
          flex: 1 0 0;

          .slider-main-div {
            display: flex;
            flex-direction: column;
            align-items: flex-start;
            //gap: var(--spacing-xl, 5rem);
            gap: 0.25rem;
            align-self: stretch;

            .slider-heading-value {
              margin: 0;
            }

            .sliders-data-div {
              display: flex;
              padding: 0rem var(--spacing-m, 2.5rem);
              flex-direction: column;
              align-items: flex-start;
              gap: var(--spacing-s, 1.25rem);
              align-self: stretch;

              .sliders-lines {
                display: flex;
                flex-direction: column;
                align-items: flex-start;
                gap: 0.625rem;
                align-self: stretch;
              }
            }
          }
        }
      }
    }
  }

  .ds-section3 {
    .occasion-background {
      display: flex;
      //margin: var(--spacing-section, 9.375rem) var(--spacing-horizontal-margin, 6.25rem) 4.1875rem var(--spacing-horizontal-margin, 6.25rem);
      margin: 6.25rem 6.25rem 0 6.25rem;
      flex-direction: column;
      align-items: center;
      gap: var(--spacing-xxl, 6.25rem);
      max-height: theme.$max-view-height;
      //min-height: theme.$max-view-height;

      .occasion-main {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
      }
    }

    .content {
      //max-height: theme.$max-view-height;
      min-height: theme.$max-view-height;
      gap: 1.25rem;
      margin-bottom: 6.25rem;

      .demandspace-occasion-content-container {

        .demandspace-occasion-content-container-item {
          //max-height: calc((theme.$max-view-height - 1.25rem - 1.25rem)/2);
          min-height: calc((theme.$max-view-height - 1.25rem - 1.25rem)/2);

          .macro-occassion-cards {
            min-height: calc((theme.$max-view-height - 1.25rem - 1.25rem)/2);
          }
        }
      }

      .demandspace-sovsoicontent {
        display: flex;
        //padding: 0.75rem var(--spacing-xxl, 6.25rem);
        flex-direction: column;
        justify-content: center;
        align-items: flex-start;
        gap: 0.625rem;
        align-self: stretch;

        .demandspace-sovsoicontent-typo {
          color: var(--grayscale-white, theme.$white);
          font-family: Inter-Bold;
          font-size: 1rem;
          font-style: normal;
          line-height: 130%;
          display: flex;
          justify-content: center;
          align-items: center;
          gap: 0.5rem;

          @media screen and (width < 100rem) {
            font-size: 0.8125rem;
          }

          .sov-soi-icon {
            color: theme.$ab-yellow;
          }
        }
      }
    }
  }
}

.occasion-cards-main {
  display: flex;
  padding: 0rem var(--spacing-horizontal-margin, 6.25rem);
  align-items: center;
  gap: var(--spacing-s, 1.25rem);
  align-self: stretch;

  .occasion-card-div {
    display: flex;
    padding: var(--spacing-m, 2.5rem) var(--spacing-s, 1.25rem);
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: var(--spacing-s, 1.25rem);
    flex: 1 0 0;
    border-radius: 1rem;
    background: var(--Actions, theme.$ab-yellow);

    .item-macrooccasion {
      color: var(--grayscale-black, theme.$black);
      text-align: center;
      font-family: Inter;
      font-size: 15px;
      font-style: normal;
      font-weight: 900;
      line-height: 150%;
      letter-spacing: 0.45px;
      text-transform: uppercase;

      @media screen and (width < 100rem) {
        font-size: 12px;
      }
    }

    .item-macro {
      align-self: stretch;
      color: var(--grayscale-black, theme.$black);
      text-align: center;
      font-family: Zuume;
      font-size: 5rem;
      font-style: normal;
      font-weight: 900;
      line-height: 80%;
      text-transform: uppercase;

      @media screen and (width < 100rem) {
        font-size: 3.75rem;
      }
    }

    .item-description {
      align-self: stretch;
      color: var(--grayscale-black, theme.$black);
      text-align: center;
      font-family: Inter;
      font-size: 1rem;
      font-style: normal;
      font-weight: 700;
      line-height: 130%;

      @media screen and (width < 100rem) {
        font-size: 0.8125rem;
      }
    }
  }
}

//section4
.section4-container-section4-contextual-factor {
  display: flex;
  // width: 100%;
  // padding: var(--spacing-section, 9.375rem);
  //margin: var(--Spacing-Section, 150px) 0px;
  flex-direction: column;
  align-items: center;
  gap: var(--spacing-xxl, 6.25rem);

  .section4-contextual-factor {
    //margin: 9.375rem 6.25rem 0rem 6.25rem;
    margin: 6.25rem;
    display: flex;
    flex-direction: column;
    align-self: stretch;
    gap: var(--spacing-xxl, 6.25rem);

    .section4-image-list {
      overflow: hidden;
      //max-height: theme.$max-view-height;
    }
  }
}

//Section5
.ds-section5 {
  display: flex;
  //margin: var(--spacing-section, 9.375rem) var(--spacing-horizontal-margin, 6.25rem) 9.375rem var(--spacing-horizontal-margin, 6.25rem);
  margin: 6.25rem;
  flex-direction: column;
  align-items: center;
  gap: var(--spacing-xxl, 6.25rem);

  .section5-demandSpaceTemplate {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    max-height: theme.$max-view-height;
  }

  .need-name {
    align-self: stretch;
    color: var(--Grayscale-White, theme.$white);
    font-family: Inter-Black;
    font-size: 2.5rem;
    font-style: normal;
    line-height: 110%;

    @media screen and (width < 100rem) {
      font-size: 1.875rem;
    }
  }

  .need-description {
    align-self: stretch;
    color: var(--Grayscale-White, theme.$white);
    font-family: Inter-Regular;
    font-size: 1rem;
    font-style: normal;
    font-weight: 400;
    line-height: 120%;
    letter-spacing: 0.5px;

    @media screen and (width < 100rem) {
      font-size: 0.8125rem;
    }
  }

  .needs-cards-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: var(--Spacing-None, 0px);
    align-self: stretch;
    min-height: 40rem;
    margin-top: auto;

    .population-index {
      display: flex;
      //padding: var(--Spacing-XS, 0.75rem) 1.25rem var(--Spacing-XS, 0.75rem) var(--Spacing-S, 1.25rem);
      padding: 0.5rem 1.25rem;
      justify-content: flex-end;
      align-items: flex-start;
      //margin-top: 2.5rem;
      gap: 1.5rem;
      align-self: stretch;
      border-radius: var(--Radius-M, 1rem);

      .population-index-typo {
        color: var(--Grayscale-300, theme.$grey-300);
        text-align: center;
        font-family: Inter-Bold;
        font-size: 1rem;
        font-style: normal;
        line-height: 130%;

        @media screen and (width < 100rem) {
          font-size: 0.8125rem;
        }
      }
    }

    .needs-mapped-cards-box {
      display: flex;
      flex-direction: column;
      justify-content: flex-start;
      align-items: flex-start;
      gap: var(--Spacing-S, 0.75rem);
      align-self: stretch;
      background: var(--Grayscale-800, #1d1d1d);
      border-radius: 1rem;
      padding: 1rem 0.5rem;
      //min-height: 43.75rem;

      @media screen and (width < 100rem) and (width > 1400px) {
        // min-height: 36rem;
      }

      @media screen and (width < 1400px) {
        //min-height: 37rem;
      }

      .needs-table-row {
        border-bottom: 0.2px solid rgba($color: theme.$white, $alpha: 0.2);
        height: 145px;
      }

      .needs-mapped-name {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        align-self: flex-start;
        gap: 0.5rem;
        flex: 1 0 0;

        .needs-mapped-name-typo1 {
          align-self: stretch;
          color: var(--Global-Colors-AB-Light-Gold, theme.$ab-yellow);
          font-family: Zuume-Bold;
          font-size: 1.875rem;
          font-style: normal;
          line-height: 100%;
          letter-spacing: 0.6px;
          text-transform: uppercase;

          @media screen and (width < 100rem) {
            font-size: 22.5px;
          }
        }

        .needs-mapped-name-typo2 {
          align-self: stretch;
          color: var(--Grayscale-White, theme.$white);
          font-family: Inter-Regular;
          font-size: 1.25rem;
          font-style: normal;
          font-weight: 400;
          line-height: 130%;

          @media screen and (width < 100rem) {
            font-size: 1rem;
          }
        }
      }

      .needs-mapped-percentage-typo1 {
        color: var(--Grayscale-White, theme.$white);
        text-align: right;
        font-family: Zuume-Bold;
        font-size: 1.8125rem;
        font-style: normal;
        line-height: normal;
        letter-spacing: 0.87px;
      }

      .needs-mapped-percentage-typo2 {
        display: flex;
        padding: 3px 8px;
        justify-content: center;
        align-items: center;
        gap: 0.625rem;
        border-radius: 0.5rem;
        background: var(--Global-Colors-Index-Positive, #309427);
        color: var(--Grayscale-White, theme.$white);
        text-align: center;
        font-family: Zuume-Bold;
        font-size: 1.8125rem;
        font-style: normal;
        line-height: normal;
        letter-spacing: 0.87px;

        &.positive {
          background-color: theme.$positive;
        }

        &.neutral {
          background-color: theme.$neutral;
        }

        &.negative {
          background-color: theme.$negative;
        }
      }

      // .needs-mapped-cards {
      //   display: flex;
      //   padding: var(--Spacing-S, 1.25rem);
      //   justify-content: space-between;
      //   align-items: center;
      //   align-self: stretch;
      //   min-height: 7.5rem;
      //   border-radius: 1rem;
      //   gap: 0.5rem;
      //   background: var(--Grayscale-800, theme.$grey-800);

      //   .needs-mapped-name {
      //     display: flex;
      //     flex-direction: column;
      //     align-items: flex-start;
      //     align-self: flex-start;
      //     gap: 0.5rem;
      //     flex: 1 0 0;

      //     .needs-mapped-name-typo1 {
      //       align-self: stretch;
      //       color: var(--Global-Colors-AB-Light-Gold, theme.$ab-yellow);
      //       font-family: Zuume-Bold;
      //       font-size: 1.875rem;
      //       font-style: normal;
      //       font-weight: 700;
      //       line-height: 100%;
      //       letter-spacing: 0.6px;
      //       text-transform: uppercase;

      //       @media screen and (width < 100rem) {
      //         font-size: 22.5px;
      //       }
      //     }

      //     .needs-mapped-name-typo2 {
      //       align-self: stretch;
      //       color: var(--Grayscale-White, theme.$white);
      //       font-family: Inter-Regular;
      //       font-size: 1.25rem;
      //       font-style: normal;
      //       font-weight: 400;
      //       line-height: 130%;

      //       @media screen and (width < 100rem) {
      //         font-size: 1rem;
      //       }
      //     }
      //   }

      //   .needs-mapped-percentage {
      //     display: flex;
      //     align-items: center;
      //     gap: 0.75rem;

      //     .needs-mapped-percentage-typo1 {
      //       color: var(--Grayscale-White, theme.$white);
      //       text-align: right;
      //       font-family: Zuume-Bold;
      //       font-size: 1.8125rem;
      //       font-style: normal;
      //       font-weight: 700;
      //       line-height: normal;
      //       letter-spacing: 0.87px;
      //     }

      //     .needs-mapped-percentage-typo2 {
      //       display: flex;
      //       padding: 3px 8px;
      //       justify-content: center;
      //       align-items: center;
      //       gap: 0.625rem;
      //       border-radius: 0.5rem;
      //       background: var(--Global-Colors-Index-Positive, #309427);
      //       color: var(--Grayscale-White, theme.$white);
      //       text-align: center;
      //       font-family: Zuume-Bold;
      //       font-size: 1.8125rem;
      //       font-style: normal;
      //       font-weight: 700;
      //       line-height: normal;
      //       letter-spacing: 0.87px;
      //       &.positive {
      //         background-color: theme.$positive;
      //       }

      //       &.neutral {
      //         background-color: theme.$neutral;
      //       }

      //       &.negative {
      //         background-color: theme.$negative;
      //       }
      //     }
      //   }
      // }
    }
  }

  @media (min-width: 925px) and (max-width: 1325px) {
    .css-1u89js,
    .css-1xr35vu-MuiGrid-root,
    .emotional-functional-needs-grid {
      gap: 30px;
    }
  }

  .all-cards-container {
    display: flex;
    align-items: flex-start;
    //gap: var(--Spacing-S, 1.25rem);
    align-self: stretch;

    .modular-item {
      display: flex;
      // height: 500px;
      // max-height: 500px;
      justify-content: center;
      align-items: center;
      gap: 10px;
      //flex: 1 0 0;
      border-radius: 16px;
      min-height: 100%;
      align-self: stretch;

      .quote-attribution-card-container {
        .quote-attribution-card-content-container {
          .card-text {
            text-align: left;
            display: flex;
            align-items: center;
          }

          .card-attribution-container {
            align-items: flex-start;
          }
        }
      }

      .statementcard-container {
        .card-content-container {
          .big-statement {

            span,
            b {
              font-size: 80px;

              @media screen and (width < 100rem) {
                font-size: 60px;
              }
            }
          }
        }
      }
    }

    .box-content-bg-color {
      background-color: theme.$grey-800;
    }
  }

  .section5-percentage-info-box {
    display: flex;
    //padding: 0px var(--Spacing-XXL, 70px);
    flex-direction: row;
    //margin-top: 50px;
    //justify-content: center;
    justify-content: flex-start;
    align-items: flex-start;
    gap: 8px;
    align-self: stretch;

    .information-icon {
      color: theme.$ab-yellow;
    }

    .section5-percentage-info {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      gap: 1rem;

      .section5-percentage-info-typo {
        color: var(--Grayscale-White, #fff);
        font-family: Inter-Regular;
        font-size: 1rem;
        font-style: normal;
        font-weight: 400;
        line-height: 130%;

        @media screen and (width < 100rem) {
          font-size: 0.8125rem;
        }

        b {
          font-family: Inter-Bold;
        }
      }
    }
  }

  .intheir-ownwords-container {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    align-self: stretch;
    gap: 12px;
  }

  .intheir-typo {
    color: theme.$white;
    font-family: Inter-Black;
    font-size: 15px;
    font-style: normal;
    line-height: 150%;
    letter-spacing: 0.45px;
    text-transform: uppercase;

    @media screen and (width < 100rem) {
      font-size: 12px;
    }
  }

  .intheir-divider {
    background: theme.$ab-yellow;
    height: 1px;
  }
}

//section-6

.section6-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: var(--spacing-xxl, 6.25rem);
  background: linear-gradient(180deg, #29270b 0%, #000 22.11%);

  // background: linear-gradient(180deg, #29270b 0%, #000 18.44%);
  .section6-consumption {
    //margin: 9.375rem 6.25rem;
    margin: 6.25rem;
    display: flex;
    flex-direction: column;
    align-self: stretch;
    gap: var(--spacing-xxl, 6.25rem);

    .section-main {
      max-height: theme.$max-view-height;
    }

    .section6-image-list-consumption {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      overflow: hidden;

      .consumption-card-content {
        min-height: 18rem;

        @media screen and (width < 100rem) {
          min-height: 15.5rem;
        }
      }
    }

    .interaction-tooltip-container {
      display: flex;
      justify-content: center;
      align-items: center;
      gap: 0.5rem;
      //margin-bottom: 2rem;

      .interaction-tooltip-text {
        color: var(--grayscale-white, theme.$white);
        font-family: Inter-Regular;
        font-style: normal;
        font-weight: 400;
        line-height: 130%;
      }
    }

    .beer-hard-deep-dive-container {
      display: flex;
      flex-direction: column;
      gap: 1.25rem;
      //max-height: theme.$max-view-height;
      min-height: theme.$max-view-height;

      .beer-deep-dive-table {
        display: flex;
        //margin-bottom: 1.5rem;
        padding: var(--Spacing-S, 1.25rem) 2rem var(--Spacing-M, 2.5rem) 2rem;
        flex-direction: column;
        align-items: flex-start;
        gap: var(--Spacing-S, 1.25rem);
        align-self: stretch;
        border-radius: var(--Radius-M, 1rem);
        background: var(--Grayscale-800, #1d1d1d);

        .price-tier {
          display: flex;
          //padding: 0px 8px;
          flex-direction: column;
          align-items: flex-start;
          gap: 0.625rem;
          align-self: stretch;

          .price-tier-typo {
            align-self: stretch;
            color: var(--Grayscale-White, #fff);
            text-transform: uppercase;
            text-align: center;
            font-family: Inter-Bold;
            font-size: 1.25rem;
            font-style: normal;
            line-height: 115%;

            @media screen and (width < 100rem) {
              font-size: 1rem;
            }
          }

          .price-tier-divider {
            width: 100%;
            height: 1px;
            opacity: 0.2;
            background: #fff;
          }
        }

        .price-tier-table {
          display: flex;
          flex-direction: column;
          align-items: center;
          gap: var(--Spacing-XS, 0.75rem);
          align-self: stretch;

          .price-tier-heading-box {
            display: flex;
            flex-direction: column;
            align-items: flex-end;
            gap: var(--Spacing-S, 1.25rem);
            align-self: stretch;
            text-transform: uppercase;

            .price-tier-heading {
              display: flex;
              justify-content: space-between;
              align-items: flex-start;
              align-self: stretch;

              .price-tier-heading-typo {
                color: var(--Global-Colors-AB-Light-Gold, #f6e003);
                font-family: Inter-Bold;
                font-size: 15px;
                font-style: normal;
                line-height: 100%;
                letter-spacing: 0.5px;

                @media screen and (width < 100rem) {
                  font-size: 12px;
                }
              }

              .price-tier-heading2 {
                display: flex;
                justify-content: flex-end;
                align-items: flex-start;
                gap: 1.25rem;

                .price-tier-heading2-typo {
                  color: var(--Global-Colors-AB-Light-Gold, #f6e003);
                  text-align: right;
                  font-family: Inter-Bold;
                  font-size: 15px;
                  font-style: normal;
                  line-height: 100%;
                  /* 15px */
                  letter-spacing: 0.5px;

                  @media screen and (width < 100rem) {
                    font-size: 12px;
                  }
                }
              }
            }

            .price-tier-divider {
              width: 100%;
              height: 1px;
              opacity: 0.2;
              background: #fff;
            }
          }

          .price-tier-table-data {
            display: flex;
            justify-content: space-between;
            align-items: center;
            align-self: stretch;

            .price-tier-beertype {
              display: flex;
              width: 310.648px;
              align-items: center;
              gap: 10px;

              .price-tier-beertype-typo {
                color: var(--Grayscale-White, #fff);
                font-family: Inter-Bold;
                font-size: 15px;
                font-style: normal;
                line-height: 100%;
                letter-spacing: 0.5px;

                @media screen and (width < 100rem) {
                  font-size: 12px;
                }
              }
            }

            .price-tier-percent {
              display: flex;
              align-items: center;
              gap: var(--Spacing-S, 20px);

              .price-tier-percent-typo {
                color: var(--Grayscale-White, #fff);
                text-align: right;
                font-family: Zuume-Bold;
                font-size: 20px;
                font-style: normal;
                line-height: normal;
                letter-spacing: 0.6px;

                @media screen and (width < 100rem) {
                  font-size: 16px;
                }
              }

              .price-tier-index {
                display: flex;
                width: 52px;
                padding: 0px 5px;
                justify-content: center;
                align-items: center;
                gap: 10px;
                border-radius: 3px;

                // background: var(--Global-Colors-Index-Neutral, #434343);
                &.positive {
                  background-color: theme.$positive;
                }

                &.neutral {
                  background-color: theme.$neutral;
                }

                &.negative {
                  background-color: theme.$negative;
                }

                .price-tier-index-typo {
                  color: var(--Grayscale-White, #fff);
                  text-align: center;
                  font-family: Zuume-Bold;
                  font-size: 20px;
                  font-style: normal;
                  line-height: normal;
                  letter-spacing: 0.6px;

                  @media screen and (width < 100rem) {
                    font-size: 16px;
                  }
                }
              }
            }
          }

          .price-tier-divider {
            width: 100%;
            height: 1px;
            opacity: 0.2;
            background: #fff;
          }
        }
      }
    }

    .howtheydrink-container {
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: center;
      align-self: stretch;
      gap: 12px;
      //margin-bottom: 2.5rem;

      .howtheydrink-typo {
        color: theme.$white;
        font-family: Inter-Black;
        font-size: 15px;
        font-style: normal;
        line-height: 150%;
        letter-spacing: 0.45px;
        text-transform: uppercase;

        @media screen and (width < 100rem) {
          font-size: 12px;
        }
      }

      .howtheydrink-divider {
        flex: 1 0 0;
        background: theme.$ab-yellow;
        height: 1px;
      }
    }

    .demandspace-meetthepeople {
      display: flex;
      justify-content: space-between;
      align-items: flex-start;
      color: var(--grayscale-white, theme.$white);
      //max-height: theme.$max-view-height;

      .demandspace-howtheydrink-avatardata-top,
      .demandspace-howtheydrink-avatardata-bottom {
        justify-content: space-between;
        //min-height: calc((theme.$max-view-height - 1rem)/2);
      }
    }

    .images-container {
      display: flex;
      justify-content: space-between;
      align-items: flex-start;
      color: var(--grayscale-white, theme.$white);
    }
  }
}

.price-tier-no-data {
  color: var(--grayscale-white, theme.$white);
  font-family: Inter;
  font-size: 15px;
  font-style: normal;
  font-weight: 700;
  align-self: stretch;
  text-align: center;
}

.ds-section7 {
  display: flex;
  //margin: var(--spacing-section, 9.375rem) var(--spacing-horizontal-margin, 6.25rem) 9.375rem var(--spacing-horizontal-margin, 6.25rem);
  margin: 6.25rem;
  flex-direction: column;
  align-items: center;
  //gap: var(--spacing-xxl, 6.25rem);

}

//section8

.section8-container-downloads {
  .section8-demandspace {
    display: flex;
    //margin: var(--Spacing-Section, 150px) var(--Spacing-Horizontal-Margin, 100px);
    margin: 6.25rem;
    align-items: flex-start;
    gap: var(--Spacing-XXL, 6.25rem);
    align-self: stretch;
  }
}

.section9-container {
  .section9 {
    display: flex;
    margin: var(--Spacing-XXL, 100px) var(--Spacing-Horizontal-Margin, 100px);
    flex-direction: column;
    justify-content: center;
    align-items: center;
    align-self: stretch;
  }
}