.legendItem {
  display: flex;
  gap: 8px;
}

.colorBox {
  border-radius: 4px;
  width: 16px;
  height: 16px;
  margin: auto 0;
}

.label {
  font-size: 18px;
  text-align: left;
  flex: 1;
  margin: auto 0;
  text-transform: none;
}

.percentage {
  font-size: 18px;
  flex: 1;
}

.stats {
  display: flex;
  align-items: center;
  gap: 13px;
  width: 92px;
}

.tag {
  border-radius: 4px;
  height: 20px;
  font-size: 16px;
  text-align: center;
  padding: 2px 5px;
  color: #fff;
  display: flex;
  align-items: center;
}

@media (max-width: 991px) {
  .stats {
    white-space: initial;
  }
  
  .tag {
    white-space: initial;
  }
}
