.container {
  background: linear-gradient(180deg, #14232F 0%, rgba(0, 0, 0, 0.00) 99.22%);
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 100vw;
  margin-top: 0;
}

.sectionHeader {
  width: 1224px;
  display: flex;
  margin: 0 auto;
  padding: 80px 0 0;
  gap: 30px;
}

@media (max-width: 991px) {
  .sectionHeader {
    max-width: 100%;
  }
}

.sectionTitle {
  width: 800px;
}

@media (max-width: 991px) {
  .sectionTitle {
    max-width: 100%;
  }
}

.highlightLine {
  background-color: #f6e003;
  height: 4px;
  max-width: 100%;
  width: 200px;
  border: 4px solid #f6e003;
}

@media (max-width: 991px) {
  .titleWrapper {
    max-width: 100%;
    margin-top: 40px;
  }
}

.mainTitle {
  text-transform: uppercase;
  font: 800 75px/1 Barlow, sans-serif;
  margin: 40px 0;
  color: #fff;
}

@media (max-width: 991px) {
  .mainTitle {
    max-width: 100%;
    font-size: 40px;
  }
}

.mainDescription {
  font: 600 36px/1 Work Sans, -apple-system, Roboto, Helvetica, sans-serif;
  margin: 0;
  color: #fff;
}

@media (max-width: 991px) {
  .mainDescription {
    max-width: 100%;
  }
}
