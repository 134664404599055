@use '../theme';

.quoteAttributionImageCard-container {
    display: flex;
    align-items: flex-start;
    align-self: stretch;

    .card-content-grid {
        background: var(--Grayscale-800, #1D1D1D);
        border-radius: 1rem;
        .left-item {
            .left-item-content {
                display: flex;
                padding: 1.5rem;
                flex-direction: column;
                justify-content: space-between;
                align-items: flex-start;
                flex: 1 0 0;
                align-self: stretch;

                .quote-icon {
                    width: 50px;
                    height: 43px;
                    color: theme.$white;
                    fill: theme.$white;
                    
                    &.Fram_PSGMT_2 {
                        fill: theme.$comfort-craver;
                        color: theme.$comfort-craver;
                    }
                    &.Fram_PSGMT_3 {
                        fill: theme.$loyal-traditionalist;
                        color: theme.$loyal-traditionalist;
                    }
                    &.Fram_PSGMT_5  {
                        fill: theme.$balanced-enjoyers;
                        color: theme.$balanced-enjoyers;
                    }
                    &.Fram_PSGMT_6 {
                        fill: theme.$diligent-discoverer;
                        color: theme.$diligent-discoverer;
                    }
                    &.Fram_PSGMT_8 {
                        fill: theme.$carefree-fun-lover;
                        color: theme.$carefree-fun-lover;
                    }
                    &.Fram_PSGMT_9 {
                        fill: theme.$proud-celebrator;
                        color: theme.$proud-celebrator;
                    }
                    &.Fram_PSGMT_11 {
                        fill: theme.$creative-explorer;
                        color: theme.$creative-explorer;
                    }
                    &.Fram_PSGMT_12 {
                        fill: theme.$social-curator;
                        color: theme.$social-curator;
                    }
                }

                .quote-text {
                    align-self: stretch;
                    color: var(--Grayscale-White, #FFF);

                    /* Desktop/Body/Default/Italic */
                    font-family: Inter-SemiBold;
                    font-size: 20px;
                    font-style: italic;
                    line-height: 130%; /* 26px */
                    @media screen and (width < 100rem) {
                        font-size: 16px;
                      }
                }

                .card-attributions-container {
                    display: flex;
                    flex-direction: column;
                    align-items: flex-start;
                    gap: 0.5rem;
                    align-self: stretch;

                    .quote-by {
                        color: var(--Grayscale-White, theme.$white);

                        /* Desktop/Functional/Label */
                        font-family: Zuume;
                        font-size: 20px;
                        font-style: normal;
                        font-weight: 700;
                        line-height: 100%; /* 20px */
                        letter-spacing: 1px;
                        text-transform: uppercase;
                        @media screen and (width < 100rem) {
                            font-size: 16px;
                          }
                    }

                    .attribution-items {
                        display: flex;
                        align-items: flex-start;
                        gap: 8px;

                        .attribution {
                            /* Desktop/Functional/Label */
                            font-family: Zuume-Bold;
                            font-size: 20px;
                            font-style: normal;
                            line-height: 100%;
                            letter-spacing: 1px;
                            text-transform: uppercase;
                            @media screen and (width < 100rem) {
                                font-size: 16px;
                              }
                        }

                        .attribution-divider {
                            width: 1px;
                            height: 20px;
                            opacity: 0.42;
                        }
                    }
                }
            }
        }

        .right-item {
            .image-container {
                height: 499px;
                max-height: 500px;
                
                border-radius: 0 1rem 1rem 0;
                
                // background-repeat: no-repeat;
                // background-position: center;
                // background-size: contain;
                overflow: hidden;

                .card-image {
                    height: 100%;
                    width: 100%;
                    border-radius: 0 1rem 1rem 0;
                    // aspect-ratio: 1;
                    object-fit: cover;
                }
            }
        }
    }

    .Fram_PSGMT_2 {
        fill: theme.$comfort-craver;
        color: theme.$comfort-craver;
    }
    .Fram_PSGMT_3 {
        fill: theme.$loyal-traditionalist;
        color: theme.$loyal-traditionalist;
    }
    .Fram_PSGMT_5  {
        fill: theme.$balanced-enjoyers;
        color: theme.$balanced-enjoyers;
    }
    .Fram_PSGMT_6 {
        fill: theme.$diligent-discoverer;
        color: theme.$diligent-discoverer;
    }
    .Fram_PSGMT_8 {
        fill: theme.$carefree-fun-lover;
        color: theme.$carefree-fun-lover;
    }
    .Fram_PSGMT_9 {
        fill: theme.$proud-celebrator;
        color: theme.$proud-celebrator;
    }
    .Fram_PSGMT_11 {
        fill: theme.$creative-explorer;
        color: theme.$creative-explorer;
    }
    .Fram_PSGMT_12 {
        fill: theme.$social-curator;
        color: theme.$social-curator;
    }
}