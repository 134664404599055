.container {
  display: flex;
  flex-direction: column;
  margin-top: -80px;
}

.content {
  display: flex;
  margin: 0 auto;
  align-items: center;
  justify-content: space-between;
  flex-wrap: wrap;
  width: 1224px;
}

.mainSection {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 724px;
  margin: auto 0;
}

.copySection {
  display: flex;
  min-width: 240px;
  max-width: 724px;
  flex-direction: column;
  margin: auto 0;
  padding-bottom: 80px;
}

.mainTitle {
  color: #fff;
  font-size: 100px;
  font-weight: 900;
  line-height: 1;
  margin-top: 4px;
}

.stats {
  align-self: start;
  display: flex;
  align-items: center;
  gap: 12px;
  color: #c7c7c7;
  justify-content: start;
  font: 500 18px/1 Work Sans, -apple-system, Roboto, Helvetica, sans-serif;
}

.divider {
  opacity: 0.2;
  align-self: stretch;
  width: 0;
  height: 40px;
  margin: auto 0;
  border: 1px solid #fff;
}

.description {
  display: flex;
  margin-top: 33px;
  width: 100%;
  flex-direction: column;
  font-size: 22px;
  justify-content: start;
}

.descriptionText {
  max-width: 700px;
  font-family: Work Sans, sans-serif;
  color: #fff;
  font-weight: 600;
  line-height: 32px;
}

.cardsContainer {
  display: flex;
  margin-top: 40px;
  width: 100%;
  max-width: 100%;
  flex-direction: row;
}

.cardsGrid {
  display: flex;
  flex-direction: row;
  width: 680px;
  align-items: center;
  gap: 20px;
  justify-content: space-between;
  flex-wrap: wrap;
}

.productsSection {
  display: flex;
  min-width: 240px;
}

.subcatCans {
  max-height: 250px;
  width: auto;
  max-width: 410px;
  object-fit: contain;
  transition: transform 0.2s ease;
  margin-top: -220px;
}

.subcatCans:hover {
  transform: scale(1.05);
}



@media (max-width: 1275px) {
  .content {
    max-width: 90%;
    padding: 0 20px;
  }

  .mainSection {
    width: 100%;
    max-width: 600px;
  }

  .copySection {
    width: 100%;
    max-width: 600px;
  }

  .mainTitle {
    font-size: 80px;
  }

  .cardsGrid {
    width: 100%;
    max-width: 600px;
  }

  .subcatCans {
    max-height: 200px;
    margin-top: -180px;
  }
}

@media (max-width: 991px) {
  .content {
    flex-direction: column;
    gap: 20px;
  }

  .mainTitle {
    font-size: 60px;
  }

  .descriptionText {
    max-width: 100%;
  }

  .cardsGrid {
    justify-content: center;
  }

  .subcatCans {
    max-height: 380px;
    margin-top: -40px;
  }
}
