@use "theme";

.card-container {
  width: 100%;
  max-width: 300;
  border-radius: 1rem;

  &.dimmed {
    opacity: 0.5;
  }

  .card-media {
    height: 100%;
  }

  .card-content {
    height: 2.375rem;
    display: flex;
    justify-content: space-between;
    align-items: center;
    z-index: 1;

    .card-content-text {
      margin: 0;
      font-family: Zuume-Black;
      font-size: 31.871px;
      font-style: normal;
      line-height: 90%;
      letter-spacing: 1.275px;
      @media screen and (width < 100rem) {
        font-size: 23px;
      }

      &.disabled {
        color: theme.$white;
      }

      &.active {
        color: theme.$black;
      }
    }
  }
}
.macro-occassion-cards {
  display: flex;
  padding: var(--Spacing-M, 40px) var(--Spacing-S, 20px);
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: var(--spacing-s, 1.25rem);
  flex: 1 0 0;
  //min-height: 23rem;
  border-radius: 1rem;
  background: theme.$ab-yellow !important;

  .card-text-content-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 24px;
    align-self: stretch;

    .macro-card-icontext {
      color: theme.$black;
      text-align: center;
      font-family: Inter-Black;
      font-size: 15px;
      font-style: normal;
      line-height: 150%;
      letter-spacing: 0.45px;
      text-transform: uppercase;
      width: 296px;
      @media screen and (width < 100rem) {
        font-size: 12px;
     }
    }
    .macro-card-title {
      color: theme.$black;
      text-align: center;
      font-family: Zuume-Black;
      font-size: 5rem;
      font-style: normal;
      line-height: 80%;
      text-transform: uppercase;
      overflow: hidden;
      white-space: nowrap;
      @media screen and (width < 100rem){
        font-size: 3.75rem;
      }
    }
    .macro-card-description {
      align-self: stretch;
      color: theme.$black;
      color: var(--Grayscale-Black, #000);
      text-align: left;
      font-family: Inter-Bold;
      font-size: 1rem;
      font-style: normal;
      line-height: 130%;
      @media screen and (width < 100rem){
        font-size: 0.8125rem;
      }
    }
  }
}

.macroItems-main-div {
  display: flex;
  // padding: var(--spacing-m, 2.5rem);
  // min-height: 21rem;
  flex-direction: column;
  justify-content: space-between;
  align-items: flex-start;
  flex: 1 0 0;
  align-self: stretch;
  border-radius: 1.25rem;
  height: 100%;
  background: theme.$grey-800 !important;
  .macroItems-cardcontent{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    gap: 1.5rem;
    //padding: 2.5rem;
    padding: 1.5rem;
    //min-height: 22rem;
    flex: 1;
  .macroItems-div {
    display: flex;
    //padding-right: var(--spacing-s, 1.25rem);
    flex-direction: column;
    align-items: flex-start;
    justify-content: space-around;
    gap: 0.6875rem;
    align-self: stretch;
    flex: 1;
    
    .occasionDisplayName-typo {
      align-self: stretch;
      color: theme.$white;
      font-family: Inter-Bold;
      font-size: 1.75rem;
      font-style: normal;
      line-height: 130%;
      padding-right: 20px;
      @media screen and (width < 100rem){
        font-size: 1.3125rem;
      }
    }
    .description-typo {
      align-self: stretch;
      color: theme.$white;
      font-family: Inter-Regular;
      font-size: 1rem;
      font-style: normal;
      font-weight: 400;
      line-height: 130%;
      @media screen and (width < 100rem){
        font-size: 0.8125rem;
      }
    }
  }
  .icon-main-div {
    display: flex;
    justify-content: center;
    align-items: flex-start;
    gap: var(--spacing-m, 1.5625rem);
    align-self: stretch;
    .macroitem-details-box {
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      align-items: flex-start;
      align-self: stretch;
      gap: var(--spacing-none, 0.625rem);
      .macro-item-soi-main {
        display: flex;
        flex-direction: column;
        align-items: center;
        gap: var(--spacing-none, 0rem);
        .macro-item-soi {
          display: flex;
          width: 2.8125rem;
          height: 2.375rem;
          padding: 0.375rem;
          justify-content: center;
          align-items: center;
          gap: 0.5rem;
          border: 1px solid theme.$ab-yellow;
          color: theme.$ab-yellow;
          text-align: center;
          font-family: Zuume-Bold;
          font-size: 1.8125rem;
          font-style: normal;
          line-height: normal;
          letter-spacing: 0.87px;
        }
        .macro-item-soi-typo {
          display: flex;
          padding-bottom: 2px;
          align-items: center;
          justify-content: center;
          gap: 0.5rem;
          align-self: stretch;
          background: theme.$ab-yellow;
          color: theme.$grey-800;
          text-align: center;
          flex: 1 0 0;
          font-family: Zuume-Bold;
          font-size: 1.25rem;
          font-style: normal;
          line-height: 100%;
          letter-spacing: 1px;
          text-transform: uppercase;
          @media screen and (width < 100rem){
            font-size: 1rem;
          }
        }
      }
    }
    .icon-main-second-div {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      gap: var(--spacing-xs, 1rem);
      flex: 1 0 0;
      .location-items {
        display: flex;
        align-items: center;
        flex-direction: row;
        justify-content: center;
        gap: 0.625rem;

        .location-items-typo {
          display: flex;
          padding: 0.5rem;
          justify-content: center;
          align-items: center;
          gap: 0.5rem;
          border-radius: var(--radius-l, 1.5rem);
          color: #000;
          background-color: theme.$ab-yellow;
        }
        .location-items-typo2 {
          color: var(--grayscale-white, theme.$white);
          font-family: Zuume-Bold;
          font-size: 1.25rem;
          font-style: normal;
          line-height: 100%;
          letter-spacing: 1.3px;
          text-transform: uppercase;
          @media screen and (width < 100rem){
            font-size: 1rem;
          }
        }
      }
    }
  }
}
}
