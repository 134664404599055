@use "theme";

.main-content {
  .template-container {
    width: 100%;
    min-height: theme.$max-view-height;
    // max-height: 100vh;
    background-position: center;
    background-size: cover;
    background-color: lightgray;
    background-repeat: no-repeat;
    //min-height: 700px;
    display: flex;
    justify-content: space-between;

    .hero-container {
      display: flex;
      //padding: 7rem 6.25rem 5rem 6.25rem;
      margin: 7rem 6.25rem 5rem 6.25rem;
      flex-direction: column;
      justify-content: space-between;
      align-items: flex-end;
      flex: 1 0 0;
      align-self: stretch;

      .hero-wayfinder {
        display: flex;
        width: 23.875rem;
        min-height: 10rem;
        padding: 0.28rem 0.625rem 0.5rem 0.1875rem;
        flex-direction: column;
        align-items: center;
        gap: 1.721px;

        border-radius: 0.4375rem;
        background: rgba(29, 29, 29, 0.8);
        backdrop-filter: blur(13.330080032348633px);
      }

      .hero-content {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: flex-end;
        // gap: var(--Spacing-M, 8.25rem);
        align-self: stretch;
        // margin-bottom: 5rem;

        .occasion-type {
          display: flex;
          align-items: flex-start;
          align-self: flex-start;
          max-width: 14.375rem;
          gap: var(--spacing-s, 1.25rem);

          color: var(--grayscale-white, theme.$white);
          /* Desktop/Headers/H1 */
          font-family: Zuume-Black;
          font-size: 144px;
          font-style: normal;
          line-height: 80%;
          /* 115.2px */
          text-transform: uppercase;

          @media screen and (width < 100rem) {
            font-size: 108px;
          }
        }

        .occasion-type-scope {
          display: flex;
          align-items: flex-start;
          gap: 1rem;
        }
      }
    }
  }

  .section1 {
    display: flex;
    margin: 6.25rem;
    flex-direction: column;
    align-items: flex-start;
    gap: var(--spacing-xxl, 6.25rem);
    align-self: stretch;

    .intro-container-main {
      display: flex;
      flex-direction: column;
      gap: 3.75rem;

      .paragraph-class {
        .paragraph {
          color: var(--grayscale-white, theme.$white);
          /* Desktop/Headers/H4 */
          font-family: Inter-Black;
          font-style: normal;
          line-height: 110%;

          /* 44px */
          @media screen and (width < 100rem) {
            font-size: 30px;
          }

          i {
            color: var(--Actions, theme.$ab-yellow);
            font-style: normal;
          }
        }
      }

      .intro-container {
        display: flex;
        align-items: flex-start;
        // gap: var(--spacing-xxl, 6.25rem);
        flex: 1 0 0;

        .left-item {
          .left-item-content {
            display: flex;
            flex-direction: column;
            align-items: flex-start;
            gap: 3.75rem;
            flex: 1 0 0;

            .visual {
              display: flex;
              flex-direction: column;
              justify-content: center;
              align-items: flex-start;
              gap: 11.005px;
              align-self: stretch;

              .card-container {
                .card-media {
                  min-height: 9.25rem;
                }
              }

              .visual-heading-macro {
                display: flex;
                padding: 0.75rem var(--spacing-none, 0px);
                flex-direction: column;
                justify-content: center;
                align-items: center;
                gap: 18.116px;
                align-self: stretch;
                border-radius: 14.493px;
                background: var(--grayscale-800, theme.$grey-800);

                .macro-text {
                  color: var(
                    --global-colors-ab-light-gold,
                    var(--Actions, theme.$ab-yellow)
                  );
                  text-align: center;
                  font-family: Inter-Bold;
                  font-size: 25.362px;
                  font-style: normal;
                  line-height: 130%;
                  /* 32.971px */
                }
              }

              .visual-cards {
                display: flex;
                align-items: flex-start;
                gap: 18.116px;
                align-self: stretch;

                .icon-background {
                  display: flex;
                  width: 32.886px;
                  height: 32.886px;
                  padding: 3.059px;
                  align-items: center;
                  justify-content: center;
                  gap: 7.648px;
                  border-radius: 68.052px;

                  &.dark {
                    background: var(--grayscale-black, theme.$black);
                  }

                  &.light {
                    background: var(--grayscale-white, theme.$white);
                  }
                }
              }
            }
          }
        }

        .right-item {
          display: flex;

          .intro-right-box {
            display: flex;
            min-height: 13.25rem;
            padding: 2.5rem;
            flex-direction: column;
            justify-content: space-between;
            align-items: flex-start;
            align-self: stretch;
            flex: 1;
            flex-shrink: 0;
            gap: 1.25rem;

            border-radius: var(--Radius-M, 16px);
            background: var(--Grayscale-800, #1d1d1d);

            .heading {
              text-transform: uppercase;
              color: var(--Actions, theme.$ab-yellow);

              font-family: Zuume-Black;
              font-size: 40px;
              font-style: normal;
              line-height: 90%;
              /* 38.7px */
              letter-spacing: 1.72px;
              width: 80%;

              @media screen and (width < 100rem) {
                font-size: 30px;
              }
            }

            .description {
              color: var(--grayscale-white, theme.$white);
              font-family: Inter-Bold;
              font-size: 16px;
              font-style: normal;
              line-height: 130%;
              text-align: start;

              @media screen and (width < 100rem) {
                font-size: 13px;
              }

              i {
                color: var(--Actions, theme.$ab-yellow);
                font-style: normal;
              }

              b {
                text-transform: uppercase;
                color: var(--Actions, theme.$ab-yellow);

                font-family: Zuume;
                font-size: 40px;
                font-style: normal;
                line-height: 90%;
                /* 38.7px */
                letter-spacing: 1.72px;

                @media screen and (width < 100rem) {
                  font-size: 30px;
                }
              }
            }
          }
        }
      }
    }

    .section1-image-list {
      //gap: var(--Spacing-S, 1.25rem);
      min-height: theme.$max-view-height;

      .section1-image-list-item {
        max-height: 22rem;

        .card-icon {
          width: 2.75rem;
          height: 2.75rem;
        }
      }

      .ugcImages {
        width: 100%;
        height: 100%;
        object-fit: cover;
        border-radius: 1rem;
      }
    }
  }

  .section2-container {
    display: flex;
    width: 100%;
    flex-direction: column;
    align-items: center;
    gap: var(--spacing-xxl, 6.25rem);
    background: linear-gradient(180deg, #29270b 0%, #000 18.44%);

    .section2 {
      display: flex;
      flex-direction: column;
      //margin: 9.375rem 6.25rem;
      margin: 6.25rem;
      gap: 6.25rem;
      //border-top: 4px solid linear-gradient(to right, theme.$ab-yellow 12.5%, transparent 87.5%);

      .section-main {
        min-height: theme.$max-view-height;
      }

      .contextual-factors-container {
        .left-item-content {
          display: flex;
          max-width: 600px;
          flex-direction: column;
          justify-content: center;
          align-items: flex-start;
          gap: var(--spacing-m, 2.5rem);
          //flex: 1 0 0;
        }

        .right-item {
          .right-item-content {
            height: 650px;
            flex-shrink: 0;
            position: relative;

            .front-image {
              position: absolute;
              display: flex;
              max-width: 27.5rem;
              max-height: 18.75rem;
              flex-direction: column;
              justify-content: flex-end;
              align-items: center;
              flex-shrink: 0;
              border-radius: 1rem;
              //background: url(<path-to-image>), lightgray 50% / cover no-repeat;
              box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.3),
                0px 8px 12px 6px rgba(0, 0, 0, 0.15);
            }

            .back-image {
              position: absolute;
              display: flex;
              max-width: 43.75rem;
              max-height: 35rem;
              flex-direction: column;
              justify-content: flex-end;
              align-items: center;
              flex-shrink: 0;
              border-radius: 1rem;
            }

            .contextual-factors-image {
              height: 100%;
              width: 100%;
            }
          }
        }
      }

      .section2-image-list {
        display: flex;
      }
    }
  }

  .section3-container {
    display: flex;
    width: 100%;
    flex-direction: column;
    align-items: center;
    gap: var(--spacing-xxl, 6.25rem);
    background: linear-gradient(180deg, #29270b 0%, #000 18.44%);

    .section3 {
      display: flex;
      flex-direction: column;
      margin: 6.25rem;
      gap: var(--Spacing-XXL, 6.25rem);
      background: none;

      .section-main {
        max-height: theme.$max-view-height;
      }

      .occasions-modular-content {
        display: flex;
        flex-direction: column;
        flex-grow: 1;
        gap: 2.5rem;

        .inthierownowrds-container {
          display: flex;
          flex-direction: row;
          align-items: center;
          justify-content: center;
          align-self: stretch;
          gap: 0.75rem;

          .snapshot-typo {
            color: theme.$white;
            font-family: Inter-Black;
            font-size: 15px;
            font-style: normal;
            line-height: 150%;
            letter-spacing: 0.45px;
            text-transform: uppercase;

            @media screen and (width < 100rem) {
              font-size: 12px;
            }
          }

          .snapshot-divider {
            background: theme.$ab-yellow;
            height: 1px;
          }
        }

        .whatItLooksLike-modularcontent-container {
          display: flex;
          align-items: flex-start;
          align-self: stretch;

          .modular-item {
            display: flex;
            height: 21.875rem;
            justify-content: center;
            align-items: center;
            gap: 10px;
            align-self: stretch;
            border-radius: 16px;

            .bigquoteimageright-card-container {
              .card-content-container {
                .bigquoteimageright-card-left-item,
                .bigquoteimageright-card-right-item {
                  max-height: 100%;

                  .smallquote-attribution-card-container {
                    width: 100%;
                    background: var(--Grayscale-800, #1d1d1d);

                    .smallquote-attribution-card-content-container {
                      margin: 0;
                      padding: 1.5rem;

                      .card-attribution-container {
                        align-items: flex-start;
                      }
                    }
                  }
                }
              }
            }
          }
        }

        .divider-container {
          display: flex;
          align-items: center;
          gap: 0.5rem;
          align-self: stretch;

          .divider-text {
            color: var(--grayscale-white, theme.$white);
            /* Desktop/Body/Overline */
            font-family: Inter-Black;
            font-style: normal;
            line-height: 150%;
            /* 22.5px */
            letter-spacing: 0.45px;
            text-transform: uppercase;
          }

          .divider {
            display: flex;
            flex: 1 0;
            border: 0.5px solid #f6e003;
          }
        }

        .grid-background {
          display: flex;
          height: 250px;
          max-height: 250px;
          flex-direction: column;
          align-items: flex-start;
          gap: var(--Spacing-S, 20px);
          flex: 1 0 0;
          border-radius: 16px;
          background: #1d1d1d;

          .left-items {
            display: flex;
            padding: 24px;
            flex-direction: column;
            justify-content: space-between;
            align-items: flex-start;
            flex: 1 0 0;
            align-self: stretch;

            .quote-mark {
              display: flex;
              align-items: flex-start;
              gap: 6px;
              align-self: stretch;
              color: #f6e003;
              font-family: Zuume;
              font-size: 100px;
              font-style: normal;
              font-weight: 900;
              line-height: 90%;
              letter-spacing: 1.72px;
            }

            .quote {
              color: var(--Grayscale-White, #fff);
              font-family: Inter;
              font-size: 16px;
              font-style: italic;
              font-weight: 600;
              line-height: 130%;

              @media screen and (width < 100rem) {
                font-size: 13px;
              }
            }

            .profile {
              display: flex;
              flex-direction: column;
              align-items: flex-start;
              gap: 4px;
              align-self: stretch;
              color: var(--Grayscale-White, #fff);
              /* Desktop/Functional/Label */
              font-family: Zuume;
              font-size: 20px;
              font-style: normal;
              font-weight: 700;
              line-height: 100%;
              /* 20px */
              letter-spacing: 1px;
              text-transform: uppercase;
              margin-top: 30px;

              @media screen and (width < 100rem) {
                font-size: 16px;
              }
            }
          }

          .right-items {
            display: flex;
            //   padding: 24px;
            flex-direction: column;
            justify-content: flex-end;
            align-items: center;
            flex: 1 0 0;
            align-self: stretch;
            overflow: hidden;
            height: 240px;
            width: 345px;
            border-radius: 16px;
          }

          .right-items img {
            width: 100%;
            height: 100%;
            object-fit: cover;
          }

          .own-words {
            display: flex;
            justify-content: space-between;
            align-items: flex-end;
            align-self: stretch;
          }

          .grid-style {
            display: flex;
            min-height: 240px;
            padding: 32px 24px 24px 24px;
            flex-direction: column;
            justify-content: space-between;
            align-items: flex-start;
            flex: 1 0 0;
            align-self: stretch;
          }

          .text {
            //   width: 279.874px;
            height: 100px;
            flex-shrink: 0;

            color: var(--Grayscale-White, #fff);
            /* Desktop/Functional/Label */
            font-family: Zuume;
            font-size: 64px;
            font-style: normal;
            font-weight: 700;
            line-height: 100%;
            /* 20px */
            letter-spacing: 1px;
            text-transform: uppercase;

            @media screen and (width < 100rem) {
              font-size: 48px;
            }
          }

          .text img {
            width: 64px;
            height: 47px;
          }

          .play-icon img {
            width: 72px;
            height: 72px;
          }
        }
      }
    }
  }

  .occasions-template-section4-container {
    display: flex;
    width: 100%;
    flex-direction: column;
    align-items: center;
    background: linear-gradient(180deg, #29270b 0%, #000 18.44%);

    .section4 {
      margin: 6.25rem;
      display: flex;
      flex-direction: column;
      //align-items: flex-start;
      align-self: stretch;
      gap: var(--spacing-xxl, 6.25rem);

      .section-main {
        max-height: theme.$max-view-height;
      }

      .interaction-tooltip-container {
        display: flex;
        justify-content: center;
        align-items: center;
        gap: 0.5rem;

        .interaction-tooltip-text {
          color: var(--grayscale-white, theme.$white);
          /* Desktop/Body/Small/Small Regular */
          font-family: Inter-Regular;
          font-style: normal;
          font-weight: 400;
          line-height: 130%;

          /* 20.8px */
          @media screen and (width < 100rem) {
            font-size: 13px;
          }
        }
      }
    }
  }

  .section5-container {
    .section5 {
      display: flex;
      margin: 6.25rem;
      flex-direction: column;
      align-items: flex-start;
      align-self: stretch;
      min-height: theme.$max-view-height;
    }
  }

  .section6-container {
    .section6 {
      display: flex;
      //padding: var(--Spacing-Section, 150px) var(--Spacing-XXL, 100px);
      margin: 6.25rem;
      flex-direction: column;
      align-items: flex-start;
      gap: var(--Spacing-XL, 80px);
      align-self: stretch;

      .wrapup-main {
        gap: 2.5rem;
        max-height: theme.$max-view-height;

        .wrapup-intro
          .wrapup-grid
          .wrapup-left-item
          .wrapup-section-title-container {
          gap: 1.25rem;

          .section-title {
            gap: 0.625rem;
          }

          .section-description {
            gap: 1.25rem;
          }
        }
      }
    }
  }

  .occasions-template-section7-container {
    .section7 {
      display: flex;
      //padding: var(--Spacing-Section, 150px) var(--Spacing-Horizontal-Margin, 100px);
      margin: 6.25rem;
      justify-content: space-between;
      align-items: flex-start;
      align-self: stretch;
      max-height: theme.$max-view-height;

      .download-resource-container {
        justify-content: space-between;
      }
    }
  }

  .occasions-template-section8-container {
    width: 100%;

    .section8 {
      margin: 0 6.25rem 6.25rem 6.25rem;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      align-self: stretch;
    }
  }
}
