.themeCard {
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
}

.header {
  border-radius: 16px 16px 0 0;
  color: #fff;
  text-transform: uppercase;
  justify-content: center;
  padding: 20px 0;
  font: 700 36px/1 Barlow, sans-serif;
  text-align: center;
}

.content {
  border-radius: 0 0 16px 16px;
  background-color: #2E2E2E;
  display: flex;
  flex-direction: column;
  color: #fff;
  text-align: center;
  justify-content: center;
  flex: 1;
  font: 600 32px/1 Work Sans, sans-serif;
  padding: 20px 0;
}

.themeText {
  width: 90%;
  margin: 0 auto;
}

.highlight {
  color: #F5DF42;
}

.divider {
  width: 90%;
  border: 1px solid rgba(255, 255, 255, 0.5);
  margin: 15px auto;
}

@media (max-width: 991px) {
  .themeCard {
    max-width: 100%;
  }
  
  .header {
    padding: 0 20px;
  }
  
  .content {
    padding: 0 20px;
  }
  
  .themeText {
    max-width: 100%;
  }
  
  .divider {
    max-width: 100%;
  }
}
