.section {
  display: flex;
  flex-direction: column;
  justify-content: start;
  width: 1224px;
  margin: 0 auto;
}

.header {
  display: flex;
  width: 1200px;
  flex-direction: column;
  font-family: Barlow, sans-serif;
  font-weight: 700;
  text-transform: uppercase;
}

.subheading {
  color: #f6e003;
  font-size: 24px;
}

.mainHeading {
  color: #fff;
  font-size: 48px;
  line-height: 1;
  margin-top: 14px;
}

.factorsContainer {
  display: flex;
  margin-top: 30px;
  gap: 10px;
  font-family: Work Sans, sans-serif;
  font-weight: 600;
  text-align: center;
}

@media (max-width: 991px) {
  .subheading,
  .mainHeading,
  .factorsContainer {
    max-width: 100%;
  }
  
  .mainHeading {
    font-size: 40px;
  }
}
