@use "../theme";

.quote-attribution-card-container {
    display: flex;
    width: 100%;
    height: 100%;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    //gap: var(--Spacing-S, 1.25rem);
    flex: 1 0 0;
    align-self: stretch;
    border-radius: 1rem;
    background: var(--Grayscale-800, #1d1d1d);

    &.demandspace {
        background-color: theme.$grey-800;
    }

    &.occasionsyellow {
        background-color: theme.$ab-yellow;
    }

    .quote-attribution-card-content-container {
        display: flex;
        flex-direction: column;
        gap: var(--Spacing-S, 1.25rem);
        margin: var(--Spacing-S, 1.25rem) var(--Spacing-M, 2.5rem);

        &.demandspace {
            height: 100%;
            display: flex;
            justify-content: space-around;
        }

        &.occasions,
        &.occasionsyellow {
            align-items: flex-start;
            justify-content: space-between;
            flex: 1;
        }

        .card-icon {
            width: 3.125rem;
            height: 3.125rem;
            fill: theme.$ab-yellow;
        }

        .quote-icon {
            width: 50px;
            height: 43px;
            color: theme.$white;
            fill: theme.$white;

            // This class provides a unified color for demand space
            &.demandspace {
                fill: theme.$ab-yellow;
                color: theme.$ab-yellow;
            }

            &.occasions {
                fill: theme.$ab-yellow;
                color: theme.$ab-yellow;
            }

            &.occasionsyellow {
                fill: theme.$black;
                color: theme.$black;
            }

            //Demand spaces - below classes set the demand space specific colors to the quote icon
            // Demand spaces have linear gradients that don't set for svgs
            // Setting the first color from the gradient
            &.Fram_DS_5 {
                //"Casual Good Times"
                fill: #8fe8bd;
                color: #8fe8bd;
            }

            &.Fram_DS_1 {
                //"Easygoing Hangout"
                fill: #ada1f8;
                color: #ada1f8;
            }

            &.Fram_DS_3 {
                //"Energetic Night Out"
                fill: #fff;//#fff;
                color: #fff;//#fff;
            }

            &.Fram_DS_4 {
                //"Upbeat Social Meal"
                fill: #fff;//#fff;
                color: #fff;//#fff;
            }

            &.Fram_DS_9 {
                //"Refined Weekend Out"
                fill: #ffc759;
                color: #ffc759;
            }

            &.Fram_DS_7 {
                //"Lively Social Gathering"
                fill: #a1e8f8;
                color: #a1e8f8;
            }

            &.Fram_DS_10 {
                //"Thoughtful Connection"
                fill: #ffc759;
                color: #ffc759;
            }

            &.Fram_DS_2 {
                //"Routine Living"
                fill: #ada1f8;
                color: #ada1f8;
            }

            &.Fram_DS_12 {
                //"Intimate Dining"
                fill: #fff;
                color: #fff;
            }

            &.Fram_DS_13 {
                //"Everyday Meal"
                fill: #fff;
                color: #ffff;
            }

            &.Fram_DS_6 {
                //"Simple Unplug"
                fill: #8fe8bd;
                color: #8fe8bd;
            }

            &.Fram_DS_8 {
                //"Intentional Unwind"
                fill: #a1e8f8;
                color: #a1e8f8;
            }

            &.Fram_DS_11 {
                //"Sensible Slowdown"
                fill: #ffc759;
                color: #ffc759;
            }
        }

        .card-text {
            display: flex;
            align-self: stretch;
            text-align: center;

            color: var(--Global-Colors-AB-Light-Gold, #f6e003);

            /* Desktop/Headers/H4 - Styled */
            font-family: Zuume-Bold;
            font-size: 43px;
            font-style: normal;
            line-height: 90%;
            /* 38.7px */
            letter-spacing: 1.72px;

            @media screen and (width < 100rem) {
                font-size: 32.25px;
            }

            &.demandspace {
                color: theme.$white;
                // text-transform: capitalize;
                font-family: Inter-SemiBold;
                font-size: 20px;
                font-style: italic;
                line-height: 130%;
                letter-spacing: 0;

                /* 26px */
                @media screen and (width < 100rem) {
                    font-size: 16px;
                }
            }

            &.occasionsyellow {
                text-align: left;
                color: theme.$black;
                text-shadow: none;
                font-family: Inter-SemiBold;
                font-size: 20px;
                font-style: italic;
                line-height: 130%;
                /* 38.7px */
                letter-spacing: 0;
                display: flex;
                align-items: center;

                @media screen and (width < 100rem) {
                    font-size: 15px;
                }
            }

            &.occasions {
                text-align: left;
                font-family: Inter-SemiBold;
                font-size: 20px;
                font-style: italic;
                line-height: 130%;
                /* 38.7px */
                letter-spacing: 0;
                display: flex;
                align-items: center;

                @media screen and (width < 100rem) {
                    font-size: 15px;
                }
            }
        }

        .card-attribution-container {
            display: flex;
            flex-direction: column;
            align-items: center;
            gap: 0.5rem;

            &.occasions,
            &.occasionsyellow {
                align-items: flex-start;
            }

            .attribution-name {
                color: var(--Grayscale-White, #fff);

                /* Desktop/Functional/Label */
                font-family: Zuume-Bold;
                font-size: 20px;
                font-style: normal;
                line-height: 100%;
                /* 20px */
                letter-spacing: 1px;
                text-transform: uppercase;

                @media screen and (width < 100rem) {
                    font-size: 16px;
                }

                &.occasionsyellow {
                    color: theme.$black;
                }
            }

            .attribution-items {
                display: flex;
                align-items: flex-start;
                gap: 0.5rem;

                .attribution {
                    color: var(--Grayscale-White, #fff);

                    /* Desktop/Functional/Label */
                    font-family: Zuume-Bold;
                    font-size: 20px;
                    font-style: normal;
                    line-height: 100%;
                    /* 20px */
                    letter-spacing: 1px;
                    text-transform: uppercase;

                    @media screen and (width < 100rem) {
                        font-size: 16px;
                    }

                    &.occasionsyellow {
                        color: theme.$black;
                    }
                }

                &.demandspace {
                    color: theme.$white;
                }

                &.occasionsyellow {
                    color: theme.$black;
                    text-shadow: none;
                }

                .attribution-divider {
                    width: 1px;
                    height: 100%;
                    opacity: 0.52;
                    background: #fff;

                    &.occasionsyellow {
                        background: theme.$black;
                    }
                }
            }
        }
    }
}