.definitions {
  border-radius: 0 0 16px 16px;
  background-color: #141414;
  width: 100%;
  font-size: 13px;
  color: #fff;
  font-weight: 500;
  line-height: 1;
  text-align: center;
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.term1 {
  text-decoration: underline;
}
.term {
  text-decoration: underline;
  margin-left: 3px;
}

@media (max-width: 991px) {
  .definitions {
    max-width: 100%;
    padding: 0 20px;
  }
}
