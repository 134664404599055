.stats {
  display: flex;
  align-items: center;
  gap: 20px;
  font-family: Barlow, sans-serif;
  font-weight: 700;
  justify-content: center;
}

.value {
  font-size: 48px;
  line-height: 1;
  color: #fff;
}

.tag {
  border-radius: 4px;
  min-height: 24px;
  font-size: 36px;
  margin: auto 0;
  padding: 4px 8px;
  color: #fff;
}

.label {
  margin-top: 20px;
  font: 600 24px/1 Work Sans, -apple-system, Roboto, Helvetica, sans-serif;
  color: #fff;
  width: 230px;
}

@media (max-width: 991px) {
  .stats {
    white-space: initial;
  }
  
  .value {
    font-size: 40px;
  }
  
  .tag {
    white-space: initial;
  }
}
