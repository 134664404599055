@use "theme";

.horizontal-main {
  display: flex;
  padding: var(--spacing-s, 1.25rem);
  flex-direction: column;
  align-items: flex-start;
  gap: 1.5rem;
  flex: 1 0 0;
  border-radius: 1rem;
  background: theme.$grey-800;

    .horizontal-heading {
      align-self: stretch;
      color: theme.$white;
      text-align: center;
      font-family: Inter-Bold;
      font-size: 1.25rem;
      font-style: normal;
      line-height: 130%;
      text-transform: uppercase;
      @media screen and (width < 100rem){
        font-size: 1rem;
      }
    }
    .horizontal-line {
      width: 100%;
      height: 1px;
      opacity: 0.2;
      background: theme.$white;
    }
  
  
  .horizontal-data {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: var(--spacing-s, 1.25rem);
    align-self: stretch;
    .horizontal-bar {
      display: flex;
      // padding: 0rem 0.5rem;
      justify-content: space-between;
      align-items: baseline;
      align-self: stretch;
      .horizontal-name {
        color: theme.$white;
        font-family: Inter-Bold;
        font-size: 0.9375rem;
        font-style: normal;
        line-height: 100%;
        letter-spacing: 0.5px;
      }
      .horizontal-end {
        display: flex;
        align-items: baseline;
        gap: 0.625rem;
        .horizontal-percent {
          color: theme.$white;
          text-align: right;
          font-family: Zuume-Bold;
          font-size: 1.25rem;
          font-style: normal;
          line-height: normal;
          letter-spacing: 0.6px;
        }
        .horizontal-index {
          width: 1.5625rem;
          color: theme.$white;
          text-align: center;
          font-family: Zuume-Bold;
          font-size: 1.25rem;
          font-style: normal;
          line-height: normal;
          letter-spacing: 0.6px;
          display: flex;
          padding: 0px 5px;
          align-items: center;
          justify-content: center;
          gap: 0.625rem;
          border-radius: 3px;
          
          &.positive {
            background-color: theme.$positive;
          }
          &.neutral {
            background-color: theme.$neutral;
          }
          &.negative {
            background-color: theme.$negative;
          }
        }
      }
    }
  }
  .custom-horizontal-data-container{
    display: flex;
    flex-direction: column;
    width: 100%;
    gap: 20px;
    flex-wrap: wrap;
    height: 11.7rem;
    @media screen and (width < 100rem) {
      flex-wrap: nowrap;
      overflow-y: scroll;
      &::-webkit-scrollbar {
        width: 0; /* Set width to 0 to hide scrollbar by default */
      }
      &:hover{
        &::-webkit-scrollbar {
          width: 5px;
        }
        &::-webkit-scrollbar-thumb {
          background-color: #888; 
        }
      }
    }

    .custom-horizontal-data {
      display: flex;
      flex-wrap: wrap;
      flex-direction: column;
      align-items: flex-start;
      gap: var(--spacing-s, 1.4rem);
      align-self: stretch;

      .custom-horizontal-bar {
        display: flex;
        justify-content: space-between;
        align-items: baseline;
        align-self: stretch;

        .custom-horizontal-name {
          color: theme.$white;
          font-family: Inter-Bold;
          font-size: 0.9375rem;
          font-style: normal;
          line-height: 100%;
          letter-spacing: 0.5px;
        }
        .custom-horizontal-end {
          display: flex;
          align-items: baseline;
          gap: 0.625rem;

          .custom-horizontal-percent {
            color: theme.$white;
            text-align: right;
            font-family: Zuume-Bold;
            font-size: 1.25rem;
            font-style: normal;
            line-height: normal;
            letter-spacing: 0.6px;
          }
          .custom-horizontal-index {
            width: 1.5625rem;
            color: theme.$white;
            text-align: center;
            font-family: Zuume-Bold;
            font-size: 1.25rem;
            font-style: normal;
            line-height: normal;
            letter-spacing: 0.6px;
            display: flex;
            padding: 0px 5px;
            align-items: center;
            justify-content: center;
            gap: 0.625rem;
            border-radius: 3px;
            @media screen and (width < 100rem){
              font-size: 1rem;
            }
            
            &.positive {
              background-color: theme.$positive;
            }
            &.neutral {
              background-color: theme.$neutral;
            }
            &.negative {
              background-color: theme.$negative;
            }
          }
        }
      }
    }
  }
 
  .piechart-box {
    display: flex;
    height: 15.75rem;
    justify-content: space-evenly;
    align-items: center;
    align-self: stretch;

      .piechart-typo-name {
        text-align: right;
        font-family: Inter-Bold;
        font-style: normal;
        font-size: 15px;
        line-height: 100%;
        letter-spacing: 0.5px;
        color: var(--Grayscale-White, theme.$white);
      }

      .piechart-typo-name1 {
        text-align: right;
        font-family: Inter-Bold;
        font-style: normal;
        font-size: 15px;
        line-height: 100%;
        letter-spacing: 0.5px;
      }
  
      .piechart-typo-index {
        display: flex;
        padding: 0px 5px;
        align-items: center;
        gap: 0.625rem;
        border-radius: 3px;
        text-align: center;
        color: var(--Grayscale-White, theme.$white);
        font-family: Zuume-Bold;
        font-style: normal;
        line-height: normal;
        font-size: 1.25rem;
        letter-spacing: 0.6px;
  
        &.positive {
          background-color: theme.$positive;
        }
        &.neutral {
          background-color: theme.$neutral;
        }
        &.negative {
          background-color: theme.$negative;
        }
      }
    }

    .recharts-layer {
      text {
        text-align: center;
        font-family: Zuume-Bold;
        font-size: 29px;
        font-style: normal;
        line-height: normal;
        letter-spacing: 0.87px;
      }
    }
  // }

  .bar-chart-main {
    display: flex;
    align-items: center;
    justify-content: center;
    background: theme.$grey-800;
    gap: var(--spacing-s, 1.25rem);
    flex: 1 0 0;
    align-self: stretch;

    .bar-chart-submain {
      display: flex;
      width: 100%;
      justify-content: space-evenly;
      height: 16rem;

      .bar-chart-box {
        display: flex;
        gap: 0.625rem;
        flex-direction: column-reverse;
        align-items: center;

        .bar-chart-agegroup {
          color: theme.$white;
          /* Desktop/Data/Data Label */
          font-family: Inter-Bold;
          font-size: 15px;
          font-style: normal;
          line-height: 100%; /* 15px */
          letter-spacing: 0.5px;
        }
        .bar-chart-index-box {
          display: flex;
          padding: 0px 5px;
          align-items: center;
          gap: 0.625rem;
          border-radius: 3px;
          
          &.positive {
            background-color: theme.$positive;
          }
          &.neutral {
            background-color: theme.$neutral;
          }
          &.negative {
            background-color: theme.$negative;
          }

          .bar-chart-index {
            color: theme.$white;
            text-align: center;
            font-family: Zuume-Bold;
            font-size: 1.25rem;
            font-style: normal;
            line-height: normal;
            letter-spacing: 0.6px;
          }
        }
        .bar-chart-percent {
          color: theme.$white;
          text-align: center;
          font-family: Zuume-Bold;
          font-size: 1.25rem;
          font-style: normal;
          line-height: normal;
          letter-spacing: 0.6px;
        }
      }
    }
  }
}
