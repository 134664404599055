@use "theme";

.persona-bg-img {
  display: flex;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  background-color: lightgray;
  min-height: calc(100vh - theme.$main-nav-height - theme.$anchor-nav-height - theme.$runner-height);
  //height: calc(100vh - theme.$main-nav-height - theme.$anchor-nav-height - theme.$runner-height);

  // height: 100%;
  // max-height: 100vh;
  //margin-top: 5rem;
  .hero-content-container {
    display: flex;
    //min-height: 700px;
    // height: 100%;
    // max-height: 100%;
    //margin: 7rem 6.25rem 5rem 6.25rem;
    padding: calc((theme.$max-view-height - theme.$runner-height)*0.15) 6.25rem calc((theme.$max-view-height - theme.$runner-height)*0.1) 6.25rem;
    flex-direction: column;
    justify-content: space-between;
    align-items: flex-end;
    flex: 1 0;
    align-self: stretch;

    .hero-wayfinder {
      width: 21.875rem;
      //min-height: 10rem;
      display: flex;
      padding: 0 0.625rem 0.5rem 0.1875rem;
      flex-direction: column;
      align-items: center;
      gap: 1.721px;
      border-radius: 0.4375rem;
      background: rgba(29, 29, 29, 0.8);
      backdrop-filter: blur(13.330080032348633px);
    }

    .hero-content {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      align-items: flex-end;
      //gap: var(--Spacing-M, 8.25rem);
      // gap: var(--Spacing-M, 20.25rem);
      align-self: stretch;

      .comfort-cravers-heading {
        display: flex;
        align-items: flex-start;
        align-self: flex-start;

        color: var(--grayscale-white, theme.$white);
        font-family: Zuume-Black;
        font-style: normal;
        line-height: 80%;
        /* 115.2px */
        text-transform: uppercase;
        // width: 70%;
        // word-wrap: break-word;
        // margin-right: 2.5rem;
        word-break: break-word;

        @media screen and (width < 100rem) {
          font-size: 108px;
        }
      }

      .scope-badge-container {
        display: flex;
        align-items: flex-end;
        align-self: flex-end;
        justify-content: flex-end;
        gap: 1rem;
      }
    }
  }
}

.people-section1-main-div {
  display: flex;
  flex-direction: column;
  align-items: center;
  //gap: var(--Spacing-Section, 9.375rem);
  gap: 6.25rem;
  align-self: stretch;
  background: linear-gradient(180deg, #1c1737 0%, rgba(0, 0, 0, 0) 100%);

  &.Fram_PSGMT_1-dark {
    background: theme.$familiar-reserved-dark;
    // background: linear-gradient(180deg, #1C1737 0%, rgba(0, 0, 0, 0.00) 100%);
  }

  &.Fram_PSGMT_4-dark {
    background: theme.$exploratory-reserved-dark;
  }

  &.Fram_PSGMT_7-dark {
    background: theme.$familiar-outgoing-dark;
  }

  &.Fram_PSGMT_10-dark {
    background: theme.$exploratory-outgoing-dark;
  }

  .intro-container {
    margin: 6.25rem 6.25rem 0 6.25rem;

    .intro-graphic-grid-container {
      min-height: calc(100vh - theme.$main-nav-height - theme.$anchor-nav-height);
      //height: calc(100vh - theme.$main-nav-height - theme.$anchor-nav-height);

      .people-section1-left-div {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        //gap: var(--Spacing-XL, 5rem);
        gap: var(--Spacing-XL, 2.5rem);
        align-self: stretch;
        margin-right: 3.125rem;

        .intro-text {
          color: var(--Grayscale-White, theme.$white);
          font-family: Inter-Black;
          font-size: 2.5rem;
          font-style: normal;
          line-height: 110%;

          @media screen and (width < 100rem) {
            font-size: 1.875rem;
          }
        }

        .people-section1-img-div {
          display: flex;
          padding: var(--Spacing-None, 0rem) var(--Spacing-S, 1.25rem);
          flex-direction: column;
          justify-content: center;
          align-items: center;
          align-self: stretch;
          gap: var(--Spacing-S, 1.25rem);
          border-radius: 13.071px;

          .people-section1-img-heading {
            display: flex;
            padding: 9.413px 0px;
            justify-content: center;
            align-items: center;
            gap: 0.5rem;
            align-self: stretch;
            border-radius: 10.758px;

            .people-section1-heading-divider {
              flex: 1 0 0;
              width: 100%;
              height: 1px;
            }

            .people-section1-img-typo {
              text-align: center;
              font-family: Inter-Black;
              font-size: 0.9375rem;
              font-style: normal;
              line-height: 150%;
              letter-spacing: 0.45px;
              text-transform: uppercase;

              @media screen and (width < 100rem) {
                font-size: 0.75rem;
              }
            }
          }

          .people-section1-img-box {
            display: flex;
            width: 14.5rem;
            flex-direction: column;
            align-items: center;
            gap: 1rem;
            align-self: stretch;
            border-radius: var(--Radius-M, 1rem);

            .people-section1-img1 {
              border: 6px solid;
              border-radius: 50%;
              cursor: pointer;

              img {
                display: flex;
                width: 9rem;
                height: 9rem;
                flex-direction: column;
                justify-content: flex-end;
                align-items: flex-start;
                gap: 0.5rem;
                border-radius: 6.25rem;
                object-fit: cover;
              }
            }

            .non-selected-people {
              border-color: theme.$grey-800;

              img {
                background: lightgray -1.247px 0px / 150.147% 100% no-repeat;
                mix-blend-mode: luminosity;
              }
            }

            .people-section1-img2 {
              display: flex;
              width: 9.5rem;
              height: 9.5rem;
              flex-direction: column;
              justify-content: flex-end;
              align-items: flex-start;
              gap: 0.5rem;
              border-radius: 6.25rem;
            }

            .people-section1-img1-typo {
              display: flex;
              padding: 15px 10px;
              flex-direction: column;
              justify-content: center;
              align-items: center;
              gap: 4px;
              align-self: stretch;
              border-radius: var(--Radius-L, 1.5rem);
              // color: var(--Grayscale-800, green);
              // width: max-content;
              text-align: center;
              font-family: Inter-Black;
              font-size: 0.9375rem;
              font-style: normal;
              line-height: 150%;
              letter-spacing: 0.45px;
              text-transform: uppercase;

              @media screen and (width < 100rem) {
                font-size: 0.75rem;
              }

              &.non-selected-typo {
                background: rgba(255, 255, 255, 0.1);
              }
            }

            // .people-section1-img1-typo-box {
            //   display: flex;
            //   padding: 15px 10px;
            //   flex-direction: column;
            //   justify-content: center;
            //   align-items: center;
            //   gap: 4px;
            //   align-self: stretch;
            //   border-radius: var(--Radius-L, 1.5rem);
            // .people-section1-img1-typo {
            //   // color: var(--Grayscale-800, green);
            //   text-align: center;
            //   font-family: Inter;
            //   font-size: 0.9375rem;
            //   font-style: normal;
            //   font-weight: 900;
            //   line-height: 150%;
            //   letter-spacing: 0.45px;
            //   text-transform: uppercase;
            // }
            // .people-section1-img1-typo2 {
            //   color: var(--Grayscale-800, theme.$grey-800);
            //   text-align: center;
            //   font-family: Inter;
            //   font-size: 0.9375rem;
            //   font-style: normal;
            //   font-weight: 900;
            //   line-height: 150%;
            //   letter-spacing: 0.45px;
            //   text-transform: uppercase;
            // }
            // }
            // .non-selected-typo {
            //   background: rgba(255, 255, 255, 0.05);
            // }
          }

          .people-section-compare-persona {
            display: flex;
            flex-direction: row;
            align-items: center;
            justify-content: center;
            gap: 8px;
            padding: 10px var(--Spacing-M, 40px);      
            cursor: pointer;
  
            .compare-typo{
              color: #FFF;
              text-align: center;
              font-family: Inter-Bold;
              font-size: 15px;
              font-style: normal;
              line-height: 130%; /* 19.5px */
              letter-spacing: -0.3px;
              text-decoration-line: underline;
            }
            // @media screen and (width < 100rem) {
            //   font-size: 0.75rem;
            // }
          }
        }
      }

      .people-section1-right-div {
        display: flex;
        flex-direction: column;
        gap: 2rem;

        .text-container {
          display: flex;
          flex-direction: column;
          gap: 1rem;

          .people-section1-right-div-typo {
            color: var(--Grayscale-White, theme.$white);
            font-family: Inter-Bold;
            font-size: 1.25rem;
            font-style: normal;
            line-height: 130%;

            @media screen and (width < 100rem) {
              font-size: 1rem;
            }
          }

          .people-section1-onhover {
            display: flex;
            align-items: center;
            gap: 10px;

            .people-section1-onhover-typo {
              color: var(--Grayscale-White, theme.$white);
              font-family: Inter-Regular;
              font-size: 1rem;
              font-style: normal;
              font-weight: 400;
              line-height: 130%;
              opacity: 0.8;

              @media screen and (width < 100rem) {
                font-size: 0.8125rem;
              }
            }
          }
        }

        .people-section1-slider-div {
          display: flex;
          padding: var(--Spacing-S, 1.25rem) var(--Spacing-M, 2.5rem);
          flex-direction: column;
          justify-content: center;
          align-items: center;
          gap: var(--Spacing-S, 0.625rem);
          align-self: stretch;
          border-radius: 13.071px;
          background: linear-gradient(180deg,
              rgba(255, 255, 255, 0.12) 0%,
              rgba(255, 255, 255, 0.1) 100%);
        }
      }
    }
  }

  .video-text-container {
    display: flex;
    flex-direction: column;
    margin: 0 6.25rem 6.25rem 6.25rem;
    gap: 2.5rem;
    //height: calc(100vh - theme.$main-nav-height - theme.$anchor-nav-height);
    //max-height: calc(100vh - theme.$main-nav-height - theme.$anchor-nav-height);

    .people-section1-featuredPerson-typo {
      width: 100%;
      flex-shrink: 0;
      color: var(--Grayscale-White, theme.$white);
      font-family: Inter-Black;
      font-size: 2.5rem;
      font-style: normal;
      line-height: 110%;

      @media screen and (width < 100rem) {
        font-size: 1.875rem;
      }
    }

    .people-section1-divider-video {
      background: theme.$white;
      opacity: 0.5;
      height: 100%;
      width: 0.02rem;
    }

    .people-section1-featuredPerson-desc {
      width: 100%;
      color: var(--Grayscale-White, theme.$white);
      font-family: Inter-Regular;
      font-size: 1.25rem;
      font-style: normal;
      font-weight: 400;
      line-height: 130%;

      @media screen and (width < 100rem) {
        font-size: 1rem;
      }
    }

    .people-featuredPerson-video-div {
      position: relative;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: flex-end;

      gap: 2.5rem;
      // margin-top: 4rem;
      align-self: stretch;
      border-radius: 2.5rem;

      .callout-button {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        z-index: 1;
        color: theme.$black;
        border-radius: 2rem;

        .play-circle {
          color: theme.$white;
          height: 12rem;
          width: 12rem;
        }
      }

      // .people-featuredPerson-container {
      //   // padding: 0;
      //   // width: 100%;
      //   box-sizing: none;

      //   .video-tag {
      //     width: 100%;
      //     height: 100%;
      //     flex-shrink: 0;
      //     border-radius: 2rem;
      //     align-self: stretch;

      //     &.Fram_PSGMT_8 {
      //       box-shadow: 0px 0px 20px 0px rgba(143, 232, 189, 0.31);
      //     }

      //     &.Fram_PSGMT_9 {
      //       box-shadow: 0px 0px 20px 0px rgba(121, 210, 113, 0.31);
      //     }

      //     &.Fram_PSGMT_2 {
      //       box-shadow: 0px 0px 20px 0px rgba(185, 131, 255, 0.31);
      //     }

      //     &.Fram_PSGMT_3 {
      //       box-shadow: 0px 0px 20px 0px rgba(173, 161, 248, 0.31);
      //     }

      //     &.Fram_PSGMT_11 {
      //       box-shadow: 0px 0px 20px 0px rgba(161, 232, 248, 0.31);
      //     }

      //     &.Fram_PSGMT_12 {
      //       box-shadow: 0px 0px 20px 0px rgba(131, 188, 255, 0.31);
      //     }

      //     &.Fram_PSGMT_5 {
      //       box-shadow: 0px 0px 20px 0px rgba(255, 199, 89, 0.31);
      //     }

      //     &.Fram_PSGMT_6 {
      //       box-shadow: 0px 0px 20px 0px rgba(255, 136, 17, 0.31);
      //     }
      //   }
      // }
      .video-tag {
        width: 100%;
        height: 100%;
        flex-shrink: 0;
        border-radius: 2rem;
        align-self: stretch;

        &.Fram_PSGMT_8 {
          box-shadow: 0px 0px 20px 0px rgba(143, 232, 189, 0.31);
        }

        &.Fram_PSGMT_9 {
          box-shadow: 0px 0px 20px 0px rgba(121, 210, 113, 0.31);
        }

        &.Fram_PSGMT_2 {
          box-shadow: 0px 0px 20px 0px rgba(185, 131, 255, 0.31);
        }

        &.Fram_PSGMT_3 {
          box-shadow: 0px 0px 20px 0px rgba(173, 161, 248, 0.31);
        }

        &.Fram_PSGMT_11 {
          box-shadow: 0px 0px 20px 0px rgba(161, 232, 248, 0.31);
        }

        &.Fram_PSGMT_12 {
          box-shadow: 0px 0px 20px 0px rgba(131, 188, 255, 0.31);
        }

        &.Fram_PSGMT_5 {
          box-shadow: 0px 0px 20px 0px rgba(255, 199, 89, 0.31);
        }

        &.Fram_PSGMT_6 {
          box-shadow: 0px 0px 20px 0px rgba(255, 136, 17, 0.31);
        }
      }
    }
  }
}

.MuiSlider-root {
  cursor: default;
}

/* Add your modal styles in a CSS file */
.compare-persona-modal {
  display: flex;
  justify-content: center;
  background-color: rgba(0, 0, 0, 0.5);

  .cp-modal-content {
    display: flex;
    width: 60vw;
    height: 90vh;
    flex-direction: column;
    align-items: center;
    gap: var(--Spacing-None, 0px);
    border-radius: var(--Radius-L, 24px);
    box-shadow: 0px 4px 200px 0px rgba(255, 255, 255, 0.1);

    .scrollable-main {
      width: 100%;
      -ms-overflow-style: none;
      /* IE and Edge */
      overflow-y: auto;

      &::-webkit-scrollbar {
        display: none;
      }

      .cp-modal-main {
        display: flex;
        padding: var(--Spacing-M, 40px) var(--Spacing-M, 40px) var(--Spacing-None, 0px) var(--Spacing-M, 40px);
        border-radius: 12px 12px 0 0;
        flex-direction: column;
        align-items: center;
        gap: var(--Spacing-L, 60px);
        align-self: stretch;
        background: var(--persona-colors-reserved-familiar-purple-gradient,
            linear-gradient(180deg, #322b59 0%, #000 100%));
      }

      .text-contents {
        width: 559px;
        display: flex;
        flex-direction: column;
        align-items: center;
        gap: 20px;
      }

      .header-text {
        color: var(--Grayscale-White, #fff);
        text-align: center;

        /* Desktop/Headers/H5 */
        font-family: Inter-Bold;
        font-size: 28px;
        font-style: normal;
        line-height: 130%;

        /* 36.4px */
        @media screen and (width < 100rem) {
          font-size: 1.3125rem;
        }
      }

      .header-description {
        align-self: stretch;
        color: #fff;
        text-align: center;

        /* Desktop/Body/Small/Small Bold */
        font-family: Inter-Regular;
        font-size: 16px;
        font-style: normal;
        font-weight: 700;
        line-height: 130%;

        /* 20.8px */
        @media screen and (width < 100rem) {
          font-size: 13px;
        }
      }

      .tabs-section {
        display: flex;
        align-items: flex-start;
        gap: 18px;

        .tab {
          display: flex;
          padding: 8px 16px;
          justify-content: center;
          align-items: center;
          gap: 10px;
          align-self: stretch;
          font-family: Inter-Bold;
          font-size: 15px;
          font-style: normal;
          line-height: 130%;
          /* 19.5px */
          letter-spacing: -0.3px;
          cursor: pointer;

          @media screen and (width < 100rem) {
            font-size: 12px;
          }
        }

        .tab-selected {
          color: #f6e003;
          border-bottom: 2px solid #f6e003;
        }

        .tab-unselected {
          color: #c7c7c7;
        }
      }
    }

    .close-bg {
      display: flex;
      align-items: center;
      justify-content: center;
      width: 100%;
      /* Set the width to 100% to extend the background color */
      background-color: #1d1d1d;
      /* Set the desired background color for the line */
      border-radius: 0 0 12px 12px;
      padding: 10px 0;
    }

    .close {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      border-radius: 100px;
      border: 1px solid var(--Grayscale-300, #c7c7c7);
    }

    .close-text {
      display: flex;
      padding: 0px 24px;
      justify-content: center;
      align-items: center;
      gap: 8px;
      align-self: stretch;

      color: var(--Grayscale-200, #efefef);
      text-align: center;

      /* Desktop/Functional/Button */
      font-family: Inter-Bold;
      font-size: 15px;
      font-style: normal;
      line-height: 50%;
      letter-spacing: -0.3px;
      text-transform: none;

      @media screen and (width < 100rem) {
        font-size: 12px;
      }
    }

    .tab-content {
      display: flex;
      padding: var(--Spacing-M, 40px) var(--Spacing-L, 60px);
      flex-direction: column;
      align-items: center;
      gap: var(--Spacing-M, 40px);
      align-self: stretch;
      background: var(--Grayscale-800, #1d1d1d);

      .shared-traits-modal-div {
        display: flex;
        // padding: var(--spacing-m, 2.5rem);
        flex-direction: column;
        align-items: center;
        gap: var(--spacing-m, 2.5rem);
        align-self: stretch;
        // margin-top: 3rem;
        border-radius: var(--radius-l, 1.5rem);
        background: var(--grayscale-800, theme.$grey-800);

        // .shared-traits-text {
        //   display: flex;
        //   flex-direction: column;
        //   align-items: center;
        //   gap: var(--spacing-s, 1.25rem);
        //   align-self: stretch;
        //   color: var(--grayscale-white, theme.$white);
        //   text-align: center;
        //   font-family: Zuume;
        //   font-size: 2.6875rem;
        //   font-style: normal;
        //   font-weight: 900;
        //   line-height: 90%;
        //   letter-spacing: 1.72px;
        // }
        .shared-traits-div {
          display: flex;
          // flex-direction: column;
          justify-content: space-between;
          align-items: flex-start;
          // gap: var(--spacing-s, 2.5rem);
          flex: 1 0 0;

          .shared-traits-title-div {
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            gap: 1rem;

            .shared-traits-title-container {
              display: flex;
              padding-bottom: var(--spacing-xs, 0.75rem);
              align-items: center;
              justify-content: center;
              gap: 0.5rem;
              align-self: stretch;

              .shared-divider-line {
                flex: 1 0 0;
                border: 1px solid #f6e003;
              }

              .shared-traits-typo {
                color: var(--grayscale-white, theme.$white);
                font-family: Inter-Black;
                font-size: 0.9375rem;
                font-style: normal;
                line-height: 150%;
                text-transform: uppercase;
                letter-spacing: 0.45px;

                @media screen and (width < 100rem) {
                  font-size: 0.75rem;
                }
              }
            }

            .shared-traits-Box {
              display: flex;
              flex-direction: column;
              gap: 1rem;
              width: 100%;

              .shared-traits-items {
                display: flex;
                padding: 1rem;
                justify-content: center;
                align-items: center;
                gap: 1.5rem;
                min-height: 3.2rem;
                align-self: stretch;
                border-radius: 1rem;
                background: var(--grayscale-black, theme.$black);

                .shared-traits-desc-typo {
                  color: var(--grayscale-white, theme.$white);
                  text-align: center;
                  text-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
                  font-family: Inter-Bold;
                  font-size: 1rem;
                  font-style: normal;
                  line-height: 130%;

                  @media screen and (width < 100rem) {
                    font-size: 0.8125rem;
                  }
                }
              }
            }
          }
        }
      }
    }

    .examples-columns {
      display: flex;
      align-items: flex-start;
      gap: var(--Spacing-XL, 80px);
      align-self: stretch;

      .data-table-cell-head {
        border-bottom: 0.2px solid rgba($color: theme.$white, $alpha: 0.2);
      }

      .data-table-cell-body {
        display: flex;
        flex-direction: column;
        align-self: stretch;
        border-bottom: 0.2px solid rgba($color: theme.$white, $alpha: 0.2);
        min-height: 3.25rem;

        &.last-row {
          border-bottom: 0;
        }
      }
    }

    .examples-col {
      display: flex;
      flex-direction: column;
      align-items: center;
      gap: var(--Spacing-XS, 12px);
      flex: 1 0 0;
    }

    .title {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: flex-start;
      gap: -2px;
      align-self: stretch;
    }

    .title1 {
      align-self: stretch;
      color: var(--Grayscale-White, #fff);
      text-align: center;

      /* Desktop/Body/Overline */
      font-family: Inter-Black;
      font-size: 15px;
      font-style: normal;
      line-height: 150%;
      /* 22.5px */
      letter-spacing: 0.45px;
      text-transform: uppercase;

      @media screen and (width < 100rem) {
        font-size: 12px;
      }
    }

    .title2 {
      align-self: stretch;
      text-align: center;

      /* Desktop/Body/Overline */
      font-family: Inter-Black;
      font-size: 15px;
      font-style: normal;
      line-height: 150%;
      /* 22.5px */
      letter-spacing: 0.45px;
      text-transform: uppercase;

      @media screen and (width < 100rem) {
        font-size: 12px;
      }
    }

    .examples-content {
      display: flex;
      flex-direction: column;
      align-items: center;
      gap: var(--Spacing-S, 20px);
      align-self: stretch;
    }

    .divider {
      width: 100%;
      height: 1px;
      background-color: #3d3d3d;
    }

    .text {
      align-self: stretch;
      color: var(--Grayscale-White, #fff);
      text-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);

      /* Desktop/Body/Small/Small Regular */
      font-family: Inter-Regular;
      font-size: 16px;
      font-style: normal;
      font-weight: 400;
      line-height: 130%;

      /* 20.8px */
      @media screen and (width < 100rem) {
        font-size: 13px;
      }
    }
  }
}

.Fram_PSGMT_1 {
  background: linear-gradient(100deg, #ada1f8 0%, #b983ff 100%);
  background-clip: text;
  -webkit-background-clip: text;
  /* For Safari */
  color: transparent;
}

.Fram_PSGMT_4 {
  background: theme.$exploratory-reserved;
  background-clip: text;
  -webkit-background-clip: text;
  color: transparent;
}

.Fram_PSGMT_7 {
  background: theme.$familiar-outgoing;
  background-clip: text;
  -webkit-background-clip: text;
  color: transparent;
}

.Fram_PSGMT_10 {
  background: theme.$exploratory-outgoing;
  background-clip: text;
  -webkit-background-clip: text;
  color: transparent;
}

.body-no-scroll {
  overflow: hidden;
}

//Section 2 Styles
.people-section2-background {
  display: flex;
  //padding: var(--Spacing-Section, 9.375rem) var(--Spacing-Horizontal-Margin, 6.25rem);
  flex-direction: column;
  // align-items: flex-start;
  gap: 5rem;
  align-self: stretch;

  // background: linear-gradient(180deg, #1c1737 0%, rgba(0, 0, 0, 0) 23.47%);
  &.Fram_PSGMT_1-dark {
    background: theme.$familiar-reserved-dark;
  }

  &.Fram_PSGMT_4-dark {
    background: theme.$exploratory-reserved-dark;
  }

  &.Fram_PSGMT_7-dark {
    background: theme.$familiar-outgoing-dark;
  }

  &.Fram_PSGMT_10-dark {
    background: theme.$exploratory-outgoing-dark;
  }

  .people-section2-main {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin: 6.25rem;
    max-height: calc(100vh - theme.$main-nav-height - theme.$anchor-nav-height);
  }
}

.modular-content {
  display: flex;
  flex-direction: column;
  //gap: 9.37rem;
  gap: 6.25rem;
  margin: 0 6.25rem;

  .propensitytoexplore-content {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    .ugc-propensitytoexplore-box {
      width: 60%;
      display: flex;
      align-items: center;
      justify-content: center;
    }

    .propensitytoexplore-four-cards-container {
      display: flex;
      align-items: flex-start;
      //gap: var(--Spacing-S, 1.25rem);
      align-self: stretch;

      .modular-item {
        display: flex;
        // height: 500px;
        // max-height: 500px;
        justify-content: center;
        align-items: center;
        gap: 10px;
        //flex: 1 0 0;
        border-radius: 16px;
        min-height: 100%;
        align-self: stretch;
      }

      .box-content-bg-color {
        background-color: theme.$grey-800;
      }
    }
  }

  .sociability-content {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    .ugc-sociability-box {
      width: 60%;
      display: flex;
      align-items: center;
      justify-content: center;
    }

    .sociability-four-cards-container {
      display: flex;
      align-items: flex-start;
      //gap: var(--Spacing-S, 1.25rem);
      align-self: stretch;

      .modular-item {
        display: flex;
        // height: 500px;
        // max-height: 500px;
        justify-content: center;
        align-items: center;
        gap: 10px;
        //flex: 1 0 0;
        border-radius: 16px;
        min-height: 100%;
        align-self: stretch;
      }
    }
  }

  .focusonhealth-spending-status-outlook-container {
    display: flex;
    align-items: flex-start;
    align-self: stretch;

    .focusonhealth-content {
      min-height: 45.9375rem;
      align-self: stretch;
      display: flex;
      flex-direction: column;
      // justify-content: space-between;
      flex: 1 0 0;
      align-self: stretch;
    }

    .spending-content {
      min-height: 45.9375rem;
      align-self: stretch;
      display: flex;
      flex-direction: column;
      // justify-content: space-between;
      flex: 1 0 0;
      align-self: stretch;
    }

    .status-content {
      min-height: 45.9375rem;
      align-self: stretch;
      display: flex;
      flex-direction: column;
      // justify-content: space-between;
      flex: 1 0 0;
      align-self: stretch;
    }

    .outlook-content {
      min-height: 45.9375rem;
      align-self: stretch;
      display: flex;
      flex-direction: column;
      // justify-content: space-between;
      flex: 1 0 0;
      align-self: stretch;
    }

    .card-content-box {
      .modular-cards-container {
        display: flex;
        align-items: flex-start;
        align-self: stretch;
        //height: 100%;

        .modular-item {
          display: flex;
          justify-content: center;
          align-items: center;
          gap: 10px;
          flex: 1 0 0;
          border-radius: 16px;
          max-height: 100%;
          align-self: stretch;

          .statementquoteimage-card-container {
            min-height: 31.25rem;

            // flex: 1 0;
            // align-self: stretch;
            .image-card-container {
              //min-height: 15.6rem;
              max-height: 100%;
            }
          }
        }

        .box-content-bg-color {
          background-color: theme.$grey-800;
        }

        .statementcard-container {
          .card-content-container {
            margin: 0 35px;

            .big-statement {
              text-align: center;
              font-family: Inter-Bold;
              font-size: 16px;
              font-style: normal;
              line-height: 130%;

              /* 20.8px */
              @media screen and (width < 100rem) {
                font-size: 13px;
              }

              span,b {
                text-align: center;
                font-family: Zuume-Black;
                font-size: 80px;
                font-style: normal;
                line-height: 80%;
                /* 64px */
                text-transform: uppercase;

                @media screen and (width < 100rem) {
                  font-size: 60px;
                }
              }
            }
          }
        }

        .smallquote-attribution-card-container {
          .smallquote-attribution-card-content-container {
            color: var(--Grayscale-800, #1d1d1d);
            font-family: Inter;
            font-size: 16px;
            font-style: normal;
            font-weight: 600;
            line-height: 130%;
            display: flex;
            flex-direction: column;
            justify-content: space-between;
            padding: 20px;
            margin: 0;
            align-self: stretch;
            /* 20.8px */

            .quote-icon {
              width: 24px;
            }

            .smallquote-card-text {
              text-align: left;
              //font-style: normal;
            }
          }

          @media screen and (width < 100rem) {
            font-size: 13px;
          }
        }
      }
    }
  }
}

//ugc styles

.ugc-openness-box {
  display: flex;
  //padding: 2rem;
  padding: 1.25rem;
  align-items: center;
  justify-content: center;
}

.carousel-card-container {
  display: flex;
  align-items: flex-start;
  //gap: var(--Spacing-S, 1.25rem);
  align-self: stretch;

  .modular-item {
    display: flex;
    height: 500px;
    max-height: 500px;
    justify-content: center;
    align-items: center;
    gap: 10px;
    //flex: 1 0 0;
    border-radius: 16px;
  }
}

.card-height {
  height: 100%;
  border-radius: 0px 1rem 1rem 0px !important;
}

.mostly-card-box {
  display: flex;
  height: 65vh;
  border-radius: 1rem !important;

  .mostly-card-box-cardmedia {
    margin-left: -12px;
    margin-top: -18px;
    width: 110%;
  }

  .mostly-card-box-cardcontent {
    position: absolute;
    width: 38vw;
    background: theme.$grey-800;
    align-items: center;
    margin-left: 2rem;
    margin-top: 40vh;
    border-radius: 1rem !important;

    .mostly-card-box-cardmedia-typo1 {
      font-family: Zuume;
      font-size: 5rem;
      font-style: normal;
      font-weight: 900;
      line-height: 80%;
      text-transform: uppercase;

      @media screen and (width < 100rem) {
        font-size: 3.75rem;
      }
    }

    .mostly-card-box-cardmedia-typo2 {
      font-family: Inter;
      font-size: 1.25rem;
      font-style: normal;
      font-weight: 700;
      line-height: 130%;

      @media screen and (width < 100rem) {
        font-size: 1rem;
      }
    }
  }

  .people-section2-slider-box {
    display: flex;
    width: 45rem;
    min-width: 27rem;
    max-width: 45rem;
    justify-content: center;
    align-items: center;
    border-radius: 7.2px;
  }
}

.section2-box2 {
  width: 36rem;
  height: 32rem;
  flex-shrink: 0;
  position: relative;
}

//UGC_Card_Section

.people-section2-experiences {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: var(--Spacing-S, 1.25rem);
  align-self: stretch;

  .people-section2-experiences-typo {
    align-self: stretch;
    color: var(--Grayscale-White, theme.$white);
    text-align: center;
    font-family: Zuume-Black;
    font-size: 5rem;
    font-style: normal;
    line-height: 80%;
    text-transform: uppercase;

    @media screen and (width < 100rem) {
      font-size: 3.75rem;
    }
  }

  .people-section2-experiences-typo2 {
    color: var(--Grayscale-White, theme.$white);
    font-family: Inter-Regular;
    font-size: 1rem;
    // width: 55%;
    text-align: center;
    font-style: normal;
    font-weight: 400;
    line-height: 130%;

    @media screen and (width < 100rem) {
      font-size: 0.8125rem;
    }
  }
}

.people-section2-experiences-typo3 {
  text-align: center;
  color: var(--Grayscale-White, theme.$white);
  font-family: Inter;
  font-size: 1rem;
  font-style: normal;
  font-weight: 400;
  line-height: 130%;

  @media screen and (width < 100rem) {
    font-size: 0.8125rem;
  }
}

//UGC_Card_text

.ugc-card-text-box {
  background: var(--Grayscale-800, theme.$grey-800);
  height: 100%;
  // border-radius: 1rem;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 1rem;
  flex-direction: column;

  .ugc-card-text-title {
    text-align: center;
    font-family: Inter;
    font-size: 20px;
    font-style: normal;
    font-weight: 700;
    line-height: 130%;

    @media screen and (width < 100rem) {
      font-size: 16px;
    }
  }

  .ugc-card-text-title-hero-large {
    text-align: center;
    font-family: Zuume;
    font-size: 8rem;
    font-style: normal;
    font-weight: 900;
    line-height: 80%;
    text-transform: uppercase;

    @media screen and (width < 100rem) {
      font-size: 6rem;
    }
  }

  .ugc-card-text-title-hero-small {
    text-align: center;
    font-family: Zuume;
    font-size: 5rem;
    font-style: normal;
    font-weight: 900;
    line-height: 80%;
    text-transform: uppercase;

    @media screen and (width < 100rem) {
      font-size: 3.75rem;
    }
  }

  .ugc-card-text-title-content-large {
    width: 50%;
    text-align: center;
    font-family: Inter;
    font-size: 1.25rem;
    font-style: normal;
    font-weight: 700;
    line-height: 130%;

    @media screen and (width < 100rem) {
      font-size: 1rem;
    }
  }

  .ugc-card-text-title-content-small {
    width: 50%;
    text-align: center;
    font-family: Inter;
    font-size: 1rem;
    font-style: normal;
    font-weight: 700;
    line-height: 130%;
    opacity: 0.59;

    @media screen and (width < 100rem) {
      font-size: 0.8125rem;
    }
  }

  .ugc-card-text-title-footer {
    color: var(--Grayscale-White, theme.$white);
    text-align: center;
    font-family: Inter;
    font-size: 1.25rem;
    font-style: normal;
    font-weight: 700;
    line-height: 130%;
    opacity: 0.25;

    @media screen and (width < 100rem) {
      font-size: 1rem;
    }
  }

  .ugc-card-text-title-card-title-large {
    display: flex;
    padding: 0px 4.5625rem;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    flex: 0 1 0;
    align-self: stretch;
    text-align: center;
    gap: 24px;

    font-family: Inter;
    font-size: 1.25rem;
    font-style: normal;
    font-weight: 700;
    line-height: 130%;

    @media screen and (width < 100rem) {
      font-size: 1rem;
    }
  }

  .ugc-card-text-title-card-title-small {
    display: flex;
    padding: 0px 2.1875rem;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    flex: 1 0 0;
    align-self: stretch;
    text-align: center;
    gap: 17px;

    font-family: Inter;
    font-size: 1rem;
    font-style: normal;
    font-weight: 700;
    line-height: 130%;
    opacity: 0.59;

    @media screen and (width < 100rem) {
      font-size: 0.8125rem;
    }
  }
}

//UGC_Card_Image

.ugc-card-img-background {
  display: flex;
  height: 100%;
  // border-radius: 1rem;
  // background-color: theme.$grey-800 !important;
}

.ugc-card-greycolor {
  background-color: theme.$grey-800 !important;
}

//UGC_Card_Text_Image

.ugc-card-text-img {
  display: flex;
  height: 32rem;
  max-height: 32rem;
  align-items: flex-start;
  align-self: stretch;
  border-radius: var(--Radius-M, 1rem);
}

//ugc-slider-div
.ugc-slider-div {
  width: 32rem;

  .propensityToExplore {
    width: 25rem;
    display: flex;
    height: 4px;
    justify-content: center;
    align-items: center;
    flex: 1 0 0;
    border-radius: 4px;
    color: rgba(255, 255, 255, 0.5);
  }

  .selected-slider-thumb {
    width: 1.7rem;
    height: 1.4rem;
    border-radius: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    font-family: Zuume-Bold;
  }

  // .custom-thumb1 {
  //   // background-color: theme.$ab-yellow !important;
  // }
  .custom-mark1 {
    height: 12px !important;
  }
}

//ugc-voice-card

.ugc-voice-card-box {
  display: flex;
  //height: 29vh;
  height: 11rem;
  max-height: 11rem;
  //padding: 1rem 1.3rem 1rem 1.3rem;
  padding: 1rem;
  flex-direction: column;
  justify-content: space-between;
  align-items: flex-start;
  flex: 1 0 0;
  align-self: stretch;
  border-radius: 1rem;
  background-color: theme.$grey-800;

  .ugc-voice-card-card1 {
    display: flex;
    justify-content: space-between;
    align-items: flex-end;
    align-self: stretch;

    .ug-voice-card-typo1 {
      font-size: 3.9rem;
      font-family: Zuume-Black;
      margin-bottom: -40px;
      text-transform: uppercase;
    }

    .ug-voice-card-typo2 {
      display: flex;
      align-items: center;
      margin-top: -1rem;
      font-size: 3.9rem;
      font-family: Zuume-Black;
      text-transform: uppercase;
    }

    .ugc-voice-card-playcircle {
      display: flex;
      align-items: center;
      align-self: center;
      font-size: 5rem;
    }
  }

  .ugc-voice-card-bottom-content-container {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 1rem;
    align-self: stretch;

    .ugc-voice-card-divider {
      width: 100%;
    }

    .ugc-voice-card-people-box {
      display: flex;
      justify-content: flex-end;
      margin-left: auto;
    }
  }
}

//ug-card-image
.ugc-boxcontent-div {
  height: 100%;

  .ugc-boxcontent-div-box {
    height: 68%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;

    .image2Quote {
      transform: rotate(180deg);
      font-size: 4rem;

      @media screen and (width < 100rem) {
        font-size: 48px;
      }
    }

    .image1Quote {
      transform: rotate(180deg);
      font-size: 2.5rem;

      @media screen and (width < 100rem) {
        font-size: 1.875rem;
      }
    }

    .ugc-boxcontent-div-box-typo1 {
      // color: theme.$black;
      margin-bottom: 2rem;
      font-style: normal;
      font-family: Inter;
      line-height: 130%;
      font-style: italic;
      font-weight: 600;

      .ugc-boxcontent-div-box-typo2 {
        color: theme.$black;
        text-transform: uppercase;
        font-family: Zuume;
      }
    }
  }
}

.ugc-box-image-div2 {
  width: 100%;
  height: 50%;
  align-self: stretch;

  img {
    height: 100%;
  }
}

.section2-box2 {
  width: 36rem;
  height: 32rem;
  flex-shrink: 0;
  position: relative;

  .section2-img1 {
    display: flex;
    padding-left: 3rem;
    z-index: 1;
    width: 26rem;
    height: 22rem;
  }

  .section2-img2 {
    display: flex;
    position: absolute;
    bottom: 4rem;
    width: 20rem;
    height: 14rem;
  }
}

//people-details
.people-details-full-box {
  display: flex;
  flex-direction: row;
  justify-content: center;
  height: 1.25rem;
  align-items: center;
  gap: 0.5rem;

  .people-details-typos {
    // color: theme.$black;
    font-size: 20px;
    font-family: Zuume-Bold;
    font-style: normal;
    letter-spacing: 1px;
    text-transform: uppercase;

    @media screen and (width < 100rem) {
      font-size: 16px;
    }
  }

  .attributions-divider {
    width: 1px;
    opacity: 0.2;
    height: 1.25rem;
  }
}

//Section 5 - What they Drink

.section5-container-people-segment {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: var(--Spacing-XXL, 6.25rem);
  align-self: stretch;

  .section5-people-segment {
    //margin: 9.375rem 6.25rem;
    margin: 6.25rem;
    display: flex;
    flex-direction: column;
    align-self: stretch;
    gap: var(--spacing-xxl, 6.25rem);

    .section-main {
      max-height: calc(100vh - theme.$main-nav-height - theme.$anchor-nav-height);
    }

    .section5-image-list {
      display: flex;
      overflow: hidden;
    }

    .interaction-tooltip-container {
      display: flex;
      justify-content: center;
      align-items: center;
      gap: 0.5rem;

      .interaction-tooltip-text {
        color: var(--grayscale-white, theme.$white);
        font-family: Inter-Regular;
        font-style: normal;
        font-weight: 400;
        line-height: 130%;
      }
    }

    .section5-drinking-behaviour-content {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      gap: var(--Spacing-S, 1.25rem);
      align-self: stretch;
      min-height: calc(100vh - theme.$main-nav-height - theme.$anchor-nav-height);

      .section5-drinking-behaviour {
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: center;
        align-self: stretch;
        gap: 12px;
        //margin-bottom: 1rem;

        .section5-drinking-typo {
          color: theme.$white;
          font-family: Inter-Black;
          font-size: 15px;
          font-style: normal;
          line-height: 150%;
          letter-spacing: 0.45px;
          text-transform: uppercase;

          @media screen and (width < 100rem) {
            font-size: 12px;
          }
        }

        .section5-drinking-divider {
          height: 1px;
        }
      }

      .peoplesegment-drinking-behaviour-container {
        display: flex;
        align-items: flex-start;
        gap: var(--Spacing-M, 2.5rem);
        align-self: stretch;

        .left-item {
          display: flex;
          flex-direction: column;
          align-items: flex-start;
          gap: var(--Spacing-S, 1.25rem);
          flex: 1 0 0;

          .ownWords-card-container {
            display: flex;
            flex-direction: column;
            align-items: center;
            gap: var(--Spacing-S, 1.25rem);
            align-self: stretch;

            .image-container {
              max-height: 450px;
            }
          }
        }
      }
    }

    .custom-mobile-stepper {
      max-width: 100%;
      flex-grow: 1;
      display: flex;
      justify-content: center;
      align-items: center;
      background-color: theme.$black;

      .custom-mobile-stepper-button {
        &.MuiButton-root {
          &.Mui-disabled {
            background-color: transparent;

            .MuiSvgIcon-root {
              color: theme.$ab-yellow;
            }
          }
        }
      }

      .MuiMobileStepper-dot {
        background-color: grey;

        &.MuiMobileStepper-dotActive {
          background-color: theme.$ab-yellow;
        }
      }

      &.Fram_PSGMT_2 {
        .MuiMobileStepper-dotActive {
          background-color: theme.$comfort-craver;
        }
      }

      &.Fram_PSGMT_3 {
        .MuiMobileStepper-dotActive {
          background-color: theme.$loyal-traditionalist;
        }
      }

      &.Fram_PSGMT_5 {
        .MuiMobileStepper-dotActive {
          background-color: theme.$balanced-enjoyers;
        }
      }

      &.Fram_PSGMT_6 {
        .MuiMobileStepper-dotActive {
          background-color: theme.$diligent-discoverer;
        }
      }

      &.Fram_PSGMT_8 {
        .MuiMobileStepper-dotActive {
          background-color: theme.$carefree-fun-lover;
        }
      }

      &.Fram_PSGMT_9 {
        .MuiMobileStepper-dotActive {
          background-color: theme.$proud-celebrator;
        }
      }

      &.Fram_PSGMT_11 {
        .MuiMobileStepper-dotActive {
          background-color: theme.$creative-explorer;
        }
      }

      &.Fram_PSGMT_12 {
        .MuiMobileStepper-dotActive {
          background-color: theme.$social-curator;
        }
      }
    }

    .section5-video-container {
      display: flex;
      padding-bottom: 1.75rem;
      flex-direction: column;
      align-items: flex-start;
      gap: var(--Spacing-S, 0.5rem);
      align-self: stretch;
      border-radius: var(--Radius-M, 1rem);
      width: 100%;
      height: 100%;

      .section5-video-box {
        display: flex;
        flex-direction: row;
        align-self: stretch;
        justify-content: center;
        align-items: center;
        gap: var(--Spacing-S, 1.25rem);
        flex: 1 0 0;
        border-radius: var(--Radius-M, 16px);
        background: #1D1D1D;
        .section5-video-box-div {
          // position: relative;
          width: 50%;
          height: 100%;
          border-radius: 8px;

          video {
            width: 100%;
            height: 100%;
            object-fit: contain;
            border-radius: 0.5rem
          }

          .section5-video-box-button {
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);

            .custom-play-icon {
              color: theme.$white;
              font-size: 6.5rem;
            }
          }
        }

        .video-people-details-box {
          display: flex;
          height: 100%;
          width: 50%;
          flex-direction: column;
          justify-content: center;
          align-items: flex-start;
          //margin-top: 4rem;
          gap: var(--Spacing-XS, 12px);
          flex: 1 0 0;

          .name-typo1 {
            font-family: Zuume-Black;
            font-size: 2.6875rem;
            font-style: normal;
            line-height: 90%;
            letter-spacing: 1.72px;
          }

          .age-typo2 {
            color: var(--Grayscale-White, #fff);
            font-family: Zuume;
            font-size: 20px;
            font-style: normal;
            font-weight: 700;
            line-height: 100%;
            letter-spacing: 1px;
            text-transform: uppercase;

            @media screen and (width < 100rem) {
              font-size: 16px;
            }

            i {
              font-style: normal;
              font-size: 1px;
              opacity: 0.52;
            }
          }

          .people-divider {
            width: 1px;
            height: 20px;
            opacity: 0.52;
            background: #fff;
          }

          .name-typo2 {
            p {
              color: var(--Grayscale-White, #fff) !important;
              font-family: Zuume;
              font-size: 20px;
              font-style: normal;
              font-weight: 700;
              line-height: 100%;
              letter-spacing: 1px;
              text-transform: uppercase;

              @media screen and (width < 100rem) {
                font-size: 16px;
              }
            }
          }

          .name-typo3 {
            color: var(--Grayscale-White, theme.$white);
            font-family: Inter;
            display: -webkit-box;
            -webkit-line-clamp: 2;
            -webkit-box-orient: vertical;
            overflow: hidden;
            text-overflow: ellipsis;
            white-space: normal;
            font-size: 1rem;
            font-style: normal;
            font-weight: 400;
            line-height: 130%;

            @media screen and (width < 100rem) {
              font-size: 0.8125rem;
            }
          }
        }
      }
    }
  }
}

.section3-main {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  color: var(--grayscale-white, theme.$white);
  //gap: var(--spacing-m, 4rem);
  //background-color: #000;
  //row-gap: 4rem;

  .background {
    display: flex;
    //margin: var(--spacing-section, 9.375rem) var(--spacing-horizontal-margin, 6.25rem) 9.375rem var(--spacing-horizontal-margin, 6.25rem);
    margin: 6.25rem;
    flex-direction: column;
    align-items: center;
    gap: var(--spacing-xxl, 100px);

    .section-main {
      max-height: calc(100vh - theme.$main-nav-height - theme.$anchor-nav-height);

      // .section-left-item {
      //   display: flex;
      //   flex-direction: column;
      //   gap: var(--spacing-m, 2.5rem);
      //   flex: 1 0 0;
      //   max-height: 100%;
      //   height: 100%;
    
      //   .divider {
      //     display: flex;
      //     flex: 1 0 0;
      //     border: 2px solid #f6e003;
      //     width: 10rem;
      //   }
    
      //   .section-title-container {
      //     display: flex;
      //     flex-direction: column;
      //     align-items: flex-start;
      //     gap: var(--spacing-s, 1.25rem);
      //     margin: 25% 0;
    
      //     .section-heading {
      //       width: 50%;
      //       align-self: stretch;
      //       white-space: nowrap;
      //       font-family: Zuume;
      //       font-size: 128px;
      //       font-style: normal;
      //       font-weight: 900;
      //       line-height: 80%;
      //       text-transform: uppercase;
    
      //       @media screen and (width < 100rem) {
      //         font-size: 96px;
      //       }
      //     }
    
      //     .section-description {
      //       display: -webkit-box;
      //       width: 500px;
      //       -webkit-box-orient: vertical;
      //       -webkit-line-clamp: 5;
    
      //       overflow: hidden;
      //       color: var(--Grayscale-White, #fff);
      //       text-overflow: ellipsis;
    
      //       /* Desktop/Body/Default/Bold */
      //       font-family: Inter;
      //       font-size: 20px;
      //       font-style: normal;
      //       font-weight: 700;
      //       line-height: 130%;
    
      //       /* 26px */
      //       @media screen and (width < 100rem) {
      //         font-size: 16px;
      //       }
      //     }
      //   }
      // }
    
      // .section-right-item {
      //   flex-grow: 1;
    
      //   .visual-container {
      //     display: flex;
      //     position: relative;
      //     max-height: 100%;
      //     height: 100%;
    
      //     .back-image {
      //       float: right;
      //       display: flex;
      //       margin-left: 6.25rem;
      //       margin-bottom: 5.625rem;
      //       width: 43.75rem;
      //     }
    
      //     .front-image {
      //       display: flex;
      //       position: absolute;
      //       z-index: 1;
      //       bottom: 0;
      //       width: 27.5rem;
      //     }
      //   }
    
      //   .visual {
      //     width: 100%;
      //     height: 100%;
      //     border-radius: 16px;
      //   }
      // }
    }

    .main {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
    }

    // .meetthepeople-container {
    //   max-height: theme.$max-view-height;
    // }
  }
}

//section4
.demographic-background {
  display: flex;
  //padding: var(--spacing-section, 9.375rem) var(--spacing-horizontal-margin, 6.25rem) 4.1875rem var(--spacing-horizontal-margin, 6.25rem);
  flex-direction: column;
  // align-items: center;
  gap: var(--spacing-xxl, 6.25rem);

  // background: linear-gradient(180deg, #1c1737 0%, rgba(0, 0, 0, 0) 100%);
  &.Fram_PSGMT_1-dark {
    background: theme.$familiar-reserved-dark;
  }

  &.Fram_PSGMT_4-dark {
    background: theme.$exploratory-reserved-dark;
  }

  &.Fram_PSGMT_7-dark {
    background: theme.$familiar-outgoing-dark;
  }

  &.Fram_PSGMT_10-dark {
    background: theme.$exploratory-outgoing-dark;
  }

  .demographic-main {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin: 6.25rem;
    max-height: calc(100vh - theme.$main-nav-height - theme.$anchor-nav-height);
  }
}

.demographic-background-main {
  display: flex;
  //margin: var(--spacing-section, 0.375rem) var(--spacing-horizontal-margin, 6.25rem) 4.1875rem var(--spacing-horizontal-margin, 6.25rem);
  margin: 0 var(--spacing-horizontal-margin, 6.25rem) 6.25rem var(--spacing-horizontal-margin, 6.25rem);
  flex-direction: column;
  align-items: center;
  gap: 1.25rem;

  // .demographic-items-container {
  //   max-height: theme.$max-view-height;

  //   .demographic-items {
  //     max-height: calc((theme.$max-view-height - 1rem)/2);
  //   }
  // }

  .demographic-info {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 8px;

    .demographic-typo {
      color: var(--Grayscale-White, theme.$white);
      font-family: Inter-Regular;
      font-size: 1rem;
      font-style: normal;
      font-weight: 400;
      line-height: 130%;

      @media screen and (width < 100rem) {
        font-size: 0.8125rem;
      }
    }
  }
}

.section8-downloads {
  padding-top: var(--spacing-section, 9.375rem);
  display: flex;
}

.ps-section6 {
  .section6 {
    display: flex;
    //margin: var(--Spacing-Section, 9.375rem) 6.25rem;
    margin: 6.25rem;
    flex-direction: column;
    align-items: flex-start;
    gap: 6.25rem;
    align-self: stretch;
    //max-height: calc(100vh - theme.$main-nav-height - theme.$anchor-nav-height);
  }
}

.ps-section7 {
  .section7 {
    display: flex;
    //margin: var(--Spacing-Section, 9.375rem) 6.25rem;
    margin: 6.25rem;
    flex-direction: column;
    align-items: flex-start;
    // gap: 6.25rem;
    align-self: stretch;
    min-height: calc(100vh - theme.$main-nav-height - theme.$anchor-nav-height);

    .section7-wrapup {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: flex-start;
      gap: 6.25rem;
      //flex: 1 0 0;
      margin-bottom: 2.5rem;

      .wrapup-content-container {
        //margin-top: 6.25rem;
        //margin-bottom: 5rem;

        .wrapup-left-item {
          padding-right: 3.125rem;
          .section7-type {
            margin-bottom: 0.25rem;
            font-family: Inter-Black;
            font-size: 15px;
            font-style: normal;
            line-height: 150%;
            /* 22.5px */
            letter-spacing: 0.45px;
            text-transform: uppercase;

            @media screen and (width < 100rem) {
              font-size: 12px;
            }

            &.Fram_PSGMT_2 {
              //Comfort Cravers
              color: theme.$comfort-craver;
            }

            &.Fram_PSGMT_3 {
              //Loyal Traditionalists
              color: theme.$loyal-traditionalist;
            }

            &.Fram_PSGMT_5 {
              //Balanced Enjoyers
              color: theme.$balanced-enjoyers;
            }

            &.Fram_PSGMT_6 {
              //Diligent Discoverers
              color: theme.$diligent-discoverer;
            }

            &.Fram_PSGMT_8 {
              //Carefree Fun-Lovers
              color: theme.$carefree-fun-lover;
            }

            &.Fram_PSGMT_9 {
              //Proud Celebrators
              color: theme.$proud-celebrator;
            }

            &.Fram_PSGMT_11 {
              //Creative Explorers
              color: theme.$creative-explorer;
            }

            &.Fram_PSGMT_12 {
              //Social Curators
              color: theme.$social-curator;
            }
          }

          .section7-head {
            margin-bottom: 1.25rem;
            color: var(--Grayscale-White, #fff);
            font-family: Zuume-Black;
            font-size: 128px;
            font-style: normal;
            line-height: 80%;
            /* 102.4px */
            text-transform: uppercase;

            @media screen and (width < 100rem) {
              font-size: 96px;
            }
          }

          .micro-attributes {
            display: flex;
            flex-direction: row;
            align-items: center;
            gap: var(--Spacing-XS, 0.75rem);
            margin-bottom: 2.5rem;

            .micro-sov,
            .micro-sop {
              color: theme.$white;
              font-family: Inter-Bold;
              font-size: 16px;
              font-style: normal;
              line-height: 130%;

              @media screen and (width < 100rem) {
                font-size: 13px;
              }

              u {
                font-weight: bold;
                text-decoration: none;
              }
            }

            .divider {
              width: 1px;
              height: 40px;
              opacity: 0.2;
              background: #fff;
            }
          }

          .wrap-up-section7-description {
            //margin-bottom: 3.75rem;
            margin-bottom: 2.5rem;
            color: var(--Grayscale-White, #fff);
            font-family: Inter-Regular;
            font-size: 20px;
            font-style: normal;
            font-weight: 400;
            line-height: 130%;

            @media screen and (width < 100rem) {
              font-size: 16px;
            }
          }

          .wrap-visual-image {
            display: flex;
            align-items: flex-end;
            // width: 100%;
            padding: 30px;
            height: 248px;
            background-position: center;
            background-repeat: no-repeat;
            background-size: cover;
            border-radius: var(--Radius-M, 16px);

            @media screen and (width < 100rem) {
              height: 180px;
            }

            .wrap-visual-text {
              color: theme.$white;
              font-family: Inter-Bold;
              font-size: 20px;
              font-style: normal;
              line-height: 130%;

              /* 26px */
              @media screen and (width < 100rem) {
                font-size: 16px;
              }

              .Fram_PSGMT_2 {
                //Comfort Cravers
                color: theme.$comfort-craver;
              }

              .Fram_PSGMT_3 {
                //Loyal Traditionalists
                color: theme.$loyal-traditionalist;
              }

              .Fram_PSGMT_5 {
                //Balanced Enjoyers
                color: theme.$balanced-enjoyers;
              }

              .Fram_PSGMT_6 {
                //Diligent Discoverers
                color: theme.$diligent-discoverer;
              }

              .Fram_PSGMT_8 {
                //Carefree Fun-Lovers
                color: theme.$carefree-fun-lover;
              }

              .Fram_PSGMT_9 {
                //Proud Celebrators
                color: theme.$proud-celebrator;
              }

              .Fram_PSGMT_11 {
                //Creative Explorers
                color: theme.$creative-explorer;
              }

              .Fram_PSGMT_12 {
                //Social Curators
                color: theme.$social-curator;
              }
            }
          }

          .micro-keywords-container {
            display: flex;
            flex-direction: column;
            align-items: flex-start;
            gap: 11px;
            flex: 1 0 0;
            align-self: stretch;

            .micro-keyword {
              display: flex;
              padding: 7px 14px;
              justify-content: center;
              align-items: center;
              gap: 26px;
              flex: 1 0 0;
              align-self: stretch;
              border-radius: 10px;
              color: var(--Grayscale-Black, #000);
              text-align: center;
              font-family: Inter-Black;
              font-size: 15px;
              font-style: normal;
              line-height: 150%;
              /* 22.5px */
              letter-spacing: 0.45px;
              text-transform: uppercase;

              @media screen and (width < 100rem) {
                font-size: 12px;
              }

              &.Fram_PSGMT_2 {
                //Comfort Cravers
                background: theme.$comfort-craver;
              }

              &.Fram_PSGMT_3 {
                //Loyal Traditionalists
                background: theme.$loyal-traditionalist;
              }

              &.Fram_PSGMT_5 {
                //Balanced Enjoyers
                background: theme.$balanced-enjoyers;
              }

              &.Fram_PSGMT_6 {
                //Diligent Discoverers
                background: theme.$diligent-discoverer;
              }

              &.Fram_PSGMT_8 {
                //Carefree Fun-Lovers
                background: theme.$carefree-fun-lover;
              }

              &.Fram_PSGMT_9 {
                //Proud Celebrators
                background: theme.$proud-celebrator;
              }

              &.Fram_PSGMT_11 {
                //Creative Explorers
                background: theme.$creative-explorer;
              }

              &.Fram_PSGMT_12 {
                //Social Curators
                background: theme.$social-curator;
              }
            }
          }
        }

        .wrapup-right-item {
          display: flex;
          // width: 650px;
          flex-direction: column;
          justify-content: flex-end;
          align-items: center;
          //gap: var(--Spacing-S, 20px);
          align-self: stretch;
          .wrapup-slider-heading{
            display: flex;
            align-self: flex-start;
            margin-left: 50px;
            margin-bottom: 25px;
            .wrapup-slider-heading-typo{
              color: #FFF;
              font-family: Zuume-Bold;
              font-size: 50px;
              font-style: normal;
              line-height: normal;
            }
          }

          .wrapup-right-item-slider-background {
            display: flex;
            padding: var(--Spacing-S, 1.25rem) var(--Spacing-M, 2.5rem);
            flex-direction: column;
            justify-content: center;
            align-items: center;
            gap: var(--Spacing-S, 0.625rem);
            align-self: stretch;
            border-radius: 13.071px;
            background: linear-gradient(180deg,
                rgba(255, 255, 255, 0.12) 0%,
                rgba(255, 255, 255, 0.1) 100%);
            }
        }
      }
    }

    .wrapup-bottom {
      display: flex;
      flex-direction: row;
      justify-content: center;
      align-items: center;
      gap: 0.5rem;
    }
  }
}

.ps-footer-container {
  width: 100%;

  // background: linear-gradient(0deg, #1c1737 10.53%, rgba(0, 0, 0, 0) 97.51%);
  &.Fram_PSGMT_1-dark {
    background: linear-gradient(0deg, #322b59 0%, #000000 25%);
  }

  &.Fram_PSGMT_4-dark {
    background: linear-gradient(0deg, #542323 0%, #000000 25%);
  }

  &.Fram_PSGMT_7-dark {
    background: linear-gradient(0deg, #2a5653 0%, #000000 25%);
  }

  &.Fram_PSGMT_10-dark {
    background: linear-gradient(0deg, #1d2846 0%, #000000 25%);
  }

  .ps-section8 {
    .section8 {
      display: flex;
      //margin: var(--Spacing-Section, 9.375rem) var(--Spacing-Horizontal-Margin, 6.25rem);
      margin: var(--Spacing-Horizontal-Margin, 6.25rem);
      justify-content: space-between;
      align-items: flex-start;
      align-self: stretch;
    }
  }

  .ps-section9 {
    .section9 {
      display: flex;
      margin: var(--Spacing-XXL, 6.25rem) var(--Spacing-Horizontal-Margin, 6.25rem);
      flex-direction: column;
      justify-content: center;
      align-items: center;
      align-self: stretch;
    }
  }
}

.Fram_PSGMT_2 {

  //Comfort Cravers
  i {
    font-style: normal;
    color: theme.$comfort-craver;
  }

  b {
    font-family: Zuume;
    font-size: 128px;
    font-style: normal;
    font-weight: 900;
    line-height: 80%;
    /* 102.4px */
    text-transform: uppercase;

    @media screen and (width < 100rem) {
      font-size: 96px;
    }
  }

  strong {
    font-family: Zuume;
    font-size: 80px;
    font-style: normal;
    font-weight: 900;
    line-height: 80%;
    /* 64px */
    text-transform: uppercase;

    @media screen and (width < 100rem) {
      font-size: 60px;
    }
  }
}

.Fram_PSGMT_3 {

  //Loyal Traditionalists
  i {
    font-style: normal;
    color: theme.$loyal-traditionalist;
  }

  b {
    font-family: Zuume;
    font-size: 128px;
    font-style: normal;
    font-weight: 900;
    line-height: 80%;
    /* 102.4px */
    text-transform: uppercase;

    @media screen and (width < 100rem) {
      font-size: 96px;
    }
  }

  strong {
    font-family: Zuume;
    font-size: 80px;
    font-style: normal;
    font-weight: 900;
    line-height: 80%;
    /* 64px */
    text-transform: uppercase;

    @media screen and (width < 100rem) {
      font-size: 60px;
    }
  }
}

.Fram_PSGMT_5 {

  //Balanced Enjoyers
  i {
    font-style: normal;
    color: theme.$balanced-enjoyers;
  }

  b {
    font-family: Zuume;
    font-size: 128px;
    font-style: normal;
    font-weight: 900;
    line-height: 80%;
    /* 102.4px */
    text-transform: uppercase;

    @media screen and (width < 100rem) {
      font-size: 96px;
    }
  }

  strong {
    font-family: Zuume;
    font-size: 80px;
    font-style: normal;
    font-weight: 900;
    line-height: 80%;
    /* 64px */
    text-transform: uppercase;

    @media screen and (width < 100rem) {
      font-size: 60px;
    }
  }
}

.Fram_PSGMT_6 {

  //Diligent Discoverers
  i {
    font-style: normal;
    color: theme.$diligent-discoverer;
  }

  b {
    font-family: Zuume;
    font-size: 128px;
    font-style: normal;
    font-weight: 900;
    line-height: 80%;
    /* 102.4px */
    text-transform: uppercase;

    @media screen and (width < 100rem) {
      font-size: 96px;
    }
  }

  strong {
    font-family: Zuume;
    font-size: 80px;
    font-style: normal;
    font-weight: 900;
    line-height: 80%;
    /* 64px */
    text-transform: uppercase;

    @media screen and (width < 100rem) {
      font-size: 60px;
    }
  }
}

.Fram_PSGMT_8 {

  //Carefree Fun-Lovers
  i {
    font-style: normal;
    color: theme.$carefree-fun-lover;
  }

  b {
    font-family: Zuume;
    font-size: 128px;
    font-style: normal;
    font-weight: 900;
    line-height: 80%;
    /* 102.4px */
    text-transform: uppercase;

    @media screen and (width < 100rem) {
      font-size: 96px;
    }
  }

  strong {
    font-family: Zuume;
    font-size: 80px;
    font-style: normal;
    font-weight: 900;
    line-height: 80%;
    /* 64px */
    text-transform: uppercase;

    @media screen and (width < 100rem) {
      font-size: 60px;
    }
  }
}

.Fram_PSGMT_9 {

  //Proud Celebrators
  i {
    font-style: normal;
    color: theme.$proud-celebrator;
  }

  b {
    font-family: Zuume;
    font-size: 128px;
    font-style: normal;
    font-weight: 900;
    line-height: 80%;
    /* 102.4px */
    text-transform: uppercase;

    @media screen and (width < 100rem) {
      font-size: 96px;
    }
  }

  strong {
    font-family: Zuume;
    font-size: 80px;
    font-style: normal;
    font-weight: 900;
    line-height: 80%;
    /* 64px */
    text-transform: uppercase;

    @media screen and (width < 100rem) {
      font-size: 60px;
    }
  }
}

.Fram_PSGMT_11 {

  //Creative Explorers
  i {
    font-style: normal;
    color: theme.$creative-explorer;
  }

  b {
    font-family: Zuume;
    font-size: 128px;
    font-style: normal;
    font-weight: 900;
    line-height: 80%;
    /* 102.4px */
    text-transform: uppercase;

    @media screen and (width < 100rem) {
      font-size: 96px;
    }
  }

  strong {
    font-family: Zuume;
    font-size: 80px;
    font-style: normal;
    font-weight: 900;
    line-height: 80%;
    /* 64px */
    text-transform: uppercase;

    @media screen and (width < 100rem) {
      font-size: 60px;
    }
  }
}

.Fram_PSGMT_12 {

  //Social Curators
  i {
    font-style: normal;
    color: theme.$social-curator;
  }

  b {
    font-family: Zuume;
    font-size: 128px;
    font-style: normal;
    font-weight: 900;
    line-height: 80%;
    /* 102.4px */
    text-transform: uppercase;

    @media screen and (width < 100rem) {
      font-size: 96px;
    }
  }

  strong {
    font-family: Zuume;
    font-size: 80px;
    font-style: normal;
    font-weight: 900;
    line-height: 80%;
    /* 64px */
    text-transform: uppercase;

    @media screen and (width < 100rem) {
      font-size: 60px;
    }
  }
}