.pricingTable {
  width: 100%;
  height: 100%;
  margin: 80px auto;
  display: flex;
  justify-content: center;
  align-items: center;
}

.pricingTableImage {
  max-width: 1224px;
  height: auto;
}

@media (max-width: 1230px) {
  .pricingTableImage {
    max-width: 85%;
  }
}
