.section {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  width: 1224px;
  margin: 0 auto;
}

.sectionTitle {
  align-self: flex-start;
  display: flex;
  flex-direction: column;
  font-family: Barlow, sans-serif;
  font-weight: 700;
  text-transform: uppercase;
}

.subtitle {
  color: #f6e003;
  font-size: 24px;
}

.title {
  color: #fff;
  font-size: 48px;
  line-height: 1;
  margin-top: 10px;
}

.content {
  display: flex;
  margin-top: 30px;
  width: 100%;
  align-items: stretch;
  gap: 40px;
  font-family: Work Sans, sans-serif;
}

@media (max-width: 991px) {
  .title {
    font-size: 40px;
  }
  .content {
    flex-direction: column;
  }
  .introText,
  .table {
    width: 100%;
  }
}
