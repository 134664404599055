.header {
  display: flex;
  flex-direction: column;
  font-family: Barlow, sans-serif;
  text-transform: uppercase;
  justify-content: center;
  padding: 2px 0;
  margin: 0 auto;
  width: 1224px;
}

@media (max-width: 1275px) {
  .header {
    width: 100%;
    max-width: 1000px;
  }
}

@media (max-width: 991px) {
  .header {
    width: 100%;
    max-width: 600px;
  }
}

.growthContainer {
  display: flex;
  margin-top: 4px;
  min-height: 141px;
  width: 100%;
  flex-direction: column;
  justify-content: space-between;
}

.center {
  align-items: center;
}

@media (max-width: 991px) {
  .growthContainer {
    max-width: 100%;
  }
}

.accentLine {
  min-height: 1px;
  max-width: 100%;
  width: 176px;
  border: 1px solid;
}

.textContainer {
  display: flex;
  flex-direction: column;
  justify-content: start;
}

@media (max-width: 991px) {
  .textContainer {
    max-width: 100%;
  }
}

.subtitle {
  font-size: 28px;
  font-weight: 700;
  line-height: 1;
}

.title {
  color: #fff;
  font-size: 75px;
  font-weight: 800;
  line-height: 1;
  margin-top: -15px;
  margin-bottom: 0px;
}

.large {
  font-size: 100px;
}

@media (max-width: 991px) {
  .title {
    max-width: 100%;
    font-size: 40px;
  }
}
