.messageCard {
  position: relative;
  display: flex;
  flex-direction: column;
  color: #fff;
  text-align: center;
  text-transform: uppercase;
  justify-content: center;
  padding: 24px;
  font: 600 36px/1 Work Sans, -apple-system, Roboto, Helvetica, sans-serif;
  border-radius: 16px;
  overflow: hidden;
}

@media (max-width: 991px) {
  .messageCard {
    padding: 0 20px;
  }
}

.cardBackground {
  border-radius: 16px;
  background: #434343;
  box-shadow: 0px 0px 4px 0px rgba(0, 0, 0, 0.16);
  position: absolute;
  z-index: 0;
  display: flex;
  min-height: 170px;
  width: 100%;
  justify-content: start;
  inset: 0;
}

@media (max-width: 991px) {
  .cardBackground {
    max-width: 100%;
  }
}

.messageContent {
  z-index: 1;
  position: relative;
}

@media (max-width: 991px) {
  .messageContent {
    max-width: 100%;
  }
}