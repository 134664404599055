.outerCircle {
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 166px;
  height: 166px;
}

.innerCircle {
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 152px;
  height: 152px;
  border: 1px solid #fff;
}

.content {
  display: flex;
  min-height: 78px;
  flex-direction: column;
  justify-content: space-between;
}

.percentage {
  font: 700 48px/1 Barlow, sans-serif;
  color: #fff;
}

.label {
  font: 500 18px/1 Work Sans, -apple-system, Roboto, Helvetica, sans-serif;
  color: #fff;
}

@media (max-width: 991px) {
  .percentage {
    font-size: 40px;
  }
  
  .innerCircle {
    padding: 0 20px;
  }
}
