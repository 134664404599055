@use "theme.scss";

.container {
  .main-content {
    .content {
      //margin: 0 6.25rem;
      .section1-hero {
        display: flex;
        align-items: flex-start;
        flex: 1 0 0;
        align-self: stretch;
        // max-width: 87.5rem;

        .hero-title-container {
          display: flex;
          flex-direction: column;
          align-items: flex-start;
          gap: var(--spacing-m, 40px);
          height: 100%;
          width: 100%;

          .title-intro {
            text-transform: uppercase;
            color: theme.$ab-yellow;
            font-family: Inter-Black;
            font-style: normal;
            line-height: 150%; /* 22.5px */
            letter-spacing: 0.45px;
          }
          .title-text {
            color: var(--Grayscale-White, theme.$white);
            font-family: Zuume-Black;
            font-size: 9rem;
            font-style: normal;
            line-height: 80%;
            text-transform: uppercase;
            width: 67%;
            @media screen and (width < 100rem) {
              font-size: 108px;
              width: 55%;
            }
          }

          .explore-demandlandscape-btn {
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            padding: 0.625rem 1.5rem;
            text-align: center;
            color: var(--Grayscale-Black, theme.$black);
            text-align: center;
            font-family: Inter-Bold;
            font-size: 20px;
            font-style: normal;
            line-height: 130%;
            text-transform: none;
            letter-spacing: -0.4px;
            @media screen and (width < 100rem) {
              font-size: 16px;
            }
          }
        }

        .offset-video {
          align-self: flex-end;
          width: 21rem;
          height: 15rem;
          margin-right: 6rem;

          img {
            width: 100%;
            height: 100%;
            border-radius: var(--radius-m, 1rem);
          }
        }

        .subhead {
          display: flex;
          justify-content: space-between;
          align-items: flex-start;
          // position: relative;
          width: 40rem;
          height: 20rem;
          // right: 0px;
          align-self: flex-end;
          margin-bottom: 5rem;
          // z-index: 1;

          .subhead-content {
            display: flex;
            // position: relative;
            width: 100%;
            height: 100%;
            align-items: center;

            .homepage-subhead-image {
              background-color: transparent;
              width: 14rem;
              height: 10rem;
              z-index: 1;
              margin-left: 1rem;
              // position: absolute;
              // left: 0;

              img {
                width: 100%;
                height: 100%;
                border-radius: var(--radius-m, 1rem);
              }
            }
            .homepage-subhead-video {
              width: 28rem;
              height: 20rem;
              flex-shrink: 0;
              background-color: transparent;
              // position: absolute;
              // right: 0;

              video {
                object-fit: cover;
                height: 100%;
                width: 100%;
                border-radius: var(--radius-m, 1rem);
              }
            }
          }
        }
      }
      .item {
        flex: 1;
      }
    }
    .home-section2-background {
      background: linear-gradient(180deg, #27240a 0%, rgba(0, 0, 0, 0) 100%);
      .section2-hero {
        display: flex;
        // max-width: 87.5rem;
        //margin: var(--spacing-section, 9.375rem) var(--spacing-xxl, 6.25rem) var(--spacing-l, 3.75rem) var(--spacing-xxl, 6.25rem);
        margin: 6.25rem;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        gap: var(--spacing-l, 3.75rem);
        align-self: stretch;

        .section2-paragraph {
          display: flex;
          justify-content: center;
          align-items: center;
          gap: 0.5rem;
          align-self: center;
          max-width: 65%;
          flex: 1 0 0;

          color: var(--Grayscale-White, #fff);
          text-align: center;
          font-family: Zuume-Black;
          font-style: normal;
          line-height: 80%; /* 64px */
          text-transform: uppercase;

          @media screen and (width < 100rem) {
            font-size: 3.75rem;
          }

          strong {
            color: theme.$ab-yellow;
            font-style: normal;
            display: contents;
          }
          span{
            color: theme.$ab-yellow;
            font-style: normal;
            display: contents;
          }
        }

        .section2-heading-with-icon-container {
          display: flex;
          flex-direction: column;
          justify-content: center;
          align-items: center;
          gap: 0.5rem;
          align-self: center;
          width: 80%;
          flex: 1 0 0;

          .section2-visual {
            display: flex;
            padding: 0px var(--spacing-none, 0px);
            justify-content: center;
            align-items: flex-start;
            // gap: 1rem;
            align-self: stretch;

            .visual-item {
              display: flex;
              flex-direction: column;
              align-items: flex-start;

              .visual-item-container {
                display: flex;
                flex-direction: column;
                // padding-bottom: var(--Spacing-M, 40px);
                align-items: center;
                gap: 8px;
                align-self: stretch;
                .divider-box {
                  display: flex;
                  padding: 10px 24px;
                  justify-content: center;
                  align-items: center;
                  gap: 4px;
                  align-self: stretch;
                  // width: 100%;
                  border: 1px solid #000;
                  background: #3d3d3d;
                  border-bottom: 1px solid #c7c7c7;
                  margin-bottom: var(--Spacing-M, 30px);
                  .divider {
                    &::after,
                    &::before {
                      border-color: theme.$ab-yellow !important;
                    }
                  }
                  .divider-text {
                    color: var(--Grayscale-White, theme.$white);
                    font-family: Inter-Bold;
                    font-size: 15px;
                    font-style: normal;
                    line-height: 150%;
                    letter-spacing: 0.45px;
                    text-transform: uppercase;
                    @media screen and (width < 100rem) {
                      font-size: 12px;
                    }
                  }
                }
              }
            }
          }

          .visual-item-content-container {
            display: flex;
            flex-direction: row;
            // justify-content: center;
            align-items: flex-start;
            // gap: var(--spacing-m, 1.25rem);

            .visual-item-inner {
              display: flex;
              flex-direction: column;
              align-items: center;
              gap: var(--spacing-s, 1.25rem);
              align-self: stretch;

              .icon {
                height: 3rem;
                width: 3rem;
              }
              .text {
                color: theme.$white;
                align-self: stretch;
                text-align: center;
                font-family: Inter-Bold;
                font-size: 20px;
                font-style: normal;
                line-height: 130%;
                // width: min-content;
                margin: 0 auto;
                @media screen and (width < 100rem) {
                  font-size: 16px;
                }
                &.factor1 {
                  width: min-content;
                }
              }
            }

            #last-icon {
              display: flex;
              //align-items: flex-end;
              align-items: center;
            }
            #last-icon-text {
              //text-align: end;
              text-align: center;
            }
          }
        }
      }
    }

    .home-section3 {
      display: flex;
      // max-width: 87.5rem;
      margin: var(--spacing-m, 2.5rem) 6.25rem;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      gap: var(--spacing-s, 1.25rem);
      align-self: stretch;
      border-radius: var(--radius-l, 1.5rem);
      background: var(--grayscale-800, #1d1d1d);

      .inner-content {
        display: flex;
        padding: var(--spacing-l, 60px) 0px;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        gap: 1.25rem;
        align-self: stretch;

        .paragraph {
          color: var(--grayscale-white, theme.$white);
          align-self: center;
          justify-content: center;
          text-align: center;
          font-family: Inter-Bold;
          font-size: 28px;
          font-style: normal;
          line-height: 130%;
          max-width: 39rem;
          @media screen and (width < 100rem) {
            font-size: 1.3125rem;
          }
        }

        .visual-container {
          display: flex;
          padding: 0px var(--spacing-none, 0px);
          justify-content: center;
          align-items: flex-start;
          gap: var(--spacing-s, 1.25rem);

          .visual-items {
            border-radius: var(--radius-m, 1rem);
            .visual-item-content-container {
              display: flex;
              // padding: var(--spacing-s, 1.25rem);
              flex-direction: column;
              align-items: center;
              gap: var(--spacing-s, 1rem);

              .divider-box {
                width: 60%;
                .divider {
                  &::after,
                  &::before {
                    border-color: theme.$ab-yellow !important;
                  }
                }
                .divider-text {
                  color: var(--Grayscale-White, theme.$white);
                  font-family: Inter-Black;
                  font-size: 15px;
                  font-style: normal;
                  line-height: 150%;
                  letter-spacing: 0.45px;
                  text-transform: uppercase;
                  @media screen and (width < 100rem) {
                    font-size: 12px;
                  }
                }
              }

              .info-image {
                width: 15rem;
                height: 15rem;
                background-position: center;
                background-repeat: no-repeat;
                &.measure {
                  background-image: url("../assets/images/Home/Measure.svg");
                }
                &.analyze {
                  background-image: url("../assets/images/Home/Analyze.svg");
                }
                &.articulate {
                  background-image: url("../assets/images/Home/Articulate.svg");
                }
              }

              .info-text {
                color: theme.$ab-yellow;
                text-align: center;
                font-family: Zuume-Black;
                font-size: 43px;
                font-style: normal;
                line-height: 90%; /* 38.7px */
                letter-spacing: 1.72px;
              }

              .info-subtext {
                margin-top: -1rem;
                color: theme.$white;
                text-align: center;
                font-family: Inter-Regular;
                font-size: 16px;
                font-style: normal;
                font-weight: 400;
                line-height: 130%; /* 20.8px */
                @media screen and (width < 100rem) {
                  font-size: 13px;
                }
              }
            }
          }
        }
      }
    }

    .home-section4 {
      display: flex;
      // max-width: 87.5rem;
      margin: 6.25rem 0;
      flex-direction: column;
      align-items: center;
      //gap: var(--spacing-section, 9.375rem);
      gap: 6.25rem;
      align-self: stretch;

      .segment-paragraph {
        color: var(--grayscale-white, theme.$white);
        text-align: center;
        /* Desktop/Headers/H5 */
        font-family: Inter-Bold;
        font-size: 1.75rem;
        font-style: normal;
        line-height: 130%; /* 36.4px */
        @media screen and (width < 100rem) {
          font-size: 1.3125rem;
        }
      }

      .section4-segment-one {
        width: 100%;
        display: flex;
        flex-direction: column;
        align-items: center;
        gap: var(--spacing-l, 3.75rem);
        align-self: stretch;

        .segment-heading {
          color: var(--Global-Colors-AB-Light-Gold, #f6e003);
          font-family: Zuume-Black;
          font-size: 80px;
          font-style: normal;
          line-height: 150%; /* 120px */
          letter-spacing: 2.4px;
          text-transform: uppercase;
        }

        .segment-paragraph {
          max-width: 54.75rem;

          strong {
            color: theme.$ab-yellow;
            font-style: normal;
            display: contents;
          }
          span{
            color: theme.$ab-yellow;
            font-style: normal;
            display: contents;
          }
        }

        .segment-visual {
          display: flex;
          flex-direction: column;
          justify-content: center;
          align-items: center;
          align-self: stretch;

          .segment-visual-container {
            justify-content: center;
            align-items: center;
            display: flex;
            align-self: stretch;
            gap: var(--spacing-m, 2.5rem);

            .grid-item-content-box {
              display: flex;
              padding: 2px 2px 4px 2px;
              flex-direction: column;
              align-items: center;
              gap: 0.5rem;
              border-radius: var(--radius-l, 1.5rem);
              /*border: 1px solid theme.$white;*/
              background: linear-gradient(0deg, rgba(0, 0, 0, 0.2) 0%, rgba(0, 0, 0, 0.2) 100%),
                linear-gradient(180deg, rgba(255, 255, 255, 0.1) 0%, rgba(255, 255, 255, 0) 100%);
              min-width: 13rem;

              .section4-segment-one-visual-buttongroup-heading {
                display: flex;
                padding: 8px 0px;
                justify-content: center;
                align-items: center;
                gap: 8px;
                align-self: stretch;
                flex: 1 0 0;
                color: var(--grayscale-300, theme.$grey-300);
                text-align: center;
                font-family: Inter-Bold;
                font-size: 15px;
                font-style: normal;
                line-height: 130%;
                letter-spacing: -0.3px;
                @media screen and (width < 100rem) {
                  font-size: 12px;
                }
                // &.color-change {
                //   color: theme.$ab-yellow;
                // }
                i {
                  font-style: normal;
                  color: theme.$ab-yellow;
                }
              }

              .items {
                display: flex;
                min-height: 34px;
                padding: 6px 6px 6px 16px;
                justify-content: center;
                align-items: center;
                align-self: stretch;
                gap: 10px;
                flex: 1 0 0;
                border-radius: 1.375rem;
                background: var(--grayscale-700, theme.$grey-700);

                p {
                  //Text style
                  color: var(--grayscale-white, theme.$white);
                  text-align: center;
                  font-family: Inter-Bold;
                  font-size: 15px;
                  font-style: normal;
                  line-height: 130%; /* 19.5px */
                  letter-spacing: -0.3px;
                  text-transform: capitalize;
                  @media screen and (width < 100rem) {
                    font-size: 12px;
                  }
                }
              }
            }

            .grid-item-divider {
              color: var(--grayscale-white, theme.$white);
              text-align: center;
              font-family: Inter-Black;
              font-size: 3rem;
              font-style: normal;
              line-height: 80%; /* 38.4px */
              text-transform: uppercase;
            }
          }
        }
      }

      .section4-segment-two {
        width: 100%;
        display: flex;
        flex-direction: column;
        align-items: center;
        gap: var(--spacing-l, 3.75rem);
        align-self: stretch;

        .segment-heading {
          color: var(--Global-Colors-AB-Light-Gold, #f6e003);
          font-family: Zuume-Black;
          font-size: 80px;
          font-style: normal;
          line-height: 150%; /* 120px */
          letter-spacing: 2.4px;
          text-transform: uppercase;
        }

        .segment-paragraph {
          max-width: 43.75rem;

          strong {
            color: theme.$ab-yellow;
            font-style: normal;
            display: contents;
          }
          span{
            color: theme.$ab-yellow;
            font-style: normal;
            display: contents;
          }
        }

        // .segment-visual {
        //   display: flex;
        //   margin: 0 12.5rem;
        //   padding: 1.25rem;
        //   gap: 0.625rem;
        //   border-radius: 1.25rem;
        //   border: 0.821px solid rgba(255, 255, 255, 0.1);
        //   background: linear-gradient(
        //       0deg,
        //       rgba(255, 255, 255, 0.1) 0%,
        //       rgba(255, 255, 255, 0.1) 100%
        //     ),
        //     rgba(0, 0, 0, 0.8);

        //   .segment-visual-container {
        //     display: flex;
        //     flex-direction: column;
        //     align-items: flex-start;
        //     gap: 1.2315rem;
        //     padding: 0;

        //     .top-item {
        //       display: flex;
        //       justify-content: center;
        //       padding: 0;
        //       align-items: center;
        //       gap: 1.25rem;
        //       align-self: stretch;

        //       .top-item-container {
        //         display: flex;
        //         flex-direction: row;
        //         align-items: center;
        //         justify-content: center;

        //         .top-item-left {
        //           display: flex;
        //           flex-grow: 1;
        //         }

        //         .top-item-right {
        //           display: flex;
        //           flex-direction: column;
        //           flex-grow: 1;
        //           height: 6.25rem;
        //           justify-content: center;
        //           align-items: center;
        //           align-self: stretch;
        //           gap: 0.625rem;
        //           border-radius: 0.8125rem;
        //           background: #282828;
        //           padding: 0 1.5rem;
        //         }
        //       }
        //     }

        //     .bottom-item {
        //       display: flex;
        //       justify-content: center;
        //       align-items: center;
        //       gap: 1.25rem;

        //       .bottom-item-container {
        //         display: flex;
        //         flex-direction: row;
        //         align-items: center;
        //         justify-content: center;

        //         .bottom-item-left {
        //           display: flex;
        //           flex-direction: column;
        //           min-height: 23.75rem;
        //           justify-content: center;
        //           align-items: center;
        //           align-self: stretch;
        //           gap: 0.625rem;
        //           border-radius: 0.8125rem;
        //           background: #282828;
        //           padding: 0 1.59075rem;
        //         }

        //         .bottom-item-right {
        //           display: flex;
        //           flex-direction: column;
        //           min-height: 23.75rem;
        //           justify-content: center;
        //           align-items: center;
        //           align-self: stretch;
        //           gap: 0.625rem;
        //           border-radius: 0.8125rem;
        //           background: #282828;
        //           padding: 0 1.5rem;
        //         }
        //       }
        //     }
        //   }

        //   .grid-item-text {
        //     text-align: center;
        //     font-family: Inter;
        //     font-style: normal;
        //     font-weight: 700;
        //     line-height: 130%; /* 26px */
        //     display: flex;

        //     &.title {
        //       color: var(
        //         --global-colors-ab-light-gold,
        //         var(--Actions, theme.$ab-yellow)
        //       );
        //       font-size: 20px;
        //       @media screen and (width < 100rem) {
        //         font-size: 16px;
        //       }
        //     }
        //     &.subtitle {
        //       max-width: 29rem;
        //       color: theme.$grey-300;
        //       font-size: 16px;
        //       @media screen and (width < 100rem) {
        //         font-size: 13px;
        //      }
        //     }
        //   }
        // }
        .segment-visual {
          display: flex;
          // padding: 1.25rem;
          flex-direction: column;
          justify-content: center;
          align-items: center;
          gap: 0.625rem;
          border-radius: 1.25rem;

          .image-container {
            min-width: 62rem;
            min-height: 32.875rem;
            background-position: center;
            background-size: contain;
            background-repeat: no-repeat;
          }
        }
      }

      .section4-segment-three {
        width: 100%;
        display: flex;
        flex-direction: column;
        align-items: center;
        gap: var(--spacing-l, 3.75rem);
        align-self: stretch;

        .segment-paragraph {
          // max-width: 43.75rem;
          max-width: 62.5rem;
          text-transform: uppercase;
          color: var(--Grayscale-White, #fff);
          text-align: center;
          font-family: Zuume-Black;
          font-size: 80px;
          font-style: normal;
          line-height: 80%; /* 64px */

          strong {
            color: theme.$ab-yellow;
            font-style: normal;
            display: contents;
          }
          span{
            color: theme.$ab-yellow;
            font-style: normal;
            display: contents;
          }
        }

        .segment-visual {
          display: flex;
          // padding: 1.25rem;
          flex-direction: column;
          justify-content: center;
          align-items: center;
          gap: 0.625rem;
          border-radius: 1.25rem;
          //border: 0.8px solid rgba(255, 255, 255, 0.1);
          &:hover {
            .image-container {
              opacity: 0.8;
              mix-blend-mode: luminosity;
            }
            .explore-demandlandscape-button {
              display: block !important;
            }
          }

          .image-container {
            min-width: 63.0625rem;
            min-height: 32.875rem;
            background-position: center;
            background-size: contain;
            background-repeat: no-repeat;
          }
          .explore-demandlandscape-button {
            display: none;
            position: absolute;
            z-index: 1;
            text-transform: none;
            color: var(--M3-black, #000);
            text-align: center;
            font-family: Inter-Bold;
            font-size: 15px;
            font-style: normal;
            line-height: 130%;
            letter-spacing: -0.3px;
            @media screen and (width < 100rem) {
              font-size: 12px;
            }
          }
        }

        .segment-visual-buttons {
          display: flex;
          justify-content: center;
          align-items: flex-start;
          gap: 1.5rem;
          .outlined-button {
            display: flex;
            padding: 10px 24px;
            justify-content: center;
            align-items: center;
            gap: 8px;
            align-self: stretch;
            font-family: Inter-Bold;
            font-size: 20px;
            font-style: normal;
            line-height: 130%; /* 26px */
            letter-spacing: -0.4px;
          }
        }
      }
    }
    .home-section5 {
      margin: 6.25rem;
      display: flex;
    }
    .runner {
      width: 100%;
    }

    .outlined-button {
      color: var(--grayscale-black, theme.$black);
      height: 2.5rem;
      gap: 0.5rem;
      border-radius: 6.25rem;
      text-transform: capitalize;

      font-family: Inter-Bold;
      font-size: 16px;
      font-style: normal;
      line-height: 130%; /* 26px */
      letter-spacing: -0.4px;
      @media screen and (width < 100rem) {
        font-size: 13px;
      }
    }

    .divider {
      color: theme.$ab-yellow;
      .divider-text {
        color: theme.$white;
        text-transform: uppercase;
      }
    }
  }
}
