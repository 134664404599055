@use "../theme";

.video-card-container {
    display: flex;
    height: 30rem;
    width: 100%;
    align-items: flex-end;
    gap: 10px;
    flex: 1 0 0;
    border-radius: 1rem;
    // background-position: center;
    // background-size: cover;
    // background-repeat: no-repeat;
}
