.card {
  display: flex;
  flex-direction: column;
  min-height: 520px;
  width: 100%;
  text-align: center;
}

.cardTop {
  border-radius: 16px 16px 0 0;
  display: flex;
  padding: 10px 25px;
  color: #fff;
  text-transform: uppercase;
  font: 700 28px/32px Barlow, sans-serif;
  align-items: center;
  justify-content: center;
}

.imgCopy {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  min-height: 154px;
}

.img {
  height: 85px;
  object-fit: contain;
}

.cardBtm {
  border-radius: 0 0 16px 16px;
  background-color: rgba(57, 57, 57, 1);
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 20px;
  padding: 20px;
  flex: 1;
}

.stats {
  display: flex;
  gap: 15px;
}

.stat {
  border-radius: 4px;
  display: flex;
  height: 75px;
  width: 65px;
  color: #fff;
  justify-content: center;
  padding: 10px 4px;
}

.content {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.percentage {
  font: 700 28px/1 Barlow, sans-serif;
  text-transform: uppercase;
}

.label {
  margin-top: 6px;
  font: 500 18px Work Sans, sans-serif;
}

.copy {
  color: #fff;
  font: 400 16px/24px Inter, sans-serif;
  padding: 0px;
}

.prefix, .suffix {
  font-weight: 500;
}

.highlight {
  font-weight: 700;
}

@media (max-width: 991px) {
  .cardTop {
    padding: 20px;
  }
}

.cardButton {
  padding: 0;
  border: none;
  background: none;
  cursor: pointer;
  text-align: left;
  width: 100%;
  transition: transform 0.2s ease;
}

.cardButton:hover {
  transform: scale(1.02);
}