.container {
  width: 1224px;
  margin: 0 auto;
}

.header {
  font-family: Barlow, sans-serif;
  font-weight: 700;
  text-transform: uppercase;
}

.subtitle {
  color: #f6e003;
  font-size: 24px;
}

.title {
  color: #fff;
  font-size: 48px;
  line-height: 1;
  margin-top: 10px;
  width: 1200px;
  margin-bottom: 30px;
}

.content {
  display: flex;
  margin-top: 20px;
  gap: 20px;
  justify-content: start;
}

@media (max-width: 991px) {
  .title {
    font-size: 40px;
  }
  
  .content {
    max-width: 100%;
  }
  
  .highlight {
    max-width: 100%;
    padding: 0 20px;
  }
  
  .highlightText {
    max-width: 100%;
  }
}
