@use "theme";

.scope-container-outer {

  .scope-container-inner {

    .scope-data-container {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      align-self: stretch;
      width: 105px;
      color: var(--grayscale-black, theme.$black);
      text-align: center;

      &.scopeBadgeDemandspace{
        width: 77px;
      }

      .value {
        color: var(--Grayscale-Black, #000);
        text-align: center;
        font-size: 80px;
        line-height: 80%;
        font-family: Zuume-Black;
        font-style: normal;
        text-transform: uppercase;
        @media screen and (width < 100rem) {
          font-size: 60px;
        }
        &.scopeBadgeDemandspace{
          font-size: 43px;
          @media screen and (width < 100rem) {
            font-size: 32.25px;
          }
        }
      }
      .label {
        color: var(--Grayscale-Black, #000);
        text-align: center;
        font-size: 16px;
        line-height: 130%;
        font-family: Inter-Bold;
        font-style: normal;
        @media screen and (width < 100rem) {
          font-size: 13px;
        }
        &.scopeBadgeDemandspace{
          font-size: 15px;
          @media screen and (width < 100rem) {
            font-size: 12px;
         }
        }
      }
    }
  }
}