.container {
  width: 470px;
}

.card {
  flex: 1;
}

.header {
  border-radius: 16px 16px 0px 0px;
  background-color: #1D1D1D;
  color: #fff;
  text-align: center;
  text-transform: uppercase;
  padding: 20px 0;
  font: 700 24px Barlow, sans-serif;
}

.divider {
  width: 85%;
  background-color: rgba(255, 255, 255, 0.5);
  border: 1px solid rgba(255, 255, 255, 0.5);
  margin: 20px auto 0;
}

.content {
  background-color: #1D1D1D;
}

.pieChart {
  aspect-ratio: 1;
  width: 100%;
  height: auto;
  max-height: 300px;
  margin: 0 auto;
}

.pieChartImg {
  inset: 0;
  height: 100%;
  width: 100%;
  object-fit: cover;
  object-position: center;
}

.footer {
  border-radius: 0px 0px 16px 16px;
  min-height: 100px;
  z-index: 0;
  color: #fff;
  padding: 44px 40px;
  font: 400 24px/35px Work Sans, -apple-system, Roboto, Helvetica, sans-serif;
}

.bold {
  font-weight: 700;
}

@media (max-width: 991px) {
  .card,
  .header,
  .content,
  .footer {
    max-width: 100%;
  }

  .header,
  .footer {
    padding: 0 20px;
  }

  .chartContainer {
    max-width: 100%;
    padding-right: 20px;
  }

  .pieChart {
    margin-top: 15px;
  }
}
