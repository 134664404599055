@use 'theme';

.ugc-card-container {
    display: flex;
    //max-height: 23.125rem;
    height: 100%;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 2.1875rem;
    flex: 1 0 0;
    align-self: stretch;
    background: var(--Actions, theme.$ab-yellow);

    .ugc-card-content {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        gap: 1rem;
        align-self: stretch;
        //margin: 3.125rem 2.1875rem;
        padding: 1.5rem;
        //padding: 0;

        .ugc-card-label {
            color: var(--grayscale-black, #000);
            text-align: center;
            /* Desktop/Body/Overline */
            font-family: Inter-Black;
            font-size: 15px;
            font-style: normal;
            line-height: 150%; /* 22.5px */
            letter-spacing: 0.45px;
            text-transform: uppercase;
            @media screen and (width < 100rem) {
                font-size: 12px;
             }
        }

        .ugc-card-labeltext {
            color: var(--grayscale-black, #000);
            text-align: center;
            /* Desktop/Headers/H3 */
            font-family: Zuume-Black;
            font-size: 70px;
            font-style: normal;
            line-height: 80%; /* 64px */
            text-transform: uppercase;
            @media screen and (width < 100rem) {
                font-size: 60px;
              }
        }
    }
}