.wrapper {
  display: flex;
  font-size: 36px;
  color: #fff;
  text-transform: uppercase;
  line-height: 45px;
  width: 335px;
  padding: 24px;
  text-align: center;
  border-radius: 16px;
  background: #434343;
  box-shadow: 0px 0px 4px 0px rgba(0, 0, 0, 0.16);
}

@media (max-width: 991px) {
  .wrapper {
    padding: 0 20px;
  }
}

.content {
  z-index: 1;
  position: relative;
}
