@use "../theme";

.statementcard-container {
  display: flex;
  height: 100%;
  width: 100%;
  align-items: flex-start;
  justify-content: space-between;
  gap: 10px;
  flex: 1 0 0;
  border-radius: 1rem;
  background: var(--Grayscale-800, #1d1d1d);

  // This classname sets a unified yellow background
  &.demandspace {
    background: theme.$ab-yellow;
  }

  //Demand spaces - below classes set the demand space specific colors to the background
  &.Fram_DS_5 { //"Casual Good Times"
    background: theme.$casual-good-times;
  }
  &.Fram_DS_1 { //"Easygoing Hangout"
    background: theme.$easygoing-hangout;
  }
  &.Fram_DS_3 { //"Energetic Night Out"
    background: theme.$energetic-night-out;
  }
  &.Fram_DS_4 { //"Upbeat Social Meal"
    background: theme.$upbeat-social-meal;
  }
  &.Fram_DS_9 { //"Refined Weekend Out"
    background: theme.$refined-weekend-out;
  }
  &.Fram_DS_7 { //"Lively Social Gathering"
    background: theme.$lively-social-gathering;
  }
  &.Fram_DS_10 { //"Thoughtful Connection"
    background: theme.$thoughtful-connection;
  }
  &.Fram_DS_2 { //"Routine Living"
    background: theme.$routine-living;
  }
  &.Fram_DS_12 { //"Intimate Dining"
    background: theme.$intimate-dining;
  }
  &.Fram_DS_13 { //"Everyday Meal"
    background: theme.$everyday-meal;
  }
  &.Fram_DS_6 { //"Simple Unplug"
    background: theme.$simple-unplug;
  }
  &.Fram_DS_8 { //"Intentional Unwind"
    background: theme.$intentional-unwind;
  }
  &.Fram_DS_11 { //"Sensible Slowdown"
    background: theme.$sensible-slowdown;
  }

  .card-content-container {
    display: flex;
    margin: 0px 73px;
    flex-direction: column;
    justify-content: space-evenly;
    gap: 1.5rem;
    align-items: center;
    flex: 1 0 0;
    align-self: stretch;

    .big-statement {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      gap: var(--Spacing-XS, 12px);
      align-self: stretch;
      text-align: left;

      color: theme.$white;
      /* Desktop/Body/Default/Bold */
      font-family: Inter-Bold;
      font-size: 20px;
      font-style: normal;
      line-height: 130%; /* 26px */
      @media screen and (width < 100rem) {
        font-size: 16px;
      }

      span,b {
        color: theme.$ab-yellow;
        /* Desktop/Headers/H2 */
        font-family: Zuume-Black;
        text-align: center;
        font-size: 128px;
        font-style: normal;
        line-height: 80%; /* 102.4px */
        text-transform: uppercase;
        @media screen and (width < 100rem) {
          font-size: 96px;
        }
      }

      &.demandspace {
        color: theme.$black;
        span,b {
          color: theme.$black;
        }
      }
      &.Fram_PSGMT_2 {
        text-align: center;
        color: theme.$comfort-craver;

        span,b {
          color: theme.$comfort-craver;
        }
      }

      &.Fram_PSGMT_3 {
        text-align: center;
        color: theme.$loyal-traditionalist;
        span,b {
          color: theme.$loyal-traditionalist;
        }
      }

      &.Fram_PSGMT_5 {
        color: theme.$balanced-enjoyers;
        text-align: center;
        font-family: Inter-Bold;
        font-size: 16px;
        font-style: normal;
        line-height: 130%;
        @media screen and (width < 100rem) {
          font-size: 13px;
        }
        span,b {
          color: theme.$balanced-enjoyers;
          text-align: center;
          font-family: Zuume-Black;
          font-size: 80px;
          font-style: normal;
          line-height: 80%; /* 64px */
          text-transform: uppercase;
          @media screen and (width < 100rem) {
            font-size: 60px;
          }
        }
      }

      &.Fram_PSGMT_6 {
        text-align: center;
        color: theme.$diligent-discoverer;
        span,b {
          color: theme.$diligent-discoverer;
        }
      }

      &.Fram_PSGMT_8 {
        text-align: center;
        color: theme.$carefree-fun-lover;
        span,b {
          color: theme.$carefree-fun-lover;
        }
      }

      &.Fram_PSGMT_9 {
        text-align: center;
        color: theme.$proud-celebrator;
        span,b {
          color: theme.$proud-celebrator;
        }
      }

      &.Fram_PSGMT_11 {
        text-align: center;
        color: theme.$creative-explorer;
        span,b {
          color: theme.$creative-explorer;
        }
      }
      &.Fram_PSGMT_12 {
        text-align: center;
        color: theme.$social-curator;
        span,b {
          color: theme.$social-curator;
        }
      }
    }
  }
}
