@use "theme";

.drop-down-menu-container {
  //position: fixed;
  //top: 5rem;
  display: flex;
  //width: 100vw;
  width: 100%;
  flex: 1 0 0;
  // background-color: black;
  // backdrop-filter: blur(25px);
  padding: 0;
  border-bottom: 1px solid rgba(255, 255, 255, 0.3);
  background: rgba(0, 0, 0, 0.5);
  backdrop-filter: blur(25px);

  .menu-container {
    display: flex;
    margin: var(--Spacing-None, 0px) 6.25rem;
    flex-direction: row;
    justify-content: space-between;
    align-items: flex-start;
    // backdrop-filter: blur(25px);

    width: 100%;
    flex: 1 0 0;
    // background-color: black;
    //gap: 2.75rem;

    .left-menu-item {
      display: flex;
      align-self: flex-start;
      .menu-item-hub-container {
        display: flex;
        //margin: 1.5rem 0 2.5rem 0;
        justify-content: space-between;
        padding: 1.25rem;

        .menu-item-hub-title-button {
          display: flex;
          justify-content: space-between;
          align-items: center;
          align-self: flex-start;
          border-radius: 0.5rem;
          border: 1px solid theme.$grey-700;
          background: var(--Grayscale-Black, #000);
          height: 6rem;
          width: 16rem;
          color: theme.$white;

          .icon {
            opacity: 0.4;
          }

          &:hover {
            background-color: theme.$ab-yellow;
            color: theme.$black;
            border: none;
            .icon {
              opacity: 1;
            }
          }
          .button-text {
            text-align: left;
            font-family: Inter-Regular;
            font-size: 24px;
            font-style: normal;
            font-weight: 700;
            line-height: 130%;
            text-transform: capitalize;
          }
          .cat-trip-nav-button-text {
            text-align: left;
            font-family: Inter-Regular;
            font-size: 22px;
            font-style: normal;
            font-weight: 700;
            line-height: 130%;
            text-transform: capitalize;
          }
        }
      }
    }

    .right-menu-item {
      display: flex;
      align-items: flex-start;
      .menu-items-container {
        display: flex;
        flex-direction: row;
        margin: 1.5rem 0 2.5rem 0;
        justify-content: space-evenly;
        align-self: flex-end;
        //gap: 5.5rem;

        .menu-list-items {
          display: flex;
          flex-direction: column;
          align-items: flex-start;

          .menu-header-container {
            align-self: stretch;
            display: flex;
            padding-bottom: 16px;
            flex-direction: column;
            align-items: flex-start;
            gap: 8px;

            &.people-segment,
            &.alcohol-categories,
            &.trip-types {
              min-height: 2.5rem;
            }

            .menu-header {
              color: var(--Grayscale-White, #fff);
              font-family: Inter-Regular;
              font-size: 16px;
              font-style: normal;
              font-weight: 900;
              line-height: 130%;
              opacity: 0.4;
              text-transform: uppercase;
              @media screen and (width < 100rem) {
                font-size: 13px;
              }

              &.demand-spaces {
                &.header-2,
                &.header-3,
                &.header-4 {
                  visibility: hidden;
                }
              }
            }
          }
          .menu-list-item-container {
            display: flex;
            width: 196px;
            min-height: 31px;
            padding: 4px 0px;
            flex-direction: column;
            align-items: flex-start;
            gap: 2px;

            .menu-list-item {
              padding: 0;
              cursor: pointer;
              .menu-list-item-text {
                // color: var(--Grayscale-White, #fff);
                // color: theme.$ab-yellow;
                background-color: theme.$ab-yellow;
                background-clip: text;
                -webkit-background-clip: text;
                -webkit-text-fill-color: theme.$ab-yellow;
                color: transparent;
                display: inline-block;

                font-family: Inter-Bold;
                font-size: 16px;
                font-style: normal;
                line-height: 130%;
                @media screen and (width < 100rem) {
                  font-size: 13px;
                }

                &:hover {
                  border-bottom: 1px solid theme.$ab-yellow;
                }

                &.Fram_PSGMT_2 {
                  //comfort-cravers
                  background-color: theme.$comfort-craver;
                  -webkit-text-fill-color: theme.$comfort-craver;
                  &:hover {
                    border-bottom: 1px solid theme.$comfort-craver;
                  }
                }
                &.Fram_PSGMT_3 {
                  //Loyal-Tradionalists
                  background-color: theme.$loyal-traditionalist;
                  -webkit-text-fill-color: theme.$loyal-traditionalist;
                  &:hover {
                    border-bottom: 1px solid theme.$loyal-traditionalist;
                  }
                }
                &.Fram_PSGMT_5 {
                  //Balanced Enjoyers
                  background-color: theme.$balanced-enjoyers;
                  -webkit-text-fill-color: theme.$balanced-enjoyers;
                  &:hover {
                    border-bottom: 1px solid theme.$balanced-enjoyers;
                  }
                }
                &.Fram_PSGMT_6 {
                  //Diligent Discoverers
                  background-color: theme.$diligent-discoverer;
                  -webkit-text-fill-color: theme.$diligent-discoverer;
                  &:hover {
                    border-bottom: 1px solid theme.$diligent-discoverer;
                  }
                }
                &.Fram_PSGMT_8 {
                  //Carefree Fun-Lovers
                  background-color: theme.$carefree-fun-lover;
                  -webkit-text-fill-color: theme.$carefree-fun-lover;
                  &:hover {
                    border-bottom: 1px solid theme.$carefree-fun-lover;
                  }
                }
                &.Fram_PSGMT_9 {
                  //Proud Celebrators
                  background-color: theme.$proud-celebrator;
                  -webkit-text-fill-color: theme.$proud-celebrator;
                  &:hover {
                    border-bottom: 1px solid theme.$proud-celebrator;
                  }
                }
                &.Fram_PSGMT_11 {
                  //Creative Explorers
                  background-color: theme.$creative-explorer;
                  -webkit-text-fill-color: theme.$creative-explorer;
                  &:hover {
                    border-bottom: 1px solid theme.$creative-explorer;
                  }
                }
                &.Fram_PSGMT_12 {
                  //Social Curators
                  background-color: theme.$social-curator;
                  -webkit-text-fill-color: theme.$social-curator;
                  &:hover {
                    border-bottom: 1px solid theme.$social-curator;
                  }
                }
                &.Fram_DS_1 {
                  //Easygoing Hangout
                  background: theme.$easygoing-hangout;
                  -webkit-text-fill-color: transparent;
                  background-clip: text;
                  &:hover {
                    border-bottom: 1px solid theme.$loyal-traditionalist;
                  }
                }
                &.Fram_DS_2 {
                  //Routine Living
                  background: theme.$routine-living;
                  -webkit-text-fill-color: transparent;
                  background-clip: text;
                  &:hover {
                    border-bottom: 1px solid theme.$loyal-traditionalist;
                  }
                }
                &.Fram_DS_3 {
                  //Energetic Night Out
                  background: theme.$energetic-night-out;
                  -webkit-text-fill-color: transparent;
                  background-clip: text;
                  &:hover {
                    border-bottom: 1px solid theme.$carefree-fun-lover;
                  }
                }
                &.Fram_DS_4 {
                  //Upbeat Social Meal
                  background: theme.$upbeat-social-meal;
                  -webkit-text-fill-color: transparent;
                  background-clip: text;
                  &:hover {
                    border-bottom: 1px solid theme.$carefree-fun-lover;
                  }
                }
                &.Fram_DS_5 {
                  //Casual Good Times
                  background: theme.$casual-good-times;
                  -webkit-text-fill-color: transparent;
                  background-clip: text;
                  &:hover {
                    border-bottom: 1px solid theme.$carefree-fun-lover;
                  }
                }
                &.Fram_DS_6 {
                  //Simple Unplug
                  background: theme.$simple-unplug;
                  -webkit-text-fill-color: transparent;
                  background-clip: text;
                  &:hover {
                    border-bottom: 1px solid theme.$carefree-fun-lover;
                  }
                }
                &.Fram_DS_7 {
                  //Lively Social Gathering
                  background: theme.$lively-social-gathering;
                  -webkit-text-fill-color: transparent;
                  background-clip: text;
                  &:hover {
                    border-bottom: 1px solid theme.$creative-explorer;
                  }
                }
                &.Fram_DS_8 {
                  //Intentional Unwind
                  background: theme.$intentional-unwind;
                  -webkit-text-fill-color: transparent;
                  background-clip: text;
                  &:hover {
                    border-bottom: 1px solid theme.$creative-explorer;
                  }
                }
                &.Fram_DS_9 {
                  //Refined Weekend Out
                  background: theme.$refined-weekend-out;
                  -webkit-text-fill-color: transparent;
                  background-clip: text;
                  &:hover {
                    border-bottom: 1px solid theme.$balanced-enjoyers;
                  }
                }
                &.Fram_DS_10 {
                  //Thoughtful Connection
                  background: theme.$thoughtful-connection;
                  -webkit-text-fill-color: transparent;
                  background-clip: text;
                  &:hover {
                    border-bottom: 1px solid theme.$balanced-enjoyers;
                  }
                }
                &.Fram_DS_11 {
                  //Sensible Slowdown
                  background: theme.$sensible-slowdown;
                  -webkit-text-fill-color: transparent;
                  background-clip: text;
                  &:hover {
                    border-bottom: 1px solid theme.$balanced-enjoyers;
                  }
                }
                &.Fram_DS_12 {
                  //Intimate Dining
                  background: theme.$intimate-dining;
                  -webkit-text-fill-color: transparent;
                  background-clip: text;
                  &:hover {
                    border-bottom: 1px solid #8fe8bb;
                  }
                }
                &.Fram_DS_13 {
                  //Everyday Meal
                  background: theme.$everyday-meal;
                  -webkit-text-fill-color: transparent;
                  background-clip: text;
                  &:hover {
                    border-bottom: 1px solid #8fe8bb;
                  }
                }
              }
              .cat-trip-menu-list-item-text {
                background-color: theme.$white;
                background-clip: text;
                -webkit-background-clip: text;
                -webkit-text-fill-color: theme.$white;
                color: transparent;
                display: inline-block;

                font-family: Inter-Bold;
                font-size: 16px;
                font-style: normal;
                line-height: 130%;
                @media screen and (width < 100rem) {
                  font-size: 13px;
                }

                &:hover {
                  border-bottom: 1px solid #fff;
                }
              }
            }
          }
        }
      }
    }
  }
}
