.statContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.title {
  margin-top: 5px;
  color: inherit;
  text-align: center;
  font: 500 18px/20px Work Sans, -apple-system, Roboto, Helvetica, sans-serif;
}

.stat {
  display: flex;
  margin-top: 5px;
  align-items: center;
  gap: 10px;
  color: #fff;
  white-space: nowrap;
  justify-content: start;
  font: 500 16px/1 Work Sans, -apple-system, Roboto, Helvetica, sans-serif;
}

.tag {

  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 4px;
  min-height: 24px;
  gap: 4px;
  text-align: center;
  margin: auto 0;
  padding: 4px 8px;
}

@media (max-width: 991px) {
  .stat {
    white-space: initial;
  }
  .tag {
    white-space: initial;
  }
}
