.factorCard {
  display: flex;
  flex: 1;
  flex-direction: column;
  text-align: center;
  justify-content: center;
  padding: 12px;
  border-radius: 16px;
  box-shadow: 0 0 4px 0 rgba(0, 0, 0, 0.16);
  background-color: #1d1d1d;
  width: 270px;
}

.factorIcon {
  align-self: center;
}

.factorText {
  max-width: 250px;
  margin: 20px auto 0;
  color: #fff;
  font-family: Work Sans, sans-serif;
  font-size: 24px;
  font-weight: 600;
  text-transform: uppercase;
  line-height: 1;
}

@media (max-width: 991px) {
  .factorCard {
    max-width: 100%;
    padding: 0 20px;
  }
}
