@use "theme";

.modal-content {
    position: relative;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);

    display: flex;
    width: 498px;
    height: 493px;
    min-height: 493px;
    // padding-bottom: var(--Spacing-S, 1.25rem);
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    gap: var(--Spacing-S, 1.25rem);
    border-radius: 16px;
    background: var(--Grayscale-200, #efefef);
    border: none;

    .close-modal-button {
        position: absolute;
        right: 1rem;
        top: 1rem;
        width: 2rem;
        height: 2rem;
        flex-shrink: 0;
        border-radius: var(--Radius-L, 0px);
        backdrop-filter: blur(2px);
        color: theme.$white;
    }

    .card-content-box {
        display: flex;
        align-self: stretch;

        .card {
            display: flex;
            flex-direction: column;
            justify-content: space-between;
            align-self: stretch;
            .modal-visual {
                width: 100%;
                height: 275px;
                flex-shrink: 0;
                background-size: cover;
                background-position: 50%;
                background-repeat: no-repeat;
                border-top-left-radius: 16px;
                border-top-right-radius: 16px;
            }

            .modal-text-container {
                display: flex;
                margin: 0px var(--Spacing-M, 2.5rem);
                flex-direction: column;
                gap: 1rem;
                align-items: center;
                flex: 1 0 0;
                align-self: stretch;

                .modal-text {
                    color: var(--Grayscale-Black, theme.$black);
                    text-align: center;

                    &.heading {
                        font-family: Graphik;
                        font-size: 32px;
                        font-style: normal;
                        font-weight: 700;
                        line-height: 100%; /* 32px */
                        letter-spacing: -1.28px;
                        width: 23.5625rem;
                    }

                    &.description {
                        align-self: stretch;
                        font-family: Inter-Regular;
                        font-size: 16px;
                        font-style: normal;
                        font-weight: 400;
                        line-height: 130%; /* 20.8px */
                        @media screen and (width < 100rem) {
                            font-size: 13px;
                        }
                    }
                }
            }

            .blackDot {
                background-color: #1c1737;
            }

            .popup-stepper-container {
                display: flex;
                justify-content: space-between;
                padding: 0;
                justify-self: flex-end;
                margin: 0px var(--Spacing-M, 2.5rem);

                .next-btn {
                    display: flex;
                    align-self: center;
                }
            }

            .next-btn {
                color: #000;
                text-align: center;
                font-family: Inter-Bold;
                font-size: 15px;
                font-style: normal;
                line-height: 130%; /* 19.5px */
                letter-spacing: -0.3px;
                text-transform: none;
                @media screen and (width < 100rem) {
                    font-size: 12px;
                }
            }
        }
    }
}
