@use "theme";

.consumption-card-container {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  .consumption-card-content {
    display: flex;
    padding: var(--spacing-m, 2rem);
    flex-direction: column;
    align-items: center;
    align-self: stretch;
    gap: var(--spacing-s, 1.25rem);
    border-radius: 18px;
    min-height: 18rem;
    background: var(--grayscale-800, theme.$grey-800);
    @media screen and (width < 100rem) {
      min-height: 15.5rem;
    }

    .bev-icon {
      width: 107px;
      height: 107px;
    }
    .beverage-name {
      color: var(--grayscale-white, theme.$white);
      text-align: center;
      /* Desktop/Headers/H4 - Styled */
      font-family: Zuume-Black;
      font-style: normal;
      line-height: 90%; /* 38.7px */
      letter-spacing: 1.72px;
      font-size: 2.6875rem;
      @media screen and (width < 100rem) {
        font-size: 32.25px;
      }
    }
    .beverage-data-container {
      display: flex;
      justify-content: center;
      flex-direction: row;
      align-items: center;
      gap: var(--spacing-s, 1.25rem);
      .volume-data,
      .index-data {
        display: flex;
        flex-direction: column;
        align-items: center;
        gap: 0.5rem;
        align-self: stretch;
      }

      .data-heading {
        color: var(--grayscale-300, theme.$grey-300);
        text-align: center;
        /* Desktop/Body/Small/Small Bold */
        font-family: Inter-Bold;
        font-style: normal;
        line-height: 130%; /* 20.8px */
        @media screen and (width < 100rem) {
          font-size: 12px;
        }
      }
      .data-value {
        display: flex;
        padding: 0.25rem 1rem;
        justify-content: center;
        align-items: center;
        gap: 0.625rem;

        color: var(--grayscale-white, theme.$white);
        text-align: center;
        /* Desktop/Headers/H4 - Styled */
        font-family: Zuume-Black;
        font-style: normal;
        font-size: 2.6875rem;
        line-height: 90%; /* 38.7px */
        letter-spacing: 1.72px;
        border-radius: 0.5rem;
        @media screen and (width < 100rem) {
          font-size: 32.25px;
        }

        &.positive {
          background: var(--global-colors-index-neutral, theme.$positive);
        }
        &.neutral {
          background: var(--global-colors-index-neutral, theme.$neutral);
        }
        &.negative {
          background: var(--global-colors-index-neutral, theme.$negative);
        }
      }
    }

    .beverage-info {
      color: var(--grayscale-300, theme.$grey-300);
      text-align: center;
      /* Desktop/Body/Small/Small Bold */
      font-family: Inter;
      font-style: normal;
      font-weight: 700;
      line-height: 130%; /* 20.8px */
      @media screen and (width < 100rem) {
        font-size: 12px;
      }
    }
  }
}
