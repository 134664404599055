.container {
  justify-content: center;
  align-items: center;
  display: flex;
  flex-direction: column;
  color: #fff;
}

.heading {
  width: 940px;
  max-width: 100%;
  gap: 8px;
  text-align: center;
  font: 600 32px Work Sans, sans-serif;
}

.cardsContainer {
  display: flex;
  margin-top: 20px;
  font-family: Barlow, sans-serif;
  font-weight: 700;
  width: 75%;
}

@media (max-width: 991px) {
  .heading {
    max-width: 100%;
  }
}
