@use "theme";

.demand-spaces-section-container {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: flex-start;
  width: 100%;
  gap: 6.25rem;

  .section-intro {
    .demandspaces-intro-left-item {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: flex-start;
      gap: 6.25rem;
      flex: 1 0 0;

      .divider {
        display: flex;
        //flex: 1 0 0;
        border: 2px solid #f6e003;
        width: 10rem;
      }

      .intro-text {
        color: theme.$white;
        font-family: Zuume-Black;
        font-style: normal;
        line-height: 80%; /* 102.4px */
        text-transform: uppercase;
        font-size: 8rem;
        letter-spacing: normal;
        @media screen and (width < 100rem){
          font-size: 6rem;
        }
      }
    }

    .demandspaces-intro-right-item {
      display: flex;
      align-items: flex-end;

      .wayfinder-container {
        display: flex;
        width: 100%;
        // height: 15.5rem;
        padding: 0.75rem 0.836rem;
        justify-content: center;
        flex-shrink: 0;
        border-radius: 0.625rem;
        align-self: flex-end;
      }
    }
  }

  .demand-space-ugc-card-list {
    display: flex;
    align-items: flex-start;
    //gap: var(--Spacing-S, 1.25rem);
    align-self: stretch;

    // .demand-space-ugc-card-list-item {
    //   //padding: 0;
    // }
  }
}
