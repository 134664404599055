@use 'theme';

.loading-component-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 100vh;

    .loading-text {
        font-family: Inter-Regular;
        color: theme.$ab-yellow;
    }
}