.categoryCard {
  position: relative;
  display: flex;
  min-height: 205px;
  width: 131px;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 32px 24px;
  border-radius: 8px;
  box-shadow: 0 0 4px rgba(0, 0, 0, 0.16);
}

.categoryIcon {
  width: 62px;
  aspect-ratio: 0.73;
  object-fit: contain;
  z-index: 1;
}

.categoryTitle {
  margin-top: 16px;
  font: 700 24px Barlow, sans-serif;
  text-transform: uppercase;
  text-align: center;
  z-index: 1;
}

@media (max-width: 991px) {
  .categoryCard {
    padding: 0 20px;
  }
}
