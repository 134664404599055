.segmentCard {
  position: relative;
  height: 102px;
  padding: 21px 24px;
  border-radius: 8px;
  border: 1px solid #424242;
  background: rgba(255, 255, 255, 0.16);
  box-shadow: 0 0 4px rgba(0, 0, 0, 0.16);
  display: flex;
  align-items: center;
  justify-content: center;
}

.segmentCard.beer {
  width: 40px;
}

.segmentCard.hardBev {
  width: 84px;
}

.segmentCard:hover {
  cursor: pointer;
  transform: scale(1.05);
  transition: transform 0.3s ease-in-out;
}

.segmentTitle {
  font: 500 16px/22px Work Sans, -apple-system, Roboto, Helvetica, sans-serif;
  text-align: center;
  z-index: 1;
}

@media (max-width: 991px) {
  .segmentCard {
    padding: 0 20px;
  }
}
