.container {
  align-items: center;
  display: flex;
  flex-direction: column;
  justify-content: start;
  gap: 60px;
  width: 100vw;
  padding-bottom: 30px;
}

.heading {
  color: #fff;
  text-align: center;
  font: 600 32px Work Sans, sans-serif;
  padding: 0 20px;
  width: 800px;
}

.alcoholSubcategoriesChart {
  width: 900px;
  margin-top: 60px;
}

@media (max-width: 991px) {
  .container {
    padding: 0 20px;
  }
}
