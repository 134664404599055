@use "theme";

// .demandspace-ugc-card-container {
//   display: flex;
//   height: 13.0625rem;
//   width: 12.8125rem;
//   flex-direction: column;
//   align-items: flex-start;
//   justify-content: flex-end;
//   border-radius: 16px;
//   background-size: cover;
//   background-color: lightgray;
//   background-position: center;
//   background-repeat: no-repeat;
//   box-shadow: 0 0 0 5px transparent;
//   transition: box-shadow 0s ease;
//   &:hover {
//     box-shadow: 0 0 0 5px theme.$ab-yellow;
//   }

//   .scope-container {
//     display: inline-flex;
//     justify-content: center;
//     align-items: center;
//     align-self: flex-end;
//     margin-top: 1.25rem;
//     margin-right: 1.25rem;
//     .scope-container-outer {
//       height: 7.75rem;
//       width: 7.75rem;
//     }
//   }
//   .ugc-text-container {
//     display: flex;
//     padding: 1.5rem;
//     flex-direction: column;
//     justify-content: flex-end;
//     align-items: flex-start;
//     gap: 0.5rem;
//     flex-shrink: 0;
//     align-self: stretch;

//     .demandspace-name {
//       color: theme.$white;
//       font-family: Zuume;
//       font-style: normal;
//       font-weight: 900;
//       line-height: 80%; /* 64px */
//       text-transform: uppercase;
//       letter-spacing: normal;
//       @media screen and (width < 100rem) {
//         font-size: 60px;
//       }
//     }

//     .demandspace-occasions {
//       color: theme.$white;
//       font-family: Inter;
//       font-style: normal;
//       font-weight: 700;
//       line-height: 130%; /* 26px */
//       @media screen and (width < 100rem) {
//         font-size: 16px;
//       }
//     }
//   }
// }
.demandspace-ugc-card-container {
  .ds-hover-card {
    border-radius: 16px;
    box-shadow: 0 0 0 5px transparent;
    transition: box-shadow 0s ease;
    &:hover {
      box-shadow: 0 0 0 5px theme.$ab-yellow;
    }
  }
  .ds-card-img {
    height: 11.5rem;
    background-size: cover;
    background-color: lightgray;
    background-position: center;
    background-repeat: no-repeat;
  }
  .ds-card-title {
    display: flex;
    padding: 8.99px 11.238px 11.238px 11.238px;
    flex-direction: column;
    justify-content: flex-end;
    align-items: flex-start;
    gap: 4.495px;
    flex-shrink: 0;

    border-radius: 0px 0px 8.99px 8.99px;
    background: var(--Grayscale-700, #3d3d3d);

    .ugc-text-container {
      // height: 61px;
      .demandspace-name {
        display: flex;
        // height: 25.285px;
        flex-direction: column;
        justify-content: flex-start;
        flex: 1 0 0;

        color: var(--Grayscale-White, #fff);
        font-family: Zuume-Black;
        font-size: 24.161px;
        font-style: normal;
        line-height: 90%; /* 21.745px */
        letter-spacing: 0.966px;
        @media screen and (width < 100rem) {
          // font-size: 20px;
          height: 50px;
        }
      }
      .demandspace-occasions {
        display: flex;
        // height: 12.923px;
        flex-direction: column;
        justify-content: center;
        flex-shrink: 0;
        align-self: stretch;

        color: theme.$ab-yellow;
        font-family: Inter-Black;
        font-size: 8.428px;
        font-style: normal;
        line-height: 150%; /* 12.643px */
        letter-spacing: 0.253px;
        text-transform: uppercase;
        // @media screen and (width < 100rem) {
        //   font-size: 7px;
        // }

        .comfort-craver {
          //Comfort Cravers
          color: theme.$comfort-craver !important;
        }
        .loyal-traditionalist {
          //Loyal Traditionalists
          color: theme.$loyal-traditionalist !important;
        }
        .mature-discoverer {
          //Balanced Enjoyers
          color: theme.$balanced-enjoyers !important;
        }
        .diligent-discoverer {
          //Diligent Discoverers
          color: theme.$diligent-discoverer !important;
        }
        .carefree-fun-lover {
          //Carefree Fun-Lovers
          color: theme.$carefree-fun-lover !important;
        }
        .proud-celebrator {
          //Proud Celebrators
          color: theme.$proud-celebrator !important;
        }
        .creative-explorer {
          //Creative Explorers
          color: theme.$creative-explorer !important;
        }
        .social-curator {
          //Social Curators
          color: theme.$social-curator !important;
        }
      }
    }
  }
}
