.card {
  border-radius: 16px;
  display: flex;
  flex-direction: column;
  justify-content: start;
  flex: 1;
}

.cardInner {
  border-radius: 16px;
  background: #1d1d1d;
  display: flex;
  flex-direction: column;
  flex: 1;
}

.header {
  border-radius: 16px 16px 0px 0px;
  height: 70px;
  font-size: 24px;
  color: #fff;
  font-family: "Work Sans", sans-serif;
  font-weight: 600;
  text-align: center;
  text-transform: uppercase;
  display: flex;
  align-items: center;
  justify-content: center;
}

.content {
  border-radius: 16px;
  font-family: "Work Sans", sans-serif;
  font-size: 18px;
  color: #fff;
  font-weight: 500;
  line-height: 26px;
  padding: 20px;
}

.purposeContent {
  font-size: 24px;
  font-style: normal;
  font-weight: 600;
  line-height: 35px;
  padding: 20px 40px;
}

@media (max-width: 991px) {
  .header {
    padding: 0 20px;
  }
}