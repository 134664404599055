.mainNav {
  align-self: stretch;
  display: flex;
  min-height: 65px;
  width: 100%;
  flex-direction: column;
  color: #fff;
  text-transform: uppercase;
  letter-spacing: 0.84px;
  font: 12px/0.9 Inter, sans-serif;
}

.moleculeContent {
  background-color: rgba(0, 0, 0, 0.7);
  display: flex;
  width: 100%;
  align-items: center;
  justify-content: space-between;
  flex-wrap: wrap;
}

.hero {
  display: flex;
  width: 100%;
  max-width: 1224px;
  flex-direction: column;
  align-items: center;
  color: #fff;
  text-align: center;
}

.title {
  font-feature-settings: "salt" on;
  text-transform: uppercase;
  font: 800 75px/1 Barlow, sans-serif;
  margin: 0;
}

.description {
  font: 600 24px/35px Work Sans, -apple-system, Roboto, Helvetica, sans-serif;
}

@media (max-width: 991px) {
  .moleculeContent {
    padding: 16px 20px;
  }

  .hero {
    margin-top: 40px;
  }

  .title {
    font-size: 40px;
  }
}