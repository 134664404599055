.categories {
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 1224px;
  margin: auto;
}

.buttonGroup {
  align-self: center;
  display: flex;
  gap: 20px;
}

.activeButton {
  align-self: stretch;
  border-radius: 50px;
  background-color: #504B21;
  min-height: 50px;
  color: #FFE500;
  width: 450px;
  margin: auto 0;
  padding: 11px 0;
  border: 3px solid #F5DF42;
  font: 700 24px Barlow, sans-serif;
  text-transform: uppercase;
}

.inactiveButton {
  align-self: stretch;
  border-radius: 50px;
  background-color: #535353;
  border: 3px solid #535353;
  min-height: 50px;
  color: #fff;
  width: 450px;
  margin: auto 0;
  padding: 11px 0;
  font: 700 24px Barlow, sans-serif;
  text-transform: uppercase;
}

.statGroup {
  display: flex;
  margin-top: 40px;
  width: 100%;
  gap: 20px;
}

.tableContainer {
  display: flex;
  flex-direction: column;
  gap: 20px;
  flex: 1;
}

@media (max-width: 991px) {
  .buttonGroup,
  .statGroup {
    max-width: 100%;
  }
  
  .activeButton,
  .inactiveButton {
    padding: 0 20px;
  }
}
