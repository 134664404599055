@use "theme";

.feedback-section-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 2rem;
  //margin: 100px;
  // width: 25rem;

  .feedback-header {
    color: var(--grayscale-white, theme.$white);
    text-align: center;
    /* Desktop/Headers/H5 */
    font-family: Inter-Bold;
    font-style: normal;
    line-height: 130%; /* 36.4px */
    @media screen and (width < 100rem) {
      font-size: 21px;
    }
  }

  .feedback-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    align-self: stretch;
    gap: 4px;

    .smiley {
      display: flex;
      flex-direction: row;
      justify-content: space-evenly;
      align-items: center;
      gap: 1rem;

      .rating-icon {
        display: flex;
        justify-content: center;
        align-items: center;
        width: 2rem;
        height: 2rem;
        background: var(--grayscale-700, theme.$grey-700);
        border-radius: 50%;
        color: theme.$white;

        &:hover {
          background: theme.$ab-yellow;
          color: theme.$black;
        }

        .rating-icon-text {
          color: var(--Grayscale-White, #FFF);
          text-align: center;
          font-family: Inter-Bold;
          font-size: 20px;
          font-style: normal;
          line-height: 130%;
          @media screen and (width < 100rem) {
            font-size: 15px;
          }
        }
      }
    }

    .smiley-text-container {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      align-items: flex-start;
      gap: 7.5rem;

      .smiley-text-low-rating,
      .smiley-text-high-rating {
        display: flex;
        flex-direction: column;
        align-items: center;
        margin-right: 2px;
        margin-left: -10px;
        gap: var(--spacing-xxs, 0.15rem);

        .smiley-icon {
          width: 1rem;
          height: 1rem;
          fill: var(--grayscale-300, theme.$grey-300);
        }

        .smiley-text {
          color: var(--grayscale-300, theme.$grey-300);
          text-align: center;
          /* Mobile/Data Label */
          font-family: Inter-Bold;
          font-style: normal;
          line-height: 100%; /* 13px */
          letter-spacing: 0.5px;
        }
      }
    }
  }

  .feedback-form {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;

    .feedback-text-field {
      width: 100%;
      background-color: theme.$grey-700;
      color: theme.$white;
    }
    .feedback-submit-button {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      border-radius: 6.25rem;
      background: var(--Actions, theme.$ab-yellow);
    }
  }

  .feedback-received-notification {
    color: var(--grayscale-white, theme.$white);
    text-align: center;
    /* Desktop/Body/Small/Small Bold */
    font-family: Inter;
    font-style: normal;
    font-weight: 700;
    line-height: 130%; /* 20.8px */
  }
}
