@use "theme";

.cf-card-container {
  background-color: transparent;

  .cf-card-content {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: var(--spacing-s, 1.25rem);
    //align-self: stretch;

    .cf-card-label {
      color: var(--grayscale-white, theme.$white);
      text-align: center;
      /* Desktop/Headers/H5 */
      font-size: 1.75rem;
      font-family: Inter-Bold;
      font-style: normal;
      line-height: 130%; /* 36.4px */
      @media screen and (width < 100rem) {
        font-size: 21px;
      }
    }

    .cf-card-labeltext {
      width: 300px;
      align-self: stretch;
      color: var(--Grayscale-White, #FFF);
      text-align: center;
      font-family: Inter-Regular;
      font-size: 16px;
      font-style: normal;
      font-weight: 400;
      line-height: 120%; /* 19.2px */
      letter-spacing: 0.5px;
      @media screen and (width < 100rem) {
        font-size: 13px;
      }
    }

    .icon-container {
      width: 5.5rem;
      height: 5.5rem;

      .icon {
        position: relative;
        color: theme.$black;
        width: 3rem;
        height: 3rem;
      }
    }
  }
}
