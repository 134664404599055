@use "theme.scss";

.column-layout{
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 20px;
    width: fit-content;

    .column-heading{
        display: flex;
        align-items: center;
        gap: 23px;
        align-self: stretch;

        .column-heading-sub{
            color: var(--global-colors-ab-light-gold, var(--Actions, #F6E003));
            font-family: Inter-Bold;
            font-size: 28px;
            font-style: normal;
            line-height: 130%;
            @media screen and (width < 100rem) {
                font-size: 1.3125rem;
            }       
        }
    }
}
.custom-card-background{
    // border: 1px solid yellow;
    .custom-card{
        box-shadow: 0 0 0 5px transparent;
        transition: box-shadow 0.3s ease;
        border-radius: 16px;
        &:hover {
          transform: scale(1.02);
          opacity: 0.8;
            // box-shadow: 0 0 0 1px theme.$ab-yellow;
          }
      }
}

.card-title{
    background: var(--grayscale-700, #3D3D3D);
    padding-bottom: 12px !important;

    .card-text{
        flex: 1 0 0;
        display: flex;
        flex-direction: row;
        justify-content: space-between;

        .card-typo{
            color: var(--grayscale-white, #FFF);
            font-family: Zuume-Black;
            font-size: 1.4rem;
            font-style: normal;
            line-height: 90%;
            padding: 1px;
            letter-spacing: 1.2px;
            white-space: nowrap;
            text-overflow: ellipsis;
        }
    }
}