@use "theme";

.wrapup-main {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  gap: 5rem;
  flex: 1 0 0;

  .divider {
    display: flex;
    flex: 1 0 0;
    border: 2px solid #f6e003;
    width: 10rem;
  }

  .wrapup-intro {
    display: flex;
    flex-direction: column;
    flex: 1 0 0;
    gap: 6.25rem;
    // .divider {
    //   display: flex;
    //   flex: 1 0 0;
    //   border: 2px solid #f6e003;
    //   width: 10rem;
    // }
    .wrapup-grid {
      display: flex;
      justify-content: space-between;
      align-items: center;
      align-self: stretch;

      .wrapup-left-item {
        display: flex;
        //max-width: 37.5rem;
        flex-direction: column;
        justify-content: center;
        align-items: flex-start;
        align-self: flex-start;
        gap: var(--Spacing-M, 2.5rem);

        .wrapup-section-title-container {
          display: flex;
          //max-width: 100%;
          flex-direction: column;
          justify-content: center;
          align-items: flex-start;
          gap: var(--Spacing-M, 2.5rem);

          .section-title {
            display: flex;
            //width: 600px;
            flex-direction: column;
            align-items: flex-start;
            gap: var(--Spacing-S, 1.25rem);
            .title {
              display: flex;
              flex-direction: column;
              align-items: flex-start;
              gap: var(--Spacing-XXS, 0.25rem);
              align-self: stretch;
              .micro-name {
                /* Desktop/Body/Overline */
                font-family: Inter-Black;
                font-style: normal;
                line-height: 150%; /* 22.5px */
                letter-spacing: 0.6px;
                text-transform: uppercase;
                @media screen and (width < 100rem) {
                  font-size: 12px;
                }
              }

              .section-heading {
                font-family: Zuume-Black;
                font-style: normal;
                line-height: 80%; /* 102.4px */
                text-transform: uppercase;
                @media screen and (width < 100rem) {
                  font-size: 96px;
                }
              }
            }

            .micro-attributes {
              display: flex;
              flex-direction: row;
              align-items: center;
              gap: var(--Spacing-XS, 0.75rem);

              .micro-sov,
              .micro-sop {
                color: theme.$white;
                /* Desktop/Body/Small/Small Regular */
                font-family: Inter-Bold;
                font-style: normal;
                line-height: 130%;
                @media screen and (width < 100rem) {
                  font-size: 13px;
                }
                i {
                  font-style: normal;
                  color: theme.$ab-yellow;
                }
                u {
                  text-decoration: none;
                }
              }
              .percent-color {
                color: #f6e003;
              }
              .vertical-line {
                width: 1px;
                height: 40px;
                opacity: 0.2;
                background: theme.$white;
              }
            }
          }

          .section-description {
            display: flex;
            flex-direction: column;
            align-items: flex-start;
            gap: var(--Spacing-M, 2.5rem);
            align-self: stretch;
            .micro-description {
              color: var(--Grayscale-White, theme.$white);
              /* Desktop/Body/Default/Regular */
              font-family: Inter-Regular;
              font-style: normal;
              font-weight: 400;
              line-height: 130%;
              @media screen and (width < 100rem) {
                font-size: 16px;
              }

              i {
                font-style: normal;
                color: theme.$ab-yellow;
              }
            }

            .micro-keywords-container {
              display: flex;
              align-items: flex-start;
              gap: 10px;
              align-self: stretch;

              .micro-keyword {
                display: flex;
                padding: 0.5rem 1.25rem;
                justify-content: center;
                align-items: center;
                gap: 10px;
                flex: 1 0 0;
                border-radius: 10px;
                align-self: stretch;
                background: var(
                  --Global-Colors-AB-Light-Gold,
                  theme.$ab-yellow
                );

                text-align: center;
                /* Desktop/Body/Overline */
                font-family: Inter-Black;
                font-style: normal;
                line-height: 150%; /* 22.5px */
                letter-spacing: 0.45px;
                text-transform: uppercase;
                @media screen and (width < 100rem) {
                  font-size: 12px;
                }
              }
            }
          }
        }
      }

      .wrapup-right-item {
        display: flex;
        //min-width: 660px;
        flex-direction: column;
        align-items: center;
        gap: 10px;
        align-self: stretch;

        .wrapup-visual-container {
          display: flex;
          flex-direction: column;
          justify-content: flex-end;
          align-items: center;
          flex: 1 0 0;
          align-self: stretch;
          min-height: 25rem;

          background-position: center;
          background-size: cover;
          background-repeat: no-repeat;

          border-radius: 1rem;
          .visual-text {
            margin: var(--Spacing-M, 2.5rem) var(--Spacing-L, 6.75rem)
              var(--Spacing-M, 2.5rem) var(--Spacing-M, 2.5rem);

            /* Desktop/Headers/H5 */
            font-family: Inter-Bold;
            font-style: normal;
            line-height: 130%;
            @media screen and (width < 100rem) {
              font-size: 1.3125rem;
            }

            i {
              font-style: normal;
              color: theme.$ab-yellow;
            }
            b {
              font-style: normal;
              background: linear-gradient(100deg, #8fe8bd 0%, #79d271 100%);
              background-clip: text;
              -webkit-background-clip: text;
              -webkit-text-fill-color: transparent;
              text-overflow: ellipsis;
            }
            strong {
              font-style: normal;
              color: theme.$ab-yellow;
            }
            .colored-orange {
              background: linear-gradient(100deg, #ff8811 0%, #ff8811 100%);
              background-clip: text;
              -webkit-background-clip: text;
              -webkit-text-fill-color: transparent;
              text-overflow: ellipsis;
            }
            u {
              text-decoration: none;
              background: linear-gradient(100deg, #ada1f8 0%, #b983ff 100%);
              background-clip: text;
              -webkit-background-clip: text;
              -webkit-text-fill-color: transparent;
              text-overflow: ellipsis;
            }
            .colored-blue {
              background: linear-gradient(100deg, #a1e8f8 0%, #83bcff 100%);
              background-clip: text;
              -webkit-background-clip: text;
              -webkit-text-fill-color: transparent;
              text-overflow: ellipsis;
            }
            .colored-outgoing {
              background: linear-gradient(100deg, #79d271 0%, #83bcff 100%);
              background-clip: text;
              -webkit-background-clip: text;
              -webkit-text-fill-color: transparent;
              text-overflow: ellipsis;
            }
          }
        }
      }
    }
  }
  .wrapup-ugc-container {
    display: flex;
    align-items: flex-start;
    //gap: var(--Spacing-M, 2.5rem);
    align-self: stretch;

    .wrapup-ugc-item {
      padding: 0;
      //width: auto;
      max-height: 119px;

      .wrapup-ugc-card {
        display: flex;
        flex-direction: row;
        height: 100%;
        padding: var(--Spacing-S, 1.25rem);
        align-items: center;
        gap: 1.25rem;
        flex: 1 0 0;
        border-radius: 1rem;
        background: var(--Grayscale-800, theme.$grey-800);

        .occasions-wrapup-ugc-icon {
          width: 4.9375rem;
          height: 4.9375rem;
          border-radius: 50%;
          color: var(--Global-Colors-AB-Light-Gold, theme.$ab-yellow);
        }

        .ugc-text-container {
          display: flex;
          flex-direction: column;
          align-items: flex-start;
          gap: 0.5rem;
          flex: 1 0 0;

          .ugc-title {
            color: var(--Global-Colors-AB-Light-Gold, theme.$ab-yellow);

            /* Desktop/Body/Overline */
            font-family: Inter-Black;
            font-size: 15px;
            font-style: normal;
            line-height: 150%; /* 22.5px */
            letter-spacing: 0.45px;
            text-transform: uppercase;
            @media screen and (width < 100rem) {
              font-size: 12px;
            }
          }
          .ugc-description {
            color: var(--Grayscale-White, #FFF);
            font-family: Zuume-Bold;
            font-size: 30px;
            font-style: normal;
            line-height: 100%;
            letter-spacing: 0.6px;
            text-transform: uppercase;
            @media screen and (width < 100rem) {
              font-size: 22.5px;
            }
          }
        }
      }
    }
  }
  .wrapup-interaction-tooltip {
    display: flex;
    //   justify-content: center;
    //   align-items: center;
    gap: 8px;
    // margin-top: 80px;

    .icon {
      height: 1.5rem;
      width: 1.5rem;
    }

    .interaction-tooltip-text {
      font-family: Inter-Regular;
      font-style: italic;
      font-weight: 400;
      line-height: 130%; /* 20.8px */
      cursor: pointer;

      i {
        text-decoration: underline;
      }
    }
  }
  .main-section4-casual {
    display: flex;
    justify-content: space-between;
    align-self: stretch;
    .left-item {
      .box {
        display: flex;
        flex-direction: column;
        // justify-content: space-between;
        align-self: stretch;

        .heading {
          display: flex;
          flex-direction: row;
          align-items: center;
          justify-content: space-between;
          padding: 0.75rem 2rem 0.75rem 1.25rem;

          .top-needs {
            color: var(--grayscale-white, #fff);
            text-align: center;

            /* Desktop/Body/Default/Bold */
            font-family: Inter-Bold;
            font-size: 20px;
            font-style: normal;
            line-height: 130%; /* 26px */
            @media screen and (width < 100rem) {
              font-size: 16px;
            }
          }

          .vol-index {
            color: var(--grayscale-300, #c7c7c7);
            text-align: center;
            display: flex;
            flex-direction: row;
            align-self: flex-end;
            gap: 2rem;
            /* Desktop/Body/Small/Small Bold */
            font-family: Inter;
            font-size: 16px;
            font-style: normal;
            font-weight: 700;
            line-height: 130%; /* 20.8px */
            @media screen and (width < 100rem) {
              font-size: 13px;
            }
          }
        }
        .top-drivers-container {
          display: flex;

          .row-content {
            display: flex;
            background: var(--grayscale-800, #1d1d1d);
            padding: 1.25rem;
            min-height: 6.25rem;
            justify-content: space-between;
            align-items: center;
            align-self: stretch;
            border-radius: 1rem;
            gap: 1rem;

            .row-text {
              display: flex;
              flex-direction: column;
              align-self: flex-start;
              .head-text {
                color: var(--Global-Colors-AB-Light-Gold, #f6e003);

                /* Desktop/Headers/H5 Styled */
                font-family: Zuume;
                font-size: 1.875rem;
                font-style: normal;
                font-weight: 700;
                line-height: 100%; /* 30px */
                letter-spacing: 0.6px;
                text-transform: uppercase;
                @media screen and (width < 100rem) {
                  font-size: 21px;
                }
              }

              .head-sub-text {
                height: 20px;
                align-self: stretch;
                color: var(--Grayscale-White, #fff);

                /* Desktop/Body/Default/Regular */
                font-family: Inter;
                font-size: 20px;
                font-style: normal;
                font-weight: 400;
                line-height: 140%; /* 26px */
                @media screen and (width < 100rem) {
                  font-size: 16px;
                }
              }
            }
            .vol-index {
              display: flex;
              flex-direction: row;
              gap: 1.5rem;

              .vol-per {
                /* Desktop/Data/Data Point Medium */
                color: var(--Grayscale-White, #fff);
                text-align: right;

                /* Desktop/Data/Data Point Medium */
                font-family: Zuume;
                font-size: 29px;
                font-style: normal;
                font-weight: 700;
                line-height: normal;
                letter-spacing: 0.87px;
              }
              .index {
                display: flex;
                padding: 1px 3px;
                justify-content: center;
                align-items: center;
                gap: 10px;
                border-radius: 8px;
                // background: var(--global-colors-index-positive, #309427);
                color: var(--grayscale-white, #fff);
                text-align: center;

                /* Desktop/Data/Data Point Medium */
                font-family: Zuume;
                font-size: 29px;
                font-style: normal;
                font-weight: 700;
                line-height: normal;
                letter-spacing: 0.87px;
              }
            }
          }
        }
      }
    }
    .right-item {
      .top-needs {
        color: var(--grayscale-white, #fff);
        text-align: left;
        padding: 12px 20px;
        /* Desktop/Body/Default/Bold */
        font-family: Inter-Bold;
        font-size: 20px;
        font-style: normal;
        line-height: 130%; /* 26px */
        @media screen and (width < 100rem) {
          font-size: 16px;
        }
      }
      .right-grid {
        display: flex;
        // margin-top: 8px;
        // min-height: 9.5rem;
        padding: 20px;
        // margin-left: 15px;
        justify-content: space-between;
        align-items: center;
        align-self: stretch;
        border-radius: 16px;
        background: var(--grayscale-800, #1d1d1d);

        .icon-img {
          display: flex;
          background-position: center;
          align-items: center;
          justify-content: center;
          height: 90px;
          width: 90px;

          .icon {
            height: 100%;
            width: 100%;
            object-fit: contain;
          }
        }

        .icon-text-margin {
          display: flex;
          flex-direction: column;
          gap: 10px;

          .icon-text {
            color: var(--grayscale-white, #fff);
            text-align: start;
            font-family: Inter-Black;
            font-size: 28px;
            font-style: normal;
            line-height: 130%;
            text-transform: capitalize;
            @media screen and (width < 100rem) {
              font-size: 21px;
            }
          }
          .vol-per {
            color: var(--Grayscale-White, #fff);
            text-align: center;

            /* Desktop/Headers/H5 Styled */
            font-family: Zuume-Bold;
            font-size: 30px;
            font-style: normal;
            line-height: 100%; /* 30px */
            letter-spacing: 0.6px;
            text-transform: uppercase;
            @media screen and (width < 100rem) {
              font-size: 22.5px;
            }
          }
          .index {
            display: flex;
            padding: 1px 3px;
            justify-content: center;
            align-items: center;
            gap: 10px;
            border-radius: 8px;
            // background: var(--global-colors-index-positive, #309427);
            color: var(--grayscale-white, #fff);
            text-align: center;

            /* Desktop/Headers/H5 Styled */
            font-family: Zuume-Bold;
            font-size: 30px;
            font-style: normal;
            line-height: 100%; /* 30px */
            letter-spacing: 0.6px;
            text-transform: uppercase;
            @media screen and (width < 100rem) {
              font-size: 22.5px;
            }
          }
        }
      }
    }

    .container {
      margin-top: 0px;
      min-height: 50vh !important;
    }

    .head-text {
      align-self: stretch;
      color: var(--Global-Colors-AB-Light-Gold, #f6e003);

      /* Desktop/Headers/H5 Styled */
      font-family: Zuume;
      font-size: 30px;
      font-style: normal;
      font-weight: 400;
      line-height: 130%; /* 30px */
      letter-spacing: 1px;
      text-transform: uppercase;
    }
    .head-sub-text {
      height: 20px;
      align-self: stretch;
      color: var(--Grayscale-White, #fff);

      /* Desktop/Body/Default/Regular */
      font-family: Inter;
      font-size: 20px;
      font-style: normal;
      font-weight: 400;
      line-height: 140%; /* 26px */
      @media screen and (width < 100rem) {
        font-size: 16px;
      }
    }
    .vol-per {
      /* Desktop/Data/Data Point Medium */
      color: var(--Grayscale-White, #fff);
      text-align: right;

      /* Desktop/Data/Data Point Medium */
      font-family: Zuume;
      font-size: 29px;
      font-style: normal;
      font-weight: 700;
      line-height: normal;
      letter-spacing: 0.87px;
    }
    .index {
      display: flex;
      padding: 1px 3px;
      justify-content: center;
      align-items: center;
      gap: 10px;
      border-radius: 8px;
      // background: var(--global-colors-index-positive, #309427);
      color: var(--grayscale-white, #fff);
      text-align: center;
      width: 54px;
      /* Desktop/Data/Data Point Medium */
      font-family: Zuume;
      font-size: 29px;
      font-style: normal;
      font-weight: 700;
      line-height: normal;
      letter-spacing: 0.87px;
    }
    // .gray {
    //   background: var(--global-colors-index-neutral, #434343) !important;
    // }
    // .other-green {
    //   background: var(--global-colors-index-positive, #309427) !important;
    // }
    // .red {
    //   background: var(--global-colors-index-negative, #cb4242) !important;
    // }
    .right-box-margin {
      padding-right: 50px;
    }
  }
  .main-section4-weekday {
    .container {
      margin-top: 0px;
    }
    .weekday-box {
      display: flex;
      padding: var(--spacing-s, 20px);
      align-items: center;
      gap: 20px;
      flex: 1 0 0;
      border-radius: var(--radius-m, 16px);
      background: var(--grayscale-800, #1d1d1d);
    }
    .group-size {
      color: var(--global-colors-ab-light-gold, #f6e003);
      margin-top: 10px;
      /* Desktop/Body/Overline */
      font-family: Inter;
      font-size: 14px;
      font-style: normal;
      font-weight: 900;
      line-height: 150%; /* 22.5px */
      letter-spacing: 0.45px;
      text-transform: uppercase;
      @media screen and (width < 100rem) {
        font-size: 12px;
      }
    }
    .primarily-alone {
      color: var(--grayscale-white, #fff);
      margin-top: 8px;
      /* Desktop/Headers/H4 - Styled */
      font-family: Zuume;
      font-size: 28px;
      font-style: normal;
      font-weight: 900;
      line-height: 90%; /* 38.7px */
      letter-spacing: 1.72px;
      @media screen and (width < 100rem) {
        font-size: 1.3125rem;
      }
    }
    .always-meal {
      color: var(--grayscale-white, #fff);
      margin-top: 8px;
      /* Desktop/Headers/H4 - Styled */
      font-family: Zuume;
      font-size: 26px;
      font-style: normal;
      font-weight: 900;
      line-height: 90%; /* 38.7px */
      letter-spacing: 1.72px;
    }
  }
}
