@use "theme";

.data-table-container {
  display: flex;
  width: 100%;
  flex-direction: column;
  align-items: center;
  //gap: 1.25rem;
  gap: 1rem;

  border-radius: 1rem;
  background: var(--grayscale-800, theme.$grey-800);

  &.consumption {
    border-radius: 0;
    background: transparent;
  }

  .data-table-body {
    .data-table-row {
      .data-table-cell {
        color: var(--Grayscale-White, #fff);
        font-family: Zuume-Bold;
        font-size: 20px;
        font-style: normal;
        line-height: 100%;
        letter-spacing: 1px;
        border: none;
        // text-transform: uppercase;
        // @media screen and (width < 100rem) {
        //   font-size: 16px;
        // }

        // background-size: 50%;
        // background-position: center;

        div:nth-child(1) {
          text-align: left;
        }
        div:nth-child(2) {
          text-align: right;
        }
        // div:nth-child(3) {
        //     text-align: center;
        // }
        div:nth-last-child(1) {
          text-align: left;
        }

        &.small {
          /* Desktop/Data/Data Label */
          // width: 55%;
          /* Desktop/Data/Data Label */
          font-family: Inter;
          font-size: 15px;
          line-height: 100%; /* 15px */
          letter-spacing: 0.5px;
          text-align: center;
          @media screen and (width < 100rem) {
            font-size: 12px;
          }
        }

        &.non-expandable {
          font-size: 20px;
          line-height: 100%; /* 20px */
          letter-spacing: 1px;
          @media screen and (width < 100rem) {
            font-size: 16px;
          }
        }

        .table-cell-text {
          // height: 2rem;
          // width: 3.375rem;
          border-radius: 0.5rem;
          color: var(--Grayscale-White, #fff);
          font-family: Inter-Bold;
          font-size: 15px;
          font-style: normal;
          line-height: 100%; /* 15px */
          letter-spacing: 0.5px;

          &.small {
            // width: 55%;
            /* Desktop/Data/Data Label */
            font-family: Inter-Bold;
            font-size: 15px;
            line-height: 100%; /* 15px */
            letter-spacing: 0.5px;
            // text-align: center;
            @media screen and (width < 100rem) {
              font-size: 12px;
            }
          }
          &.medium {
            color: var(--Grayscale-White, #FFF);
            text-align: right;
            
            /* Desktop/Data/Data Point Medium */
            font-family: Zuume-Bold;
            font-size: 29px;
            font-style: normal;
            line-height: normal;
            letter-spacing: 0.87px;
          }
          &.text-center {
            color: var(--Grayscale-White, #FFF);
            text-align: center;

            /* Desktop/Data/Data Point Medium */
            font-family: Zuume-Bold;
            font-size: 29px;
            font-style: normal;
            line-height: normal;
            letter-spacing: 0.87px;
            display: flex;
            padding: 1px 3px;
            justify-content: center;
            align-items: center;
            gap: 10px;
          }
          &.positive {
            background-color: theme.$positive;
          }
          &.neutral {
            background-color: theme.$neutral;
          }
          &.negative {
            background-color: theme.$negative;
          }

          &.non-expandable {
            font-size: 20px;
            letter-spacing: 0.6px;
            @media screen and (width < 100rem) {
              font-size: 16px;
            }
          }
        }
      }
    }

    .data-unavailable-info-text {
      color: var(--grayscale-white, theme.$white);
      font-family: Inter;
      font-size: 15px;
      font-style: normal;
      font-weight: 700;
      align-self: stretch;
      text-align: center;
      @media screen and (width < 100rem) {
        font-size: 12px;
      }
    }
  }

  .data-table-pagination {
    display: flex;
    justify-content: center;
    align-items: center;

    &.hideExpand {
      visibility: hidden;
    }

    &.left {
      justify-content: flex-start;
    }
    &.right {
      justify-content: flex-end;
    }

    .label {
      font-family: Inter;
      font-style: normal;
      font-weight: 700;
      line-height: 130%; /* 20.8px */
    }

    .icon {
      color: var(--grayscale-white, theme.$white);
    }
  }
}
