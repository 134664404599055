.container {
  display: flex;
  width: 100%;
  max-width: 1224px;
  text-transform: uppercase;
  flex-wrap: wrap;
  font: 700 24px Barlow, sans-serif;
}

.barSection {
  display: flex;
  flex-direction: column;
  min-width: 300px;
}

.barNarrow {
  width: 395px;
}

.barWide {
  margin-left: 20px;
  width: 805px;
}

.sectionBar {
  border-radius: 50px;
  background-color: rgba(245, 223, 66, 1);
  min-height: 40px;
  color: #000;
  text-align: center;
  padding: 8px 40px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.scale {
  display: flex;
  margin-top: 10px;
  width: 100%;
  flex-direction: column;
  align-items: center;
  color: #fff;
}

.text {
  display: flex;
  width: 100%;
  justify-content: space-between;
  padding: 0 20px;
}

.scaleImage {
  width: 100%;
  object-fit: contain;
}

@media (max-width: 991px) {
  .container {
    margin-top: 40px;
  }

  .sectionBar {
    padding: 8px 20px;
  }
}