@use "../theme";

.smallquote-attribution-card-container {
    display: flex;
    width: 100%;
    height: 100%;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    flex: 1 0 0;
    align-self: stretch;

    // This classname sets a unified yellow background
    &.demandspace {
        background: theme.$ab-yellow;
    }

    //Personas
    &.Fram_PSGMT_2 {
        background: theme.$grey-800;
    }

    &.Fram_PSGMT_3 {
        background: theme.$grey-800;
    }

    &.Fram_PSGMT_5 {
        background: theme.$grey-800;
    }

    &.Fram_PSGMT_6 {
        background: theme.$grey-800;
    }

    &.Fram_PSGMT_8 {
        background: theme.$grey-800;
    }

    &.Fram_PSGMT_9 {
        background: theme.$grey-800;
    }

    &.Fram_PSGMT_11 {
        background: theme.$grey-800;
    }

    &.Fram_PSGMT_12 {
        background: theme.$grey-800;
    }

    //Demand spaces - below classes set the demand space specific colors to the quote icon
    // Demand spaces have linear gradients that don't set for svgs
    // Setting the first color from the gradient
    &.Fram_DS_5 {
        //"Casual Good Times"
        background: #8fe8bd;
    }

    &.Fram_DS_1 {
        //"Easygoing Hangout"
        background: #ada1f8;
    }

    &.Fram_DS_3 {
        //"Energetic Night Out"
        background: #83bcff; //#83bcff;
    }

    &.Fram_DS_4 {
        //"Upbeat Social Meal"
        background: #83bcff;//#83bcff; 
    }

    &.Fram_DS_9 {
        //"Refined Weekend Out"
        background: #ffc759;
    }

    &.Fram_DS_7 {
        //"Lively Social Gathering"
        background: #a1e8f8;
    }

    &.Fram_DS_10 {
        //"Thoughtful Connection"
        background: #ffc759;
    }

    &.Fram_DS_2 {
        //"Routine Living"
        background: #ada1f8;
    }

    &.Fram_DS_12 {
        //"Intimate Dining"
        background: #ffc759;
    }

    &.Fram_DS_13 {
        //"Everyday Meal"
        background: #83bcff;
    }

    &.Fram_DS_6 {
        //"Simple Unplug"
        background: #8fe8bd;
    }

    &.Fram_DS_8 {
        //"Intentional Unwind"
        background: #a1e8f8;
    }

    &.Fram_DS_11 {
        //"Sensible Slowdown"
        background: #ffc759;
    }

    .smallquote-attribution-card-content-container {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        gap: var(--Spacing-S, 1.25rem);
        margin: var(--Spacing-S, 1.25rem) var(--Spacing-M, 2.5rem);
        height: 100%;

        .quote-icon {
            width: 35px;
            height: 30.1px;
            fill: #fff;

            &.occasions {
                fill: theme.$ab-yellow;
            }

            &.demandspace {
                fill: theme.$black;
            }

            &.Fram_PSGMT_2 {
                fill: theme.$comfort-craver;
            }

            &.Fram_PSGMT_3 {
                fill: theme.$loyal-traditionalist;
            }

            &.Fram_PSGMT_5 {
                fill: theme.$balanced-enjoyers;
            }

            &.Fram_PSGMT_6 {
                fill: theme.$diligent-discoverer;
            }

            &.Fram_PSGMT_8 {
                fill: theme.$carefree-fun-lover;
            }

            &.Fram_PSGMT_9 {
                fill: theme.$proud-celebrator;
            }

            &.Fram_PSGMT_11 {
                fill: theme.$creative-explorer;
            }

            &.Fram_PSGMT_12 {
                fill: theme.$social-curator;
            }
        }

        .smallquote-card-text {
            display: flex;
            align-self: stretch;
            text-align: left;
            color: var(--Grayscale-800, #1d1d1d);
            font-family: Inter-SemiBold;
            font-size: 20px;
            font-style: italic;
            line-height: 130%;

            /* 20.8px */
            @media screen and (width < 100rem) {
                font-size: 15px;
            }

            &.occasions {
                color: theme.$white;
            }

            &.demandspace {
                color: var(--Grayscale-800, #1d1d1d);
                font-size: 20px;
                @media screen and (width < 100rem) {
                    font-size: 15px;
                }
            }

            &.Fram_PSGMT_2 {
                color: theme.$comfort-craver;
            }

            &.Fram_PSGMT_3 {
                color: theme.$loyal-traditionalist;
            }

            &.Fram_PSGMT_5 {
                color: theme.$balanced-enjoyers;
            }

            &.Fram_PSGMT_6 {
                color: theme.$diligent-discoverer;
            }

            &.Fram_PSGMT_8 {
                color: theme.$carefree-fun-lover;
            }

            &.Fram_PSGMT_9 {
                color: theme.$proud-celebrator;
            }

            &.Fram_PSGMT_11 {
                color: theme.$creative-explorer;
            }

            &.Fram_PSGMT_12 {
                color: theme.$social-curator;
            }
        }

        .card-attribution-container {
            display: flex;
            flex-direction: column;
            align-items: center;
            gap: 0.5rem;
            white-space: nowrap;

            .attribution-name {
                color: var(--Grayscale-Black, #000);
                font-family: Zuume-Bold;
                font-size: 20px;
                font-style: normal;
                line-height: 100%;
                /* 20px */
                letter-spacing: 1px;
                text-transform: uppercase;

                @media screen and (width < 100rem) {
                    font-size: 16px;
                }

                &.occasions {
                    color: theme.$white;
                    font-family: Zuume;
                    font-size: 43px;
                    font-style: normal;
                    font-weight: 900;
                    line-height: 90%;
                    /* 38.7px */
                    letter-spacing: 1.72px;

                    @media screen and (width < 100rem) {
                        font-size: 30px;
                    }
                }
            }

            .attribution-micro {
                color: var(--Grayscale-Black, #000);
                font-family: Zuume;
                font-size: 20px;
                font-style: normal;
                font-weight: 700;
                line-height: 100%;
                /* 20px */
                letter-spacing: 1px;
                text-transform: uppercase;

                @media screen and (width < 100rem) {
                    font-size: 16px;
                }

                &.occasions {
                    color: theme.$white;
                }
            }

            .attribution-items {
                display: flex;
                align-items: flex-start;
                align-self: flex-start;
                gap: 0.5rem;

                .attribution {
                    color: var(--Grayscale-Black, #000);
                    font-family: Zuume-Bold;
                    font-size: 20px;
                    font-style: normal;
                    line-height: 100%;
                    /* 20px */
                    letter-spacing: 1px;
                    text-transform: uppercase;

                    @media screen and (width < 100rem) {
                        font-size: 16px;
                    }

                    &.occasions {
                        color: theme.$white;
                    }

                    &.Fram_PSGMT_2 {
                        color: theme.$comfort-craver;
                    }

                    &.Fram_PSGMT_3 {
                        color: theme.$loyal-traditionalist;
                    }

                    &.Fram_PSGMT_5 {
                        color: theme.$balanced-enjoyers;
                    }

                    &.Fram_PSGMT_6 {
                        color: theme.$diligent-discoverer;
                    }

                    &.Fram_PSGMT_8 {
                        color: theme.$carefree-fun-lover;
                    }

                    &.Fram_PSGMT_9 {
                        color: theme.$proud-celebrator;
                    }

                    &.Fram_PSGMT_11 {
                        color: theme.$creative-explorer;
                    }

                    &.Fram_PSGMT_12 {
                        color: theme.$social-curator;
                    }
                }

                .attribution-divider {
                    width: 1px;
                    height: 100%;
                    opacity: 0.52;
                    background: #fff;
                }
            }
        }
    }
}

.smallquote-attribution-card-container.demandspace.Fram_DS_12:has(> .smallquote-attribution-card-content-container > .card-attribution-container > .attribution-items > .creativeexplorer) {
    background: #83bcff;
}
.smallquote-attribution-card-container.demandspace.Fram_DS_3:has(> .smallquote-attribution-card-content-container > .card-attribution-container > .attribution-items > .proudcelebrator) {
    background: #8fe8bd;
}