@use "../theme";

.bigquotevideoright-card-container {
    display: flex;
    height: 100%;
    width: 100%;
    align-items: flex-start;
    justify-content: space-between;
    gap: 10px;
    flex: 1 0 0;
    // border-radius: 0;

    .card-content-container {
        height: 100%;
        .smallquote-attribution-card-container {
            border-radius: 1rem 0 0 1rem;
        }

        .video-card-container {
            .video-visual {
                border-radius: 0 1rem 1rem 0;
                width: 100%;
                height: 100%;
            }
        }
    }
}
