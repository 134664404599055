@use "theme";

.download-resource-container{
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
    .download-resource-left-items-container {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        gap: 3.125rem;
        flex: 1 0 0;

        .download-resource-heading{
            margin-top: 0.5rem;
            color: var(--grayscale-white, theme.$white);
            font-family: Zuume-Black;
            font-size: 8rem;
            font-style: normal;
            line-height: 80%;
            text-transform: uppercase;
            @media screen and (width < 100rem){
                font-size: 6rem;
            }
        }

        .download-all-button{
            display: flex;
            flex-direction: row;
            justify-content: center;
            align-items: center;
            padding: 10px 24px 10px 16px;
            border-radius: 6.25rem;
            // background: var(--Actions, theme.$ab-yellow);
            gap: 0.18rem;
            color: var(--grayscale-black, theme.$black);
            text-align: center;
            font-family: Inter-Bold;
            font-size: 15px;
            font-style: normal;
            line-height: 130%;
            letter-spacing: -0.3px;
            text-transform: none;
            @media screen and (width < 100rem) {
                font-size: 12px;
             }
        }
    }

    .download-resource-list{
        width: 100%;
        .list-item{
            border-top: 1px solid rgba($color: theme.$white, $alpha: 0.26);
            &:last-child{
                border-bottom: 1px solid rgba($color: theme.$white, $alpha: 0.26);
            }
            .text{
                color: theme.$white;
                text-align: left;
                .primary {
                    /* Desktop/Headers/H5 */
                    font-family: Inter-Bold;
                    font-size: 28px;
                    font-style: normal;
                    line-height: 130%; /* 36.4px */
                    @media screen and (width < 100rem){
                        font-size: 1.3125rem;
                    }
                }
                .secondary {
                    font-size: 16px;
                    font-family: Inter-Regular;
                    font-style: normal;
                    font-weight: 400;
                    line-height: 130%; /* 20.8px */
                    @media screen and (width < 100rem){
                        font-size: 13px;
                    }
                }
            }
            .icon{
                color: theme.$ab-yellow;
                display: flex;
                justify-content: flex-end;
            }
        }
    }
}