@use "../theme";

.statementquoteimage-card-container {
    display: flex;
    height: 100%;
    width: 100%;
    align-items: flex-start;
    justify-content: space-between;
    gap: 10px;
    flex: 1 0 0;
    // border-radius: 0;

    .card-content-container {
        height: 100%;

        .statementcard-container{
            border-radius: 1rem 0 0 1rem;
        }

        .image-card-container {
            .image-card-visual {
                height: 100%;
                width: 100%;
                border-radius: 0 1rem 0 0;
            }
        }

        .smallquote-attribution-card-container {
            border-radius: 0 0 1rem 0;
            &.Fram_PSGMT_2 {
                background: theme.$comfort-craver;
            }
            &.Fram_PSGMT_3 {
                background: theme.$loyal-traditionalist;
            }
            &.Fram_PSGMT_5 {
                background: theme.$balanced-enjoyers;
            }
            &.Fram_PSGMT_6 {
                background: theme.$diligent-discoverer;
            }
            &.Fram_PSGMT_8 {
                background: theme.$carefree-fun-lover;
            }
            &.Fram_PSGMT_9 {
                background: theme.$proud-celebrator;
            }
            &.Fram_PSGMT_11 {
                background: theme.$creative-explorer;
            }
            &.Fram_PSGMT_12 {
                background: theme.$social-curator;
            }

            .smallquote-attribution-card-content-container {
                .quote-icon {
                    fill: var(--Grayscale-800, #1d1d1d);
                }
                .smallquote-card-text {
                    color: var(--Grayscale-800, #1d1d1d);
                }
                .card-attribution-container {
                    .attribution-items {
                        .attribution {
                            color: var(--Grayscale-800, #1d1d1d);
                        }
                        .attribution-divider {
                            background: #1d1d1d;
                        }
        
                    }
                }
            }
        }
    }
}
