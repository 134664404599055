@use "theme.scss";

.main-content {
  &.help-page {
    background: linear-gradient(
        90deg,
        rgb(121, 210, 113) 4.05%,
        rgb(143, 232, 189) 16.91%,
        rgb(161, 232, 248) 29.77%,
        rgb(131, 188, 255) 42.63%,
        rgb(173, 161, 248) 55.5%,
        rgb(185, 131, 255) 68.36%,
        rgb(243, 178, 214) 81.22%,
        rgb(255, 140, 140) 94.09%
      ),
      linear-gradient(180deg, rgb(66, 66, 66) 5%, rgba(0, 0, 0, 1) 95%);
    background-size: 100% 9.3%;
    background-repeat: no-repeat;
    background-blend-mode: multiply;
    // gap: 4rem;
  }
  .help-content {
    display: flex;
    margin: 4.375rem 0;
    flex-direction: column;
    align-items: flex-start;
    gap: 3.75rem;

    .content-header {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      gap: 0.75rem;
      align-self: stretch;

      .page-heading {
        font-family: Inter-Black;
        font-size: 15px;
        font-style: normal;
        line-height: 150%;
        letter-spacing: 0.45px;
        text-transform: uppercase;
        @media screen and (width < 100rem) {
          font-size: 12px;
        }
      }
      .page-title {
        font-family: Zuume-Black;
        font-size: 8rem;
        font-style: normal;
        line-height: 80%;
        text-transform: uppercase;
        align-self: stretch;
        color: #ffffff;
        @media screen and (width < 100rem) {
          font-size: 6rem;
        }
      }
    }

    .user-guide-container {
      display: flex;
      flex-direction: row;
      align-items: flex-start;
      flex: 1 0 0;

      .left-items {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        gap: var(--spacing-s, 10px);
        position: sticky;
        max-height: 70rem;
        top: 0;
        bottom: 0;
        // overflow: hidden;

        .popup-cta-button {
          display: flex;
          padding: 0.625rem 0.75rem;
          align-items: center;
          justify-content: flex-start;
          align-self: stretch;
          border-radius: 0.5rem;
          border: 2px solid var(--Global-Colors-AB-Light-Gold, theme.$ab-yellow);
          background: var(--Grayscale-700, theme.$grey-700);

          font-family: Inter-Bold;
          font-size: 16px;
          font-style: normal;
          line-height: 130%; 
          text-transform: capitalize;

          &:hover {
            background: theme.$ab-yellow;
            color: theme.$black;
          }
        }

        .item {
          background: var(--grayscale-700, theme.$grey-700);
          background-color: theme.$grey-700;
          width: 100%;
          border-radius: var(--radius-s, 8px);

          .accordion-summary {
            color: var(--grayscale-white, theme.$white);
            font-family: Inter-Bold;
            font-size: 16px;
            font-style: normal;
            line-height: 130%;
            @media screen and (width < 100rem) {
              font-size: 13px;
            }
          }
          .accordion-details {
            background-color: theme.$black;
            display: flex;
            flex-direction: column;
            align-items: flex-start;
            gap: 1rem;
            flex: 1 0 0;
            border-left: 2px solid theme.$grey-800;

            .accordion-question {
              color: var(--grayscale-200, theme.$grey-200);
              font-family: Inter-Regular;
              font-size: 16px;
              font-style: normal;
              font-weight: 400;
              line-height: 130%;
              @media screen and (width < 100rem) {
                font-size: 13px;
              }

              &:hover {
                background-color: theme.$grey-700;
                border-radius: 4px;
              }
            }
          }
        }
      }

      .right-items {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        gap: 7rem;
        align-self: stretch;

        .right-items-div {
          display: flex;
          flex-direction: column;
          align-items: flex-start;
          gap: 3rem;
          align-self: stretch;

          .right-items-title {
            align-self: stretch;
            color: theme.$ab-yellow;
            font-family: Zuume-Black;
            font-size: 2.6875rem;
            font-style: normal;
            line-height: 90%;
            letter-spacing: 1.72px;
          }
          .right-items-box {
            display: flex;
            flex-direction: column;
            align-items: flex-start;
            gap: 1rem;
            align-self: stretch;
            padding-bottom: 2rem;
            border-bottom: 1px solid rgba($color: theme.$white, $alpha: 0.26);
          }
          .right-items-question {
            align-self: stretch;
            color: var(--grayscale-white, theme.$white);
            font-family: Inter-Bold;
            font-size: 1.2rem;
            font-style: normal;
            line-height: 130%;
          }
          .right-items-answer {
            align-self: stretch;
            color: var(--grayscale-white, theme.$white);
            font-family: Inter-Regular;
            font-size: 1rem;
            font-style: normal;
            font-weight: 100;
            line-height: 130%;
            @media screen and (width < 100rem) {
              font-size: 0.8125rem;
            }
          }
          .answer-section {
            display: flex;
            flex-direction: column;
            align-items: flex-start;
            gap: var(--spacing-m, 2.5rem);
            margin-bottom: var(--Spacing-XS, 12px);
            align-self: stretch;

            .answer {
              color: var(--grayscale-white, theme.$white);
              font-family: Inter-Regular;
              font-size: 20px;
              font-style: normal;
              font-weight: 400;
              line-height: 130%;
              @media screen and (width < 100rem) {
                font-size: 16px;
              }

              a {
                color: white;
                font-family: Inter-Regular;
                font-size: 20px;
                font-style: normal;
                font-weight: 400;
                line-height: 130%;
                @media screen and (width < 100rem) {
                  font-size: 16px;
                }
              }

              strong {
                font-family: Inter-Bold;
                display: contents;
              }

              i {
                color: theme.$ab-yellow;
                font-style: normal;
                display: contents;
              }

              .underlined {
                text-decoration: underline;
                color: var(--grayscale-white, theme.$white);
              }

              .danger {
                text-transform: uppercase;
                color: theme.$negative;
              }
            }
            .answer-visual {
              display: flex;
              align-items: center;
              flex-direction: row;
              border-radius: var(--radius-m, 1rem);
              background-size: contain;
              background-position: center;
              background-repeat: no-repeat;
              height: auto;
              max-height: 11rem;
              max-width: 28.75rem;
              margin: 0 20%;

              &.section2-qna9 {
                width: 100%;
                max-width: none;
                margin: 0px;
              }
            }
          }
        }
      }
    }
  }
}
