.container {
  display: flex;
  flex-direction: column;
  justify-content: start;
  width: 1224px;
  margin: 0 auto;
}

.header {
  font-family: Barlow, sans-serif;
  font-weight: 700;
  text-transform: uppercase;
}

.subheading {
  color: #f6e003;
  font-size: 24px;
}

.heading {
  color: #fff;
  font-size: 48px;
  line-height: 1;
  margin-top: 10px;
}

.statsGrid {
  display: flex;
  margin-top: 30px;
  gap: 40px;
  text-align: center;
}

@media (max-width: 991px) {
  .header {
    max-width: 100%;
  }

  .heading {
    font-size: 40px;
  }

  .statsGrid {
    max-width: 100%;
  }
}
