.container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin: 40px auto;
  width: 1224px;
}

.header {
  align-self: flex-start;
  display: flex;
  min-height: 104px;
  flex-direction: column;
  align-items: flex-start;
  color: #fff;
}

.title {
  font: 700 48px Barlow, sans-serif;
  text-transform: uppercase;
}

.subtitle {
  font: 600 24px/1 Work Sans, -apple-system, Roboto, Helvetica, sans-serif;
  margin: -25px 0 0;
}

.testimonialGrid {
  display: flex;
  margin-top: 32px;
  max-width: 100%;
  width: 1224px;
  align-items: center;
  justify-content: space-between;
  flex-wrap: wrap;
}

@media (max-width: 991px) {
  .header {
    max-width: 100%;
  }
  
  .title {
    font-size: 40px;
  }
  
  .subtitle {
    max-width: 100%;
  }
}
