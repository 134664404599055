@use "theme";

.explore-section-container{
    justify-content: flex-start;
    align-items: center;

    .explore-section-heading{
        color: var(--grayscale-white, theme.$white);
        /* Desktop/Headers/H2 */
        font-family: Zuume;
        font-style: normal;
        font-weight: 900;
        line-height: 80%; /* 102.4px */
        text-transform: uppercase;
    }

    .explore-section-list{
        .list-item{
            border-top: 1px solid rgba($color: theme.$white, $alpha: 0.26);
            &:last-child{
                border-bottom: 1px solid rgba($color: theme.$white, $alpha: 0.26);
            }
            .text{
                color: theme.$white;
                text-align: left;
                font-family: Inter;
                font-style: normal;
                line-height: 130%;
            }
            .icon{
                color: theme.$ab-yellow;
            }
        }
    }
}