
/* index.css */

/* Default font size for larger screens */
body {
  font-size: 16px;
  margin: 0;
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  overflow-x: hidden;
  font-family: 'Zuume', 'Inter', sans-serif;
  -ms-overflow-style: none;  /* IE and Edge */
  overflow: -moz-scrollbars-none;
}
body::-webkit-scrollbar {
  display: none;
}

/* Adjust font size for medium-sized screens */
@media only screen and (max-width: 1200px) {
  body {
    font-size: 14px;
    margin: 0;
    font-family: 'Zuume', 'Inter', sans-serif;
  }
}

/* Adjust font size for smaller screens */
@media only screen and (max-width: 600px) {
  body {
    font-size: 12px;
    margin: 0;
    font-family: 'Zuume', 'Inter', sans-serif;
  }
}



