@use "theme";

.main-content {
  .anchor-nav-bar {
    display: flex;
    //min-height: 40px;
    height: theme.$anchor-nav-height;
    padding: 0px var(--spacing-none, 0px);
    align-items: flex-start;
    justify-content: center;
    border-bottom: 1px solid var(--grayscale-800, theme.$grey-800);
    background: var(--Actions, theme.$ab-yellow);
    z-index: 100;
    top: theme.$main-nav-height;

    //Personas
    &.Fram_PSGMT_2 {
      background: var(--Actions, theme.$comfort-craver);
    }
    &.Fram_PSGMT_3 {
      background: var(--Actions, theme.$loyal-traditionalist);
    }
    &.Fram_PSGMT_5 {
      background: var(--Actions, theme.$balanced-enjoyers);
    }
    &.Fram_PSGMT_6 {
      background: var(--Actions, theme.$diligent-discoverer);
    }
    &.Fram_PSGMT_8 {
      background: var(--Actions, theme.$carefree-fun-lover);
    }
    &.Fram_PSGMT_9 {
      background: var(--Actions, theme.$proud-celebrator);
    }
    &.Fram_PSGMT_11 {
      background: var(--Actions, theme.$creative-explorer);
    }
    &.Fram_PSGMT_12 {
      background: var(--Actions, theme.$social-curator);
    }

    //Demand spaces
    &.Fram_DS_5 { //"Casual Good Times"
      background: theme.$casual-good-times;
    }
    &.Fram_DS_1 { //"Easygoing Hangout"
      background: theme.$easygoing-hangout;
    }
    &.Fram_DS_3 { //"Energetic Night Out"
      background: theme.$energetic-night-out;
    }
    &.Fram_DS_4 { //"Upbeat Social Meal"
      background: theme.$upbeat-social-meal;
    }
    &.Fram_DS_9 { //"Refined Weekend Out"
      background: theme.$refined-weekend-out;
    }
    &.Fram_DS_7 { //"Lively Social Gathering"
      background: theme.$lively-social-gathering;
    }
    &.Fram_DS_10 { //"Thoughtful Connection"
      background: theme.$thoughtful-connection;
    }
    &.Fram_DS_2 { //"Routine Living"
      background: theme.$routine-living;
    }
    &.Fram_DS_12 { //"Intimate Dining"
      background: theme.$intimate-dining;
    }
    &.Fram_DS_13 { //"Everyday Meal"
      background: theme.$everyday-meal;
    }
    &.Fram_DS_6 { //"Simple Unplug"
      background: theme.$simple-unplug;
    }
    &.Fram_DS_8 { //"Intentional Unwind"
      background: theme.$intentional-unwind;
    }
    &.Fram_DS_11 { //"Sensible Slowdown"
      background: theme.$sensible-slowdown;
    }

    .nav-bar-container {
      //margin-left: 6.25rem;
      //   margin: 0 0 0 6.25rem;
      display: flex;
      align-self: stretch;
      //max-height: 2.5rem;
      min-height: theme.$anchor-nav-height;
      height: theme.$anchor-nav-height;

      .nav-bar-grid-container {
        // display: flex;
        //flex-direction: column;
        //align-self: stretch;
        align-items: flex-start;
        justify-content: center;

        .navbar-left-grid {
          margin-left: 6.25rem;
          .navbar-info-container {
            display: flex;
            //margin: 0.5rem var(--spacing-s, 1.25rem) 0.5rem var(--spacing-s, 1.25rem);
            padding: 0.5rem 0;
            justify-content: flex-start;
            align-items: center;
            gap: var(--spacing-xxs, 4px);
            align-self: stretch;

            .text {
              color: var(--grayscale-black, theme.$black);
              font-family: Inter-Bold;
              font-size: 14px;
              font-style: normal;
              line-height: 130%; /* 18.2px */
              @media screen and (width < 100rem) {
                font-size: 12px;
             }

              &.category {
                opacity: 0.5;
              }
            }
          }
        }

        .navbar-right-grid {
          // height: 100%;
          display: flex;
          align-items: center;
          justify-content: flex-start;
          flex: 1;
          align-self: stretch;
          background: rgba(0, 0, 0, 0.12);

          .navbar-items-container-background {
            //background: rgba(0, 0, 0, 0.12);
            display: flex;
            align-self: stretch;
            flex: 1 0 0;
            //margin-right: 6.25rem;
            gap: 0.5rem;

            // .navbar-items-container {
            //   .navbar-items {
            //     color: var(--grayscale-black, theme.$black);
            //     font-family: Inter;
            //     font-size: 14px;
            //     font-style: normal;
            //     font-weight: 700;
            //     line-height: 130%; /* 18.2px */
            //     text-transform: capitalize;
            //     height: 100%;
            //     //min-width: 8rem;
            //     background: transparent;
            //     border-radius: 0;
            //     transition: none;

            //     display: flex;
            //     padding: 0.5rem var(--Spacing-S, 1.25rem);
            //     justify-content: center;
            //     align-items: center;
            //     gap: 0.5rem;
            //     align-self: stretch;

            //     @media screen and (width < 100rem) {
            //       font-size: 12px;
            //    }

            //     &:hover {
            //       border-bottom: 3px solid theme.$black;
            //     }

            //   }
            // }
            .navbar-items {
              color: var(--grayscale-black, theme.$black);
              font-family: Inter-Bold;
              font-size: 14px;
              font-style: normal;
              line-height: 130%; /* 18.2px */
              text-transform: capitalize;
              height: 100%;
              //min-width: 8rem;
              background: transparent;
              border-radius: 0;
              transition: none;

              display: flex;
              //padding: 0.5rem var(--Spacing-S, 1.25rem);
              justify-content: center;
              align-items: center;
              gap: 0.5rem;
              align-self: stretch;

              @media screen and (width < 100rem) {
                font-size: 12px;
             }

              &:hover {
                border-bottom: 3px solid theme.$black;
              }

            }
          }
        }
      }
    }
  }
}
