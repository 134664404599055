.segmentGradientWrap {
  position: relative;
}

.segmentGradient {
  background: linear-gradient(
    176deg,
    rgba(241, 185, 26, 0.425) -3%,
    rgba(0, 0, 0, 0) 50%
  );
  position: absolute;
  top: 125px;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 0;
}

.segmentCardTop {
  position: relative;
  height: auto;
  justify-content: center;
  align-items: center;
  border-radius: 16px;
  background: #141414;
  box-shadow: 0 0 4px 0 rgba(0, 0, 0, 0.16);
  display: flex;
  flex-direction: column;
  padding: 50px 0;
  margin: 0 auto;
  width: 1340px;
  z-index: 1;
}

.content {
  display: flex;
  width: 1200px;
  max-width: 100%;
  align-items: center;
  justify-content: space-between;
}

.copy {
  display: flex;
  flex-direction: column;
  align-items: start;
  justify-content: space-between;
  width: 500px;
  margin: auto 0;
}

.copyInner {
  display: flex;
  width: 413px;
  max-width: 100%;
  flex-direction: column;
}

.title {
  color: #fff;
  text-align: center;
  align-self: start;
  font: 600 36px/1 Work Sans, -apple-system, Roboto, Helvetica, sans-serif;
}

.segmentCount {
  color: #ffe800;
  text-transform: uppercase;
  margin-top: -20px;
  font: 800 75px/68px Barlow, -apple-system, Roboto, Helvetica, sans-serif;
}

.info {
  display: flex;
  min-height: 48px;
  width: 335px;
  max-width: 100%;
  align-items: center;
  gap: 8px;
  color: #fff;
  justify-content: center;
  padding: 10px;
  font: 500 16px/1 Work Sans, -apple-system, Roboto, Helvetica, sans-serif;
}

.infoIcon {
  aspect-ratio: 1;
  object-fit: contain;
  object-position: center;
  width: 24px;
  margin: auto 0;
}

.segmentCard {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: auto;
  width: 130px;
  margin: auto 0;
}

.segmentCard:hover {
  cursor: pointer;
  transform: scale(1.05);
  transition: transform 0.3s ease-in-out;
}

.segmentCardButton {
  border: none;
  background: none;
}

.segmentCards {
  align-self: stretch;
  display: flex;
  min-width: 240px;
  align-items: center;
  gap: 22px 0;
  justify-content: space-between;
  flex-wrap: wrap;
  width: 735px;
  margin: auto 0;
}

@media (max-width: 991px) {
  .segmentCardTop {
    padding: 0 20px;
  }
  
  .copy {
    max-width: 100%;
  }
  
  .segmentCards {
    max-width: 100%;
  }
  
  .segmentCount {
    font-size: 40px;
    line-height: 40px;
  }
}
