.donutChart {
  display: flex;
  align-items: center;
  gap: 40px;
}

.chartImage {
  width: 250px;
}

.legend {
  display: flex;
  height: 190px;
  flex-direction: column;
  justify-content: space-between;
  width: 250px;
  margin-top: 30px;
}

@media (max-width: 991px) {
  .donutChart {
    max-width: 100%;
  }
}
